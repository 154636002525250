<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 186 124">
    <path
      d="M92.232 22.7529L31.5825 52.5748L62.472 70.3152L120.76 36.8728L92.232 22.7529Z"
      fill="#C8C0D8" />
    <path
      d="M92.2351 53.2222L32.4033 87.6603L93.6266 122.446L154.154 87.6603L92.2351 53.2222Z"
      fill="#393151" />
    <path
      d="M65.1021 6.26123L4.92236 32.399L31.6375 52.7509L91.8172 22.5803L65.1021 6.26123Z"
      fill="#FAF8FF" />
    <path
      d="M31.5825 88.1686V52.5747L93.3615 88.1686V123.904L31.5825 88.1686Z"
      fill="#E3DDED" />
    <path
      d="M91.8872 52.9866V22.2627L155.058 51.5951V87.3305L91.8872 52.9866Z"
      fill="#E3DDED" />
    <path
      d="M155.14 52.4336V88.169L93.3613 123.904V88.169L155.14 52.4336Z"
      fill="#C8C0D8" />
    <path
      d="M119.397 106.068L151.891 86.3789L151.891 70.9092L119.397 90.5982L119.397 106.068Z"
      fill="white" />
    <path
      d="M149.625 77.6424L137.571 85.1862V85.8029L149.625 78.2591V77.6424Z"
      fill="#393151" />
    <path
      d="M149.625 79.7894L137.571 87.333L137.571 87.9499L149.625 80.4061V79.7894Z"
      fill="#393151" />
    <path
      d="M149.625 81.9364L137.571 89.4798L137.571 90.0969L149.625 82.5531V81.9364Z"
      fill="#393151" />
    <path
      d="M137.572 91.6247L137.572 92.2432L142.507 89.117V88.4984L137.572 91.6247Z"
      fill="#393151" />
    <path
      d="M149.625 75.337L137.571 82.8808V83.4975L149.625 75.9537V75.337Z"
      fill="#393151" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.786 95.7339L130.911 96.2855C130.722 93.0133 130.661 89.8315 130.713 86.7508L131.588 86.1992C131.536 89.2799 131.597 92.4617 131.786 95.7339ZM130.061 87.162L129.41 87.5726C129.366 90.6492 129.435 93.8258 129.632 97.0917L130.276 96.6856C130.081 93.418 130.015 90.2398 130.061 87.162ZM128.768 87.9772L129.06 87.7933C129.008 90.874 129.068 94.0559 129.257 97.328L128.966 97.5119C128.776 94.2397 128.716 91.0579 128.768 87.9772ZM127.893 88.5288L127.608 88.7087C127.555 91.7895 127.616 94.9715 127.804 98.2438L128.091 98.0635C127.901 94.7914 127.841 91.6095 127.893 88.5288ZM126.967 89.1127L127.343 88.8758C127.287 91.958 127.345 95.1419 127.531 98.4165L127.139 98.6635C126.958 95.3849 126.906 92.1976 126.967 89.1127ZM126.356 89.4979L125.37 90.1196C125.301 93.2082 125.345 96.4002 125.519 99.6845L126.525 99.0507C126.345 95.7712 126.294 92.5833 126.356 89.4979ZM124.861 90.4401L125.074 90.3058C125.008 93.3932 125.054 96.5839 125.23 99.8666L125.009 100.006C124.836 96.7215 124.792 93.529 124.861 90.4401ZM132.282 95.421C132.075 92.1631 131.996 88.9932 132.028 85.9218L132.294 85.7542C132.265 88.8241 132.347 91.9922 132.557 95.2478L132.282 95.421ZM132.8 95.0948L133.193 94.8466C132.987 91.5877 132.91 88.4169 132.944 85.3448L132.538 85.6005C132.508 88.6706 132.59 91.8389 132.8 95.0948ZM133.675 94.5432C133.465 91.2873 133.383 88.119 133.413 85.0489L134.057 84.6428C134.036 87.7088 134.126 90.8719 134.343 94.1216L133.675 94.5432ZM124.497 100.329C124.318 97.0489 124.268 93.8605 124.33 90.7749L123.822 91.0954C123.759 94.1814 123.808 97.3701 123.987 100.65L124.497 100.329ZM123.765 100.79C123.589 97.5077 123.542 94.3172 123.609 91.2298L123.226 91.4707C123.174 94.5514 123.235 97.7333 123.424 101.005L123.765 100.79ZM122.987 101.281C122.797 98.0091 122.737 94.8272 122.789 91.7465L122.351 92.0223C122.299 95.103 122.36 98.2849 122.549 101.557L122.987 101.281Z"
      fill="#393151" />
    <path
      d="M128.476 88.161L128.33 88.253C128.278 91.3337 128.339 94.5155 128.528 97.7877L128.674 97.6957C128.485 94.4235 128.424 91.2417 128.476 88.161Z"
      fill="#393151" />
    <path
      d="M155.058 52.1787L93.2788 88.0555L124.168 98.8104L185.947 63.0751L155.058 52.1787Z"
      fill="#FAF8FF" />
    <path
      d="M114.846 0L92.2354 22.6109L155.546 51.8311L181.287 29.5681L114.846 0Z"
      fill="#FAF8FF" />
    <path
      d="M31.7079 52.8745L0.0527344 64.7017L63.3632 101.575L93.2791 88.0083L31.7079 52.8745Z"
      fill="#FAF8FF" />
  </svg>
</template>

<script>
  export default {
    name: 'IconOpenedBox',
  };
</script>
