import httpService from '@/services/http/http.service';
import httpHelper from '@/helpers/http/http.helper';
import { checkoutFromCartCheckoutLinkMapper } from '@/helpers/data-mapper/data-mapper.helper';
import spreedly from '@/plugins/spreedly';

const completeCheckout = async(transactionToken) => {
  const route = httpHelper.buildV1ApiRoute(httpHelper.CHECKOUT_ENDPOINT + '/' + 'complete');

  return httpService.post(route, { transactionToken });
};

const saveCheckoutLink = (checkout) => {
  const endpoints = [httpHelper.CHECKOUT_ENDPOINT, httpHelper.STANDALONE_ENDPOINT];
  const route = httpHelper.buildV1ApiRoute(endpoints.join('/'));

  // Add spreedly browser info for 3DS2 challenge check
  checkout.browser_info = spreedly.browserInfos();

  return httpService.post(route, checkout);
};

const saveCheckoutFromCartCheckoutLink = async(
  {
    contact, shippingId, coupons, cartId, paymentMethod, checkoutLinkId = null, dynamicProductIds = null,
    funnelId = null, orderId = null, creditCardId = null, creditCard = null, stripePaymentMethod = null,
    addressId = null, address = null, subscriptionsShipping = [],
    productIdsSubscriptionOnly = null, productIdsSubscriptionHybrid = null,
  },
  contactId,
) => {
  return saveCheckoutLink(
    checkoutFromCartCheckoutLinkMapper(
      contact, shippingId, coupons, checkoutLinkId, cartId, contactId, paymentMethod, dynamicProductIds, funnelId,
      orderId, creditCardId, creditCard, addressId, address, subscriptionsShipping, productIdsSubscriptionOnly, productIdsSubscriptionHybrid,
      stripePaymentMethod,
    ),
  );
};

const loadOrder = async(orderId, publicRequest = false) => {
  const endpoint = publicRequest ? httpHelper.ORDER_PUBLIC_ENDPOINT : httpHelper.ORDER_ENDPOINT;
  const route = httpHelper.buildV1ApiRoute(endpoint, [orderId]);

  return httpService.get(route, { trigger403Error: false });
};

export default {
  completeCheckout,
  saveCheckoutFromCartCheckoutLink,
  loadOrder,
};
