import isEqual from 'lodash/isEqual';

const mappedItem = (item) =>
  item.map(i => ({
    variantId: i.variantId,
    categories: i.categories || [],
    collection: i?.collection || '',
    collectionId: i?.collectionId || '',
    imageUrl: i.imageUrl,
    productId: i.productId,
    label: i.label,
    options: i.attributes.map(item => ({ key: item.name, value: item.value })),
    quantity: i.quantity,
    quantityPolicy: i.quantityPolicy,
    unitPrice: i.price,
    url: i.url,
    purchaseOption: i.purchaseOption,
    source: i.source,
    ...((!!i.customFieldsValues && { customFieldsValues: i.customFieldsValues }) || {}),
  }));

const mappedSubscriptionItem = (item) => item.map(i => ({
  productId: i.productId,
  variantId: i.variantId,
  quantity: i.quantity,
}));

export const areCustomFieldsValuesEqual = (valuesInItemFromCart, valuesInItemToAdd) =>
  isEqual(valuesInItemFromCart, valuesInItemToAdd);

export const isExistingItemInCart = (itemInCart, itemToAdd) => {
  // Not the same items if variantId or source is different
  if ((itemInCart.variantId !== itemToAdd.variantId) || (itemInCart.source !== itemToAdd.source)) {
    return false;
  }

  // If variantId is the same and there are no custom fields to compare, items are the same and the purchaseOption isn't the same
  if ((!itemInCart.customFieldsValues || !itemInCart.customFieldsValues.length) &&
    (!itemToAdd.customFieldsValues || !itemToAdd.customFieldsValues.length) &&
    (itemInCart.purchaseOption === itemToAdd.purchaseOption)) {
    return true;
  }

  // If there are custom fields to compare, items are the same only when their custom fields values are equal. And the purchaseOption isn't the same
  return areCustomFieldsValuesEqual(itemInCart.customFieldsValues, itemToAdd.customFieldsValues) && (itemInCart.purchaseOption === itemToAdd.purchaseOption);
};

export const getItemQuantityWithUpdatedTotal = (item, quantity) =>
  ({ ...item, quantity, price: item.price * quantity });

export const addItemInCart = (cart, item) => {
  if (process.client) {
    item.forEach(function(product) {
      window.dispatchEvent(new CustomEvent('acquirePluginEventAddToCart', { detail: product }));
    });
  }

  return {
    ...cart,
    items: cart?.items?.concat(mappedItem(item)) || [...mappedItem(item)],
  };
};

export const updateQuantityOnItemInCart = (cart, item, quantity) => {
  return {
    ...cart,
    items: cart.items
      .map(el => isExistingItemInCart(el, item) ? getItemQuantityWithUpdatedTotal(el, quantity) : el)
      .filter(el => el.quantity > 0),
  };
};

export const addSubscriptionsShippingProduct = (item, options) => {
  let subscriptionProducts = [];

  if (item[0].purchaseOption === 'SUBSCRIPTION') {
    subscriptionProducts = options ? options?.products?.concat(mappedSubscriptionItem(item)) : [...mappedSubscriptionItem(item)];
  }

  return subscriptionProducts.length > 0 ? [{ products: subscriptionProducts }] : [];
};

export const updateQtySubscriptionsShippingProduct = (item, options, quantity) => {
  let subscriptionProducts = [];

  if (item.purchaseOption === 'SUBSCRIPTION') {
    subscriptionProducts = options
      ? options?.products?.map(el => el.productId === item.productId && el.variantId === item.variantId ? ({ ...el, quantity }) : el).filter(el => el.quantity > 0)
      : [];
  }

  return subscriptionProducts.length > 0 ? [{ products: subscriptionProducts }] : [];
};

export const removeItemInCart = (cart, item) => {
  return {
    ...cart,
    items: cart?.items?.filter(el => el.variantId !== item.variantId || el.purchaseOption !== item.purchaseOption),
  };
};

export const removeItemInSubscription = (subscription, item) => {
  return [{
    ...subscription,
    products: subscription?.products?.filter(el => el.variantId !== item.variantId),
  }];
};

export const isReceivedCartEmpty = cart => Object.keys(cart).length === 0;
