<template>
  <div class="modal-display">
    <div class="modal-display__container">
      <AppIconButton
        v-if="!isPreRegistration"
        class="container__close"
        size="large"
        name="times"
        @click.native="onClose" />

      <div class="container__main">
        <div class="main__content">
          <div class="content__header">
            <img
              v-if="siteLogo"
              class="header__logo"
              alt="logo"
              :src="siteLogo" />
            <AppLink
              v-if="!isPreRegistration"
              data-test-id="header-close"
              class="header__close"
              icon-placement="left"
              @click="onClose">
              {{ $t('checkoutLink.back.bundle') }}
            </AppLink>
          </div>

          <div class="content__slot">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import AppLink from '@/checkout-link/components/app/app-link/AppLink';
  import AppIconButton from '@/checkout-link/components/app/app-icon-button/AppIconButton';

  export default {
    name: 'ModalDisplay',
    components: {
      AppIconButton,
      AppLink,
    },
    computed: {
      ...mapGetters('SessionModule', ['siteLogo']),
      ...mapGetters('CheckoutLinkModule', ['isPreRegistration']),
    },
    methods: {
      onClose() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss" scoped>
  // shared styles in modals mixins file
  .modal-display {
    @include modal-display;
  }

  .container {
    @include modal-container;
  }

  .main {
    @include modal-main;
  }

  .content {
    @include modal-content;
  }

  .header {
    @include modal-header;
  }

  @include breakpoint(medium) {
    .modal-display {
      @include modal-display-medium;
    }

    .content {
      &__header {
        padding: 40px 40px 20px;
      }
    }

    .header {
      &__close {
        display: none;
      }
    }
  }
</style>
