import sessionService from '@/services/session/session.service';
import { objectToQueryString } from '@/helpers/data/data.helper';
import httpHelper from '@/helpers/http/http.helper';
import isEmpty from 'lodash/isEmpty';

const state = () => ({
  acquireWarningSeen: false,
  session: {
    values: {
      sessionId: null,
      cartId: null,
      siteId: null,
      contactId: null,
      currency: null,
      authorizationContext: {
        //   cartId: "7fd0591d-8955-4a45-a55a-a181b18c294d"
        //   contactId: "16dd98ca-3714-4254-8690-8b124814871b"
        //   role: "CONTACT"
        //   sessionId: "7f9f2ac0-200a-4b76-9c1a-d5cb7a5aa2fd"
        //   siteId: "b58d4c12-ccfa-4e7b-b0e6-378264e2399e"
        //   subscriptionEndDate: 1596764759417
        //   subscriptionType: "FREE"
        //   timestamp: 1596733223417
        //   type: "CONTACT"
        //   userId: "16dd98ca-3714-4254-8690-8b124814871b"
      },
      contact: {
        //   addresses: [],
        //   insertTime: "2020-08-06T16:55:38.320Z",
        //   phoneNumber: "+15819999590",
        //   _id: "16dd98ca-3714-4254-8690-8b124814871b"
        //   _type: "contact",
      },
    },
    duration: null,
    error: null,
    loading: false,
  },
  country: {
    value: null,
  },
  settings: {
    loading: false,
    error: null,
    value: null,
  },
  timezone: null,
  fakeSite: {
    name: null,
    currency: null,
  },
  url: null,
  ipAddress: '',
  cookieStack: [],
  acceptsLanguages: 'en-US',
});

const actions = {
  async initSession({ commit, dispatch, getters }, data) {
    dispatch('initCountry', data.country);
    dispatch('initSettings', data.settings);
    commit('setSession', data.session);

    if (!!data.session?.contact) {
      await dispatch('ContactModule/updateContact', data.session.contact, { root: true });
      dispatch('UserModule/updateUser', data.session.authorizationContext, { root: true });
    }

    commit('loadSessionSuccess', data.session);

    return getters.sessionValues;
  },
  async loadSession({ commit, dispatch, getters, rootGetters }) {
    commit('loadSession');

    // construct the session route dynamically
    let route = await httpHelper.buildRouteUrl(httpHelper.SESSION_ENDPOINT);

    const queryParams = httpHelper.buildSessionAndInitCheckoutLinkParameters(
      getters.fullUrl,
      rootGetters['CheckoutLinkModule/getId'],
      getters.ipAddress,
    );

    if (!isEmpty(queryParams)) {
      const query = objectToQueryString(queryParams);
      route = `${route}?${query}`;
    }

    try {
      const data = await sessionService.loadSession(route);

      commit('setSession', data);

      await dispatch('ContactModule/updateContact', data.contact, { root: true });
      dispatch('UserModule/updateUser', data.authorizationContext, { root: true });

      commit('loadSessionSuccess', data);

      return data;
    } catch (error) {
      commit('loadSessionFailure', error);
      throw error;
    }
  },
  async loadSettings({ commit }) {
    commit('LOAD_SETTINGS');

    try {
      const data = await sessionService.loadSettings();
      commit('LOAD_SETTINGS_SUCCESS', data);
    } catch (error) {
      commit('LOAD_SETTINGS_FAILURE', error);
      throw error;
    }
  },
  async initCountry({ commit, getters }, data) {
    commit('LOAD_COUNTRY_SUCCESS', data);
  },
  async initSettings({ commit, dispatch }, data) {
    commit('LOAD_SETTINGS_SUCCESS', data);
  },
  setFakeSite({ commit }, fakeSite) {
    commit('SET_FAKE_SITE', fakeSite);
  },
  async setWarningMessageSeen({ commit, rootState }) {
    const checkoutLinkId = rootState.route.params.customLink;

    if (process.client && checkoutLinkId) {
      document.cookie = `acquireWarningSeen-${checkoutLinkId}=true; SameSite=None; path=/; Secure`;
    }

    commit('SET_ACQUIRE_WARNING_MESSAGE_SEEN', true);
  },
  setUrl({ commit }, url) {
    commit('SET_URL', url);
  },
  setFullUrl({ commit }, url) {
    commit('SET_FULL_URL', url);
  },
  setIpAddress({ commit }, ipAddress) {
    commit('SET_IP_ADDRESS', ipAddress);
  },
  setSiteId({ commit }, siteId) {
    commit('SET_SITE_ID', siteId);
  },
  setSessionId({ commit }, sessionId) {
    commit('SET_SESSION_ID', sessionId);
  },
  setSessionDuration({ commit }, sessionDuration) {
    commit('SET_SESSION_DURATION', sessionDuration);
  },
  addCookie({ commit }, cookie) {
    // cookie must be a string ready to push `name=value; path=/; SameSite=None; expires=date; Secure`
    commit('ADD_COOKIE_STACK', cookie);
  },
  setAcceptsLanguages({ commit }, acceptsLanguages) {
    // sometimes we can receive empty acceptsLanguages or = "*" -> fallback to 'en-US'
    if (!acceptsLanguages || acceptsLanguages === '*') {
      acceptsLanguages = 'en-US';
    }

    commit('SET_ACCEPTS_LANGUAGES', acceptsLanguages);
  },
};

const mutations = {
  loadSession(state) {
    state.session.loading = true;
    state.session.error = null;
  },
  loadSessionSuccess(state, values) {
    state.session.loading = false;
    state.session.error = null;
    state.session.values = values;
  },
  loadSessionFailure(state, error) {
    state.session.loading = false;
    state.session.error = error;
  },
  setSession(state, values) {
    state.session.values = values;
  },
  LOAD_COUNTRY_SUCCESS(state, country) {
    state.country.value = country;
  },
  LOAD_SETTINGS(state) {
    state.settings.loading = true;
    state.settings.error = null;
  },
  LOAD_SETTINGS_SUCCESS(state, settings) {
    state.settings.loading = false;
    state.settings.error = null;
    state.settings.value = settings;

    if (settings.timezone) {
      state.timezone = settings.timezone;
    }
  },
  LOAD_SETTINGS_FAILURE(state, error) {
    state.settings.error = error;
    state.settings.loading = false;
  },
  SET_FAKE_SITE(state, fakeSite) {
    state.fakeSite = fakeSite;
  },
  SET_URL(state, url) {
    state.url = url;
  },
  SET_FULL_URL(state, url) {
    state.fullUrl = url;
  },
  SET_IP_ADDRESS(state, ip) {
    state.ipAddress = ip;
  },
  SET_SESSION_ID(state, sessionId) {
    state.session.values.sessionId = sessionId;
  },
  SET_SESSION_DURATION(state, duration) {
    state.session.duration = duration;
  },
  SET_SITE_ID(state, siteId) {
    state.session.values.siteId = siteId;
  },
  SET_ACQUIRE_WARNING_MESSAGE_SEEN(state, value) {
    state.acquireWarningSeen = value;
  },
  ADD_COOKIE_STACK(state, cookie) {
    state.cookieStack = [
      ...state.cookieStack,
      cookie,
    ];
  },
  SET_ACCEPTS_LANGUAGES(state, acceptsLanguages) {
    state.acceptsLanguages = acceptsLanguages;
  },
};

const getters = {
  sessionState: state => state.session,
  sessionIsLoading: (_, { sessionState }) => sessionState?.loading,
  sessionError: (_, { sessionState }) => sessionState?.error,
  sessionValues: (_, { sessionState }) => sessionState?.values,

  sessionDuration: (_, { sessionState }) => sessionState?.duration,
  sessionId: (_, { sessionValues }) => sessionValues?.sessionId,
  cartId: (_, { sessionValues }) => sessionValues?.cartId,
  siteId: (_, { sessionValues }) => sessionValues?.siteId,
  contactId: (_, { sessionValues }) => sessionValues?.contactId,
  sessionCurrency: (_, { sessionValues }) => sessionValues?.currency,

  countryState: state => state.country,
  country: (_, { countryState }) => countryState?.value,
  countryCode: (_, { country }) => country?.countryCode,
  countryName: (_, { country }) => country?.country,

  fakeSite: state => state.fakeSite,
  fakeSiteName: (_, { fakeSite }) => fakeSite?.name || null,
  fakeSiteCurrency: (_, { fakeSite }) => fakeSite?.currency || null,

  settingsState: state => state.settings,
  settings: (_, { settingsState }) => settingsState?.value,
  settingsCurrency: (_, { settings }) => settings?.currency,
  settingsGatewayProvider: (_, { settings }) => settings?.gatewayProvider,
  settingsStripePublishableKey: (_, { settings }) => settings?.stripePublishableKey,
  advancedSettings: (_, { settings }) => settings?.advanced_settings || {},

  siteUrl: (_, { settings }) => settings?.domain,
  siteLogo: (_, { settings }) => settings?.logo,
  siteCurrency: (_, { fakeSiteCurrency, settingsCurrency, sessionCurrency }) => fakeSiteCurrency || sessionCurrency || settingsCurrency,
  siteName: (_, { fakeSiteName, settings }) => fakeSiteName || settings?.title,

  timezone: state => state.timezone || 'America/Los_Angeles',

  url: state => state.url,
  fullUrl: state => state.fullUrl,
  acquireWarningSeen: state => state.acquireWarningSeen || 0,
  cookieStack: state => state.cookieStack,
  ipAddress: state => state.ipAddress,
  acceptsLanguages: state => state.acceptsLanguages ?? 'en-US',
};

export const SessionModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
