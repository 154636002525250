import localforage from 'localforage';
import * as memoryDriver from 'localforage-driver-memory';

const options = {
  name: 'storage',
};

if (!process.client) {
  localforage.defineDriver(memoryDriver);
  options.driver = memoryDriver._driver;
}
const storage = localforage.createInstance(options);

export const dumpStorage = () => {
  return storage._dbInfo?.mStore.data || {};
};

export const loadStorage = (data) => {
  return data.iterate((value, key) => {
    storage.setItem(key, value);
  });
};
export default storage;
