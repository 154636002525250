<template>
  <div
    ref="footer"
    class="checkout-link-footer"
    :class="{ flat: !hasMobileBottomSheet }"
    :style="{ height: footerHeight }">
    <div
      v-if="hasSlotHeader()"
      ref="header"
      class="checkout-link-footer__header"
      :style="{ height: headerHeight }"
      data-test-id="checkout-link-footer-header">
      <slot name="header" />
    </div>

    <div
      class="checkout-link-footer__action"
      data-test-id="checkout-link-footer-action">
      <div
        v-if="hasSlotContent()"
        ref="content"
        class="action__content"
        :style="{ height: contentHeight }">
        <slot name="content" />
      </div>

      <div
        v-if="hasSlotSide()"
        ref="side"
        class="action__side">
        <slot name="side" />
      </div>

      <div
        class="action__button"
        :class="{ 'full-width': isMobile && !hasSlotSide() }"
        data-test-id="checkout-link-footer-action-button">
        <div
          v-if="hasSlotAction()"
          class="button__side">
          <slot name="action" />
        </div>

        <AppButton
          class="button__main action__button--flat"
          type="primary"
          :disabled="actionDisabled"
          :icon-prefix="iconPrefix"
          data-cy="checkout-link-footer-action-button"
          data-test-id="checkout-link-footer-action-button-element"
          @click.native="emitClick">
          {{ actionText }} {{ hasAmount ? actionAmount : '' }}
        </AppButton>
      </div>

      <AppError
        v-if="paymentErrorMessage"
        class="action__payment-error"
        data-test-id="checkout-link-footer-action-payment-error-element"
        :error="paymentErrorMessage" />
    </div>
  </div>
</template>

<script>
  import { AcquireError } from '@/services/http/http.service';
  import MobileMixin from '@mixins/isMobile.mixin';

  import AppError from '@/checkout-link/components/app/app-error/AppError';
  import AppButton from '@/checkout-link/components/app/app-button/AppButton';

  export default {
    name: 'CheckoutLinkFooter',
    components: {
      AppButton,
      AppError,
    },
    mixins: [
      MobileMixin,
    ],
    props: {
      hasMobileBottomSheet: {
        type: Boolean,
        default: true,
      },
      hasIcon: {
        type: Boolean,
        default: true,
      },
      hasAmount: {
        type: Boolean,
        default: true,
      },
      actionDisabled: {
        type: Boolean,
        default: false,
      },
      actionText: {
        type: String,
        required: true,
      },
      actionAmount: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        contentHeight: 'auto',
        footerHeight: 'auto',
        headerHeight: 'fit-content',
        paymentErrorMessage: null,
      };
    },
    computed: {
      iconPrefix() {
        return this.hasIcon ? 'lock' : '';
      },
    },
    methods: {
      hasSlotHeader() {
        return !!this.$slots.header;
      },
      hasSlotSide() {
        return !!this.$slots.side;
      },
      hasSlotContent() {
        return !!this.$slots.content;
      },
      hasSlotAction() {
        return !!this.$slots.side;
      },
      emitClick() {
        this.$emit('click');
      },
    },
    mounted() {
      // If get query paymentError (redirection from payment gateway), display payment error message + remove query parameter
      if (this.$route.query.paymentError) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            paymentError: undefined,
          },
        });

        this.paymentErrorMessage = new AcquireError({ message: 'error.payment.failure' });
      }
    },
  };
</script>

<style lang="scss" scoped>
  $action-height: rem(50px);

  .checkout-link-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    background: var(--card-background);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding: $layout-padding;
    box-sizing: border-box;

    &__header {
      overflow: auto;
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      min-height: 0;
      max-height: 100%;
    }

    &.flat {
      background: transparent;
      box-shadow: none;
      border-radius: unset;

      .action__button--flat {
        width: 100%;
      }
    }
  }

  .action {
    &__content {
      order: 1;
      width: 100%;
      min-height: 0;
      overflow-y: auto;
    }

    &__side {
      order: 2;
      margin-right: 20px;
    }

    &__button {
      order: 3;
      display: flex;
      align-items: center;
      flex: 1;
    }

    &__payment-error {
      order: 1;
      margin-bottom: rem(10px);
      width: 100%;
    }

    &__brand {
      display: none;
    }
  }

  .button {
    &__main {
      color: var(--checkout-text-color);
      background: var(--checkout-background-color);
    }
  }

  .full-width {
    width: 100%;
    justify-content: space-between;

    .button {
      &__main {
        .button__side + & {
          width: fit-content;
        }
      }
    }
  }

  @include breakpoint(medium) {
    .checkout-link-footer {
      position: initial;
      box-shadow: none;
      max-height: unset;
      border-radius: unset;
      border-top: 1px solid var(--primary-background);
      padding: 0;
      background: transparent;

      &.flat {
        .action__button--flat {
          width: auto;
        }
      }

      &__action {
        flex-direction: column;
        align-items: unset;
        flex-wrap: nowrap;
        height: 46px;
        padding: rem(24px) rem(40px);
      }

      &__header {
        margin-bottom: initial;
        padding-bottom: initial;
        border-bottom: none;
      }

      &.opened &__header {
        display: block;
        justify-content: space-between;

        .close-button {
          display: none;
        }
      }
    }

    .action {
      &__content {
        order: 3;
        display: flex;
      }

      &__side {
        order: 2;
        display: flex;
        margin-top: 20px;
        margin-right: 0;
        border-top: 1px solid var(--product-description-border);
        padding-top: 20px;
      }

      &__button {
        order: 1;
      }

      &__payment-error {
        margin-top: rem(10px);
        margin-bottom: 0;
        width: auto;
      }

      &__brand {
        margin: 10px auto 0 auto;
        display: flex;
      }
    }

    .button {
      &__side {
        display: none;
      }
    }

    .full-width {
      width: 100%;
      justify-content: space-between;

      .button {
        &__main {
          width: 100%;
        }
      }
    }
  }
</style>
