<template>
  <ProductDrawer
    :value="drawerOpen"
    :tab.sync="drawerTab"
    :product="product"
    :images="mappedImages"
    :selected-variant="selectedVariant"
    :preview="false"
    :description="formattedDescription"
    :min-quantity-product="minQuantityProduct"
    :custom-fields-values="customFieldsValues"
    :user-context="userContext"
    :purchase-option="purchaseOption"
    :product-price="productPrice"
    :discounted-price="discountedPrice"
    :currency="getCurrency"
    :is-quantity-max-reached="isQuantityMaxReached"
    :add-to-cart-disabled="addToCartDisabled"
    :add-to-cart-btn-text="addToCartButtonText"
    :has-options="hasOptions"
    @input="closeProductDetails"
    @add-to-cart="handleAddToCart"
    @changed-variant="onVariantChange" />
</template>

<script>
  import { mapGetters } from 'vuex';

  import { getProductVariantImages } from '@/helpers/checkout-link/checkout-link.helper';
  import {
    extractDescription,
    getVariantPrice,
    getVariantDiscountedPrice,
    hasVariantsAndAttributes,
    productHasAvailableVariantsQuantities,
    isVariantAvailable,
  } from '@/helpers/product/product.helper';

  import { ProductDrawer } from 'vue-product-layout';

  export default {
    name: 'FunnelProductDetails',
    components: {
      ProductDrawer,
    },
    props: {
      product: {
        type: Object,
        required: true,
      },
      tab: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        drawerOpen: false,
        selectedVariant: this.product.selectedVariant,
        drawerTab: this.tab === 'details' && this.product.descriptionHtml ? 'details' : 'options',
        purchaseOption: 'ONE_TIME',
        customFieldsValues: [],
      };
    },
    computed: {
      ...mapGetters('CartModule', ['getCurrency', 'getItemTotal', 'isUpdating']),
      ...mapGetters('CheckoutLinkModule', ['getCheckoutLinkSettings']),
      ...mapGetters('FunnelModule', ['getFunnelCurrency', 'isMonoProductFunnel', 'isTimerExpired', 'isPresellFunnel']),
      ...mapGetters('OrderModule', ['isSaving']),
      mappedImages() {
        return getProductVariantImages(this.product, this.getCheckoutLinkSettings);
      },
      minQuantityProduct() {
        return this.product.quantity?.min || 0;
      },
      productPrice() {
        return getVariantPrice(this.selectedVariant);
      },
      discountedPrice() {
        return getVariantDiscountedPrice(this.selectedVariant);
      },
      isProductOutOfStock() {
        return !productHasAvailableVariantsQuantities(this.product);
      },
      isQuantityMaxReached() {
        return this.product.quantity.max > 0 && (this.getItemTotal(this.product.productId) >= this.product.quantity.max);
      },
      isVariantOutOfStock() {
        return !isVariantAvailable(this.selectedVariant);
      },
      addToCartDisabled() {
        return this.isTimerExpired ||
          this.isProductOutOfStock ||
          this.isVariantOutOfStock ||
          this.isQuantityMaxReached ||
          this.isSaving ||
          this.isUpdating;
      },
      addToCartButtonText() {
        if (this.isVariantOutOfStock) {
          return this.$t('product.outOfStock');
        }

        if (this.isQuantityMaxReached) {
          return this.$t('product.quantityLimited', { number: this.product.quantity.max });
        }

        if (!this.isPresellFunnel && this.isMonoProductFunnel) {
          return this.$t('product.buyNow');
        }

        if (this.isPresellFunnel && this.isMonoProductFunnel) {
          return this.$t('funnel.add');
        }

        return this.$t('checkoutLink.addProduct');
      },
      hasOptions() {
        return this.showAttributesSelector && this.hasMultipleVariants;
      },
      hasMultipleVariants() {
        return this.product.variants && this.product.variants.length > 1;
      },
      showAttributesSelector() {
        return hasVariantsAndAttributes(this.product) && !this.isProductOutOfStock;
      },
      userContext() {
        return {
          currency: this.getCurrency,
        };
      },
      formattedDescription() {
        return extractDescription(this.product.descriptionHtml);
      },
    },
    methods: {
      handleAddToCart() {
        this.$emit('add-to-cart', {
          product: this.product,
          variant: this.selectedVariant,
          buyNow: this.isMonoProductFunnel,
        });
      },
      closeProductDetails() {
        this.$emit('click');
      },
      onVariantChange(variant) {
        this.selectedVariant = variant;
        this.$emit('changed-variant', variant);
      },
    },
    mounted() {
      this.drawerOpen = true;
    },
  };
</script>
