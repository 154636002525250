<template>
  <div class="discount">
    <IconTags class="discount__icon" />

    <div class="discount__display">
      <i18n path="checkoutLink.discount.flat">
        <template #savings>
          <span class="display__text">{{ savings }}</span>
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
  import IconTags from '@/checkout-link/components/icon/IconTags.vue';
  import { checkoutLinkDiscount } from '@/helpers/checkout-link/checkout-link.helper';
  import { formattedPrice } from '@/helpers/data/data.helper';

  export default {
    name: 'DiscountFlat',
    components: {
      IconTags,
    },
    props: {
      checkoutLinkIncentive: {
        type: Object || null,
        default: null,
      },
      currency: {
        type: String,
        required: true,
      },
    },
    computed: {
      savings() {
        const checkoutLink = this.checkoutLinkIncentive;

        return checkoutLink.incentiveType === checkoutLinkDiscount.FIXED_AMOUNT ? formattedPrice(checkoutLink.amount * 100, this.currency) : `${checkoutLink.amount}%`;
      },
    },
  };
</script>

<style lang="scss" scoped>
.discount {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: $radius-default-button;

  &__icon {
    width: 14px;
    margin-right: 6px;
  }

  &__display {
    display: flex;
    flex-wrap: wrap;
  }
}

.display {
  &__text {
    font-weight: $bold;
  }
}
</style>
