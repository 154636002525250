export const EmptyState = {
  tempCreditCard: null,
  tempStripePaymentMethod: null,
  tempAddress: null,
  tempEmail: null,
  tempPhone: null,
  tempSignUpOption: false,
};

const state = { ...EmptyState };

const actions = {
  // Credit card
  storeTempCreditCard({ commit, dispatch }, creditCard) {
    commit('STORE_TEMP_CREDIT_CARD', creditCard);
    // Needs to be selected in order to enable the payment button when no other cards exist. Will be canceled if user returns to selection mode
    dispatch('CartModule/selectCreditCard', creditCard, { root: true });
  },
  cleanTempCreditCard({ commit, dispatch }) {
    commit('CLEAN_TEMP_CREDIT_CARD');
    dispatch('CartModule/selectCreditCard', null, { root: true });
  },
  // Stripe payment method
  storeTempStripePaymentMethod({ commit, dispatch }, paymentMethod) {
    commit('STORE_TEMP_STRIPE_PAYMENT_METHOD', paymentMethod);
    // Needs to be selected in order to enable the payment button when no other cards/payment method exist. Will be canceled if user returns to selection mode
    dispatch('CartModule/selectStripePaymentMethod', paymentMethod, { root: true });
  },
  cleanTempStripePaymentMethod({ commit, dispatch }) {
    commit('CLEAN_TEMP_STRIPE_PAYMENT_METHOD');
    dispatch('CartModule/selectStripePaymentMethod', null, { root: true });
  },
  // Address
  async storeTempAddress({ commit, dispatch, rootGetters }, { address = null, firstname = null, lastname = null, line2 = null }) {
    if (!!address) {
      commit('STORE_TEMP_ADDRESS', address);
      // Needs to follow the existing flow to update the shipping cost properly. Will be canceled if user returns to selection mode
      if (!rootGetters['CheckoutLinkModule/isPreRegistration']) {
        await dispatch('CartModule/saveAddress', address, { root: true });
      }
    }

    // First name and last name are not needed for shipping cost calculation
    if (!!firstname || !!lastname || !!line2) {
      commit('STORE_TEMP_NON_SHIPPING_INFO', { firstname, lastname, line2 });
    }
  },
  async cleanTempAddress({ commit, dispatch, getters, rootGetters }, { firstname = false, lastname = false, address = false }) {
    if (address && getters.hasTempShippingInfo) {
      commit('CLEAN_TEMP_ADDRESS');
      if (!rootGetters['CheckoutLinkModule/isPreRegistration']) {
        await dispatch('CartModule/saveAddress', null, { root: true });
      }
    }

    if (firstname || lastname) {
      commit('CLEAN_TEMP_NON_SHIPPING_INFO', { firstname, lastname });
    }
  },
  async cleanFullTempAddress({ commit }) {
    commit('CLEAN_FULL_TEMP_ADDRESS');
  },
  // Email
  storeTempEmail({ commit }, email) {
    commit('STORE_TEMP_EMAIL', email);
  },
  cleanTempEmail({ commit }) {
    commit('CLEAN_TEMP_EMAIL');
  },
  // Phone
  storeTempPhone({ commit }, phone) {
    commit('STORE_TEMP_PHONE', phone);
  },
  cleanTempPhone({ commit }) {
    commit('CLEAN_TEMP_PHONE');
  },
  // Signup option
  storeTempSignUpOption({ commit }, value) {
    commit('STORE_TEMP_SIGNUP_OPTION', value);
  },
  cleanTempSignUpOption({ commit }) {
    commit('CLEAN_TEMP_SIGNUP_OPTION');
  },
  // All
  cleanUserTempInfo({ commit }) {
    commit('CLEAN_USER_TEMP_INFO');
  },
};

const mutations = {
  // Credit Card
  STORE_TEMP_CREDIT_CARD(state, creditCard) {
    state.tempCreditCard = creditCard;
  },
  CLEAN_TEMP_CREDIT_CARD(state) {
    state.tempCreditCard = null;
  },
  // Stripe payment method
  STORE_TEMP_STRIPE_PAYMENT_METHOD(state, stripePaymentMethod) {
    state.tempStripePaymentMethod = stripePaymentMethod;
  },
  CLEAN_TEMP_STRIPE_PAYMENT_METHOD(state) {
    state.tempStripePaymentMethod = null;
  },
  // Address
  STORE_TEMP_ADDRESS(state, address) {
    state.tempAddress = { ...address };
  },
  CLEAN_FULL_TEMP_ADDRESS(state) {
    state.tempAddress = null;
  },
  CLEAN_TEMP_ADDRESS(state) {
    state.tempAddress = {
      firstname: state.tempAddress?.firstname,
      lastname: state.tempAddress?.lastname,
    };
  },
  STORE_TEMP_NON_SHIPPING_INFO(state, { firstname, lastname, line2 }) {
    state.tempAddress = {
      ...state.tempAddress,
      ...((firstname && { firstname: firstname }) || {}),
      ...((lastname && { lastname: lastname }) || {}),
      ...((line2 && { line2: line2 }) || {}),
    };
  },
  CLEAN_TEMP_NON_SHIPPING_INFO(state, { firstname, lastname }) {
    state.tempAddress = {
      ...state.tempAddress,
      ...((firstname && { firstname: '' }) || {}),
      ...((lastname && { lastname: '' }) || {}),
    };
  },
  // Email
  STORE_TEMP_EMAIL(state, email) {
    state.tempEmail = email;
  },
  CLEAN_TEMP_EMAIL(state) {
    state.tempEmail = null;
  },
  // Phone
  STORE_TEMP_PHONE(state, phone) {
    state.tempPhone = phone;
  },
  CLEAN_TEMP_PHONE(state) {
    state.tempPhone = null;
  },
  // Signup option
  STORE_TEMP_SIGNUP_OPTION(state, value) {
    state.tempSignUpOption = value;
  },
  CLEAN_TEMP_SIGNUP_OPTION(state) {
    state.tempSignUpOption = false;
  },
  // All
  CLEAN_USER_TEMP_INFO(state) {
    state.tempCreditCard = null;
    state.tempAddress = null;
    state.tempEmail = null;
    state.tempPhone = null;
    state.tempSignUpOption = false;
  },
};

const getters = {
  getTempCreditCard: state => state.tempCreditCard,
  getTempStripePaymentMethod: state => state.tempStripePaymentMethod,
  getTempAddress: state => state.tempAddress,
  hasTempShippingInfo: state => !!state.tempAddress?.city || !!state.tempAddress?.country || !!state.tempAddress?.province || !!state.tempAddress?.line1 || !!state.tempAddress?.zip,
  getTempEmail: state => state.tempEmail, // Must return null if not defined, for checkouts without email required
  getTempPhone: state => state.tempPhone,
  getTempSignUpOption: state => state.tempSignUpOption,
  getTempContactFormatted: (_, { getTempCreditCard, getTempAddress, getTempEmail, getTempPhone, getTempSignUpOption }, __, rootGetters) => ({
    firstname: getTempAddress?.firstname || '',
    lastname: getTempAddress?.lastname || '',
    paymentMethod: getTempCreditCard?.paymentMethod || 'creditcard',
    email: getTempEmail, // very important to let this to null for order that do not requires email
    phoneNumber: getTempPhone,
    addressFormatted: getTempAddress?.address || '',
    shippingAddress: getTempAddress || {},
    shippingAddressLine2: getTempAddress?.line2 || '',
    shippingMethod: rootGetters['CartModule/getSelectedShipping']?.id || '',
    billingZipCode: getTempCreditCard?.zipCode || getTempAddress?.zip || '',
    creditCard: getTempCreditCard || {},
    creditCardValid: !!getTempCreditCard,
    signUp: getTempSignUpOption,
  }),
};

export const UserTemporaryInfoModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
