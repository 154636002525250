import httpService from '@/services/http/http.service';
import httpHelper from '@/helpers/http/http.helper';

const loadFunnel = async() => {
  const route = httpHelper.buildV1ApiRoute(httpHelper.FUNNEL);

  return await httpService.get(route);
};

export default {
  loadFunnel,
};
