<template>
  <div class="user-agreement">
    <div class="user-agreement__label">
      <i18n path="registration.agreement">
        <template #privacyPolicy>
          <a
            v-if="advancedSettings.privacyPolicyUrl"
            class="label__link"
            :href="advancedSettings.privacyPolicyUrl"
            target="_blank"
            rel="noopener noreferrer">
            {{ $t('registration.privacyPolicy') }}
          </a>
          <span v-else>
            {{ $t('registration.privacyPolicy') }}
          </span>
        </template>

        <template #terms>
          <a
            v-if="advancedSettings.termsUrl"
            class="label__link"
            :href="advancedSettings.termsUrl"
            target="_blank"
            rel="noopener noreferrer">
            {{ $t('registration.terms') }}
          </a>
          <span v-else>
            {{ $t('registration.terms') }}
          </span>
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UserAgreement',
    props: {
      advancedSettings: {
        type: Object,
        default: null,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .user-agreement {
    line-height: 1.4;
  }

  .label {
    &__link {
      color: var(--primary-text);
    }
  }
</style>
