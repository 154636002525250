<template>
  <div class="not-found">
    <div class="not-found__content">
      <div class="content__error">
        <div class="error__title">{{ $t('error.page.title') }}</div>
        <div class="error__code">{{ $t('error.page.404.code') }}</div>
        <div class="error__content">{{ $t('error.page.404.content') }}</div>
        <p class="error__description">
          {{ $t('error.page.404.description') }}
        </p>
      </div>

      <button
        type="button"
        class="content__action"
        @click="goToRoute">
        {{ $t('error.page.action') }}
      </button>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'NotFound',
    methods: {
      ...mapActions('DocumentHeaderModule', ['setErrorHeader']),
      goToRoute() {
        window.location.href = 'https://acquire.app/';
      },
    },
    async serverPrefetch() {
      this.setErrorHeader({
        title: this.$t('error.page.404.content'),
        description: this.$t('error.page.404.description'),
      });
    },
  };
</script>

<style lang="scss" scoped>
  .not-found {
    display: table;
    overflow-y: auto; // fall back for "overlay" value non compatible browsers
    overflow-y: overlay;
    box-sizing: border-box;
    height: 100%;
    width: 100%;

    padding: rem(10px);
    background: rgba(#24242B, 0.85);

    &__content {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }

    &::after {
      content: "";
      background-color: #24242B;
      @include icon-background('error-background', auto 100%, top right);

      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: fixed;
      z-index: -1;
    }
  }

  .content {
    &__error {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: 5%;
    }

    &__action {
      cursor: pointer;
      padding: 12px 20px;
      background: var(--main-color);
      border-radius: 5px;
      border: none;

      @include body-large();
      font-weight: $semi-bold;
      line-height: 140%;
      color: var(--main-color-text);

      &:hover, &:focus {
        border: none;
        outline: none;
        background: rgba(var(--main-color), 0.9);
      }
    }
  }

  .error {
    &__title {
      opacity: 0.2;

      @include heading();
      font-size: $xx-large-font-size;
      font-weight: $bold;
      line-height: 140%;
      color: $error-page;
    }

    &__code {
      opacity: 0.122;

      @include heading();
      font-weight: $bold;
      font-size: rem(100px);
      line-height: 100%;
      color: $error-page;
    }

    &__content {
      text-align: center;

      @include heading();
      font-size: $xx-large-font-size;
      font-weight: $bold;
      line-height: 140%;
      color: $error-page;
    }

    &__description {
      max-width: 600px;
      text-align: center;

      @include body-large();
      font-weight: $light-bold;
      line-height: 140%;
      color: $error-page;
    }
  }

  @include breakpoint(xsmall) {
    .not-found {
      padding: rem(20px);
    }

    .error {
      &__code {
        font-size: rem(150px);
      }
    }
  }

  @include breakpoint(small) {
    .not-found {
      padding: rem(40px);
    }

    .error {
      &__code {
        font-size: rem(200px);
      }
    }
  }
</style>
