<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'AppTag',
    props: {
      variant: {
        type: String,
        default: 'small',
      },
      mainTextColor: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      textColor() {
        return this.mainTextColor ? 'app-tag--color' : '';
      },
      classes() {
        return `app-tag app-tag--${this.variant} ${this.textColor}`;
      },
    },
  };
</script>

<style scoped lang="scss">
  .app-tag {
    display: inline-block;
    align-items: center;
    justify-content: center;
    padding: 2px 5px;
    border-radius: $radius-badge;

    @include body-large();
    background: var(--main-color);
    color: var(--main-color-text);
    font-weight: $bold;

    &--large {
      padding: 5px 10px;

      @include heading();
    }

    &--color {
      background: none;
      color: var(--main-color);
    }
  }
</style>
