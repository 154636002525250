<template>
  <div class="checkout-link-layout-wrapper">
    <InfoBar />

    <div class="checkout-link-layout">
      <div v-if="hasSlotOverlay()"
        class="checkout-link-layout__block">
        <div class="block__card">
          <slot name="overlay" />

          <div class="block__brand">
            <LayoutBrand />
          </div>
        </div>
      </div>

      <div
        class="checkout-link-layout__content"
        :class="{ blur: isBlur, 'checkout-link-layout__content-confirmation': isConfirmationRoute }">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import MobileMixin from '@mixins/isMobile.mixin';
  import InfoBar from '@/checkout-link/components/layout/info-bar/InfoBar.vue';
  import LayoutBrand from '@/checkout-link/components/layout/brand/LayoutBrand';

  export default {
    name: 'CheckoutLinkLayout',
    components: {
      LayoutBrand,
      InfoBar,
    },
    mixins: [MobileMixin],
    props: {
      isBlur: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('CheckoutLinkModule', [
        'getCheckoutLinkCountDown',
      ]),
      ...mapGetters('FunnelModule', ['hasFunnel']),
      isConfirmationRoute() {
        return this.$route?.name === 'Confirmation' ||
          this.$route?.name === 'FunnelCheckout' ||
          this.$route?.name === 'FunnelInformation';
      },
    },
    methods: {
      ...mapActions('FunnelModule', ['clearFunnel']),
      hasSlotOverlay() {
        return !!this.$slots.overlay;
      },
    },
    mounted() {
      if (this.hasFunnel) {
        this.clearFunnel();
      }
    },
  };
</script>

<style lang="scss" scoped>
  .checkout-link-layout-wrapper {
    @include main-layout-wrapper();

    position: fixed;
  }

  .checkout-link-layout {
    &__content {
      max-width: 100%;
      min-width: 0;
    }

    &__content-confirmation {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
    }

    &__footer {
      padding-top: rem(6px);
    }

    &__block {
      @include scrollBarDisplay;
      z-index: 101;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      background: rgba(0, 0, 0, 0.88);
    }
  }

  .block {
    &__links {
      box-sizing: border-box;
      padding: 18px 24px;
      margin: auto;
      max-width: rem(1230px);
      width: 100%;
      height: auto !important;
    }

    &__card {
      flex: 1;
      width: 100%;
      margin: auto 0;
      box-sizing: border-box;
    }

    &__brand {
      margin: $layout-padding auto 120px;
      max-width: rem(400px);
    }
  }

  .blur {
    @include blur;
  }

  @include breakpoint(medium) {
    .checkout-link-layout {
      &__content-confirmation {
        margin-top: 72px;
        flex-direction: row;
      }
    }

    .checkout-link-layout-wrapper {
      &__background {
        .checkout-link-layout-wrapper.overlay & {
          height: 100%;
        }
      }
    }
  }
</style>
