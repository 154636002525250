<template>
  <div class="cart-with-products">
    <div
      v-if="hasOneTimeItem"
      class="products__one-time">
      <div class="cart-with-products__title">
        {{ $tc('cart.products', getOneTimeItems.length) }}
        <span v-if="hasMultipleOneTimeItems">
          ({{ getOneTimeItems.length }})
        </span>
      </div>

      <CheckoutProductInCart
        v-for="product in getOneTimeItems"
        :key="product._id"
        class="cart-with-products__product"
        :product="product"
        :qty-total="getItemTotal(product.productId)"
        :can-delete="canDelete(product)"
        @quantity-changed="onUpdateItemQuantity"
        @delete="onDeleteItem" />
    </div>

    <div
      v-if="hasGiftItem"
      class="products__one-time">
      <div class="cart-with-products__title">
        {{ $tc('cart.gifts', getGiftItems.length) }}
        <span v-if="hasMultipleGiftItems">
          ({{ getGiftItems.length }})
        </span>
      </div>

      <CheckoutProductInCart
        v-for="product in getGiftItems"
        :key="product._id"
        class="cart-with-products__product"
        :product="product"
        :qty-total="getItemTotal(product.productId)"
        :can-delete="canDelete(product)"
        @quantity-changed="onUpdateItemQuantity"
        @delete="onDeleteItem" />
    </div>

    <div
      v-if="hasSubscriptionItem"
      class="products__subscription">
      <div class="cart-with-products__title">
        {{ $tc('cart.subscriptions', getSubscriptionItems.length) }}
        <span v-if="hasMultipleSubscriptionItems">
          ({{ getSubscriptionItems.length }})
        </span>
      </div>

      <template>
        <CheckoutProductInCart
          v-for="product in getSubscriptionItems"
          :key="product._id"
          class="cart-with-products__product"
          :product="product"
          :qty-total="getItemTotal(product.productId)"
          :can-delete="canDelete(product)"
          @quantity-changed="onUpdateItemQuantity"
          @delete="onDeleteItem" />
      </template>

      <div v-if="displaySettings">
        <SubscriptionSettings />
      </div>

      <SubscriptionInfoCard
        class="products__info-card"
        :is-in-cart="true"
        :frequency="getSelectedFrequency"
        :amount="getTotalDiscountedCart"
        :future-amount="getTotalSubscriptions"
        :currency="getCurrency"
        :title="$t('checkout.purchaseOption.subscription.details.title')"
        content-key="checkout.purchaseOption.subscription.details.contentAmount" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import IsInsideModal from '@mixins/isInsideModal.mixin';

  import { areCustomFieldsValuesEqual } from '@/helpers/cart/cart.helper';

  import CheckoutProductInCart from '@/checkout-link/components/product/product-in-cart/CheckoutProductInCart';
  import SubscriptionInfoCard from '@/checkout-link/components/subscription/subscription-info-card/SubscriptionInfoCard.vue';
  import SubscriptionSettings from '@/checkout-link/components/subscription/subscription-settings/SubscriptionSettings.vue';

  export default {
    name: 'CartWithProducts',
    components: {
      CheckoutProductInCart,
      SubscriptionInfoCard,
      SubscriptionSettings,
    },
    mixins: [IsInsideModal],
    props: {
      displaySettings: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('CartModule', [
        'getMappedItems',
        'getSubscriptionItems',
        'getOneTimeItems',
        'getGiftItems',
        'getItemTotal',
        'canDelete',
        'getSelectedFrequency',
        'getTotalSubscriptions',
        'getTotalDiscountedCart',
        'getCurrency',
      ]),
      hasOneTimeItem() {
        return !!this.getOneTimeItems.length;
      },
      hasMultipleOneTimeItems() {
        return this.getOneTimeItems.length > 1;
      },
      hasGiftItem() {
        return !!this.getGiftItems.length;
      },
      hasMultipleGiftItems() {
        return this.getGiftItems.length > 1;
      },
      hasSubscriptionItem() {
        return !!this.getSubscriptionItems.length;
      },
      hasMultipleSubscriptionItems() {
        return this.getSubscriptionItems.length > 1;
      },
    },
    methods: {
      ...mapActions('CartModule', ['updateItemQuantity', 'removeItem']),
      async onUpdateItemQuantity({ quantity, _id, purchaseOption, customFieldsValues = [] }) {
        await this.updateItemQuantity({ item: this.getItem(_id, purchaseOption, customFieldsValues), quantity });
      },
      onDeleteItem({ _id, purchaseOption, customFieldsValues = [] }) {
        this.removeItem(this.getItem(_id, purchaseOption, customFieldsValues));
      },
      getItem(id, purchaseOption, customFieldsValues = []) {
        return this.getMappedItems.find(i => i.variantId === id && areCustomFieldsValuesEqual(i.customFieldsValues || [], customFieldsValues) && i.purchaseOption === purchaseOption);
      },
    },
  };
</script>

<style scoped lang="scss">
.cart-with-products {
  margin-bottom: rem(20px);

  &__title {
    margin-bottom: rem(5px);
    font-size: rem(16px);
    font-weight: $bold;
    color: var(--tertiary-text);
  }

  &__product {
    max-height: 104px;
    height: 104px;
    border: 1px solid var(--primary-background);
    border-radius: 4px;
    padding: 16px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.products {
  &__one-time {
    margin-bottom: 20px;
  }

  &__info-card {
    margin-top: rem(10px);
  }
}

.summary {
  &__shipping {
    margin: 20px 0;
  }
}
</style>
