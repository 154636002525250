<template>
  <div
    class="post-sell-content"
    :class="postSellContentClasses">
    <ContentBlockReadOnly
      v-if="getFunnelContent"
      :content="getFunnelContent" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ContentBlockReadOnly from '@/checkout-link/components/content/content-block/read-only/ContentBlockReadOnly';

  export default {
    name: 'PostSellContent',
    components: {
      ContentBlockReadOnly,
    },
    computed: {
      ...mapGetters('FunnelModule', ['getFunnelContent', 'getFunnelProducts']),
      postSellContentClasses() {
        return {
          'post-sell-content--three-products': this.lessThanFourProducts,
          'post-sell-content--four-products': this.fourProducts,
        };
      },
      lessThanFourProducts() {
        return this.getFunnelProducts.length < 4;
      },
      fourProducts() {
        return this.getFunnelProducts.length === 4;
      },
    },
  };
</script>

<style scoped lang="scss">
  .post-sell-content {
    box-sizing: border-box;
    background: var(--card-background);
    margin: 0 auto;

    &--three-products {
      max-width: 680px;
    }

    &--four-products {
      max-width: 888px;
    }
  }

  @include breakpoint(small) {
    .post-sell-content {
      border-radius: $radius-post-sell-product;
    }
  }
</style>
