<template>
  <div
    v-if="loaded"
    class="checkout">
    <!-- following class is used in toggleParentScrollElements method -->
    <div class="checkout__content">
      <div class="content__cart">
        <EmptyCart v-if="isCartEmpty" />

        <CartWithProducts
          v-else
          :display-settings="true" />
      </div>

      <hr class="content__separator" />

      <!-- following class is used in toggleParentScrollElements method -->
      <div class="content__user-info">
        <LayoutLoader
          v-show="isSaving"
          class="user-info__loader" />

        <!--
          Do not use v-if here instead of v-show since
          it will force child components to remount and causes
          issues when edit mode is open and payment fails
        -->
        <div
          v-show="!isSaving"
          class="user-info__display">
          <div
            v-if="displayUserCheckoutInfo"
            class="display__summary">
            <div class="summary__title"
              :class="{'summary__title--connected': hasContact }">
              {{ summaryTitle }}
            </div>
            <div v-if="hasContact"
              class="summary__subtitle">
              {{ summarySubtitle }}
            </div>

            <div v-if="(!isMobile || getGiftItems.length > 0)"
              class="display__products">
              <EmptyCart v-if="isCartEmpty" />

              <CartWithProducts
                v-else
                :display-settings="true" />
            </div>

            <div v-if="!!updatedOtpCredential"
              class="summary__updated-credentials">
              <font-awesome-icon icon="lock"
                class="updated-credentials__icon" />
              {{ credentialsUpdateMessage }}
              <font-awesome-icon icon="times"
                class="updated-credentials__icon"
                @click="clearUpdatedOtpCredentials" />
            </div>

            <div
              v-if="displayConfirmPhone"
              class="summary__confirm-phonenumber"
              @click="onCloseConfirmPhoneNumber">
              <font-awesome-icon
                class="confirm-phonenumber__lock"
                icon="lock" />

              <p class="confirm-phonenumber__text">
                {{ $t('checkout.confirmedPhoneNumber') }}
              </p>

              <font-awesome-icon
                icon="times"
                class="confirm-phonenumber__close" />
            </div>

            <UserEmail
              v-if="!hasContact"
              :disabled="isVerifyingOtpAccount"
              class="summary__email"
              @email-changed="handleAuthInfoChange('email', $event)" />
            <UserPhone
              v-if="!hasContact"
              class="summary__phone"
              :disabled="isVerifyingOtpAccount"
              :default-country="defaultCountry"
              @phone-changed="handleAuthInfoChange('phoneNumber', $event)" />
            <AppError v-if="errorOtpAccount"
              :error="errorOtpAccount" />
            <UserAddresses
              v-if="authInfoCompleted || hasContact"
              ref="address"
              class="summary__addresses"
              :inside-modal="isInsideModal"
              :is-mobile="isMobile"
              @edit-mode-state="handleEditModeState('address', $event)" />
            <UserShipping
              v-if="shippingRequired && (authInfoCompleted || hasContact)"
              class="summary__shipping"
              :inside-modal="isInsideModal"
              :is-mobile="isMobile" />
            <UserPaymentMethods
              v-if="displayPaymentMethods || hasContact"
              ref="creditCard"
              class="summary__payment"
              :inside-modal="isInsideModal"
              :is-mobile="isMobile"
              :display-title="false"
              @edit-mode-state="handleEditModeState('creditCard', $event)" />

            <SubscriptionSettings
              v-if="isMobile && hasSubscriptionItems" />
          </div>

          <CartTotal />

          <UserOptions v-if="!hasContact"
            class="display__options" />
        </div>
      </div>
    </div>

    <div class="checkout__footer">
      <LayoutBrand class="footer__brand" />

      <CheckoutLinkMainFooter
        class="footer__action"
        :action-disabled="mainActionDisabled"
        @pay="handlePay" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import IsInsideModal from '@mixins/isInsideModal.mixin';
  import MobileMixin from '@mixins/isMobile.mixin';
  import { sanitizedParams, userDefaultCountry } from '@/helpers/contact/contact.helper';

  import CartTotal from '@/checkout-link/views/total/cart-total/CartTotal';
  import CheckoutLinkMainFooter from '@/checkout-link/views/footer/MainFooter';

  import CartWithProducts from '@/checkout-link/components/checkout/cart-with-products/CartWithProducts';
  import EmptyCart from '@/checkout-link/components/checkout/empty-cart/EmptyCart';
  import LayoutLoader from '@/checkout-link/components/layout/loader/LayoutLoader';
  import UserAddresses from '@/checkout-link/components/user-addresses/UserAddresses';
  import UserEmail from '@/checkout-link/components/user-email/UserEmail';
  import UserPaymentMethods from '@/checkout-link/components/user-payment-methods/UserPaymentMethods';
  import UserShipping from '@/checkout-link/components/user-shipping/UserShipping';
  import LayoutBrand from '@/checkout-link/components/layout/brand/LayoutBrand.vue';
  import UserPhone from '@/checkout-link/components/user-phone/UserPhone';
  import UserOptions from '@/checkout-link/components/user-options/UserOptions';
  import AppError from '@/checkout-link/components/app/app-error/AppError';
  import SubscriptionSettings from '@/checkout-link/components/subscription/subscription-settings/SubscriptionSettings.vue';

  export default {
    name: 'CheckoutLinkCheckout',
    components: {
      CheckoutLinkMainFooter,
      CartWithProducts,
      CartTotal,
      EmptyCart,
      LayoutLoader,
      LayoutBrand,
      SubscriptionSettings,
      UserAddresses,
      UserPaymentMethods,
      UserEmail,
      UserShipping,
      UserPhone,
      UserOptions,
      AppError,
    },
    mixins: [IsInsideModal, MobileMixin],
    data() {
      return {
        loaded: false,
        isInfoInEditMode: {
          creditCard: null,
          address: null,
        },
        localEmail: null,
        localPhoneNumber: null,
        authInfo: {},
        displayConfirmPhone: false,
        authInfoCompleted: false,
        addressCompleted: false,
        shippingSelected: false,
      };
    },
    computed: {
      ...mapGetters('OrderModule', ['isSaving']),
      ...mapGetters('ContactModule', ['fullName', 'email', 'phoneNumber', 'hasContact', 'updatedOtpCredential', 'isPartial', 'contactId']),
      ...mapGetters('ContactModule/UserTemporaryInfoModule', ['getTempCreditCard', 'getTempStripePaymentMethod', 'getTempAddress', 'getTempEmail']),
      ...mapGetters('CartModule', ['getCart', 'getSelectedShipping', 'hasCheckoutInformation', 'isCartEmpty', 'getSubscriptionItems', 'shippingRequired', 'getGiftItems']),
      ...mapGetters('CheckoutLinkModule', ['hasCheckoutLink']),
      ...mapGetters('FunnelModule', ['hasFunnel', 'isPresellFunnel', 'isFunnelLoading']),
      ...mapGetters('AuthModule', ['errorPhone', 'isSending', 'isVerifyingOtpAccount', 'errorOtpAccount']),
      ...mapGetters('SessionModule', ['countryCode']),
      displayPaymentMethods() {
        return this.shippingRequired ? this.addressCompleted && this.shippingSelected : this.addressCompleted;
      },
      displayUserCheckoutInfo() {
        return this.hasCheckoutLink || this.hasFunnel;
      },
      editableInfoList() {
        return Object.keys(this.isInfoInEditMode);
      },
      isInfoValid() {
        return {
          // The values will exist in store only if they are valid - See logic in User info related components
          creditCard: !!this.getTempCreditCard || !!this.getTempStripePaymentMethod,
          address: !!this.getTempAddress && !!this.getTempAddress.firstname && !!this.getTempAddress.lastname,
        };
      },
      allowProcessing() {
        // Allow payment processing only if info currently in edit mode are valid
        return this.editableInfoList.every(info => !this.isInfoInEditMode[info] || this.isInfoValid[info]);
      },
      mainActionDisabled() {
        return !this.hasCheckoutInformation(this.$router.currentRoute.name) ||
          this.isFunnelLoading ||
          this.isSaving ||
          !this.allowProcessing ||
          this.needsAuthInfo;
      },
      defaultCountry() {
        return userDefaultCountry(this.countryCode, this?.$ssrContext?.acceptsLanguages);
      },
      needsAuthInfo() {
         if (this.hasContact) {
           return false;
         } else {
           return this.localEmail === null || this.localPhoneNumber === null;
         }
      },
      summaryTitle() {
        return this.hasContact ? this.fullName : this.$t('checkout.title');
      },
      summarySubtitle() {
        return `${this.email} | ${this.phoneNumber}`;
      },
      credentialsUpdateMessage() {
        return this.$t(`checkoutLink.authentication.infoUpdate.${this.updatedOtpCredential === 'email' ? 'confirmationMessageEmail' : 'confirmationMessagePhone'}`);
      },
      hasSubscriptionItems() {
        return !!this.getSubscriptionItems.length;
      },
    },
    watch: {
      getSelectedShipping(shipping) {
        if (shipping) {
          this.shippingSelected = true;
        }
      },
      getTempAddress(address) {
        if (address && address.firstname && address.lastname && address.address) {
          this.addressCompleted = true;
        }
      },
    },
    methods: {
      ...mapActions('OrderModule', ['processToCheckoutLink']),
      ...mapActions('CartModule', ['clearCartItems', 'clearFunnelId', 'updateCart']),
      ...mapActions('FunnelModule', ['clearFunnel']),
      ...mapActions('AuthModule', ['verifyOtpAccount', 'sendOtp']),
      ...mapActions('ContactModule', ['clearUpdatedOtpCredentials']),
      // Event handlers
      handleEditModeState(info, isDisplayed) {
        this.isInfoInEditMode[info] = isDisplayed;
      },
      async handlePay() {
        await this._validateEditModesValues();

        if (this.allowProcessing) {
          await this.processToCheckoutLink({ init: false, router: this.$router });
        }
      },
      async handleAuthInfoChange(info, value) {
        if (info === 'email') {
          this.localEmail = value;
        }

        if (info === 'phoneNumber') {
          this.localPhoneNumber = value;
        }

        this.authInfo[info] = value;
        const { email = null, phoneNumber = null } = this.authInfo;

        if (email && phoneNumber) {
          const query = {
            email,
            phoneNumber,
          };

          await this.verifyOtpAccount({ query, router: this.$router });

          this.authInfoCompleted = true;
        }
      },
      // Methods for internal use
      async _validateEditModesValues() {
        if (this.isInfoInEditMode.creditCard) {
          await this.$refs.creditCard?.validateForm(true);
        }

        if (this.isInfoInEditMode.address) {
          await this.$refs.address?.validateForm();
        }
      },
      async _checkInformation() {
        if (this.isCartEmpty) {
          await this._backToInformation();
        } else if (this.$route.name === 'Checkout' && this.getCart?.funnelId && !this.isPresellFunnel) {
          // Prevent cart errors when users go back with their browser, from funnel cart to /checkout
          // If the actual cart has come from a funnel, clear the cart and go back to information
          this.clearCartItems();
          this.clearFunnel();
          this.clearFunnelId();

          await this._backToInformation();
        } else {
          const { email = null, phoneNumber = null, phone = null } = this.$route.query;
          const authenticatePartialUser = (this.isPartial && !this.email && !this.phoneNumber) && (email || phoneNumber || phone);

          if (authenticatePartialUser) {
            const query = sanitizedParams({
              ...((email && { email }) || {}),
              ...(((phoneNumber || phone) && { phoneNumber: phoneNumber || phone }) || {}),
            });

            await this.verifyOtpAccount({ query, router: this.$router, otpPartialContactAuthentication: true });
          }

          this.loaded = true;
          if (process.client) {
            window.dispatchEvent(new CustomEvent('acquirePluginEventStartCheckout', { detail: this.getCart }));
          }

          if (this.hasContact) {
            this.updateCart(this.getCart);
          }
        }
      },
      async _backToInformation() {
        await this.$router.replace({
          name: 'Information',
          params: { ...this.$route.params },
        });
      },
      onCloseConfirmPhoneNumber() {
        this.displayConfirmPhone = false;
      },
    },
    async mounted() {
      await this._checkInformation();

      if (this.$router.currentRoute.params.confirmPhoneNumber) {
        this.displayConfirmPhone = true;
      }
    },
    async serverPrefetch() {
      await this._checkInformation();
    },
  };
</script>

<style lang="scss" scoped>
  $field-spacing: 22px;
  $field-inner-spacing: 8px;

  .checkout {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__content {
      @include scrollBarDisplay();
      flex: 1 1 auto;
      box-sizing: border-box;
      padding: rem(20px);
    }

    &__footer {
      display: flex;
    }
  }

  .content {
    &__cart {
      display: none;
    }

    &__separator {
      display: none;
    }

    &__user-info {
      min-width: 0;
    }
  }

  .cart {
    &__title {
      @include large-heading();
      margin-bottom: rem(24px);
    }
  }

  .user-info {
    &__loader {
      height: 80%;
    }
  }

  .display {
    &__summary,
    &__products {
      margin-bottom: rem(30px);
    }
  }

  .summary {
    &__title {
      @include heading();
      margin-bottom: rem(16px);

      font-weight: $bold;
      line-height: calc(#{$x-large-font-size} + 2px);

      &--connected {
        margin-bottom: 0;
      }
    }

    &__subtitle {
      flex-grow: 1;

      @include body-medium();
      color: var(--tertiary-raised-text);
      line-height: 140%;
      margin-bottom: rem(16px);
    }

    &__updated-credentials {
      @include body-medium();

      position: relative;
      box-sizing: border-box;
      padding: rem(14px) rem(42px);
      margin-bottom: $field-spacing;
      border: 1px solid #2288FF;
      border-radius: 4px;
      background: rgba(34, 136, 255, 0.07);
      color: #2288FF;
    }

    &__addresses,
    &__payment,
    &__shipping {
      margin-bottom: $field-spacing;
    }

    &__confirm-phonenumber {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      background-color: rgba($message-color, 0.1);
      border: 1px solid $message-color;
      border-radius: $radius-default-button;
      padding: 14px;
      cursor: pointer;
    }
  }

  .updated-credentials {
    &__icon {
      position: absolute;

      &:first-child {
        left: 14px;
      }

      &:last-child {
        right: 14px;
        cursor: pointer;
      }
    }
  }

  .confirm-phonenumber {
    &__lock,
    &__text,
    &__close {
      color: $message-color;
      font-size: rem(12px);
    }

    &__text {
      margin: 0 14px;
      line-height: 17px;
      flex: 1;
    }
  }

  .footer {
    &__brand {
      display: none;
    }

    &__action {
      position: initial;
      flex: 1;
    }
  }

  @include breakpoint(medium) {
    .checkout {
      &__content {
        overflow: hidden;
        display: flex;
        margin: 0;
        padding: 0;
      }
    }

    .content {
      &__cart {
        @include scrollBarDisplay();
        display: initial;
        flex: 1 0;
        padding: 0 rem(40px);
      }

      &__separator {
        display: initial;
        flex: initial;
        align-self: center;
        border: none;
        border-right: 1px solid var(--primary-background);
        height: 30vh;
      }

      &__user-info {
        @include scrollBarDisplay();
        flex: 1 0;
        padding: 0 rem(40px) rem(20px) rem(40px);
      }
    }

    .display {
      &__products {
        display: none;
      }
    }

    .footer {
      &__brand {
        display: flex;
        flex: 1;
        margin-right: 0;
        border-top: 1px solid var(--primary-background);
      }
    }
  }
</style>
