export const checkoutLinksRoutes = [
  {
    path: '',
    name: 'Information',
  },

  // AUTH ROUTES DISPLAYED WITH AUTHDISPLAY COMPONENT AS MODALS
  {
    path: 'authentication-code',
    name: 'AuthenticationCode',
    component: () => import(/* webpackChunkName: "authentication-code" */ '../views/authentication-code/AuthenticationCode'),
    meta: {
      isAuthView: true,
    },
    props: route => ({
      isAuthView: !route.meta?.isAuthView,
    }),
  },
  {
    path: 'landline-phone',
    name: 'LandlinePhone',
    component: () => import(/* webpackChunkName: "landline-phone" */ '../views/landline-phone/LandlinePhone'),
    meta: {
      isAuthView: true,
    },
    props: route => ({
      isAuthView: !route.meta?.isAuthView,
    }),
  },
  {
    path: 'info-update',
    name: 'InfoUpdate',
    component: () => import(/* webpackChunkName: "info-update" */ '../views/info-update/InfoUpdate'),
    meta: {
      isAuthView: true,
    },
    props: route => ({
      isAuthView: !route.meta?.isAuthView,
    }),
  },
  {
    path: 'account-selection',
    name: 'AccountSelection',
    component: () => import(/* webpackChunkName: "account-selection" */ '../views/account-selection/AccountSelection'),
    meta: {
      isAuthView: true,
    },
    props: route => ({
      isAuthView: !route.meta?.isAuthView,
    }),
  },

  // MODAL ROUTES
  {
    path: 'preregistration',
    name: 'Preregistration',
    component: () => import(/* webpackChunkName: "registration" */ '../views/pre-registration/Preregistration'),
    meta: {
      modal: true,
    },
    props: route => ({
      insideModal: route.meta?.modal,
    }),
  },
  {
    path: 'checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '../views/checkout/Checkout'),
    meta: {
      modal: true,
      hideBottomBar: true,
    },
    props: route => ({
      insideModal: route.meta?.modal,
    }),
  },
  {
    path: 'details',
    name: 'Details',
    component: () => import(/* webpackChunkName: "detailed-view" */ '../views/details/DetailedView'),
    meta: {
      modal: true,
    },
  },
  {
    path: 'presell',
    name: 'Presell',
    meta: {
      isPresell: true,
    },
  },

  // EXCEPTION ROUTES AS OVERLAY
  {
    path: 'exception',
    name: 'Exception',
    component: () => import(/* webpackChunkName: "exception" */ '../views/exception/Exception'),
    meta: {
      overlay: true,
    },
  },
  {
    path: 'not-opened',
    name: 'NotOpened',
    component: () => import(/* webpackChunkName: "not-opened" */ '../views/not-opened/NotOpened'),
    meta: {
      overlay: true,
    },
  },
];

export const checkoutSuffixRoutes = checkoutLinksRoutes.map(route => route.path);
