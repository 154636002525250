export default {
  API_VERSION_1: 'v1',
  METHOD_GET: 'GET',
  METHOD_POST: 'POST',
  METHOD_PUT: 'PUT',
  METHOD_PATCH: 'PATCH',
  METHOD_DELETE: 'DELETE',
  PREFIX: process.env.VUE_APP_API_PREFIX,
  PATH: process.env.VUE_APP_API_PATH,
  CART_ENDPOINT: 'cart',
  COUPON_ENDPOINT: 'coupon',
  CHECKOUT_ENDPOINT: 'checkout',
  PRE_REGISTER_ENDPOINT: 'checkout/pre-register',
  STANDALONE_ENDPOINT: 'standalone',
  INIT_CHECKOUT_LINK_ENDPOINT: 'init-checkout-link',
  ORDER_ENDPOINT: 'order',
  ORDER_PUBLIC_ENDPOINT: 'public/order',
  CONTACT_ENDPOINT: 'contact',
  EMAIL_ENDPOINT: 'contact/email',
  ADDRESS_ENDPOINT: 'contact/address',
  CREDIT_CARD_ENDPOINT: 'contact/creditcard',
  PHONE_NUMBER_ENDPOINT: 'contact/phonenumber',
  OTP_ENDPOINT: 'otp',
  OTP_CONTACT_ENDPOINT: 'otp/contact',
  OTP_CONTACT_CREDENTIALS_ENDPOINT: 'otp/contact-credentials',
  MASKED_ENDPOINT: 'masked',
  SESSION_ENDPOINT: 'session',
  TAXES_AND_SHIPPING_ENDPOINT: 'taxes_and_shipping',
  FEEDBACK_ENDPOINT: 'feedback',
  SETTINGS_ENDPOINT: 'settings',
  ANALYTICS_ENDPOINT: 'statistics-event',
  TRACKER_FACEBOOK_CONVERSION_API_ENDPOINT: 'facebook-conversion-event',
  FUNNEL: 'cart/funnel',

  buildV1ApiRoute(endpoint, params = []) {
    return [this.PREFIX, this.PATH, this.API_VERSION_1, endpoint, ...params].join('/');
  },

  async buildRouteUrl(endpoint) {
    if (process.client) {
      return new URL(this.buildV1ApiRoute(endpoint));
    } else {
      const url = await import('url');
      return new url.URL(this.buildV1ApiRoute(endpoint));
    }
  },

  buildSessionAndInitCheckoutLinkParameters(fullUrl, checkoutLinkId, ipAddress) {
    return ({
      url: fullUrl,
      ...((checkoutLinkId && { standaloneCheckoutId: checkoutLinkId }) || {}),
      ...((ipAddress && { ip: ipAddress }) || {}),
    });
  },
};
