const allPhoneCountries = [
  ['Afghanistan (‫افغانستان‬‎)', 'af', '93', '701234567'],
  ['Albania (Shqipëri)', 'al', '355', '691234567'],
  ['Algeria (‫الجزائر‬‎)', 'dz', '213', '612345678'],
  ['American Samoa', 'as', '1', '6841234567', 5, ['684']],
  ['Andorra', 'ad', '376', '612345'],
  ['Angola', 'ao', '244', '912345678'],
  ['Anguilla', 'ai', '1', '2647721234', 6, ['264']],
  ['Antigua and Barbuda', 'ag', '1', '2687123456', 7, ['268']],
  ['Argentina', 'ar', '54', '1112345678'],
  ['Armenia (Հայաստան)', 'am', '374', '55123456'],
  ['Aruba', 'aw', '297', '9612345'],
  ['Australia', 'au', '61', '412345678', 0],
  ['Austria (Österreich)', 'at', '43', '67712345678'],
  ['Azerbaijan (Azərbaycan)', 'az', '994', '401234567'],
  ['Bahamas', 'bs', '1', '2423512345', 8, ['242']],
  ['Bahrain (‫البحرين‬‎)', 'bh', '973', '31234567'],
  ['Bangladesh (বাংলাদেশ)', 'bd', '880', '1312345678'],
  ['Barbados', 'bb', '1', '2461234567', 9, ['246']],
  ['Belarus (Беларусь)', 'by', '375', '291234567'],
  ['Belgium (België)', 'be', '32', '456123456'],
  ['Belize', 'bz', '501', '6123456'],
  ['Benin (Bénin)', 'bj', '229', '95421234'],
  ['Bermuda', 'bm', '1', '4411234567', 10, ['441']],
  ['Bhutan (འབྲུག)', 'bt', '975', '17512345'],
  ['Bolivia', 'bo', '591', '71234567'],
  ['Bosnia and Herzegovina (Босна и Херцеговина)', 'ba', '387', '61123456'],
  ['Botswana', 'bw', '267', '71 123 456'],
  ['Brazil (Brasil)', 'br', '55', '11912345678'],
  ['British Indian Ocean Territory', 'io', '246', '3871234'],
  ['British Virgin Islands', 'vg', '1', '2841234567', 11, ['284']],
  ['Brunei', 'bn', '673', '2291234'],
  ['Bulgaria (България)', 'bg', '359', '891234567'],
  ['Burkina Faso', 'bf', '226', '70123456'],
  ['Burundi (Uburundi)', 'bi', '257', '61123456'],
  ['Cambodia (កម្ពុជា)', 'kh', '855', '921234567'],
  ['Cameroon (Cameroun)', 'cm', '237', '612345678'],
  ['Canada', 'ca', '1', '2043456789', 1, ['204', '226', '236', '249', '250', '289', '306', '343', '365', '387', '403', '416', '418', '431', '437', '438', '450', '506', '514', '519', '548', '579', '581', '587', '604', '613', '639', '647', '672', '705', '709', '742', '778', '780', '782', '807', '819', '825', '867', '873', '902', '905']],
  ['Cape Verde (Kabu Verdi)', 'cv', '238', '9123456'],
  ['Caribbean Netherlands', 'bq', '599', '3123456', 1, ['3', '4', '7']],
  ['Cayman Islands', 'ky', '1', '3451234567', 12, ['345']],
  ['Central African Republic (République centrafricaine)', 'cf', '236', '72123456'],
  ['Chad (Tchad)', 'td', '235', '66123456'],
  ['Chile', 'cl', '56', '974712345'],
  ['China (中国)', 'cn', '86', '14812345678'],
  ['Christmas Island', 'cx', '61', '891641234', 2, ['89164']],
  ['Cocos (Keeling) Islands', 'cc', '61', '891621234', 1, ['89162']],
  ['Colombia', 'co', '57', '3101234567'],
  ['Comoros (‫جزر القمر‬‎)', 'km', '269', '3212345'],
  ['Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', 'cd', '243', '801234567'],
  ['Congo (Republic) (Congo-Brazzaville)', 'cg', '242', '012345678'],
  ['Cook Islands', 'ck', '682', '51234'],
  ['Costa Rica', 'cr', '506', '61234567'],
  ['Côte d’Ivoire', 'ci', '225', '01234567'],
  ['Croatia (Hrvatska)', 'hr', '385', '921234567'],
  ['Cuba', 'cu', '53', '51234567'],
  ['Curaçao', 'cw', '599', '3184123', 0],
  ['Cyprus (Κύπρος)', 'cy', '357', '94123456'],
  ['Czech Republic (Česká republika)', 'cz', '420', '601123456'],
  ['Denmark (Danmark)', 'dk', '45', '30123456'],
  ['Djibouti', 'dj', '253', '77612345'],
  ['Dominica', 'dm', '1', '7671234567', 13, ['767']],
  ['Dominican Republic (República Dominicana)', 'do', '1', '8091234567', 2, ['809', '829', '849']],
  ['Ecuador', 'ec', '593', '912345678'],
  ['Egypt (‫مصر‬‎)', 'eg', '20', '1012345678'],
  ['El Salvador', 'sv', '503', '61234567'],
  ['Equatorial Guinea (Guinea Ecuatorial)', 'gq', '240', '222123456'],
  ['Eritrea', 'er', '291', '1711234'],
  ['Estonia (Eesti)', 'ee', '372', '51234567'],
  ['Ethiopia', 'et', '251', '911123456'],
  ['Falkland Islands (Islas Malvinas)', 'fk', '500', '52123'],
  ['Faroe Islands (Føroyar)', 'fo', '298', '512345'],
  ['Fiji', 'fj', '679', '8312345'],
  ['Finland (Suomi)', 'fi', '358', '4571234567', 0],
  ['France', 'fr', '33', '612345678'],
  ['French Guiana (Guyane française)', 'gf', '594', '694123456'],
  ['French Polynesia (Polynésie française)', 'pf', '689', '712345'],
  ['Gabon', 'ga', '241', '2123456'],
  ['Gambia', 'gm', '220', '3012345'],
  ['Georgia (საქართველო)', 'ge', '995', '544123456'],
  ['Germany (Deutschland)', 'de', '49', '15212345678'],
  ['Ghana (Gaana)', 'gh', '233', '501234567'],
  ['Gibraltar', 'gi', '350', '20052200'],
  ['Greece (Ελλάδα)', 'gr', '30', '6901234567'],
  ['Greenland (Kalaallit Nunaat)', 'gl', '299', '211234'],
  ['Grenada', 'gd', '1', '4734112345', 14, ['473']],
  ['Guadeloupe', 'gp', '590', '690123456', 0],
  ['Guam', 'gu', '1', '6711234567', 15, ['671']],
  ['Guatemala', 'gt', '502', '23241234'],
  ['Guernsey', 'gg', '44', '1481123456', 1, ['1481', '7781', '7839', '7911']],
  ['Guinea (Guinée)', 'gn', '224', '63401234'],
  ['Guinea-Bissau (Guiné Bissau)', 'gw', '245', '955612345'],
  ['Guyana', 'gy', '592', '6451234'],
  ['Haiti', 'ht', '509', '34123456'],
  ['Honduras', 'hn', '504', '31234567'],
  ['Hong Kong (香港)', 'hk', '852', '46012345'],
  ['Hungary (Magyarország)', 'hu', '36', '311234567'],
  ['Iceland (Ísland)', 'is', '354', '6123456'],
  ['India (भारत)', 'in', '91', '9012345678'],
  ['Indonesia', 'id', '62', '873123456'],
  ['Iran (‫ایران‬‎)', 'ir', '98', '9901234567'],
  ['Iraq (‫العراق‬‎)', 'iq', '964', '7123456789'],
  ['Ireland', 'ie', '353', '871234567'],
  ['Isle of Man', 'im', '44', '7457612345', 2, ['1624', '74576', '7524', '7924', '7624']],
  ['Israel (‫ישראל‬‎)', 'il', '972', '521234567'],
  ['Italy (Italia)', 'it', '39', '3221234567', 0],
  ['Jamaica', 'jm', '1', '8761234567', 4, ['876', '658']],
  ['Japan (日本)', 'jp', '81', '07012345678'],
  ['Jersey', 'je', '44', '1534123456', 3, ['1534', '7509', '7700', '7797', '7829', '7937']],
  ['Jordan (‫الأردن‬‎)', 'jo', '962', '712345678'],
  ['Kazakhstan (Казахстан)', 'kz', '7', '7011234567', 1, ['33', '7']],
  ['Kenya', 'ke', '254', '701123456'],
  ['Kiribati', 'ki', '686', '63011234'],
  ['Kosovo', 'xk', '383', '44123456'],
  ['Kuwait (‫الكويت‬‎)', 'kw', '965', '51234567'],
  ['Kyrgyzstan (Кыргызстан)', 'kg', '996', '541234567'],
  ['Laos (ລາວ)', 'la', '856', '305123456'],
  ['Latvia (Latvija)', 'lv', '371', '21234567'],
  ['Lebanon (‫لبنان‬‎)', 'lb', '961', '70123456'],
  ['Lesotho', 'ls', '266', '27123456'],
  ['Liberia', 'lr', '231', '4123456'],
  ['Libya (‫ليبيا‬‎)', 'ly', '218', '911234567'],
  ['Liechtenstein', 'li', '423', '7123456'],
  ['Lithuania (Lietuva)', 'lt', '370', '61234567'],
  ['Luxembourg', 'lu', '352', '661123456'],
  ['Macau (澳門)', 'mo', '853', '61234567'],
  ['Macedonia (FYROM) (Македонија)', 'mk', '389', '70123456'],
  ['Madagascar (Madagasikara)', 'mg', '261', '221234567'],
  ['Malawi', 'mw', '265', '771234567'],
  ['Malaysia', 'my', '60', '161234567'],
  ['Maldives', 'mv', '960', '7712345'],
  ['Mali', 'ml', '223', '61234567'],
  ['Malta', 'mt', '356', '99123456'],
  ['Marshall Islands', 'mh', '692', '6351234'],
  ['Martinique', 'mq', '596', '696123456'],
  ['Mauritania (‫موريتانيا‬‎)', 'mr', '222', '24123456'],
  ['Mauritius (Moris)', 'mu', '230', '57123456'],
  ['Mayotte', 'yt', '262', '269123456', 1, ['269', '639']],
  ['Mexico (México)', 'mx', '52', '5512345678'],
  ['Micronesia', 'fm', '691', '9212345'],
  ['Moldova (Republica Moldova)', 'md', '373', '60123456'],
  ['Monaco', 'mc', '377', '91234567'],
  ['Mongolia (Монгол)', 'mn', '976', '70123456'],
  ['Montenegro (Crna Gora)', 'me', '382', '60123456'],
  ['Montserrat', 'ms', '1', '6641234567', 16, ['664']],
  ['Morocco (‫المغرب‬‎)', 'ma', '212', '612345678', 0],
  ['Mozambique (Moçambique)', 'mz', '258', '82123456'],
  ['Myanmar (Burma) (မြန်မာ)', 'mm', '95', '936123456'],
  ['Namibia (Namibië)', 'na', '264', '85123456'],
  ['Nauru', 'nr', '674', '5561234'],
  ['Nepal (नेपाल)', 'np', '977', '9812345678'],
  ['Netherlands (Nederland)', 'nl', '31', '612345678'],
  ['New Caledonia (Nouvelle-Calédonie)', 'nc', '687', '712345'],
  ['New Zealand', 'nz', '64', '221234567'],
  ['Nicaragua', 'ni', '505', '81234567'],
  ['Niger (Nijar)', 'ne', '227', '91234567'],
  ['Nigeria', 'ng', '234', '7011234567'],
  ['Niue', 'nu', '683', '6123'],
  ['Norfolk Island', 'nf', '672', '351234'],
  ['North Korea (조선 민주주의 인민 공화국)', 'kp', '850', '1912345678'],
  ['Northern Mariana Islands', 'mp', '1', '6701234567', 17, ['670']],
  ['Norway (Norge)', 'no', '47', '59123456', 0],
  ['Oman (‫عُمان‬‎)', 'om', '968', '91234567'],
  ['Pakistan (‫پاکستان‬‎)', 'pk', '92', '3012345678'],
  ['Palau', 'pw', '680', '7791234'],
  ['Palestine (‫فلسطين‬‎)', 'ps', '970', '591234567'],
  ['Panama (Panamá)', 'pa', '507', '61234567'],
  ['Papua New Guinea', 'pg', '675', '70123456'],
  ['Paraguay', 'py', '595', '991234567'],
  ['Peru (Perú)', 'pe', '51', '912345678'],
  ['Philippines', 'ph', '63', '9051234567'],
  ['Poland (Polska)', 'pl', '48', '501234567'],
  ['Portugal', 'pt', '351', '921123456'],
  ['Puerto Rico', 'pr', '1', '7871234567', 3, ['787', '939']],
  ['Qatar (‫قطر‬‎)', 'qa', '974', '33123456'],
  ['Réunion (La Réunion)', 're', '262', '692123456', 0],
  ['Romania (România)', 'ro', '40', '701234567'],
  ['Russia (Россия)', 'ru', '7', '9021234567', 0],
  ['Rwanda', 'rw', '250', '783123456'],
  ['Saint Barthélemy', 'bl', '590', '690123456', 1],
  ['Saint Helena', 'sh', '290', '62158'],
  ['Saint Kitts and Nevis', 'kn', '1', '8691234567', 18, ['869']],
  ['Saint Lucia', 'lc', '1', '7581234567', 19, ['758']],
  ['Saint Martin (Saint-Martin (partie française))', 'mf', '590', '690123456', 2],
  ['Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', 'pm', '508', '551234'],
  ['Saint Vincent and the Grenadines', 'vc', '1', '7841234567', 20, ['784']],
  ['Samoa', 'ws', '685', '7771234'],
  ['San Marino', 'sm', '378', '612345678'],
  ['São Tomé and Príncipe (São Tomé e Príncipe)', 'st', '239', '9812345'],
  ['Saudi Arabia (‫المملكة العربية السعودية‬‎)', 'sa', '966', '501234567'],
  ['Senegal (Sénégal)', 'sn', '221', '701012345'],
  ['Serbia (Србија)', 'rs', '381', '621234567'],
  ['Seychelles', 'sc', '248', '2512345'],
  ['Sierra Leone', 'sl', '232', '76123456'],
  ['Singapore', 'sg', '65', '81234567'],
  ['Sint Maarten', 'sx', '1', '7211234567', 21, ['721']],
  ['Slovakia (Slovensko)', 'sk', '421', '901123456'],
  ['Slovenia (Slovenija)', 'si', '386', '30123456'],
  ['Solomon Islands', 'sb', '677', '7412345'],
  ['Somalia (Soomaaliya)', 'so', '252', '1234567'],
  ['South Africa', 'za', '27', '711234567'],
  ['South Korea (대한민국)', 'kr', '82', '123456789'],
  ['South Sudan (‫جنوب السودان‬‎)', 'ss', '211', '921234567'],
  ['Spain (España)', 'es', '34', '612345678'],
  ['Sri Lanka (ශ්‍රී ලංකාව)', 'lk', '94', '701234567'],
  ['Sudan (‫السودان‬‎)', 'sd', '249', '923123456'],
  ['Suriname', 'sr', '597', '7112345'],
  ['Svalbard and Jan Mayen', 'sj', '47', '79123456', 1, ['79']],
  ['Swaziland', 'sz', '268', '71234567'],
  ['Sweden (Sverige)', 'se', '46', '761234567'],
  ['Switzerland (Schweiz)', 'ch', '41', '751234567'],
  ['Syria (‫سوريا‬‎)', 'sy', '963', '931234567'],
  ['Taiwan (台灣)', 'tw', '886', '912345678'],
  ['Tajikistan', 'tj', '992', '372212345'],
  ['Tanzania', 'tz', '255', '612345678'],
  ['Thailand (ไทย)', 'th', '66', '612345678'],
  ['Timor-Leste', 'tl', '670', '78123456'],
  ['Togo', 'tg', '228', '91112345'],
  ['Tokelau', 'tk', '690', '7123'],
  ['Tonga', 'to', '676', '15123'],
  ['Trinidad and Tobago', 'tt', '1', '8681234567', 22, ['868']],
  ['Tunisia (‫تونس‬‎)', 'tn', '216', '21234567'],
  ['Turkey (Türkiye)', 'tr', '90', '5551212'],
  ['Turkmenistan', 'tm', '993', '61123456'],
  ['Turks and Caicos Islands', 'tc', '1', '6491234567', 23, ['649']],
  ['Tuvalu', 'tv', '688', '90123'],
  ['U.S. Virgin Islands', 'vi', '1', '3401234567', 24, ['340']],
  ['Uganda', 'ug', '256', '712123456'],
  ['Ukraine (Україна)', 'ua', '380', '501234567'],
  ['United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', 'ae', '971', '501234567'],
  ['United Kingdom', 'gb', '44', '7112345678', 0],
  ['United States', 'us', '1', '2131234567', 0],
  ['Uruguay', 'uy', '598', '99123456'],
  ['Uzbekistan (Oʻzbekiston)', 'uz', '998', '901234567'],
  ['Vanuatu', 'vu', '678', '5721234'],
  ['Vatican City (Città del Vaticano)', 'va', '39', '0669812345', 1, ['06698']],
  ['Venezuela', 've', '58', '4121234567'],
  ['Vietnam (Việt Nam)', 'vn', '84', '512345678'],
  ['Wallis and Futuna (Wallis-et-Futuna)', 'wf', '681', '401234'],
  ['Western Sahara (‫الصحراء الغربية‬‎)', 'eh', '212', '528812345', 1, ['5288', '5289']],
  ['Yemen (‫اليمن‬‎)', 'ye', '967', '712345678'],
  ['Zambia', 'zm', '260', '951234567'],
  ['Zimbabwe', 'zw', '263', '712123456'],
  ['Åland Islands', 'ax', '358', '4571234567', 1, ['18']],
];

const getMappedPhoneCountries = () => {
  return allPhoneCountries.map((country) => ({
    name: country[0],
    iso2: country[1],
    dialCode: country[2],
    nsn: country[3],
    priority: country[4] || 0,
    areaCodes: country[5] || null,
  }));
};

const findPhoneCountryByCode = (iso2) => {
  return getMappedPhoneCountries().find((country) => country.iso2 === iso2) || null;
};

export {
  allPhoneCountries,
  getMappedPhoneCountries,
  findPhoneCountryByCode,
};
