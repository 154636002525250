import UserService from '@/services/user/user.service';

export const EmptyUser = {
  _id: '',
  siteId: '',
  sessionId: '',
  cartId: '',
  contactId: '',
  contact: {
    // _id: '',
    // firstname: '',
    // lastname: '',
    // email: '',
    // accountIds: [
    //   {
    //     accountId: '',
    //     siteId: ''
    //   }
    // ],
    // creditCards: [
    //   {
    //     _id: '',
    //     token: '',
    //     cardType: '',
    //     paymentMethod: '',
    //     last4: '',
    //     expirationMonth: '',
    //     expirationYear: ''
    //   }
    // ],
    // addresses: [
    //   {
    //     _id: '',
    //     line1: '',
    //     city: '',
    //     province: '',
    //     zip: '',
    //     country: ''
    //   }
    // ],
    // phoneNumber: ''
  },
  userId: null,
  mfaTime: '',
};

const state = () => ({
  user: { ...EmptyUser },
  feedback: {
    isSending: false,
    isSent: false,
    error: null,
  },
});

const actions = {
  setUser({ commit }, user) {
    commit('SET_USER', user);
  },
  updateUser({ commit }, user) {
    commit('UPDATE_USER', user);
  },
  async sendFeedback({ commit }, feedback) {
    commit('SEND_FEEDBACK_REQUEST');

    try {
      await UserService.sendFeedback(feedback);
      commit('SEND_FEEDBACK_SUCCESS');
    } catch (error) {
      commit('SEND_FEEDBACK_FAILURE', error);

      throw error;
    }
  },
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  UPDATE_USER(state, user) {
    state.user = { ...state.user, ...user };
  },
  SEND_FEEDBACK_REQUEST(state) {
    state.feedback.isSending = true;
    state.feedback.isSent = false;
    state.feedback.error = null;
  },
  SEND_FEEDBACK_SUCCESS(state) {
    state.feedback.isSending = false;
    state.feedback.isSent = true;
    state.feedback.error = null;
  },
  SEND_FEEDBACK_FAILURE(state, error) {
    state.feedback.isSending = false;
    state.feedback.isSent = false;
    state.feedback.error = error;
  },
};

const getters = {
  user: state => state.user,
  feedback: state => state?.feedback,
  isFeedbackSending: state => state?.feedback?.isSending,
  isFeedbackSent: state => state?.feedback?.isSent,
  errorFeedback: state => state?.feedback?.error,
};

export const UserModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
