<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    height="48"
    width="48"
    fill="currentColor"><path d="m17.3 45-3.8-6.5-7.55-1.55.85-7.35L2 24l4.8-5.55-.85-7.35 7.55-1.55L17.3 3 24 6.1 30.7 3l3.85 6.55 7.5 1.55-.85 7.35L46 24l-4.8 5.6.85 7.35-7.5 1.55L30.7 45 24 41.9Zm4.55-14.35L33.2 19.4l-2.25-2.05-9.1 9-4.75-4.95-2.3 2.25Z" /></svg>
</template>

<script>
  export default {
    name: 'IconVerified',
  };
</script>
