import checkoutService from '@/services/checkout/checkout.service';
import {
  getPaymentIcon,
  getPaymentMethodDisplay, OrderStatus,
  getCreditCardParameters, getAddressParameters,
  isOrderPaymentLink,
  isOrderPaymentAfterpay,
} from '@/helpers/order/order.helper';
import analyticsTools from '@/plugins/analyticsTools';
import { BUY_CLICK, FUNNEL_PURCHASED, FUNNEL_VIEWED } from '@/services/analytics/analytics.event';
import spreedly from '@/plugins/spreedly';
import { AcquireError } from '@/services/http/http.service';
import { getCookieSessionExpire, getCookie } from '@/helpers/session/session.helper';
import { loadStripe } from '@stripe/stripe-js';

export const EmptyOrder = {
  price: {
    currency: '',
    original: {
      totalATI: 0,
      totalET: 0,
      totalTax: 0,
      taxes: [
        {
          taxId: '',
          name: '',
          percentage: 0,
          totalTax: 0,
          totalET: 0,
        },
        {
          taxId: '',
          name: '',
          totalTax: 0,
          totalET: 0,
        },
      ],
    },
    effective: {
      totalATI: 0,
      totalET: 0,
      totalTax: 0,
      totalDiscount: 0,
      taxes: [
        {
          taxId: '',
          name: '',
          percentage: 0,
          totalTax: 0,
          totalET: 0,
        },
      ],
    },
  },
  coupons: [],
  confirmationFlag: 0,
  status: '',
  orderNumber: 0,
  items: [
    // {
    //   id: '',
    //   taxable: true,
    //   taxPercentage: 0,
    //   price: {
    //     original: {
    //       totalATI: 0,
    //       totalET: 0,
    //       totalTax: 0
    //     },
    //     effective: {
    //       totalATI: 0,
    //       totalET: 0,
    //       totalTax: 0,
    //       discount: 0,
    //       coupon: '',
    //     }
    //   },
    //   quantity: 0,
    //   variantId: '',
    //   label: '',
    //   url: '',
    //   imageUrl: '',
    //   categories: [],
    //   collection: ''
    // }
  ],
  shipping: {
    carrier: '',
    trackingNumber: '',
    method: '',
    price: {
      original: {
        totalATI: 0,
        totalET: 0,
        totalTax: 0,
      },
      effective: {
        totalATI: 0,
        totalET: 0,
        totalTax: 0,
        totalDiscount: 0,
        coupon: '',
      },
    },
  },
  transaction: {
    token: '',
    paymentMethod: '',
    paymentType: '',
    last4: '',
    total: 0,
  },
  contact: {
    address: {
      firstname: '',
      lastname: '',
      line1: '',
      line2: '',
      city: '',
      zip: '',
      province: '',
      country: '',
    },
    email: '',
    phoneNumber: '',
  },
  _id: '',
  insertTime: '',
};

const state = () => ({
  order: { ...EmptyOrder },
  threeDsChallenge: false,
  status: {
    error: null,
    isSaving: false,
    isLoading: false,
  },
  timer: {
    timer: 0,
    isOrderEditable: true,
  },
});

const actions = {
  async processToCheckoutLink({ getters, dispatch, rootGetters }, { init = false, router, buyNow = false }) {
    const hasFunnel = rootGetters['FunnelModule/hasFunnel'];
    const isPresellFunnel = rootGetters['FunnelModule/isPresellFunnel'];

    if (init) {
      if (buyNow && hasFunnel && isPresellFunnel) {
        dispatch('FunnelModule/setBuyNow', buyNow, { root: true });
      }

      await router.push({
        name: hasFunnel && !isPresellFunnel ? 'FunnelCheckout' : 'Checkout',
        params: { ...router.currentRoute.params },
      });
    } else {
      if (router.currentRoute.name === 'Checkout' && !rootGetters['FunnelModule/isBuyNowSelected']) {
        // Check if there is an existing presell funnel, only the first time
        if (!rootGetters['FunnelModule/hasBeenChecked']) {
          await dispatch('FunnelModule/loadFunnel', false, { root: true });
        }

        // If there is an existing funnel, and it has not been ignored by customer, display offer in specific route
        if (rootGetters['FunnelModule/hasFunnel'] && rootGetters['FunnelModule/isPresellFunnel'] && !buyNow) {
          return await router.push({
            name: 'Presell',
            params: { ...router.currentRoute.params },
          });
        }
      }

      const data = await dispatch('saveCheckoutFromCartCheckoutLink', { router });
      if (data?.status === OrderStatus.PAID) {
        if (rootGetters['FunnelModule/hasFunnel']) {
          await dispatch('FunnelModule/clearFunnel', null, { root: true });
        } else {
          dispatch('AnalyticsModule/addCheckoutEvent', BUY_CLICK, { root: true });
        }

        // Clear all temporary user info from edit modes at checkout
        await dispatch('ContactModule/UserTemporaryInfoModule/cleanUserTempInfo', null, { root: true });
        await router.push({ name: 'Confirmation', params: { orderId: data._id } });
      }
    }
  },
  async saveCheckoutFromCartCheckoutLink({ commit, dispatch, getters, rootGetters }, { router }) {
    commit('SAVE_CHECKOUT_REQUEST');

    try {
      const payload = getters.getOrderParametersFromCartCheckoutLink;
      const orderData = await checkoutService.saveCheckoutFromCartCheckoutLink(payload, rootGetters['ContactModule/contactId']);
      await dispatch('handlePaymentProcess', { orderData, router });

      // Event use case for POST SELL
      if (orderData.funnelId && orderData.funnelTriggerOrderId) {
        const data = { event: FUNNEL_PURCHASED, data: { status: FUNNEL_PURCHASED, baseOrderId: orderData.funnelTriggerOrderId, funnelId: orderData.funnelId, funnelOrderId: orderData._id } };
        dispatch('AnalyticsModule/addFunnelEvent', data, { root: true });
      }
      // Event use case for PRE SELL
      if (orderData.funnelId && !orderData?.funnelTriggerOrderId) {
        const data = { event: FUNNEL_PURCHASED, data: { status: FUNNEL_PURCHASED, baseOrderId: orderData._id, funnelId: orderData.funnelId, funnelOrderId: orderData._id } };
        dispatch('AnalyticsModule/addFunnelEvent', data, { root: true });
      }

      // Event use case for POST SELL funnel view
      if (orderData.funnel) {
        const data = { event: FUNNEL_VIEWED, data: { status: FUNNEL_VIEWED, baseOrderId: orderData._id, funnelId: orderData.funnel._id, funnelOrderId: null } };
        dispatch('AnalyticsModule/addFunnelEvent', data, { root: true });
      }

      return orderData;
    } catch (error) {
      if (['error.max.order.exceeded', 'error.max.linkorder.exceeded'].includes(error.key)) {
        dispatch('CheckoutLinkModule/setExceptionMaxOrder', router, { root: true });
      }

      dispatch('saveCheckoutFailure', error);

      // If checkout failure from presell offer, redirect to checkout
      if (router.currentRoute.name === 'Presell') {
        await router.push({
          name: 'Checkout',
          params: { ...router.currentRoute.params },
        });
      }

      // If checkout failure from postsell offer, redirect to funnel checkout
      if (rootGetters['FunnelModule/hasFunnel'] && router.currentRoute.name === 'Confirmation') {
        await router.push({
          name: 'FunnelCheckout',
          params: { ...router.currentRoute.params },
        });
      }

      throw error;
    }
  },
  async handlePaymentProcess({ dispatch, commit, getters, rootGetters }, { orderData, router }) {
    if (orderData.status === OrderStatus.PAID) {
      await dispatch('orderSuccessCallback', orderData);
      commit('SAVE_CHECKOUT_SUCCESS', orderData);
    }

    if (orderData.status === OrderStatus.CHALLENGE) {
      commit('SAVE_CHECKOUT_CHALLENGE');

      if (orderData.transaction.stripePaymentIntentClientSecret) {
        await dispatch('stripeConfirmCardPayment', { orderData, router });
      } else {
        await dispatch('spreedlyCreateChallengeLifecycle', { orderData, router });
      }
    }

    // If the user registers for his first order, authenticate him
    // This logic MUST be done after "orderSuccessCallback" because we need to clear some stuff in store before updating contact store
    if (!!orderData.contact) {
      const userData = {
        _id: orderData.contact._id, // Check if this is supposed to be the contact id
        siteId: orderData.siteId,
        sessionId: rootGetters['SessionModule/sessionId'],
        cartId: orderData.cartId,
        contactId: orderData.contactId,
        contact: orderData.contact,
        // userId: ???,
        // mfaTime: ???,
      };

      dispatch('UserModule/setUser', userData, { root: true });
      await dispatch('ContactModule/updateContact', orderData.contact, { root: true });
    }
  },
  async orderSuccessCallback({ dispatch, rootGetters }, orderData) {
    analyticsTools.updateGtmVar(
      orderData.contactId,
      orderData.transaction.email,
      orderData.transaction.address.firstname,
      orderData.transaction.address.lastname,
    );

    dispatch('CartModule/clearCartForPostSell', null, { root: true });
    dispatch('SessionModule/loadSession', null, { root: true });
  },
  async loadOrder({ commit, dispatch, rootGetters }, orderId) {
    commit('LOAD_ORDER_REQUEST');

    const sessionId = rootGetters['SessionModule/sessionId'];
    const siteId = rootGetters['SessionModule/siteId'];
    const acquireContactId = rootGetters['ContactModule/contactId'];
    const acquireContact = rootGetters['ContactModule/contact'];

    // Define if we need to load a public confirmation order without sensitive data
    const isPublicRequest = !sessionId || !siteId || !acquireContactId;

    try {
      const data = await checkoutService.loadOrder(orderId, isPublicRequest);
      const { siteId, theme, funnel, checkoutLinkSettings, checkoutLinkCampaign, ...order } = data;

      await dispatch('SessionModule/setSiteId', siteId, { root: true });
      await dispatch('SessionModule/loadSettings', null, { root: true });

      if (theme) {
        await dispatch('ThemeModule/setTheme', theme, { root: true });
      }

      if (!!funnel && !!checkoutLinkSettings) {
        await dispatch('CheckoutLinkModule/setSettings', checkoutLinkSettings, { root: true });
      }

      if (!!checkoutLinkCampaign) {
        await dispatch('CheckoutLinkModule/setCampaign', checkoutLinkCampaign, { root: true });
      }

      !!funnel
        ? await dispatch('FunnelModule/setFunnel', { funnel, isPresell: false, isPostsell: true }, { root: true })
        : await dispatch('FunnelModule/clearFunnel', null, { root: true });

      commit('LOAD_ORDER_SUCCESS', { ...order });

      // Trigger purchase plugins event (only once)
      if (process.client && !isPublicRequest && order.number) {
        const orderEventCookie = getCookie(`acquireEventOrder${order._id}`);
        if (orderEventCookie) {
          return;
        }

        const orderEventData = {
          ...order,
          contact: acquireContact,
        };

        window.dispatchEvent(new CustomEvent('acquirePluginEventPurchase', { detail: orderEventData }));
        document.cookie = `acquireEventOrder${order._id}=1; path=/; SameSite=None; expires=${getCookieSessionExpire(720)}; Secure`;
      }

      return { ...order };
    } catch (error) {
      commit('LOAD_ORDER_FAILURE', error);
      throw error;
    }
  },
  updateTimer({ commit }, timer) {
    commit('UPDATE_TIMER', timer);
  },
  endTimer({ commit }) {
    commit('END_TIMER');
  },
  saveCheckoutFailure({ commit }, error) {
    commit('SAVE_CHECKOUT_FAILURE', error);
  },
  saveCheckoutChallengeSuccess({ commit }) {
    commit('SAVE_CHECKOUT_CHALLENGE_SUCCESS');
  },
  spreedlyCreateChallengeLifecycle({ commit, dispatch }, { orderData, router }) {
    if (!process.client || typeof window.Spreedly === 'undefined') {
      return null;
    }

    spreedly.recreateIframe();
    spreedly.createDomElements();

    // Create Spreedly 3DS Lifecycle
    const lifecycle = new window.Spreedly.ThreeDS.Lifecycle({
      environmentKey: orderData.environmentKey,
      hiddenIframeLocation: 'spreedly-device-fingerprint',
      challengeIframeLocation: 'spreedly-challenge',
      transactionToken: orderData.transaction.token,
    });

    const challengeCompleteAction = async(event, orderId) => {
      const completionData = await checkoutService.completeCheckout(event.token);
      const isPending = completionData?.isPending;
      const isSuccessful = completionData?.isSuccessful;

      await challengeErrorAction();
      if (isSuccessful) {
        document.getElementById('spreedly-challenge-modal').classList.remove('show');
        dispatch('saveCheckoutChallengeSuccess');
        dispatch('CartModule/clearCartForPostSell', null, { root: true });

        await router.push({ name: 'Confirmation', params: { orderId: orderId } });
      } else if (isPending) {
        event.finalize(completionData?.transaction);
      } else {
        await challengeErrorAction();
      }
    };

    const challengeErrorAction = async() => {
      const error = new AcquireError({ message: 'error.payment.failure' });
      dispatch('saveCheckoutFailure', error);
      document.getElementById('spreedly-challenge-modal').classList.remove('show');

      await router.push({ name: 'Checkout' });
    };

    const spreedlyStatusUpdates = async function(event) {
      const action = event?.action;
      if (action === 'succeeded' || action === 'trigger-completion') {
        await challengeCompleteAction(event, orderData?.orderId);
      } else if (action === 'error' || action === 'finalization-timeout') {
        await challengeErrorAction();
      } else if (action === 'challenge') {
        document.getElementById('spreedly-challenge-modal').classList.add('show');
      }
    };

    window.Spreedly.on('3ds:status', spreedlyStatusUpdates);

    lifecycle.start();
  },
  async stripeConfirmCardPayment({ dispatch, rootGetters }, { orderData, router }) {
    if (!process.client || !loadStripe) {
      return null;
    }

    const challengeErrorAction = async() => {
      dispatch('saveCheckoutFailure', new AcquireError({ message: 'error.payment.failure' }));
    };

    const challengeCompleteAction = async(paymentIntentId, orderId) => {
      const { isSuccessful } = await checkoutService.completeCheckout(paymentIntentId);

      if (isSuccessful) {
        dispatch('saveCheckoutChallengeSuccess');
        dispatch('CartModule/clearCartForPostSell', null, { root: true });

        await router.push({ name: 'Confirmation', params: { orderId: orderId } });
      } else {
        await challengeErrorAction();
      }
    };

    const stripeClient = await loadStripe(rootGetters['SessionModule/settingsStripePublishableKey']);

    const confirmationMethod =
      orderData.transaction.paymentMethodType === 'afterpay_clearpay'
        ? stripeClient.confirmAfterpayClearpayPayment
        : stripeClient.confirmCardPayment;

    const result = await confirmationMethod(orderData.transaction.stripePaymentIntentClientSecret);

    if (result.error) {
      await challengeErrorAction();
    } else if (result.paymentIntent) {
      await challengeCompleteAction(result.paymentIntent.id, orderData.orderId);
    }
  },
  clearOrderError({ commit }) {
    commit('CLEAR_ORDER_ERROR');
  },
};

const mutations = {
  SAVE_CHECKOUT_REQUEST(state) {
    state.status.isSaving = true;
    state.status.error = null;
    state.threeDsChallenge = false;
  },
  SAVE_CHECKOUT_SUCCESS(state, order) {
    state.status.isSaving = false;
    state.status.error = null;
    state.order = order;
    state.threeDsChallenge = false;
  },
  SAVE_CHECKOUT_CHALLENGE(state) {
    state.status.isSaving = true;
    state.status.isLoading = true;
    state.status.error = null;
    state.threeDsChallenge = true;
  },
  SAVE_CHECKOUT_CHALLENGE_SUCCESS(state) {
    state.status.isSaving = false;
    state.status.isLoading = false;
    state.status.error = null;
    state.threeDsChallenge = false;
  },
  SAVE_CHECKOUT_FAILURE(state, error) {
    state.status.isSaving = false;
    state.status.error = error;
    state.threeDsChallenge = false;
  },
  LOAD_ORDER_REQUEST(state) {
    state.status.isLoading = true;
    state.status.error = null;
    state.threeDsChallenge = false;
  },
  LOAD_ORDER_SUCCESS(state, order) {
    state.status.isLoading = false;
    state.status.error = null;
    state.order = order;
    state.threeDsChallenge = false;
  },
  LOAD_ORDER_FAILURE(state, error) {
    state.status.isLoading = false;
    state.status.error = error;
    state.threeDsChallenge = false;
  },
  CLEAR_ORDER_ERROR(state) {
    state.status.error = null;
  },
  UPDATE_TIMER(state, timer) {
    state.timer.timer = timer;
  },
  END_TIMER(state) {
    state.timer.isOrderEditable = false;
  },
};

const getters = {
  getOrder: state => state?.order,
  isSaving: state => state.status?.isSaving,
  isLoading: state => state.status?.isLoading,
  orderError: state => state.status?.error,
  orderTimer: state => state?.timer?.timer || 0,
  isOrderEditable: state => state?.timer?.isOrderEditable || false,
  orderId: (_, { getOrder }) => getOrder?._id,
  orderTimeCreated: (_, { getOrder }) => getOrder?.insertTime,
  hasOrder: (_, { orderId }) => !!orderId,
  orderNumber: (_, { getOrder }) => getOrder?.number,
  orderTotal: (_, { getOrder }) => getOrder?.total,
  orderCurrency: (_, { getOrder }) => getOrder?.total?.currency || 'CAD',
  orderAddress: (_, { getOrder }) => getOrder?.transaction?.address,
  orderPayment: (_, { getOrder }) => getPaymentMethodDisplay(getOrder?.transaction),
  orderPaymentIcon: (_, { getOrder }) => getPaymentIcon(getOrder?.transaction),
  orderPaymentIdentifier: (_, { getOrder }) => getOrder?.transaction?.paymentIdentifier,
  orderItems: (_, { getOrder, orderCurrency }) => getOrder?.items?.map(item => ({
    ...item,
    currency: orderCurrency,
    price: item.total?.effective?.totalATI || 0,
    totalDiscountedPrice: item.discountPrice !== 'undefined' && item.discountPrice !== item.unitPrice ? item.discountPrice * item.quantity : null,
    variant_options: item.options?.map(o => (!!o.values && o.values[0]) || o.value) || [],
  })) || [],
  orderEmail: (_, { getOrder }) => getOrder?.transaction?.email,
  orderTransaction: (_, { getOrder }) => getOrder?.transaction,
  subscriptionRenewalAmount: (_, { getOrder }) => getOrder?.subscriptions?.length > 0 ? getOrder?.subscriptions[0]?.total.effective.totalATI + getOrder?.subscriptions[0]?.total.effective.totalET : null,
  subscriptionFrequency: (_, { getOrder }) => getOrder?.subscriptions?.length > 0 ? getOrder?.subscriptions[0]?.frequency : null,
  orderSummary: (_, {
    orderId,
    orderNumber,
    orderTotal,
    orderCurrency,
    orderAddress,
    orderPayment,
    orderPaymentIcon,
    orderPaymentIdentifier,
    orderItems,
    orderEmail,
    orderTransaction,
    subscriptionRenewalAmount,
    subscriptionFrequency,
  }) => ({
    _id: orderId,
    number: orderNumber,
    currency: orderCurrency,
    address: orderAddress,
    payment: { name: orderPayment, icon: orderPaymentIcon, identifier: orderPaymentIdentifier, isLink: isOrderPaymentLink(orderPayment), isAfterpay: isOrderPaymentAfterpay(orderPayment) },
    items: orderItems,
    addedPrice: null, // TODO: to change with checkoutLinks
    email: orderEmail,
    price: orderTotal?.original?.totalET || 0,
    discount: -orderTotal?.effective?.discount || 0,
    taxes: orderTotal?.effective?.totalTax || 0,
    shipping: orderTotal?.shipping?.totalET || 0,
    total: orderTransaction?.amount,
    renewalAmount: subscriptionRenewalAmount,
    frequency: subscriptionFrequency,
  }),
  getOrderParametersFromCart: (_, getters, __, rootGetters) => ({
    ...getAddressParameters(rootGetters['ContactModule/UserTemporaryInfoModule/getTempAddress'], rootGetters['CartModule/getSelectedAddress']?._id || null),
    ...getCreditCardParameters(rootGetters['ContactModule/UserTemporaryInfoModule/getTempCreditCard'], rootGetters['CartModule/getSelectedCreditCard']?._id || null, rootGetters['ContactModule/UserTemporaryInfoModule/getTempStripePaymentMethod']),
    shippingId: rootGetters['CartModule/getSelectedShipping']?.id,
    coupons: rootGetters['CartModule/coupons'],
    subscriptionsShipping: rootGetters['CartModule/getCartSubscriptionsShipping'],
    contact: {
      ...rootGetters['ContactModule/contact'],
      email: rootGetters['ContactModule/UserTemporaryInfoModule/getTempEmail'] || rootGetters['CartModule/getSelectedEmail'] || rootGetters['ContactModule/email'],
      phoneNumber: rootGetters['ContactModule/phoneNumber'] || rootGetters['ContactModule/UserTemporaryInfoModule/getTempPhone'],
    },
    paymentMethod: 'CREDITCARD',
  }),
  getOrderParametersFromCartCheckoutLink: (_, { getOrderParametersFromCart, orderId }, __, rootGetters) => ({
    ...getOrderParametersFromCart,
    cartId: rootGetters['CartModule/getCartId'],
    paymentMethod: 'CREDITCARD',
    // If no funnel, or funnel exists and is presell, add checkoutLinkId to payload
    ...(((!rootGetters['FunnelModule/hasFunnel'] || rootGetters['FunnelModule/isPresellFunnel']) &&
        { checkoutLinkId: rootGetters['CheckoutLinkModule/getCheckoutLink']?.id }) || {}),
    // If funnel exists, and is presell with added products to cart, or is not presell, add funnelId to payload
    ...(((rootGetters['FunnelModule/hasFunnel'] &&
        ((rootGetters['FunnelModule/isPresellFunnel'] && !rootGetters['FunnelModule/presellIgnored']) || !rootGetters['FunnelModule/isPresellFunnel'])) &&
        { funnelId: rootGetters['FunnelModule/getFunnelId'] }) || {}),
    // If funnel exists, and funnel is not presell, add orderId to payload
    ...((rootGetters['FunnelModule/hasFunnel'] && !rootGetters['FunnelModule/isPresellFunnel'] && { orderId }) || {}),
    ...(((rootGetters['CheckoutLinkModule/productIds']) &&
      { dynamicProductIds: rootGetters['CheckoutLinkModule/productIds'] }) || {}),
    ...(((rootGetters['CheckoutLinkModule/productIdsSubscriptionOnly']) &&
      { productIdsSubscriptionOnly: rootGetters['CheckoutLinkModule/productIdsSubscriptionOnly'] }) || {}),
    ...(((rootGetters['CheckoutLinkModule/productIdsSubscriptionHybrid']) &&
      { productIdsSubscriptionHybrid: rootGetters['CheckoutLinkModule/productIdsSubscriptionHybrid'] }) || {}),
  }),
};

export const OrderModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
