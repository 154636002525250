export default {
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    checkMobile() {
      this.isMobile = this.isOnMobile();
    },
    isOnMobile(mobileWidth = '980') {
      return process.client && window.innerWidth <= mobileWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkMobile);

    this.$nextTick(this.checkMobile);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkMobile);
  },
};
