<template>
  <div class="checkout-link-exception">
    <div class="checkout-link-exception__card">
      <div
        v-if="isOpenedBoxIcon"
        class="card__icon-box">
        <IconOpenedBox />
      </div>

      <div
        v-else-if="isClosedBoxIcon"
        class="card__icon-box">
        <IconClosedBox />
      </div>

      <div
        v-else-if="isClosedSign"
        class="card__icon-sign">
        <IconClosedSign />
      </div>

      <div
        v-else
        class="card__icon-lock">
        <IconLock />
      </div>

      <div
        v-if="getExceptionPageMessage"
        class="card__text">
        <div
          v-if="firstname"
          class="text__title">
          {{ $t(`${getExceptionPageMessage}.title.logged`, { name: firstname }) }}
        </div>
        <div
          v-else
          class="text__title">
          {{ $t(`${getExceptionPageMessage}.title.unlogged`) }}
        </div>

        <div class="text__description">
          {{ $t(`${getExceptionPageMessage}.description`, { max: getCheckoutLinkMaxOrderAmount }) }}
        </div>
      </div>

      <div
        v-if="isNotOpenedYetException && !isOverException"
        class="card__countdown">
        <CountDown
          :date="getCheckoutLinkStartDate"
          @done="reloadPage" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import {
    isMaxOrderExceed,
    isOutOfStockStatus,
  } from '@/helpers/checkout-link/checkout-link.helper';

  import CountDown from '@/checkout-link/components/count-down/CountDown';
  import IconLock from '@/checkout-link/components/icon/IconLock';
  import IconOpenedBox from '@/checkout-link/components/icon/IconOpenedBox';
  import IconClosedBox from '@/checkout-link/components/icon/IconClosedBox';
  import IconClosedSign from '@/checkout-link/components/icon/IconClosedSign';

  export default {
    name: 'CheckoutLinkException',
    components: { IconClosedSign, IconClosedBox, CountDown, IconLock, IconOpenedBox },
    computed: {
      ...mapGetters('ContactModule', ['firstname']),
      ...mapGetters('CheckoutLinkModule', [
        'getExceptionPageMessage', 'getExceptionPageStatus',
        'isNotOpenedYetException', 'isOverException', 'getCheckoutLinkStartDate',
        'getCheckoutLinkMaxOrderAmount', 'isLoadedAndNotAnException',
      ]),
      isOutOfStock() {
        return isOutOfStockStatus(this.getExceptionPageStatus);
      },
      isMaxOrderExceed() {
        return isMaxOrderExceed(this.getExceptionPageStatus);
      },
      isOpenedBoxIcon() {
        return this.isOutOfStock;
      },
      isClosedBoxIcon() {
        return this.isMaxOrderExceed;
      },
      isClosedSign() {
        return this.isOverException;
      },
    },
    watch: {
      isLoadedAndNotAnException(value) {
        value && this.reloadPage();
      },
    },
    methods: {
      reloadPage() {
        this.$router.replace({ name: 'Information', params: this.$router.currentRoute.params });
      },
    },
    mounted() {
      if (this.isLoadedAndNotAnException) {
        this.reloadPage();
      }
    },
  };
</script>

<style lang="scss" scoped>
  $product-image-size: 60px;

  .checkout-link-exception {
    display: flex;
    align-items: center;
    min-height: 60%;

    &__card {
      max-width: rem(400px);
      padding: 20px;
      margin: auto auto 0;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      background-color: var(--card-raised-background);
      border-radius: 8px;
    }
  }

  .card {

    &__icon-lock,
    &__icon-box,
    &__icon-sign {
      flex: 1 0 auto;
      max-width: 100%;
      margin-bottom: $layout-padding;

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__icon-lock {
      width: rem(48px);
    }

    &__icon-box {
      width: rem(125px);
    }

    &__icon-sign {
      width: rem(100px);
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &__countdown {
      width: 100%;
      margin-bottom: $layout-padding;
    }
  }

  .text {
    &__title {
      @include heading();
      color: var(--primary-text);
      font-weight: $bold;
      margin-bottom: rem(9px);
    }

    &__description {
      @include body-large();
      color: var(--tertiary-raised-text);
      margin-bottom: $layout-padding;
    }
  }

  @include breakpoint(medium) {
    .card {
      padding: 35px 35px 24px;

      &__icon-sign,
      &__icon-box {
        width: rem(184px);
        height: rem(184px);
      }

      &__icon-lock {
        width: rem(110px);
      }
    }
  }
</style>
