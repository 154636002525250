<template>
  <div class="account-selection">
    <div class="account-selection__container">
      <div class="container__body">
        <h2 class="body__title">
          {{ $t('checkoutLink.authentication.accountSelection.title') }}
        </h2>
        <p class="body__help">
          {{ $t('checkoutLink.authentication.accountSelection.message') }}
        </p>
        <div class="body__info-selection">
          <span v-for="(account, idx) in availableAccounts"
            :key="`info-${idx}`"
            class="info-selection__option">
            <input
              :id="`info-${idx}`"
              v-model="selectedAccount"
              class="option__input"
              :value="account.value"
              type="radio" />
            <label :for="`info-${idx}`"
              class="option__label">{{ account.display }}</label>
          </span>
        </div>

        <AppButton
          @click="handleAccountUpdate">
          {{ $t('checkoutLink.authentication.accountSelection.confirmation') }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import IsInsideModal from '@mixins/isInsideModal.mixin';

  import AppButton from '@/checkout-link/components/app/app-button/AppButton.vue';

  export default {
    name: 'AccountSelection',
    components: {
      AppButton,
    },
    mixins: [IsInsideModal],
    data() {
      return {
        selectedAccount: null,
        availableAccounts: [],
      };
    },
    computed: {
      ...mapGetters('AuthModule', ['otpMultipleAccounts']),
    },
    methods: {
    ...mapActions('AuthModule', ['updateSelectedOtpAccount']),
    async handleAccountUpdate() {
      const payload = {
        selectedAccount: this.selectedAccount,
        router: this.$router,
      };

      await this.updateSelectedOtpAccount(payload);
    },
    _setAvailableInfo() {
      this.availableAccounts = [
        {
          value: this.otpMultipleAccounts[0]._id,
          display: `${this.otpMultipleAccounts[0].email} | ${this.otpMultipleAccounts[0].phoneNumber}`,
        },
        {
          value: this.otpMultipleAccounts[1]._id,
          display: `${this.otpMultipleAccounts[1].email} | ${this.otpMultipleAccounts[1].phoneNumber}`,
        },
      ];

      this.selectedAccount = this.availableAccounts[0].value;
    },
    },
    mounted() {
      if (!this.otpMultipleAccounts) {
        this.$router.push({
          name: 'AuthenticationCode',
          params: { ...this.$route.params },
        });
      } else {
        this._setAvailableInfo();
      }
    },
  };
</script>

<style lang="scss" scoped>
.account-selection {
  align-items: center;

  &__container {
    padding: 0 rem(25px);
  }
}

.container {
  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20%;
    padding-top: rem(30px);
  }
}

.body {
  &__title {
    @include heading();

    color: var(--primary-text);
    font-weight: $bold;
    text-align: center;
    margin: 0 0 8px 0;
  }

  &__help {
    @include body-large();

    color: var(--secondary-text);
    text-align: center;
    margin: 0 0 rem(24px) 0;
    //line-height: rem(14px);
  }

  &__info-selection {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: rem(18px);
    border: 1px solid #F0F1F5;
    box-sizing: border-box;
    border-radius: 8px;
  }

  &__error {
    justify-content: center;
    text-align: center;
  }
}

.info-selection {
  &__option {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 15px;

    &:first-child {
      border-bottom: 1px solid #F0F1F5;
    }
  }
}

.option {
  &__label {
    @include body-large();

    margin-left: rem(17px);
  }
}

@include breakpoint(small) {
  .container {
    &__body {
      padding-bottom: rem(30px);
      padding-top: 0;
    }
  }
}
</style>
