<template>
  <div
    class="checkout-total-coupon-modal coupon"
    :class="{ 'no-border': hideBorderBottom }">
    <LayoutLoader
      v-if="isLoading"
      class="coupon__loading"
      :height="60"
      :width="60" />
    <FormCouponField
      v-else
      :error-message="errorMessage"
      :has-error="hasError"
      @submit="onSubmitTriggered" />
  </div>
</template>

<script>
  import FormCouponField from '@/checkout-link/components/form/form-coupon-field/FormCouponField';
  import LayoutLoader from '@/checkout-link/components/layout/loader/LayoutLoader';

  export default {
    name: 'CheckoutTotalCouponModal',
    components: {
      LayoutLoader,
      FormCouponField,
    },
    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        type: String,
        required: true,
      },
      hideBorderBottom: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      onSubmitTriggered(value) {
        this.$emit('submit-triggered', (value || null));
      },
    },
  };
</script>

<style scoped lang="scss">
  .coupon {
    &__loading {
      height: auto;
    }

    &__label {
      margin-bottom: 5px;
      @include label();
      color: var(--tertiary-text);
    }

    .no-border {
      border-bottom: none !important;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  @include breakpoint(small) {
    .coupon {
      margin-top: 0;
      border-top: none;
      padding-top: 0;
    }
  }
</style>
