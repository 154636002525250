export const setCounterDownTimer = (duration, inTimeCallback, outTimeCallback) => {
  if (duration > 0) {
    setTimeout(() => {
      duration--;
      inTimeCallback(duration);
      setCounterDownTimer(duration, inTimeCallback, outTimeCallback);
    }, 1000);
  } else {
    outTimeCallback(duration);
  }
};
