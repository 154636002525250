export const funnelCheckoutRoutes = [
  {
    path: 'checkout',
    name: 'FunnelCheckout',
    component: () => import(/* webpackChunkName: "checkout" */ '../views/checkout/Checkout'),
    meta: {
      modal: true,
    },
    props: route => ({
      insideModal: route.meta?.modal,
    }),
  },
  {
    path: '',
    name: 'FunnelInformation',
  },
];

export const funnelCheckoutSuffixRoutes = funnelCheckoutRoutes.map(route => route.path);
