import { DateTime } from 'luxon';
import { getUserLocale } from 'get-user-locale';

export const FUNNEL_TYPES = {
  PRE: 'PRE',
  POST: 'POST',
  BOTH: 'BOTH',
};

export const EMPTY_FUNNEL = {
  _id: null,
  checkoutLinks: [],
  content: '',
  criteriaMode: '',
  criteriaProducts: [],
  criterias: [],
  currency: '',
  duration: 0,
  insertTime: '',
  name: '',
  products: [],
  shipping: {},
  siteId: '',
  status: '',
  subscriptionStatus: '',
  subtitle: '',
  title: '',
  type: FUNNEL_TYPES.BOTH,
  updateTime: '',
};

export const getParsedLocaleDate = date => DateTime.fromISO(date).setLocale(getUserLocale());
