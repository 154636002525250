import { formattedPrice } from '@/helpers/data/data.helper';
import { getCreditCardParameters, getAddressParameters } from '@/helpers/order/order.helper';

export const addressMapper = address => ({
  _id: address._id,
  firstname: address.firstName || address.firstname,
  lastname: address.lastName || address.lastname,
  line1: address.line1,
  line2: address.line2,
  city: address.city,
  province: address.province,
  zip: address.zip,
  country: address.country,
});

export const creditCardMapper = card => {
  if (!card) {
    return null;
  }

  const expirationArray = card.expiration?.split('/');

  return {
    number: card.cardNumber?.replace(/ /g, ''),
    cvv: card.security,
    cardType: card.cardType,
    paymentMethod: card.paymentMethod,
    expirationMonth: expirationArray && expirationArray[0],
    expirationYear: '20' + (expirationArray && expirationArray[1]),
    zipcode: card.zipCode || card.zipcode,
  };
};

export const stripePaymentMethodMapper = paymentMethod => {
  if (!paymentMethod) {
    return null;
  }

  return {
    id: paymentMethod.id,
    type: paymentMethod.type,
    brand: paymentMethod.card?.brand,
    last4: paymentMethod.card?.last4,
    expirationMonth: paymentMethod.card?.exp_month,
    expirationYear: paymentMethod.card?.exp_year,
  };
};

export const otpParamsMapper = params => ({
  last4PhoneNumber: params.phone,
  contactId: params._id,
  siteId: params.siteId,
});

export const checkoutFromCart = (contact, addressId, address, creditCardId, creditCard, shippingId, coupons, paymentMethod, subscriptionsShipping, stripePaymentMethod) => ({
  contactInformation: {
    ...getAddressParameters(address, addressId),
    email: contact.email,
    phoneNumber: contact.phoneNumber,
  },
  ...getCreditCardParameters(creditCardMapper(creditCard), creditCardId, null),
  shippingId,
  coupons,
  allowMarketing: !!contact.signUp,
  paymentMethod: paymentMethod,
  subscriptionsShipping,
  stripePaymentMethod: stripePaymentMethodMapper(stripePaymentMethod),
});

export const checkoutFromRegistrationMapper = (contact, coupons) => ({
  contactInformation: {
    email: contact.email,
    phoneNumber: contact.phoneNumber,
    address: getAddressFromContactForm(contact),
  },
  creditCard: getCreditCardFromContact(contact),
  coupons,
  shippingId: contact.shippingMethod,
  allowMarketing: contact.signUp,
});

export const preRegistrationFromRegistrationMapper = (contact, hasCreditCard, standaloneCheckoutId) => ({
  standalonecheckoutId: standaloneCheckoutId,
  contactInformation: {
    email: contact.email,
    phoneNumber: contact.phoneNumber,
    address: getAddressFromContactForm(contact),
  },
  creditCard: hasCreditCard ? getCreditCardFromContact(contact) : null,
  allowMarketing: contact.signUp,
});

export const checkoutFromCartCheckoutLinkMapper = (contact, shippingId, coupons, checkoutLinkId, cartId, contactId, paymentMethod, dynamicProductIds, funnelId, orderId, creditCardId, creditCard, addressId, address, subscriptionsShipping, productIdsSubscriptionOnly, productIdsSubscriptionHybrid, stripePaymentMethod) => {
  const id = checkoutLinkId;
  const hasFunnel = !!funnelId;
  const isPresellFunnel = hasFunnel && !!id && !orderId;
  const isPostsellFunnel = hasFunnel && !id && !!orderId;

  /* EXPECTED PARAMS FOR PAYMENT:
    When no funnel: checkoutLinkId (No orderId or checkoutLinId)
    When presell funnel: checkoutLinkId AND funnelId (No orderId)
    When postsell funnel: orderId AND funnelId (No checkoutLinkId)
  */
  return ({
    ...checkoutFromCart(contact, addressId, address, creditCardId, creditCard, shippingId, coupons, paymentMethod, subscriptionsShipping, stripePaymentMethod),
    ...(((!hasFunnel || isPresellFunnel) && { id }) || {}),
    ...((hasFunnel && { funnelId }) || {}),
    ...((hasFunnel && isPostsellFunnel && { orderId }) || {}),
    // Add dynamicProductIds if existing
    ...((dynamicProductIds && { dynamicProductIds }) || {}),
    ...((productIdsSubscriptionOnly && { productIdsSubscriptionOnly }) || {}),
    ...((productIdsSubscriptionHybrid && { productIdsSubscriptionHybrid }) || {}),
    subscriptionsShipping,
  });
};

export const checkoutFromRegistrationCheckoutLinkMapper = (contact, coupons, checkoutLinkId, cartId, contactId, dynamicProductIds, funnelId, subscriptionsShipping, productIdsSubscriptionOnly, productIdsSubscriptionHybrid) => ({
  id: checkoutLinkId,
  contactId: contactId,
  ...checkoutFromRegistrationMapper(contact, coupons),
  ...((!!funnelId && { funnelId }) || {}),
  // Add dynamicProductIds if existing
  ...((dynamicProductIds && { dynamicProductIds }) || {}),
  ...((productIdsSubscriptionOnly && { productIdsSubscriptionOnly }) || {}),
  ...((productIdsSubscriptionHybrid && { productIdsSubscriptionHybrid }) || {}),
  subscriptionsShipping,
});

export const getShippingOption = (shipping, currency) => shipping.map(shipping => ({
  name: `${shipping.name} • ${formattedPrice((shipping?.total?.original?.totalATI || 0), currency)}`,
  subtitle: shipping.delivery_days ? `${shipping.delivery_days?.join('-') || '1-2'} Business days` : '',
  value: shipping.id,
}));

const getCreditCardFromContact = (contact) => creditCardMapper({
  ...contact.creditCard,
  paymentMethod: contact.paymentMethod,
  zipCode: contact.billingZipCode || contact.shippingAddress.zip || contact.zip,
});

const getAddressFromContactForm = (contact) => addressMapper({
  ...contact?.shippingAddress,
  line2: contact?.shippingAddressLine2,
  firstname: contact?.firstname || contact?.firstName,
  lastname: contact?.lastname || contact?.lastName,
});

export const getFlatContact = (contact) => {
  const address = getAddressFromContactForm({
    ...contact,
    shippingAddress: contact.shippingAddress || contact.primaryAddress || (contact.addresses && contact.addresses[0]) || {},
  });

  return {
    email: contact.email || '',
    firstname: contact.firstname || contact.firstName,
    lastname: contact.lastname || contact.lastName,
    phoneNumber: contact.phoneNumber || '',
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    zip: address.zip,
    province: address.province,
    country: address.country,
  };
};
