import some from 'lodash/some';

import cartService from '@/services/cart/cart.service';
import {
  addItemInCart, removeItemInCart,
  updateQuantityOnItemInCart, isReceivedCartEmpty,
  isExistingItemInCart, removeItemInSubscription,
  addSubscriptionsShippingProduct, updateQtySubscriptionsShippingProduct,
} from '@/helpers/cart/cart.helper';
import { getShippingOption } from '@/helpers/data-mapper/data-mapper.helper';
import { checkoutLinkDiscount } from '@/helpers/checkout-link/checkout-link.helper';

// shipping options model
// {
//   id: '',
//   name: '',
//   taxable: true,
//   taxIncluded: true,
//   carrier: '',
//   total: {
//     effective: {
//       totalATI: 0,
//       totalET: 0,
//       totalTax: 0,
//       discount: 0,
//       coupon: '',
//       taxes: [
//         {
//           taxId: '',
//           name: '',
//           percentage: 0,
//           totalET: 0,
//           totalTax: 0
//         }
//       ]
//     },
//     original: {
//       totalATI: 0,
//       totalET: 0,
//       totalTax: 0,
//       taxes: [
//         {
//           taxId: '',
//           name: '',
//           percentage: 0,
//           totalET: 0,
//           totalTax: 0
//         }
//       ]
//     }
//   }
// }

export const EmptyCart = {
  _id: '',
  _type: '',
  siteId: '',
  contactId: '',
  siteCartId: '',
  orderId: '',
  coupons: [],
  total: {
    currency: '',
    effective: {
      totalATI: 0,
      totalET: 0,
      totalTax: 0,
      discount: 0,
      taxes: [
        // {
        //   taxId: '',
        //   name: '',
        //   percentage: 0,
        //   totalET: 0,
        //   totalTax: 0
        // }
      ],
    },
    original: {
      totalATI: 0,
      totalET: 0,
      totalTax: 0,
      taxes: [
        // {
        //   taxId: '',
        //   name: '',
        //   percentage: 0,
        //   totalET: 0,
        //   totalTax: 0
        // }
      ],
    },
  },
  recoverUrl: '',
  items: [
    // {
    //   variantId: '',
    //   total: {
    //     effective: {
    //       totalATI: 0,
    //       totalET: 0,
    //       totalTax: 0,
    //       discount: 0,
    //       coupon: '',
    //       taxes: [
    //         {
    //           taxId: '',
    //           name: '',
    //           percentage: 0,
    //           totalET: 0,
    //           totalTax: 0
    //         }
    //       ]
    //     },
    //     original: {
    //       totalATI: 0,
    //       totalET: 0,
    //       totalTax: 0,
    //       taxes: [
    //         {
    //           taxId: '',
    //           name: '',
    //           percentage: 0,
    //           totalET: 0,
    //           totalTax: 0
    //         }
    //       ]
    //     }
    //   },
    //   taxable: true,
    //   taxPercentage: 0,
    //   quantity: 0,
    //   unitPrice: 0,
    //   productId: '',
    //   label: '',
    //   url: '',
    //   imageUrl: '',
    //   categories: [],
    //   collection: '',
    //   collectionId: '',
    //   options: [
    //     {
    //       key: '',
    //       value: []
    //     }
    //   ],
    //   purchaseOption: ''
    // }
  ],
  status: '',
  insertTime: '',
  updateTime: '',
};

const state = () => ({
  cart: { ...EmptyCart },
  type: null,
  status: {
    error: null,
    isFetching: false,
    isUpdating: false,
    isSaving: false,
    isLoaded: false,
  },
  coupon: {
    values: [],
    error: null,
    isAdding: false,
    isRemoving: false,
  },
  shippingRequired: true,
  subscriptionShippingRequired: true,
  shippingOptions: [],
  subscriptionShippingOptions: [],
  selectedFrequency: null,
  selectedEmail: null,
  selectedShippingOption: null,
  selectedSubscriptionShippingOption: null,
  selectedAddress: null,
  selectedCreditCard: null,
  selectedStripePaymentMethod: null,
  discountOnCart: {
    type: null,
    amount: null,
  },
  address: {
    isUpdating: false,
  },
  shipping: {
    isUpdating: false,
  },
  subscription: {
    shipping: {
      isUpdating: false,
    },
    frequency: {
      isUpdating: false,
    },
  },
});

const actions = {
  async loadCart({ commit, dispatch, rootGetters }, abandonedCartInfos = null) {
    commit('UPDATE_CART_REQUEST');

    try {
      const data = await cartService.loadCart(abandonedCartInfos);
      commit('UPDATE_CART_SUCCESS');

      dispatch('SsrDebugModule/push', { title: 'LOAD EXISTING CART', content: data }, { root: true });

      // Clear the cart state if it's empty or have a funnelId property
      if (isReceivedCartEmpty(data?.cart) || data?.cart?.funnelId) {
        dispatch('clearCartItems');
        dispatch('FunnelModule/clearFunnel', null, { root: true });
      } else {
        dispatch('SsrDebugModule/push', { title: 'SAVE EXISTING CART IN STORE', content: null }, { root: true });
        commit('UPDATE_CART_STATE', { data, frequencies: rootGetters['CheckoutLinkModule/getCheckoutLinkFrequencies'] });
      }
    } catch (error) {
      commit('UPDATE_CART_FAILURE', error);

      throw error;
    }
  },
  // Used to add, increment or remove cart item
  async updateCart({ commit, dispatch, rootGetters }, { cart, subscriptionsShipping }) {
    commit('UPDATE_CART_REQUEST');

    try {
      const data = await dispatch('saveCartCall', { cartToUpdate: cart, subscriptionShippingToUpdate: subscriptionsShipping });
      commit('UPDATE_CART_SUCCESS');
      commit('UPDATE_CART_STATE', { data, frequencies: rootGetters['CheckoutLinkModule/getCheckoutLinkFrequencies'] });

      return data;
    } catch (error) {
      commit('UPDATE_CART_FAILURE', error);

      throw error;
    }
  },
  async saveCartCall({ commit, getters, rootGetters }, {
    cartToUpdate = {},
    couponsToAdd = [],
    couponsToRemove = [],
    addressToUpdate = null,
    shippingToUpdate = null,
    subscriptionShippingToUpdate = [],
    subscriptionShippingIdToUpdate = null,
    frequencyToUpdate = null,
  }) {
    try {
      const backupCurrency = rootGetters['SessionModule/siteCurrency'];
      const standaloneCheckoutId = rootGetters['CheckoutLinkModule/getCheckoutLink']?.id;
      const funnelId = rootGetters['FunnelModule/getFunnelId'];
      const address = addressToUpdate || getters.getSelectedAddress;
      const shippingId = shippingToUpdate || getters.getSelectedShipping?.id || null;
      const subscriptionShippingId = subscriptionShippingIdToUpdate || getters.getSelectedSubscriptionShipping?.id || null;
      const frequency = frequencyToUpdate || getters.getSelectedFrequency || null;
      const coupons = [...getters.coupons, ...couponsToAdd].filter(coupon => !couponsToRemove.includes(coupon));
      const type = getters.getType;
      const tagsForFunnels = Object.values(rootGetters['CheckoutLinkModule/getQueryParams']);
      const productIds = rootGetters['CheckoutLinkModule/productIds'];
      const isPostSell = rootGetters['FunnelModule/isPostsellFunnel'];

      const cart = {
        ...getters.getCart,
        ...cartToUpdate,
        total: {
          ...getters?.getCart?.total || {},
          ...cartToUpdate?.total || {},
          currency: getters?.getCart?.total?.currency || cartToUpdate?.total?.currency || backupCurrency,
        },
      };

      const subscriptionsShipping = [{
        ...(getters.subscriptionShippingOptions?.[0] || {}),
        ...(subscriptionShippingToUpdate?.[0] || {}),
      }];

      subscriptionsShipping[0] = {
        ...(subscriptionsShipping[0] || {}),
        frequency,
        shippingId: subscriptionShippingId,
      };

      const params = {
        cart,
        type,
        address,
        shippingId,
        coupons,
        standaloneCheckoutId,
        subscriptionsShipping,
        ...(funnelId && { funnelId }),
        // When not on an pre-sell or post-sell, add product ids when existing
        ...((!funnelId && productIds) && { dynamicProductIds: productIds }),
      };

      // Add tags property only if defined and not on funnel cart
      if (!funnelId && tagsForFunnels.length) {
        params.cart.tags = tagsForFunnels;
      }

      // On post sell cart, remove standaloneCheckoutId property, so the backend know it can apply specific rules/discount (like free shipping)
      if (isPostSell) {
        params.cart.standaloneCheckoutId = null;
        params.standaloneCheckoutId = null;
      }

      return await cartService.saveCart(params);
    } catch (error) {
      commit('SAVE_CART_FAILURE', error);

      throw error;
    }
  },
  async replaceItem({ dispatch, commit }, item) {
    try {
      await dispatch('clearCartItems');
      await dispatch('addItem', item);
    } catch (error) {
      commit('UPDATE_CART_FAILURE', error);

      throw error;
    }
  },
  async addItem({ commit, dispatch, getters, rootGetters }, items) {
    if (!Array.isArray(items)) {
      items = [items];
    }

    let existingItem = null;
    let quantity = 0;

    for (const item of items) {
      // If item has custom fields, consider it existing only if item with same id and custom fields values exist
      existingItem = getters.getCart?.items?.find(i => isExistingItemInCart(i, item));

      const itemQty = item?.inventory?.quantity || 0;
      quantity = existingItem?.quantity || 0;

      const hasInfiniteQty = itemQty <= 0 && ('available' in (item?.inventory || {}) ? !!item?.inventory?.available : true);
      const hasQtyInferiorToInventory = itemQty > 0 && existingItem?.quantity < itemQty;
      const firstTimeAdded = itemQty > 0 && quantity === 0;

      if (hasInfiniteQty || hasQtyInferiorToInventory || firstTimeAdded) {
        quantity = (existingItem?.quantity + 1) || 1;
      }
    }

    const itemQtyInventory = existingItem?.variant?.inventory?.quantity;
    const hasInfiniteMaxQty = (existingItem?.quantityPolicy?.max || 0) === 0;
    const hasInfiniteQty = itemQtyInventory <= 0 && (existingItem && 'available' in (existingItem?.variant?.inventory || {})
      ? !!existingItem?.variant?.inventory?.available
      : true);
    const hasQtyInferiorToInventory = quantity <= itemQtyInventory;
    const hasQtyInferiorToMaxQty = quantity <= (existingItem?.quantityPolicy?.max || 0);

    if (existingItem) {
      existingItem.purchaseOption = items[0].purchaseOption;
    }

    if (existingItem === undefined || hasInfiniteQty || (hasQtyInferiorToInventory && hasQtyInferiorToMaxQty) || (hasInfiniteMaxQty && hasQtyInferiorToInventory)) {
      const newCart = existingItem
        ? updateQuantityOnItemInCart(getters.getCart, existingItem, quantity)
        : addItemInCart(getters.getCart, items, rootGetters, dispatch);

      const subscriptionsShipping = existingItem
        ? updateQtySubscriptionsShippingProduct(existingItem, getters.subscriptionShippingOptions[0], quantity)
        : addSubscriptionsShippingProduct(items, getters.subscriptionShippingOptions[0]);

      // If the user is authenticated, we need to add the contactId on the cart object if it's null.
      if (rootGetters['ContactModule/contactId'] && !newCart.contactId) {
        newCart.contactId = rootGetters['ContactModule/contactId'];
      }

      if (newCart !== {}) {
        try {
          await dispatch('updateCart', { cart: newCart, subscriptionsShipping });
        } catch (error) {
          commit('UPDATE_CART_FAILURE', error);

          throw error;
        }
      }
    }
  },
  async buyNow({ commit, dispatch, getters }, {
    router,
    identicalShippingMethods = false,
  }) {
    commit('UPDATE_CART_REQUEST');

    if (identicalShippingMethods && getters.getSelectedShipping) {
      await dispatch('OrderModule/processToCheckoutLink', { init: false, router, buyNow: true }, { root: true });
    } else if (getters.isSingleShippingOption || !getters.shippingRequired || (identicalShippingMethods && !getters.getSelectedShipping)) {
      commit('SELECT_SHIPPING_OPTION', getters.shippingOptions[0]);
      await dispatch('OrderModule/processToCheckoutLink', { init: false, router, buyNow: true }, { root: true });
    } else {
      await dispatch('OrderModule/processToCheckoutLink', { init: true, router, buyNow: true }, { root: true });
    }

    commit('UPDATE_CART_SUCCESS');
  },
  async removeItem({ commit, dispatch, getters }, item) {
    try {
      const newCart = removeItemInCart(getters.getCart, item);
      const newSubscription = removeItemInSubscription(getters.subscriptionShippingOptions[0], item);

      await dispatch('updateCart', { cart: newCart, subscriptionsShipping: newSubscription });
    } catch (error) {
      commit('UPDATE_CART_FAILURE', error);

      throw error;
    }
  },
  async updateItemQuantity({ commit, dispatch, getters }, { item, quantity }) {
    try {
      const newCart = updateQuantityOnItemInCart(getters.getCart, item, quantity);

      // The item's quantity should also be updated in the subscription bundle
      // if it's a subscription product.
      let subscriptionsShipping;
      if (getters.subscriptionShippingOptions?.length) {
        subscriptionsShipping = updateQtySubscriptionsShippingProduct(item, getters.subscriptionShippingOptions[0], quantity);
      }
      await dispatch('updateCart', { cart: newCart, subscriptionsShipping });
    } catch (error) {
      commit('UPDATE_CART_FAILURE', error);

      throw error;
    }
  },
  async updateItemVariant({ commit, dispatch, getters }, { item, newVariant }) {
    commit('UPDATE_ITEM_VARIANT', { item, newVariant });
    const cart = getters.getCart;
    try {
      await dispatch('updateCart', { cart });
    } catch (error) {
      commit('UPDATE_CART_FAILURE', error);

      throw error;
    }
  },
  async addCoupon({ commit, dispatch, rootGetters }, coupon) {
    commit('ADD_COUPON_REQUEST');

    try {
      const data = await dispatch('saveCartCall', { couponsToAdd: [coupon] });
      commit('ADD_COUPON_SUCCESS');
      commit('UPDATE_CART_STATE', { data, frequencies: rootGetters['CheckoutLinkModule/getCheckoutLinkFrequencies'] });

      return data;
    } catch (error) {
      commit('ADD_COUPON_FAILURE', error);

      throw error;
    }
  },
  async removeCoupon({ commit, dispatch, rootGetters }, coupon) {
    commit('REMOVE_COUPON_REQUEST');

    try {
      const data = await dispatch('saveCartCall', { couponsToRemove: [coupon] });
      commit('REMOVE_COUPON_SUCCESS');
      commit('UPDATE_CART_STATE', { data, frequencies: rootGetters['CheckoutLinkModule/getCheckoutLinkFrequencies'] });

      return data;
    } catch (error) {
      commit('REMOVE_COUPON_FAILURE', error);

      throw error;
    }
  },
  saveEmail({ dispatch }, email) {
    dispatch('selectEmail', email);
  },
  async saveAddress({ commit, dispatch, getters, rootGetters }, address) {
    commit('UPDATE_CART_REQUEST');
    commit('UPDATE_ADDRESS_REQUEST');

    const updateShipping = address !== getters.getSelectedAddress;
    try {
      if (updateShipping) {
        commit('UPDATE_SHIPPING_REQUEST');
        dispatch('selectAddress', address);
      }

      const data = await dispatch('saveCartCall', { addressToUpdate: address });
      commit('UPDATE_CART_STATE', { data, frequencies: rootGetters['CheckoutLinkModule/getCheckoutLinkFrequencies'] });
      commit('UPDATE_CART_SUCCESS');
      commit('UPDATE_ADDRESS_SUCCESS');

      return data;
    } catch (error) {
      dispatch('selectAddress', null);
      commit('UPDATE_CART_FAILURE', error);
      commit('UPDATE_ADDRESS_FAILURE');

      if (updateShipping) {
        commit('UPDATE_SHIPPING_FAILURE');
      }

      throw error;
    }
  },
  async saveShippingOption({ commit, dispatch, getters, rootGetters }, shippingId) {
    shippingId = shippingId?.id || shippingId;
    if (getters.getSelectedShipping?.id === shippingId) return;

    commit('UPDATE_CART_REQUEST');
    commit('UPDATE_SHIPPING_REQUEST');

    try {
      let data;

      if (getters.areShippingOptionsIdentical) {
        data = await dispatch('saveCartCall', { shippingToUpdate: shippingId, subscriptionShippingIdToUpdate: shippingId });
      } else {
        data = await dispatch('saveCartCall', { shippingToUpdate: shippingId });
      }

      commit('UPDATE_CART_SUCCESS');
      commit('UPDATE_SHIPPING_SUCCESS');
      commit('UPDATE_CART_STATE', { data, frequencies: rootGetters['CheckoutLinkModule/getCheckoutLinkFrequencies'] });

      return data;
    } catch (error) {
      commit('UPDATE_CART_FAILURE', error);
      commit('UPDATE_SHIPPING_FAILURE');

      throw error;
    }
  },
  async saveSubscriptionShippingOption({ commit, dispatch, getters, rootGetters }, shippingId) {
    shippingId = shippingId?.id || shippingId;
    if (getters.getSelectedSubscriptionShipping?.id === shippingId) {
      return;
    }

    commit('UPDATE_CART_REQUEST');
    commit('UPDATE_SUBSCRIPTION_SHIPPING_REQUEST');

    try {
      const data = await dispatch('saveCartCall', { subscriptionShippingIdToUpdate: shippingId });
      commit('UPDATE_CART_SUCCESS');
      commit('UPDATE_SUBSCRIPTION_SHIPPING_SUCCESS');
      commit('UPDATE_CART_STATE', { data, frequencies: rootGetters['CheckoutLinkModule/getCheckoutLinkFrequencies'] });

      return data;
    } catch (error) {
      commit('UPDATE_CART_FAILURE', error);
      commit('UPDATE_SUBSCRIPTION_SHIPPING_FAILURE');

      throw error;
    }
  },
  setShippingOptions({ commit }, options) {
    commit('SET_SHIPPING_OPTIONS', options);
  },
  selectShippingOption({ commit }, shippingOption) {
    commit('SELECT_SHIPPING_OPTION', shippingOption);
  },
  selectSubscriptionShippingOption({ commit }, shippingOption) {
    commit('SELECT_SUBSCRIPTION_SHIPPING_OPTION', shippingOption);
  },
  resetSubscriptionShippingOption({ commit }) {
    commit('RESET_SUBSCRIPTION_SHIPPING_OPTION');
  },
  async saveFrequencyOption({ commit, dispatch, getters, rootGetters }, frequency) {
    if (getters.getSelectedFrequency === frequency) return;

    commit('UPDATE_CART_REQUEST');
    commit('UPDATE_FREQUENCY_REQUEST');
    try {
      const data = await dispatch('saveCartCall', { frequencyToUpdate: frequency });
      commit('UPDATE_CART_SUCCESS');
      commit('UPDATE_FREQUENCY_SUCCESS');
      commit('UPDATE_CART_STATE', { data, frequencies: rootGetters['CheckoutLinkModule/getCheckoutLinkFrequencies'] });

      return data;
    } catch (error) {
      commit('UPDATE_CART_FAILURE', error);
      commit('UPDATE_FREQUENCY_FAILURE');

      throw error;
    }
  },
  selectEmail({ commit }, email) {
    commit('SELECT_EMAIL', email);
  },
  selectAddress({ commit, dispatch }, address) {
    // Need to comment it for the shipping to appears with the options when the user updates the address

    // clean the selected option to avoid country mismatch option in API
    // dispatch('setShippingOptions', [])

    dispatch('selectShippingOption', null);
    dispatch('selectSubscriptionShippingOption', null);

    commit('SELECT_ADDRESS', address);
  },
  selectCreditCard({ commit }, creditCard) {
    commit('SELECT_CREDIT_CARD', creditCard);
  },
  selectStripePaymentMethod({ commit }, stripePaymentMethod) {
    commit('SELECT_STRIPE_PAYMENT_METHOD', stripePaymentMethod);
  },
  clearCartForPostSell({ commit, dispatch }) {
    commit('CLEAR_CART');
    dispatch('selectShippingOption', null);
    dispatch('selectSubscriptionShippingOption', null);
    dispatch('resetSubscriptionShippingOption');
  },
  clearCartItems({ commit, dispatch }) {
    commit('CLEAR_CART_ITEMS');
    dispatch('selectShippingOption', null);
    dispatch('selectSubscriptionShippingOption', null);
    dispatch('resetSubscriptionShippingOption');
  },
  clearFunnelId({ commit }) {
    commit('CLEAR_FUNNEL_ID');
  },
  setCartId({ commit }, id) {
    commit('SET_CART_ID', id);
  },
  setCheckoutType({ commit }, type) {
    commit('SET_CHECKOUT_TYPE', type);
  },
};

const mutations = {
  SET_CART_SUCCESS(state, cart) {
    state.status.isFetching = false;
    state.status.isLoaded = true;
    state.status.error = null;
    state.cart = cart || state.cart || {};

    if (!state.cart.items) {
      state.cart = { ...state.cart, items: [] };
    }
  },
  SAVE_CART_FAILURE(state, error) {
    state.status.isSaving = false;
    state.status.isLoaded = false;
    state.status.error = error;
  },
  UPDATE_CART_REQUEST(state) {
    state.status.isLoaded = true;
    state.status.isUpdating = true;
    state.status.error = null;
  },
  UPDATE_CART_SUCCESS(state) {
    state.status.isUpdating = false;
    state.status.error = null;
  },
  UPDATE_CART_FAILURE(state, error) {
    state.status.isUpdating = false;
    state.status.error = error;
  },
  UPDATE_ADDRESS_REQUEST(state) {
    state.address.isUpdating = true;
  },
  UPDATE_ADDRESS_SUCCESS(state) {
    state.address.isUpdating = false;
  },
  UPDATE_ADDRESS_FAILURE(state) {
    state.address.isUpdating = false;
  },
  UPDATE_SHIPPING_REQUEST(state) {
    state.shipping.isUpdating = true;
  },
  UPDATE_SHIPPING_SUCCESS(state) {
    state.shipping.isUpdating = false;
  },
  UPDATE_SHIPPING_FAILURE(state) {
    state.shipping.isUpdating = false;
  },
  UPDATE_SUBSCRIPTION_SHIPPING_REQUEST(state) {
    state.subscription.shipping.isUpdating = true;
  },
  UPDATE_SUBSCRIPTION_SHIPPING_SUCCESS(state) {
    state.subscription.shipping.isUpdating = false;
  },
  UPDATE_SUBSCRIPTION_SHIPPING_FAILURE(state) {
    state.subscription.shipping.isUpdating = false;
  },
  UPDATE_FREQUENCY_REQUEST(state) {
    state.subscription.frequency.isUpdating = true;
  },
  UPDATE_FREQUENCY_SUCCESS(state) {
    state.subscription.frequency.isUpdating = false;
  },
  UPDATE_FREQUENCY_FAILURE(state) {
    state.subscription.frequency.isUpdating = false;
  },
  UPDATE_CART_STATE(state, { data, frequencies }) {
    state.cart = data?.cart || state.cart || {};
    state.shippingRequired = data?.shippingRequired === 'undefined' ? state.shippingRequired : data?.shippingRequired;

    // If cart created and backend send selectedShippingOption to null or undefined (mean shipping options are different),
    // select the first new shippingOption
    const selectedShipping = !state.selectedShippingOption && !data?.selectedShippingOption
      ? (data?.shippingOptions?.length ? data.shippingOptions[0] : null)
      : (data?.selectedShippingOption || data?.shippingOptions[0] || state.selectedShippingOption || null);

    state.selectedShippingOption = selectedShipping;
    state.shippingOptions = data?.shippingOptions || state.shippingOptions || (selectedShipping ? [selectedShipping] : []);
    state.subscriptionShippingOptions = data?.subscriptionShippingOptions || state.subscriptionShippingOptions || [];

    const selectedSubscriptionShipping = !state.selectedSubscriptionShippingOption && !data?.subscriptionShippingOptions[0]?.selectedShippingOption
      ? (data?.subscriptionShippingOptions[0]?.shippingOptions?.length ? data.subscriptionShippingOptions[0]?.shippingOptions[0] : null)
      : (data?.subscriptionShippingOptions[0]?.selectedShippingOption || data?.subscriptionShippingOptions[0]?.shippingOptions[0] || state.selectedSubscriptionShippingOption || null);

    state.selectedSubscriptionShippingOption = selectedSubscriptionShipping;

    state.selectedFrequency = !state.selectedFrequency && !data?.subscriptionShippingOptions[0]?.frequency
      ? (frequencies?.length ? frequencies[0] : null)
      : (data?.subscriptionShippingOptions[0]?.frequency || state.selectedFrequency || null);

    state.coupon.values = data?.coupons || state.coupon?.values || [];
    state.discountOnCart = {
      type: data?.discountType || state.discountOnCart?.type || null,
      amount: data?.totalDiscount || state.discountOnCart?.amount || null,
    };
  },
  ADD_COUPON_REQUEST(state) {
    state.coupon.isAdding = true;
    state.coupon.error = null;
  },
  ADD_COUPON_SUCCESS(state) {
    state.coupon.isAdding = false;
    state.coupon.error = null;
  },
  ADD_COUPON_FAILURE(state, error) {
    state.coupon.isAdding = false;
    state.coupon.error = error;
  },
  REMOVE_COUPON_REQUEST(state) {
    state.coupon.isRemoving = true;
    state.coupon.error = null;
  },
  REMOVE_COUPON_SUCCESS(state) {
    state.coupon.isRemoving = false;
    state.coupon.error = null;
  },
  REMOVE_COUPON_FAILURE(state, error) {
    state.coupon.isRemoving = false;
    state.coupon.error = error;
  },
  SET_SHIPPING_OPTIONS(state, options) {
    state.shipping.isUpdating = false;
    state.shippingOptions = options;
  },
  SELECT_SHIPPING_OPTION(state, option) {
    state.shipping.isUpdating = false;
    state.selectedShippingOption = option;
  },
  SELECT_SUBSCRIPTION_SHIPPING_OPTION(state, option) {
    state.shipping.isUpdating = false;
    state.selectedSubscriptionShippingOption = option;
  },
  RESET_SUBSCRIPTION_SHIPPING_OPTION(state) {
    state.subscriptionShippingOptions = [];
  },
  SELECT_EMAIL(state, email) {
    state.selectedEmail = email;
  },
  SELECT_ADDRESS(state, address) {
    state.selectedAddress = address;
  },
  SELECT_CREDIT_CARD(state, creditCard) {
    state.selectedCreditCard = creditCard;
  },
  SELECT_STRIPE_PAYMENT_METHOD(state, stripePaymentMethod) {
    state.selectedStripePaymentMethod = stripePaymentMethod;
  },
  CLEAR_CART(state) {
    state.cart = { ...EmptyCart };
  },
  CLEAR_CART_ITEMS(state) {
    state.cart.items = [];
  },
  SET_CART_ID(state, id) {
    state.cart = {
      ...state.cart,
      _id: id || state.cart._id,
    };
  },
  SET_CHECKOUT_TYPE(state, type) {
    state.type = type;
  },
  CLEAR_FUNNEL_ID(state) {
    state.cart = {
      ...state.cart,
      funnelId: null,
    };
  },
  UPDATE_ITEM_VARIANT(state, { item, newVariant }) {
    const cartItem = state.cart.items.find(i => i.source === item.source && i.variantId === item.variantId);
    cartItem.variant = newVariant;
    cartItem.variantId = newVariant.id;
  },
};

const getters = {
  getCart: state => state.cart,
  getType: state => state.type,
  cartStatus: state => state.status,
  couponState: state => state.coupon,
  shippingRequired: state => state.shippingRequired,
  subscriptionShippingRequired: state => state.subscriptionShippingOptions[0]?.shippingRequired,
  shippingOptions: state => state.shippingOptions,
  subscriptionShippingOptions: state => state.subscriptionShippingOptions,
  areShippingOptionsIdentical: (_, { shippingOptions, subscriptionShippingOptions }) => shippingOptions.length === subscriptionShippingOptions[0]?.shippingOptions?.length ? shippingOptions.every((o) => some(subscriptionShippingOptions[0].shippingOptions, o)) : false,
  discountOnCart: state => state.discountOnCart,

  getSelectedEmail: state => state.selectedEmail,
  getSelectedShipping: state => state.selectedShippingOption,
  getSelectedSubscriptionShipping: (state, { areShippingOptionsIdentical, getSelectedShipping }) => areShippingOptionsIdentical ? getSelectedShipping : state.selectedSubscriptionShippingOption,
  getSelectedFrequency: state => state.selectedFrequency,
  getSelectedAddress: (state, _, __, rootGetters) => state.selectedAddress || rootGetters['ContactModule/addresses']?.find(a => a.isDefault) || rootGetters['ContactModule/addresses'][0] || null,
  getSelectedCreditCard: (state, _, __, rootGetters) => state.selectedCreditCard || rootGetters['ContactModule/creditCards']?.find(c => c.isDefault) || rootGetters['ContactModule/creditCards'][0] || null,
  getSelectedStripePaymentMethod: state => state.selectedStripePaymentMethod || null,

  getCartId: (_, { getCart }) => getCart?._id,
  getCartUpdateTime: (_, { getCart }) => getCart?.updateTime,
  hasCart: (_, { getCartId }) => !!getCartId,
  getItems: (_, { getCart }) => getCart?.items || [],
  getItemsCount: (_, { getItems }) => {
    return (getItems && getItems.reduce(
      (result, currentValue) => result + currentValue.quantity, 0,
    )) || 0;
  },
  isCartEmpty: (_, { getItems }) => !(getItems?.length || 0),
  getMappedItems: (_, { getItems, getCurrency }) => getItems?.map(item => ({
    ...item,
    currency: getCurrency,
    variant_options: item.options?.map(o => o.value || (o.values && o.values[0])).filter(v => !!v) || [],
    price: item.total?.effective?.totalATI || 0,
    totalDiscountedPrice: item.discountPrice !== 'undefined' && item.discountPrice !== item.unitPrice ? item.discountPrice * item.quantity : null,
  })),
  getSubscriptionItems: (_, { getMappedItems }) => getMappedItems?.filter(i => i.purchaseOption === 'SUBSCRIPTION' && i.source !== 'GIFT') || [],
  getOneTimeItems: (_, { getMappedItems }) => getMappedItems?.filter(i => (i.purchaseOption === 'ONE_TIME' || i.purchaseOption === undefined) && i.source !== 'GIFT') || [],
  getGiftItems: (_, { getMappedItems }) => getMappedItems?.filter(i => i.source === 'GIFT') || [],
  getCurrency: (_, { getCart }, __, rootGetters) => getCart?.total?.currency || rootGetters['SessionModule/siteCurrency'],
  getTotalET: (_, { getCart }) => getCart?.total?.original?.totalET || 0,
  getTotalVAT: (_, { getCart }) => getCart?.total?.effective?.totalTax || 0,
  getTotalATI: (_, { getCart }) => getCart?.total?.original?.totalATI || 0,
  getTotalATIDiscounted: (_, { getCart }) => getCart?.total?.effective?.totalATI || 0,
  getTotalCart: (_, { getTotalATI, getTotalShipping }) => (getTotalATI + getTotalShipping) || 0,
  getTotalDiscountedCart: (_, { getTotalATIDiscounted, getTotalShipping }) => (getTotalATIDiscounted + getTotalShipping) || 0,
  getTotalSubscriptionsATI: (_, { subscriptionShippingOptions }) => subscriptionShippingOptions[0]?.total?.effective?.totalATI || 0,
  getTotalSubscriptions: (_, { getTotalSubscriptionsATI, getTotalSubscriptionsShipping }) => (getTotalSubscriptionsATI + getTotalSubscriptionsShipping) || 0,

  // The shipping effective total amount include the discount
  getTotalShipping: (_, { getSelectedShipping }) => getSelectedShipping?.total?.effective?.totalATI || 0,
  getTotalSubscriptionsShipping: (_, { getSelectedSubscriptionShipping }) => getSelectedSubscriptionShipping?.total?.effective?.totalATI || 0,
  getMappedShippingOptions: (_, { shippingOptions, getCurrency }) => getShippingOption(shippingOptions || [], getCurrency),
  getMappedSubscriptionShippingOptions: (_, { subscriptionShippingOptions, getCurrency }) => getShippingOption(subscriptionShippingOptions[0]?.shippingOptions || [], getCurrency),
  isSingleShippingOption: (_, { shippingOptions }) => shippingOptions?.length === 1,

  getCartDiscount: (_, { getCart }) => getCart?.total?.effective?.discount || 0,
  getTotalDiscounted: (_, { getCartDiscount }) => getCartDiscount || 0,
  getDiscountOnCartType: (_, { discountOnCart }) => discountOnCart?.type || null,
  isCheckoutLinkDiscount: (_, { getDiscountOnCartType }) => Object.keys(checkoutLinkDiscount).includes(getDiscountOnCartType?.toUpperCase()),
  isFunnelWithDiscount: (_, { getCartDiscount }, __, rootGetters) => !!rootGetters['FunnelModule/getFunnelId'] && getCartDiscount > 0,
  hasSubscriptionItems: (_, { getSubscriptionItems }) => getSubscriptionItems.length > 0,
  hasSubscriptionsWithDiscount: (_, { getSubscriptionItems, getCartDiscount }) => getSubscriptionItems.length > 0 && getCartDiscount > 0,
  hasDiscount: (_, { isFunnelWithDiscount, hasSubscriptionsWithDiscount }) => isFunnelWithDiscount || hasSubscriptionsWithDiscount,

  isFetching: (_, { cartStatus }) => cartStatus?.isFetching,
  isUpdating: (_, { cartStatus }) => cartStatus?.isUpdating,
  isSaving: (_, { cartStatus }) => cartStatus?.isSaving,
  isLoaded: (_, { cartStatus }) => cartStatus?.isLoaded,
  isCartLoading: (_, { isLoaded, isSaving, isFetching, isUpdating }, rootState, rootGetters) =>
    !isLoaded || isSaving || isFetching || isUpdating || rootGetters['OrderModule/isSaving'],
  error: (_, { cartStatus }) => cartStatus?.error || '',
  isAddressLoading: state => state.address.isUpdating,
  isShippingLoading: state => state.shipping.isUpdating,
  isSubscriptionShippingLoading: state => state.subscription.shipping.isUpdating,
  isFrequencyLoading: state => state.subscription.frequency.isUpdating,

  couponError: (_, { couponState }) => couponState.error || '',
  couponAdding: (_, { couponState }) => couponState?.isAdding,
  couponRemoving: (_, { couponState }) => couponState?.isRemoving,
  coupons: (_, { couponState }) => couponState?.values || [],
  mappedCoupons: (_, { couponsMapping, coupons, getTotalDiscounted }) => couponsMapping(coupons, getTotalDiscounted),
  couponsMapping: () => (coupons, discount) => coupons.map((coupon, index) => {
    const value = (index === (coupons.length - 1)) ? discount : null;

    return { id: coupon, name: coupon, value };
  }),
  fixedCoupons: (_, { isCheckoutLinkDiscount, hasDiscount }, __, rootGetters) => {
    const fixedCoupons = [];
    const hasIncentive = rootGetters['CheckoutLinkModule/hasCheckoutLinkIncentive'];

    if ((hasIncentive && isCheckoutLinkDiscount) || hasDiscount) {
      fixedCoupons.push('DISCOUNT');
    }

    return fixedCoupons;
  },
  mappedFixedCoupons: (_, { couponsMapping, fixedCoupons, getTotalDiscounted }) => couponsMapping(fixedCoupons, getTotalDiscounted),

  hasValidQuantity: (_, { getItems, getItemTotal, hasEveryRequiredProductInCart }) => (route = null) => hasEveryRequiredProductInCart(route) && getItems.every(i => {
    if (!!i?.variant?.inventory?.tracked) {
      const qty = getItemTotal(i?.productId);
      const qtyMin = i?.quantityPolicy?.min || 0;
      const qtyMax = i?.quantityPolicy?.max || 0;
      const qtyInventory = i?.variant?.inventory?.quantity;
      const qtySuperiorToMinQty = qty >= qtyMin || qtyMin === 0;
      const qtyInferiorToMaxQtyOrInventory = qty <= qtyMax || qtyMax === 0;
      const qtyInferiorToInventory = qty <= qtyInventory || qtyInventory <= 0;

      return qtySuperiorToMinQty && qtyInferiorToMaxQtyOrInventory && qtyInferiorToInventory;
    }

    return true;
  }),

  hasEveryRequiredProductInCart: (_, { getItems }, __, rootGetters) => (route = null) => {
    const checkFunnelProducts = !!route && route !== 'Checkout';
    const productsInCheckoutLink = rootGetters['CheckoutLinkModule/getMappedProducts'] || [];
    const productsInFunnel = rootGetters['FunnelModule/getFunnelProducts'] || [];
    const correctProductsToCheck = rootGetters['FunnelModule/hasFunnel'] && checkFunnelProducts ? productsInFunnel : productsInCheckoutLink;

    const requiredProductsIds = correctProductsToCheck.filter(p => p.quantity?.min > 0).map(p => p.productId);
    const productIdsInCart = getItems.map(i => i.productId);

    return requiredProductsIds.every(id => productIdsInCart.includes(id));
  },

  hasCheckoutInformation: (
    state, {
      hasCart, isCartEmpty, hasValidQuantity,
      isUpdating, isAddressLoading, shippingRequired, isShippingLoading,
      getSelectedShipping, getSelectedSubscriptionShipping, getSelectedAddress, getSelectedCreditCard, getSelectedStripePaymentMethod,
      getSubscriptionItems, subscriptionShippingRequired,
    },
    rootState,
    rootGetters,
  ) => (route = null) => hasCart && !isCartEmpty && hasValidQuantity(route) && (!isUpdating && !rootGetters['OrderModule/isSaving'] &&
  !isAddressLoading && getSelectedAddress && (getSelectedCreditCard || getSelectedStripePaymentMethod) &&
  (!shippingRequired || (shippingRequired && !isShippingLoading)) &&
    (!shippingRequired || (shippingRequired && getSelectedShipping)) &&
    (!subscriptionShippingRequired || (getSubscriptionItems.length === 0 || (getSubscriptionItems.length > 0 && getSelectedSubscriptionShipping)))
  ),

  getItemTotal: (_, { getMappedItems }) => (productId) => {
    const items = productId ? getMappedItems.filter(i => i?.productId === productId) : getMappedItems;

    return (items?.reduce(
      (result, currentValue) => result + currentValue.quantity, 0,
    )) || 0;
  },
  getItemTotalInCart: (_, { getItemTotal }) => getItemTotal(),
  canDelete: (_, { getMappedItems, getItemTotal }, __, rootGetters) => item => {
    const productId = item.productId;
    const isSingleProduct = rootGetters['CheckoutLinkModule/hasOneProduct'];

    const itemsByProductId = getMappedItems.filter(i => i?.productId === productId);
    const hasTotalSuperiorToMinQty = getItemTotal(productId) > (itemsByProductId[0]?.quantityPolicy?.min || 0);
    const hasMinQty = (itemsByProductId[0]?.quantityPolicy?.min || 0) !== 0;

    const funnelProductsList = rootGetters['FunnelModule/getFunnelProducts'] || [];

    const canIfSingle = isSingleProduct && (itemsByProductId.length > 1);
    const canIfBundle = !isSingleProduct && ((hasTotalSuperiorToMinQty && itemsByProductId.length !== 1 && hasMinQty) || !hasMinQty);
    const canIfIsFunnelProduct = funnelProductsList.some(e => e.productId === productId);

    return item.source !== 'GIFT' && (canIfSingle || canIfBundle || canIfIsFunnelProduct);
  },
  getCartItemsVariantsIds: (_, { getItems }) => getItems?.map(i => i.variantId) || [],
  getCartItemByProductId: (_, { getItems }) => (productId) => getItems?.find(i => i.productId === productId),
  getCartSubscriptionsShipping: (_, { subscriptionShippingOptions, getSelectedFrequency, getSelectedSubscriptionShipping }) => [{
    products: subscriptionShippingOptions[0]?.products || [],
    frequency: getSelectedFrequency,
    shippingId: getSelectedSubscriptionShipping?.id,
  }],
};

export const CartModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
