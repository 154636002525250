<template>
  <svg
    data-v-46eb0363=""
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="sort-down"
    role="img"
    height="10"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 100 320 512"
    class="svg-inline--fa fa-sort-down fa-w-10">
    <path
      data-v-46eb0363=""
      fill="currentColor"
      d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"
      class="" />
  </svg>
</template>

<script>
  export default {
    name: 'IconCaret',
  };
</script>
