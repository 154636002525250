import AuthService from '@/services/auth/auth.service';
import analyticsTools from '@/plugins/analyticsTools';
import { AcquireError } from '@/services/http/http.service';

const state = () => ({
  sentTo: null,
  otpAccount: null,
  otpPreference: 'sms',
  otpMismatchedInfo: null,
  otpMultipleAccounts: null,
  otpAccountSelected: false,
  otpPartialContactAuthentication: false,
  otpNeedNewPhoneNumber: false,
  status: {
    errors: {},
    isSending: false,
    isVerifying: false,
    isVerifyingOtpAccount: false,
  },
});

const actions = {
  async verifyOtpAccount({ commit, dispatch, getters, rootGetters }, { query, router, otpPartialContactAuthentication = false }) {
    commit('verifyOtpAccount');

    try {
      const { data, total } = await AuthService.verifyOtpAccount(query);
      commit('verifyOtpAccountSuccess', { contactData: data, otpPartialContactAuthentication });

      if (total === 1) {
        if (getters.otpAccountPhoneHidden && !otpPartialContactAuthentication) {
          await dispatch('sendOtp', { router, mismatchedInfo: 'phoneNumber' });
        } else if (getters.otpAccountEmailHidden && !otpPartialContactAuthentication) {
          await dispatch('sendOtp', { router, preference: 'email', mismatchedInfo: 'email' });
        } else {
          const payload = {
            router,
            ...(((otpPartialContactAuthentication && getters.otpAccountEmailHidden) && { preference: 'email' }) || {}),
          };

          await dispatch('sendOtp', payload);
        }
      }

      if (total === 2) {
        return await router.push({
          name: 'AccountSelection',
          params: { ...router.currentRoute.params },
        });
      }
    } catch (error) {
      commit('verifyOtpAccountFailure', error);
      throw error;
    }
  },
  async updateSelectedOtpAccount({ commit, dispatch, getters }, { selectedAccount, router }) {
    commit('selectOtpAccount', selectedAccount);

    const setPreference = !!getters.otpAccountEmailHidden || !!getters.otpAccountPhoneHidden;
    const payload = {
      router,
      ...((setPreference && { preference: getters.otpAccountEmailHidden ? 'email' : 'sms' }) || {}),
    };
    dispatch('sendOtp', payload);
  },
  async sendOtp({ commit, getters, rootGetters }, { preference = 'sms', router = null, mismatchedInfo = null }) {
    commit('sendOtpRequest');

    try {
      const data = await AuthService.sendOtp({ contactId: getters.otpAccountId, preference });

      // keep track if user has entered a landline before
      if (data.needNewPhonenumber) {
        commit('needNewPhoneNumber');
      }

      commit('sendOtpSuccess', {
        otpPreference: preference,
        sentTo: data.to,
        isDuplicateOtpRequest: data?.status === 'DUPLICATE' || false,
        otpMismatchedInfo: mismatchedInfo,
      });
    } catch (error) {
      commit('sendOtpFailure', error);
      throw error;
    } finally {
      if (router) {
        await router.push({
          name: 'AuthenticationCode',
          params: { ...router.currentRoute.params },
        });
      }
    }
  },
  async verifyOtp({ commit, dispatch, getters, rootGetters }, { code, router }) {
    commit('verifyOtpRequest');

    try {
      const userData = await AuthService.verifyOtp({ code, contactId: getters.otpAccountId });

      dispatch('UserModule/setUser', userData, { root: true });
      await dispatch('ContactModule/updateContact', userData.contact, { root: true });
      commit('verifyOtpSuccess');

      analyticsTools.updateGtmVar(
        userData?.contact?._id,
        userData?.contact?.email,
        userData?.contact?.firstname,
        userData?.contact?.lastname,
      );

      if (getters.isMismatchedInfo) {
        return await router.push({
          name: 'InfoUpdate',
          params: { ...router.currentRoute.params },
        });
      }

      dispatch('clearOtpAccount');
      dispatch('ContactModule/UserTemporaryInfoModule/cleanUserTempInfo', null, { root: true });

      if (rootGetters['CheckoutLinkModule/isPreRegistration']) {
        return await router.replace({
          name: 'NotOpened',
          params: { ...router.currentRoute.params },
        });
      }

      // if current account phone number is a landline, redirect to the confirm phone view
      if (getters.otpNeedNewPhoneNumber) {
        return await router.push({
          name: 'LandlinePhone',
          params: { ...router.currentRoute.params },
        });
      }

      return await dispatch('OrderModule/processToCheckoutLink', { init: true, router }, { root: true });
    } catch (error) {
      commit('verifyOtpFailure', error);
      throw error;
    }
  },
  clearOtpAccount({ commit }) {
    commit('clearOtpAccount');
  },
  async confirmPhoneNumber({ commit }, { phoneNumber, router }) {
    commit('confirmPhoneNumber');
    commit('ContactModule/UPDATE_PHONE_NUMBER', phoneNumber, { root: true });

    try {
      await AuthService.confirmPhoneNumber(phoneNumber);
      commit('confirmPhoneNumberSuccess');

      await router.push({
        name: 'Checkout',
        params: {
          ...router.currentRoute.params,
          confirmPhoneNumber: true,
        },
      });
    } catch (error) {
      commit('confirmPhoneNumberError', error);
      throw error;
    }
  },
};

const mutations = {
  verifyOtpAccount(state) {
    state.status.isVerifyingOtpAccount = true;

    const { otpAccount, ...errors } = state.status.errors;
    state.status.errors = {
      ...errors,
    };
  },
  verifyOtpAccountSuccess(state, { contactData, otpPartialContactAuthentication = false }) {
    state.otpAccount = contactData;
    state.otpPartialContactAuthentication = otpPartialContactAuthentication;
    state.status.isVerifyingOtpAccount = false;
  },
  verifyOtpAccountFailure(state, error) {
    state.status.errors = { ...state.status.errors, otpAccount: error };
    state.status.isVerifyingOtpAccount = false;
  },
  clearOtpAccount(state) {
    state.sentTo = null;
    state.otpAccount = null;
    state.otpPreference = 'sms';
    state.otpMismatchedInfo = null;
    state.otpMultipleAccounts = null;
    state.otpAccountSelected = false;
    state.otpPartialContactAuthentication = false;
    state.needNewPhoneNumber = false;

    state.status = {
      errors: {},
      isSending: false,
      isVerifying: false,
      isVerifyingOtpAccount: false,
    };
  },
  selectOtpAccount(state, accountId) {
    state.otpAccount = state.otpAccount.filter(contact => contact._id === accountId);
    state.otpAccountSelected = true;
  },
  sendOtpRequest(state) {
    state.status.isSending = true;

    const { phone, ...errors } = state.status.errors;
    state.status.errors = {
      ...errors,
    };
  },
  sendOtpSuccess(state, { sentTo, otpPreference = 'sms', isDuplicateOtpRequest = false, otpMismatchedInfo = null }) {
    state.sentTo = sentTo;
    state.otpPreference = otpPreference;
    state.otpMismatchedInfo = otpMismatchedInfo;
    state.status.isSending = false;

    const { phone, code, ...errors } = state.status.errors;
    state.status.errors = isDuplicateOtpRequest
      ? { ...errors, code: new AcquireError({ message: 'checkoutLink.authentication.phone.duplicate' }) }
      : { ...errors };
  },
  sendOtpFailure(state, error) {
    state.status.isSending = false;
    state.status.errors = { ...state.status.errors, phone: error };
  },
  verifyOtpRequest(state) {
    state.status.isVerifying = true;

    const { code, ...errors } = state.status.errors;
    state.status.errors = {
      ...errors,
    };
  },
  verifyOtpSuccess(state) {
    state.status.isVerifying = false;

    const { code, ...errors } = state.status.errors;
    state.status.errors = {
      ...errors,
    };
  },
  verifyOtpFailure(state, error) {
    state.status.isVerifying = false;
    state.status.errors = { ...state.status.errors, code: error };
  },
  confirmPhoneNumber(state) {
    state.status.isVerifying = true;
  },
  confirmPhoneNumberSuccess(state) {
    state.status.isVerifying = false;
  },
  confirmPhoneNumberError(state, error) {
    state.status.isVerifying = false;
    state.status.errors = { ...state.status.errors, code: error };
  },
  needNewPhoneNumber(state) {
    state.otpNeedNewPhoneNumber = true;
  },
};

const getters = {
  sentTo: state => state.sentTo,
  isSending: state => state.status.isSending,
  isVerifying: state => state.status.isVerifying,
  isVerifyingOtpAccount: state => state.status.isVerifyingOtpAccount,
  errors: state => state.status?.errors,
  errorPhone: (_, { errors }) => errors?.phone || null,
  errorCode: (_, { errors }) => errors?.code || null,
  errorOtpAccount: (_, { errors }) => errors?.otpAccount || null,
  otpAccountId: state => (state.otpAccount && state.otpAccount[0]?._id) || null,
  otpAccountPhone: state => state.otpAccount[0].phoneNumber,
  otpAccountPhoneHidden: state => state.otpAccount[0].phoneNumber.includes('*'),
  otpAccountEmail: state => state.otpAccount[0].email,
  otpAccountEmailHidden: state => state.otpAccount[0].email.includes('*'),
  isObfuscatedInfo: state => state.otpAccount && (state.otpAccount[0].phoneNumber.includes('*') || state.otpAccount[0].email.includes('*')),
  otpPreference: state => state.otpPreference,
  otpMismatchedInfo: state => state.otpMismatchedInfo,
  isMismatchedInfo: state => !!state.otpMismatchedInfo,
  otpMultipleAccounts: state => (state.otpAccount && state.otpAccount.length === 2) ? state.otpAccount : null,
  otpAccountSelected: state => state.otpAccountSelected,
  otpPartialContactAuthentication: state => state.otpPartialContactAuthentication,
  otpNeedNewPhoneNumber: state => state.otpNeedNewPhoneNumber,
};

export const AuthModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
