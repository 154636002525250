import Vue from 'vue';
import VueProductLayout from 'vue-product-layout';
import { createVueI18n } from '@/i18n';

export class TemplateHelper {
  constructor(template, checkoutLinkSettings, userContext, products, dynamicProducts, dynamicProductLayout, pageColor, ssr) {
    this.ssr = ssr;
    this.templateString = template;
    this.checkoutLinkSettings = checkoutLinkSettings;
    this.userContext = userContext;
    this.products = products;
    this.dynamicProducts = dynamicProducts;
    this.dynamicProductLayout = dynamicProductLayout;
    this.pageColor = pageColor;
  };

  async refreshProducts(addToCartCallBack, imageClickCallback) {
    const template = await this._getDOMTemplate(this.templateString);
    const products = this.products.concat(this.dynamicProducts);

    const productSections = template.querySelectorAll('.product-section');
    for (const section of productSections) {
      const productId = section.dataset.productId;
      const productIds = section.dataset.productIds?.split(',');

      let productInstance;
      if (productId) {
        productInstance = this._getProductComponentInstance({
          section,
          product: products.find(p => (p.id || p.productId) === productId),
        });
      }

      if (productIds) {
        productInstance = this._getProductComponentInstance({
          section,
          products: productIds.map(id => products.find(p => (p.id || p.productId) === id)),
        });
      }
      productInstance.$on('add-to-cart', addToCartCallBack);
      productInstance.$on('image-click', imageClickCallback);

      // replace old section by the dynamic component
      section.parentNode.replaceChild(productInstance.$el, section);
    }
  }

  async generateTemplate() {
    if (!this.ssr) {
      return null;
    }

    const template = await this._getDOMTemplate(this.templateString);
    const { createRenderer } = require('vue-server-renderer');
    const renderer = createRenderer();

    // Pre inject dynamic products sections
    const dynamicSection = template.querySelector('.dynamic-product-zone');
    if (dynamicSection && this.dynamicProducts.length > 0) {
      if (['grid', 'carousel'].includes(this.dynamicProductLayout)) {
        const productIds = this.dynamicProducts.map(p => p.productId).join(',');
        const dynamicProductSection = `<div data-product-ids="${productIds}" data-product-layout="${this.dynamicProductLayout}" class="product-section is-section is-section-auto is-box is-dynamic-product"></div>`;
        dynamicSection.insertAdjacentHTML('afterend', dynamicProductSection);
      } else {
        for (const product of this.dynamicProducts) {
          const dynamicProductSection = `<div data-product-id="${product.productId}" data-product-layout="${this.dynamicProductLayout}" class="product-section is-section is-section-auto is-box is-dynamic-product"></div>`;
          dynamicSection.insertAdjacentHTML('afterend', dynamicProductSection);
        }
      }
    }

    // Pre-render all products (including dynamic products)
    const products = this.products.concat(this.dynamicProducts);
    const productSections = template.querySelectorAll('.product-section');
    for (const section of productSections) {
      const productId = section.dataset.productId;
      const productIds = section.dataset.productIds?.split(',');

      let productInstance;
      if (productId) {
        productInstance = this._getProductComponentInstance({
          section,
          product: products.find(p => (p.id || p.productId) === productId),
        });
      }

      if (productIds) {
        productInstance = this._getProductComponentInstance({
          section,
          products: productIds.map(id => products.find(p => (p.id || p.productId) === id)),
        });
      }
      const sectionHtml = await renderer.renderToString(productInstance);

      section.insertAdjacentHTML('beforebegin', sectionHtml);
      section.remove();
    }

    return template.documentElement.outerHTML;
  }

  async _getDOMTemplate(template) {
    if (this.ssr) {
      const { JSDOM } = require('jsdom');
      const dom = new JSDOM(template);
      return dom.window.document;
    }

    return template;
  }

  _getProductComponentInstance({ section, product = null, products = null }) {
    const ComponentClass = Vue.extend(VueProductLayout);
    const instance = new ComponentClass({
      i18n: createVueI18n(this.userContext),
      propsData: {
        preview: false,
        checkoutLinkSettings: this.checkoutLinkSettings,
        userContext: this.userContext,
        layout: section.dataset.productLayout,
        product,
        products,
        isDynamicProduct: section.classList.contains('is-dynamic-product'),
        pageColor: this.pageColor,
        backgroundColor: section.dataset.backgroundColor || undefined,
        productBackgroundColor: section.dataset.productBackgroundColor || undefined,
        primaryButtonBorderRadius: section.dataset.primaryButtonBorderRadius || undefined,
        contentMaxWidth: section.dataset.contentMaxWidth || undefined,
        maxColumns: parseInt(section.dataset.maxColumns) || undefined,
        sectionId: section.dataset.sectionId,
        hideDescription: section.dataset.hideDescription === 'true',
      },
    });

    instance.$mount();

    return instance;
  }
}
