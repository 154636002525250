<template>
  <div
    class="form-select"
    :class="[isMaterial ? 'form-select--material' : 'form-select--basic']">
    <!-- material-input: do not change following class 'field-label' -->
    <span
      v-if="isMaterial"
      class="field__label">
      {{ label }}
    </span>

    <VSelect
      ref="select"
      v-model="selectedValue"
      :options="options"
      :reduce="obj => obj.value"
      label="name"
      :disabled="disabled"
      append-to-body
      :calculate-position="calculatePosition"
      :loading="loading"
      :clearable="false"
      :searchable="false"
      @input="emitValueChange">
      <!-- Loading display -->
      <template #spinner="{ loading }">
        <span
          v-if="loading"
          class="form-select__loading">
          {{ $t('checkout.summary.loading') }}
        </span>
      </template>

      <!-- Selected option display -->
      <template #selected-option="option">
        <span class="ellipsis">
          <span class="ellipsis__cut">
            {{ option.name }}
          </span>
        </span>
      </template>

      <!-- Select options in container display -->
      <template
        slot="option"
        slot-scope="option">
        <div class="form-select__options">
          <span>{{ option.name }}</span>
        </div>
        <font-awesome-icon
          class="form-select__check"
          icon="check" />
      </template>

      <!-- Add option button -->
      <template
        v-if="addOption"
        #list-footer>
        <li>
          <div class="form-select__add">
            <font-awesome-icon icon="plus" />
            <span @click="emitAddOption">{{ addOptionLabel }}</span>
          </div>
        </li>
      </template>
    </VSelect>
  </div>
</template>

<script>
  import VSelect from 'vue-select';
  import DropDownMixin from '@mixins/dropdown.mixin';

  export default {
    name: 'SimpleDropdown',
    components: {
      VSelect,
    },
    mixins: [DropDownMixin],
  };
</script>

<style scoped lang="scss">
  .form-select {
    &--material {
      @include material-input();
      text-align: left;
    }

    &__add {
      margin: 10px;
      border-radius: 2.5px;
      padding: 12px;
      background: var(--main-color);
      color: var(--main-color-text);
      line-height: 14px;
      font-weight: 400;
      font-family: var(--main-font-family);
      cursor: pointer;

      & svg {
        margin-right: 5px;
      }
    }

    &__options {
      flex: 1;
    }

    &__check {
      display: none;
    }

    &__loading {
      font-weight: $bold;
    }
  }

  .ellipsis {
    display: block;
    width: 100%;

    &__cut {
      display: block;
      @include text-overflow;
    }
  }
</style>

<style lang="scss">
  .form-select--basic {
    & .vs__selected {
      color: var(--main-color-text);
      font-weight: $bold;
    }
  }

  .form-select--material {
    & .vs__selected {
      color: var(--primary-text);
    }

    .v-select {
      height: 100%;

      & .vs__dropdown-toggle {
        height: 100%;
      }

      & .vs__selected {
        position: relative;
        top: 13px;
      }

      .vs__actions {
        position: relative;
        top: 12px;
        right: -5px;
      }

      &.vs--open .vs__selected {
        position: relative;
        top: 13px;
      }

      &.vs--loading .vs__actions {
        position: relative;
        top: 19px;
        left: 0;
      }
    }
  }
</style>
