<template>
  <div>
    <div
      v-if="hasOverlay"
      class="layout-modal-overlay" />

    <div class="layout-modal">
      <div class="layout-modal__container">
        <div
          v-if="hasCloseBtn"
          class="container__close">
          <AppIconButton
            class="button"
            size="large"
            name="times"
            @click.native="onClose" />
        </div>

        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  import AppIconButton from '@/checkout-link/components/app/app-icon-button/AppIconButton';

  export default {
    name: 'LayoutModal',
    components: { AppIconButton },
    props: {
      hasOverlay: {
        type: Boolean,
        default: false,
      },
      hasCloseBtn: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      onClose() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layout-modal-overlay {
    z-index: 99998;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: $overlay-background;
  }

  .layout-modal {
    z-index: 99999;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $layout-padding;

    &__container {
      position: relative;
      height: 100%;
      box-sizing: border-box;
      box-shadow: var(--box-shadow-large-modal);
      border-radius: $radius-card;
      padding: $layout-padding;
      background-color: var(--card-background);
    }
  }

  .container {
    &__close {
      z-index: 9999;
      position: absolute;
      top: 10px;
      right: 10px;
      display: inline-block;

      .button {
        background-color: var(--card-background);
      }
    }
  }
</style>
