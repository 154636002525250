import httpService from '@/services/http/http.service';
import httpHelper from '@/helpers/http/http.helper';
import { objectToQueryString } from '@/helpers/data/data.helper';

const getContact = async() => {
  const route = httpHelper.buildV1ApiRoute(httpHelper.CONTACT_ENDPOINT);

  return await httpService.get(route);
};

const getPartialContact = async(query) => {
  query = objectToQueryString(query);

  let route = httpHelper.buildV1ApiRoute([httpHelper.CONTACT_ENDPOINT, httpHelper.MASKED_ENDPOINT].join('/'));
  route = `${route}?${query}`;

  return await httpService.get(route);
};

const updateContact = async(contact) => {
  const route = httpHelper.buildV1ApiRoute(httpHelper.CONTACT_ENDPOINT);

  return await httpService.put(route, contact);
};

const preRegisterContact = async(contact) => {
  const route = httpHelper.buildV1ApiRoute(httpHelper.PRE_REGISTER_ENDPOINT);

  return await httpService.post(route, contact);
};

const updateContactOtpCredentials = async(params) => {
  const route = httpHelper.buildV1ApiRoute(httpHelper.OTP_CONTACT_CREDENTIALS_ENDPOINT);

  return await httpService.put(route, params);
};

export default {
  getContact,
  getPartialContact,
  updateContact,
  preRegisterContact,
  updateContactOtpCredentials,
};
