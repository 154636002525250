const state = () => ({
  enable: false,
  logs: [],
});

const actions = {
  enable({ commit }, val) {
    commit('ENABLE', val);
  },
  push({ commit }, log) {
    commit('ADD_LOG', log);
  },
  show({ commit, getters }) {
    if (!getters.isEnable || !process.client) {
      return;
    }

    getters.getLogs.forEach(item => {
      const title = item.title || 'SSR DEBUG';
      console.log(title, item.content);
    });

    commit('REMOVE_ALL');
  },
};

const mutations = {
  ENABLE(state, val) {
    state.enable = val;
  },
  ADD_LOG(state, log) {
    state.logs.push(log);
  },
  REMOVE_ALL(state) {
    state.logs = [];
  },
};

const getters = {
  isEnable: state => state.enable,
  getLogs: state => state.logs,
};

export const SsrDebugModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
