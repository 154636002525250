import { render, staticRenderFns } from "./AuthenticationCode.vue?vue&type=template&id=13109bb7&scoped=true&"
import script from "./AuthenticationCode.vue?vue&type=script&lang=js&"
export * from "./AuthenticationCode.vue?vue&type=script&lang=js&"
import style0 from "./AuthenticationCode.vue?vue&type=style&index=0&id=13109bb7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13109bb7",
  null
  
)

export default component.exports