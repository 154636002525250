import MobileMixin from '@mixins/isMobile.mixin';

export default {
  mixins: [MobileMixin],
  props: {
    insideModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isInsideModal() {
      return this.insideModal && !this.isMobile;
    },
  },
};
