import IMask from 'imask';

export const cardMasks = {
  mask: [
    {
      mask: '0000 000000 00000',
      regex: '^3[47]\\d{0,13}',
      cardtype: 'amex',
      name: 'Amex',
      icon: 'amex',
      cvvLength: 4,
    },
    {
      mask: '0000 0000 0000 0000',
      regex: '^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}',
      cardtype: 'discover',
      name: 'Discover',
      icon: 'discover',
      cvvLength: 3,
    },
    {
      mask: '0000 000000 0000',
      regex: '^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}',
      cardtype: 'diners',
      name: 'Diners',
      icon: 'diners',
      cvvLength: 3,
    },
    {
      mask: '0000 0000 0000 0000',
      regex: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
      cardtype: 'mastercard',
      name: 'Mastercard',
      icon: 'mastercard',
      cvvLength: 3,
    },
    {
      mask: '0000 000000 00000',
      regex: '^(?:2131|1800)\\d{0,11}',
      cardtype: 'jcb15',
      name: 'Jcb',
      icon: 'jcb',
      cvvLength: 3,
    },
    {
      mask: '0000 0000 0000 0000',
      regex: '^(?:35\\d{0,2})\\d{0,12}',
      cardtype: 'jcb',
      name: 'Jcb',
      icon: 'jcb',
      cvvLength: 3,
    },
    {
      mask: '0000 0000 0000 0000 000',
      regex: '^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,15}',
      cardtype: 'maestro',
      name: 'Maestro',
      icon: 'maestro',
      cvvLength: 3,
    },
    {
      mask: '0000 0000 0000 0000 000',
      regex: '(?:^4\\d{0,15})(?:\\d{2,3})?',
      cardtype: 'visa',
      name: 'Visa',
      icon: 'visa',
      cvvLength: 3,
    },
    {
      mask: '0000 0000 0000 0000',
      regex: '^62\\d{0,14}',
      cardtype: 'unionpay',
      name: 'Unipay',
      icon: 'unipay',
      cvvLength: 3,
    },
    {
      mask: '0000 0000 0000 0000',
      cardtype: 'Unknown',
      name: '',
      icon: 'unknown',
      cvvLength: 3,
    },
  ],
  dispatch(appended, dynamicMasked) {
    const number = (dynamicMasked.value + appended).replace(/\D/g, '');

    for (let i = 0; i < dynamicMasked.compiledMasks.length; i++) {
      const re = new RegExp(dynamicMasked.compiledMasks[i].regex);

      if (number.match(re) != null) {
        return dynamicMasked.compiledMasks[i];
      }
    }
  },
};

export const expirationMask = (isTwoDigitsYear) => {
  const minFullYear = new Date().getFullYear();
  const minShortYear = parseInt(minFullYear.toString().substr(-2));

  const year = isTwoDigitsYear
    ? {
      YY: {
        mask: IMask.MaskedRange,
        from: minShortYear,
        to: 99,
      },
    }
    : {
      YYYY: {
        mask: IMask.MaskedRange,
        from: minFullYear,
        to: 2100,
      },
    };

  return {
    mask: isTwoDigitsYear ? 'MM{/}YY' : 'MM{/}YYYY',
    blocks: {
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
      },
      ...year,
    },
  };
};

export const securityMask = {
  mask: [
    { mask: '000', regex: '\\d{3}' },
    { mask: '0000', regex: '\\d{4}' },
  ],
};

export const exceptionAllowed = {
  visa: [16, 18],
};
