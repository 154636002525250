import { arrayToString } from '@/helpers/data/data.helper';
import { cardMasks } from '@/checkout-link/components/form/form-card-input/masks';

export const isValidLoadPartialContactQueryParameters = (parameters) => !!parameters.email || !!parameters.phone || !!parameters.phoneNumber;

export const contactHasSomeInformation = (contact) => {
  const hasPhoneNumber = !!contact.phoneNumber;
  const hasEmails = !!contact.email;
  const hasAddress = !!contact.addresses?.length;
  const hasInformation = (!!contact.firstname && !!contact.lastname);

  return !!hasAddress && !!hasPhoneNumber && !!hasEmails && !!hasInformation;
};

export const formValidation = (form, emailRequired, i18n) => {
  const FIELD_REQUIRED = i18n.t('form.field.required');
  const EMAIL_INVALID = i18n.t('form.email.error');
  const errors = {};

  const toFilter = ['shippingAddressLine2', 'billingZipCode', 'line2', 'shippingMethod'];
  const filteredKeys = Object.keys(form).filter(key => !toFilter.includes(key));

  if (emailRequired) {
    if (Object.keys(form).includes('email') && !validateEmail(form.email)) {
      errors.email = EMAIL_INVALID;
    }
  }

  if (Object.keys(form).includes('addressFormatted')) {
    const error = validateAddress(form.shippingAddress || form, i18n);

    if (error) {
      errors.addressFormatted = error;
    }
  }

  if (Object.keys(form).includes('creditCardValid')) {
    if (!form.creditCardValid) {
      errors.creditCardValid = i18n.t('form.card.error');
    }
  }

  for (const key of filteredKeys) {
    if ((form[key] === '' && key !== 'email') || (form[key] === '' && key === 'email' && emailRequired)) {
      errors[key] = FIELD_REQUIRED;
    }
  }

  return errors;
};

function validateAddress(address, i18n) {
  const missing = [];
  const addressKeys = [
    {
      key: 'line1',
      name: i18n.t('address.form.address.label').toLowerCase(),
    },
    {
      key: 'city',
      name: i18n.t('address.form.city.label').toLowerCase(),
    },
    {
      key: 'zip',
      name: i18n.t('address.form.zip.label').toLowerCase(),
    },
    {
      key: 'country',
      name: i18n.t('address.form.country.label').toLowerCase(),
    },
  ];

  if (typeof address === 'object' && !!Object.keys(address).length) {
    for (const [key, value] of Object.entries(address)) {
      const keyConfiguration = addressKeys.find(item => item.key === key);
      if (keyConfiguration && !value) {
        missing.push(keyConfiguration.name);
      }
    }
  } else {
    missing.push(addressKeys.map(item => item.name));
  }

  return !!missing.length
    ? i18n.t('form.addressFormatted.error', { missing: missing.join(', ') })
    : null;
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const defaultContact = {
  firstname: '',
  lastname: '',
  paymentMethod: 'creditcard',
  email: null, // very important to let this to null for order that do not requires email
  addressFormatted: '',
  shippingAddress: {},
  shippingAddressLine2: '',
  shippingMethod: '',
  billingZipCode: '',
  creditCard: {},
  creditCardValid: false,
  signUp: false,
};

export const getAddressInline = ({ firstname, lastname, line1, line2, zip, city, province, country }) => {
  const nameLine = arrayToString([firstname, lastname]);
  const addressLine = arrayToString([line1, line2]);
  const zipLine = arrayToString([province, zip]);

  const address = arrayToString([addressLine, city, zipLine, country], ', ');
  return arrayToString([nameLine, address], ' • ');
};

export const getCreditCardIcon = ({ cardType }, defaultType = { name: '', icon: 'unknown' }) => {
  const provider = cardMasks.mask.find(card => card.cardtype === (cardType?.toLowerCase() || defaultType.name));

  return provider?.icon || defaultType.icon;
};

export const getCreditCardDate = ({ expirationMonth, expirationYear }) => {
  const formattedYear = expirationYear?.toString().substring(2, 4);

  return arrayToString([
    expirationMonth,
    formattedYear,
  ], '/');
};

export const isCreditCardLinkPayment = ({ cardType }) => {
  return cardType === 'link';
};

export const sanitizedParams = (params) => {
  if (!!params.phoneNumber) {
    params.phoneNumber = params.phoneNumber.replace(' ', '+');
  }

  if (!!params.phone) {
    params.phoneNumber = params.phone.replace(' ', '+');
    delete params.phone;
  }

  // If for some reason the phone number doesn't start with +, we need to add it
  if (params?.phoneNumber && params.phoneNumber.charAt(0) !== '+') {
    params.phoneNumber = '+' + params.phoneNumber;
  }

  // Replace space by "+" in email because router replace query parameter "+" by spaces
  if (params?.email) {
    params.email = params.email.replace(' ', '+');
  }

  return params;
};

export const userDefaultCountry = (countryCode, ssrContextAcceptsLanguages = null) => {
  if (countryCode) {
    return countryCode?.toLowerCase();
  }

  let locales;
  if (process.client) {
    const browserLanguage = navigator.language || 'en-US';
    locales = browserLanguage.split('-');
  } else {
    locales = ssrContextAcceptsLanguages ?? ['en', 'us'];
  }
  return locales[1]?.toLowerCase() || locales[0]?.toLowerCase();
};
