<template>
  <div
    class="user-addresses">
    <!-- Add new user address -->
    <div
      v-if="displayEditMode"
      class="user-addresses__edit-mode">
      <div class="edit-mode__form">
        <div class="form__identity">
          <FormInput
            class="identity__item"
            :value="form.firstname"
            :label="$t('address.firstname.label')"
            :error="errors.firstname"
            :show-errors="!!fieldTouched.firstname"
            @input-focus="onInputFocus('firstname')"
            @value-changed="onNameValueChange('firstname', $event)" />

          <FormInput
            class="identity__item"
            :value="form.lastname"
            :label="$t('address.lastname.label')"
            :error="errors.lastname"
            :show-errors="!!fieldTouched.lastname"
            @input-focus="onInputFocus('lastname')"
            @value-changed="onNameValueChange('lastname', $event)" />
        </div>

        <FormAddressInput
          class="form__address"
          :value="form.address"
          :label="$t('address.field.label')"
          :error="errors.addressFormatted"
          :show-errors="!!fieldTouched.addressFormatted"
          @value-changed="onNewAddressValueChange('address', $event)"
          @reset-value="onNewAddressResetAddress" />

        <AppLink
          v-if="!hasSecondLine"
          class="form__toggle"
          icon-placement="left"
          icon="plus"
          @click.native="toggleSecondLine">
          {{ $t('address.actions.addSecondLine') }}
        </AppLink>

        <template v-else>
          <FormInput
            class="form__address-two"
            :value="form.line2"
            :label="$t('address.secondLine.label')"
            :error="errors.line2"
            :show-errors="!!fieldTouched.line2"
            @input-focus="onInputFocus('line2')"
            @value-input="onNewAddressValueChange('line2', $event)" />
          <AppLink
            class="form__toggle"
            icon-placement="left"
            icon="minus"
            @click.native="toggleSecondLine">
            {{ $t('address.actions.removeSecondLine') }}
          </AppLink>
        </template>
      </div>

      <div
        v-if="cancelAllowed"
        class="form__cancel">
        <button type="button"
          @click="toggleEditMode">
          {{ $t('checkoutLink.checkout.cancel') }}
        </button>
      </div>
    </div>

    <!-- Display user addresses -->
    <SimpleDropdown
      v-else
      class="user-addresses__selection-mode"
      :inside-modal="insideModal"
      :is-material="isMaterial"
      :label="$t('checkout.summary.address')"
      :value="selectedAddress"
      :options="mappedAddresses"
      :disabled="disabledDropdown"
      :add-option="hasEmptyOption"
      :auto-select-first="!displayEditMode"
      :add-option-label="$t('checkout.summary.addAddress')"
      @first-value-selected="onSelectedAddressChange"
      @value-changed="onSelectedAddressChange"
      @add-option="toggleEditMode" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import debounce from 'lodash/debounce';

  import CheckoutUserInfo from '@mixins/checkoutUserInfo.mixin';
  import { getFullValue } from '@/helpers/data/data.helper';
  import { formValidation } from '@/helpers/contact/contact.helper';

  import AppLink from '@/checkout-link/components/app/app-link/AppLink';
  import FormAddressInput from '@/checkout-link/components/form/form-address-input/FormAddressInput';
  import FormInput from '@/checkout-link/components/form/form-input/FormInput';
  import SimpleDropdown from '@/checkout-link/components/dropdown/SimpleDropdown';

  // Exported for unit tests purposes
  export const emptyAddress = {
    firstname: '',
    lastname: '',
    line1: '',
    line2: '',
    city: '',
    province: '',
    zip: '',
    country: '',
  };

  // Exported for unit tests purposes
  export const emptyForm = {
    firstname: '',
    lastname: '',
    address: '',
    line2: '',
  };

  export default {
    name: 'UserAddresses',
    components: {
      AppLink,
      FormAddressInput,
      FormInput,
      SimpleDropdown,
    },
    mixins: [CheckoutUserInfo],
    data() {
      return {
        hasSecondLine: false,
        address: { ...emptyAddress },
        form: { ...emptyForm },
        debouncedValidation: null,
        fieldTouched: {},
      };
    },
    computed: {
      ...mapGetters('CartModule', ['getSelectedAddress', 'isAddressLoading']),
      ...mapGetters('ContactModule', ['addresses', 'mappedAddresses']),
      ...mapGetters('ContactModule/UserTemporaryInfoModule', ['getTempAddress']),
      ...mapGetters('FunnelModule', ['hasFunnel', 'isPresellFunnel']),
      /** @public */
      existingUserInfo() {
        return !!this.mappedAddresses.length;
      },
      disabledDropdown() {
        return this.hasFunnel && !this.isPresellFunnel;
      },
      hasAddressError() {
        return !!this.errors.address || !!this.errors.addressFormatted ||
          !!this.errors.city || !!this.errors.country ||
          !!this.errors.province || !!this.errors.line1 || !!this.errors.zip;
      },
      addressResetNeeded() {
        return this.hasAddressError && (
          (!!this.errors.address && !!this.getTempAddress?.address) ||
          (!!this.errors.addressFormatted && !!this.getTempAddress?.addressFormatted) ||
          (!!this.errors.city && !!this.getTempAddress?.city) ||
          (!!this.errors.country && !!this.getTempAddress?.country) ||
          (!!this.errors.province && !!this.getTempAddress?.province) ||
          (!!this.errors.line1 && !!this.getTempAddress?.line1) ||
          (!!this.errors.zip && !!this.getTempAddress?.zip)
        );
      },
      selectedAddress() {
        return (this.getSelectedAddress && this.getSelectedAddress._id) || null;
      },
    },
    methods: {
      ...mapActions('CartModule', ['saveAddress']),
      ...mapActions('ContactModule/UserTemporaryInfoModule', ['storeTempAddress', 'cleanTempAddress', 'cleanFullTempAddress']),
      // Selection mode
      async onSelectedAddressChange(value) {
        const address = getFullValue(this.addresses, value);
        await this.saveAddress(address);
      },
      // Edit mode
      onInputFocus(field) {
        this.fieldTouched[field] = true;
      },
      async onNameValueChange(field, value) {
        this.form[field] = value;
        this.address = { ...this.address, ...this.form };

        await this.validateForm(field);
      },
      async onNewAddressValueChange(field, value) {
        this.form[field] = value;
        this.address = { ...this.address, ...this.form };

        if (field === 'address') {
          await this.setAddress(value);
          await this.validateForm(field);
        } else {
          this.debouncedValidation(field);
        }
      },
      async onNewAddressResetAddress() {
        const { address, line2 } = emptyForm;

        // Reset only address related fields, but keep names
        this.form.address = address;
        this.form.line2 = line2;
        this.address = { ...emptyAddress, ...this.form };

        await this.cleanTempAddress({ address: true });
      },
      async validateForm(field = null) {
        this.errors = formValidation({
          ...this.address,
          addressFormatted: this.form.address,
        }, null, this.$i18n);

        if (this.hasError && !!this.getTempAddress) {
          const toClean = {
            firstname: !!this.getTempAddress.firstname && !!this.errors.firstname,
            lastname: !!this.getTempAddress.lastname && !!this.errors.lastname,
            address: this.addressResetNeeded,
          };

          await this.cleanTempAddress(toClean);
        }

        if (field === 'address' && !this.hasAddressError) {
          return await this.storeTempAddress({ address: this.address });
        }

        if (field === 'firstname' && !this.errors.firstname) {
          return await this.storeTempAddress({ firstname: this.address.firstname });
        }

        if (field === 'lastname' && !this.errors.lastname) {
          return await this.storeTempAddress({ lastname: this.address.lastname });
        }

        if (field === 'line2' && !this.errors.line2) {
          return await this.storeTempAddress({ line2: this.address.line2 });
        }
      },
      setAddress(value) {
        this.form.address = value.formatted_address;
        this.address = { ...value.address_components, ...this.form };
      },
      /** @public */
      async resetForm() {
        this.form = { ...emptyForm };
        this.address = { ...emptyAddress };
        // Also clean temporarily stored address
        await this.cleanFullTempAddress();
        // Reset errors is done in mixin's toggleEditMode method
      },
      toggleSecondLine() {
        this.hasSecondLine = !this.hasSecondLine;
      },
      async _preFill() {
        if (!!this.getTempAddress) {
          this.address = { ...this.getTempAddress };

          this.form.address = this.getTempAddress.address;
          this.form.firstname = this.getTempAddress.firstname;
          this.form.lastname = this.getTempAddress.lastname;

          await this.storeTempAddress({ address: this.address });
        }
      },
    },
    created() {
      this.debouncedValidation = debounce(this.validateForm, 1000);
    },
    async mounted() {
      // For now we prefill only for the registration form
      await this._preFill();
    },
  };
</script>

<style lang="scss" scoped>
  .user-addresses {
    &__edit-mode {
      margin-bottom: rem(15px);
    }
  }

  .edit-mode {
    &__separator {
      border: none;
      border-bottom: 1px solid var(--page-color);
      margin-bottom: rem(25px);
    }

    &__title {
      @include heading();
      margin-bottom: rem(15px);
      font-weight: $bold;
      font-size: rem(15px);
      color: var(--primary-text);
    }

    &__form {
      margin-bottom: rem(15px);
    }
  }

  .form {
    &__address-two {
      margin-top: rem(25px);
    }

    &__identity {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: rem(70px);

      ::v-deep .form-input {
        &__field {
          &--error {
            border-color: $error !important;
            color: $error;
          }
        }

        &__error-message {
          padding-left: rem(10px);
          margin-bottom: rem(5px);
        }
      }
    }

    &__toggle {
      padding-left: 2px;
      letter-spacing: normal;
      font-weight: 700;
      font-style: normal;
    }

    &__cancel {
      @include full-btn-cancel();
    }
  }

  .identity {
    &__item {
      box-sizing: border-box;
      flex: 1;
      flex-direction: column;

      &:first-child {
        padding-right: rem(7px);
      }

      &:last-child {
        padding-left: rem(7px);
      }
    }
  }

  .display {
     &__action {
      background: var(--main-color);
      border-radius: 2.5px;
      padding: 12px;
      line-height: 14px;
      font-weight: 400;
      color: var(--main-color-text);

      svg {
        margin-right: 5px;
      }
    }
  }
</style>
