const loadPlugins = (settings) => {
  if (!process.client) {
    return;
  }

  const plugins = getPluginList(settings);

  plugins.forEach((plugin) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = plugin.name;
    script.src = plugin.script;
    script.async = true;

    document.body.appendChild(script);
  });
};

// expose global window vars needed for plugins
const exposePluginsConfigInWindow = (settings, globalVars) => {
  if (!process.client) {
    return;
  }

  const plugins = getPluginList(settings);

  // Generate plugins vars
  const pluginsConfig = {};
  plugins.forEach((plugin) => {
    pluginsConfig[plugin.name] = plugin;
  });

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerText = 'var pluginsConfig = ' + JSON.stringify(pluginsConfig) + ';';
  script.innerText += 'var pluginsGlobalVars = ' + JSON.stringify(globalVars) + ';';
  document.head.appendChild(script);
};

// The backend should replace this method and return directly the plugins list
const getPluginList = (settings) => {
  const plugins = [];

  if (settings?.googleAnalyticsId) {
    plugins.push({
      name: 'google-analytics',
      vars: {
        code: settings.googleAnalyticsId,
      },
      script: '/plugins/google-analytics.js',
    });
  }

  if (settings?.klaviyoToken) {
    plugins.push({
      name: 'klaviyo',
      vars: {
        code: settings.klaviyoToken,
      },
      script: '/plugins/klaviyo.js',
    });
  }

  if (settings?.refersionPublicKey) {
    plugins.push({
      name: 'refersion',
      vars: {
        code: settings.refersionPublicKey,
      },
      script: '/plugins/refersion.js',
    });
  }

  if (settings?.facebookPixelId) {
    plugins.push({
      name: 'facebook',
      vars: {
        code: settings.facebookPixelId,
        facebookConversionsApi: settings.facebookConversionsApiToken,
      },
      script: '/plugins/facebook.js',
    });
  }

  if (settings?.attentiveMobileDomain) {
    plugins.push({
      name: 'attentivemobile',
      vars: {
        domain: settings.attentiveMobileDomain,
      },
      script: '/plugins/attentivemobile.js',
    });
  }

  if (settings?.gtmId) {
    plugins.push({
      name: 'gtm',
      vars: {
        domain: settings.gtmId,
      },
      script: '/plugins/gtm.js',
    });
  }

  if (settings?.vwoId) {
    plugins.push({
      name: 'vwo',
      vars: {
        id: settings.vwoId,
      },
      script: '/plugins/vwo.js',
    });
  }

  if (settings?.goaffproStorePublicKey) {
    plugins.push({
      name: 'goaffpro',
      vars: {
        storePublicKey: settings.goaffproStorePublicKey,
      },
      script: '/plugins/goaffpro.js',
    });
  }

  if (settings?.tripleWhale) {
    plugins.push({
      name: 'tripleWhale',
      vars: {
        shopifyDomain: settings.shopifyDomain,
        enabled: settings.tripleWhale,
      },
      script: '/plugins/triplewhale.js',
    });
  }

  return plugins;
};

export default {
  loadPlugins,
  exposePluginsConfigInWindow,
};
