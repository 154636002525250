import { getUserLocale } from 'get-user-locale';
import countriesFr from './countries.fr.json';
import countriesEn from './countries.en.json';

const getData = () => {
  const validLocales = ['en', 'fr'];
  const locale = getUserLocale().split('-')[0];

  const data = {
    fr: countriesFr,
    en: countriesEn,
  };

  return data[validLocales.includes(locale) ? locale : 'en'];
};

const getCountries = () => {
  return getData();
};

const getProvinces = (countryCode) => {
  return getData().find(item => item.value === countryCode)?.provinces || [];
};

export {
  getCountries,
  getProvinces,
};
