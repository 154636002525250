<template>
  <div
    v-if="bracketsToDisplay"
    class="discount">
    <IconTags class="discount__icon" />

    <div class="discount__display">
      <span v-if="actualBracket && actualBracket.isLast">
        {{ $t("checkoutLink.discount.lastBracket") }} <span class="display__text">{{ actualBracket.currentSavings }}</span>
      </span>

      <i18n
        v-if="actualBracket.amount > 0 && !actualBracket.isLast"
        class="display__actual"
        path="checkoutLink.discount.actualBracket">
        <template #actualSavings>
          <span class="display__text">{{ actualBracket.currentSavings }}</span>
        </template>
      </i18n>

      <i18n
        v-if="nextBracket && nextBracket.amount > 0"
        class="display__next"
        path="checkoutLink.discount.nextBracket">
        <template #nextLimit>
          <span class="display__text">{{ nextBracket.formattedLimit }}</span>
        </template>
        <template #nextSavings>
          <span class="display__text">{{ nextBracket.nextSavings }}</span>
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
  import { formattedPrice } from '@/helpers/data/data.helper';
  import { checkoutLinkDiscount } from '@/helpers/checkout-link/checkout-link.helper';
  import IconTags from '@/checkout-link/components/icon/IconTags.vue';

  export default {
    name: 'DiscountBrackets',
    components: {
      IconTags,
    },
    props: {
      checkoutLinkIncentive: {
        type: Object || null,
        default: null,
      },
      totalCart: {
        type: Number,
        required: true,
      },
      currency: {
        type: String,
        required: true,
      },
    },
    computed: {
      bracketsToDisplay() {
        return this.actualBracket?.amount > 0 || this.nextBracket?.amount > 0;
      },
      actualBracket() {
        const bracketsArray = this.checkoutLinkIncentive.brackets;

        if (this.totalCart < bracketsArray[0].from) { // check if total cart amount is not yet in brackets
          return {
            isLast: false,
          };
        } else if (this.totalCart >= bracketsArray[bracketsArray.length - 1].from) { // check if total cart amount is in last bracket range
          const actualBracket = bracketsArray[bracketsArray.length - 1];
          const currentSavings = this.formatAmount(actualBracket);

          return {
            isLast: true,
            amount: actualBracket.amount,
            currentSavings,
          };
        } else { // total cart amount is included in one of middle brackets
          const actualBracket = bracketsArray.find(el => el.from <= this.totalCart && this.totalCart < el.to);
          const currentSavings = this.formatAmount(actualBracket);

          return {
            isLast: false,
            amount: actualBracket.amount,
            currentSavings,
          };
        }
      },
      nextBracket() {
        if (!this.actualBracket || this.actualBracket.isLast) return;

        const nextBracket = this.checkoutLinkIncentive.brackets[this.findCurrentBracketIndex() + 1];
        const formattedLimit = formattedPrice(nextBracket.from - this.totalCart, this.currency);
        const nextSavings = this.formatAmount(nextBracket);

        return { formattedLimit, nextSavings, amount: nextBracket.amount };
      },
    },
    methods: {
      formatAmount(bracket) {
        return bracket.incentiveType === checkoutLinkDiscount.FIXED_AMOUNT
          ? formattedPrice(bracket.amount * 100, this.currency)
          : `${bracket.amount}%`;
      },
      findCurrentBracketIndex() {
        return this.checkoutLinkIncentive.brackets.findIndex(el => el.from <= this.totalCart && this.totalCart < el.to);
      },
    },
  };
</script>

<style lang="scss" scoped>
.discount {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: $radius-default-button;
  color: var(--main-color-text);

  &__icon {
    width: 14px;
    margin-right: 6px;
  }

  &__display {
    display: flex;
    flex-wrap: wrap;
  }
}

.display {
  &__text {
    font-weight: $bold;
  }

  &__actual {
    margin-right: 4px;
  }
}
</style>
