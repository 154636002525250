<template>
  <div class="app-error">
    <span class="icon" />
    <span v-if="error.message && error.key">{{ $t(error.message) }} ({{ getTranslatedKey }})</span>
    <span
      v-else-if="error.message"
      v-html="$t(error.message)" />
    <span
      v-else-if="error.key"
      v-html="getTranslatedKey" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { AcquireError } from '@/services/http/http.service';

  export const MIN_QT_ERROR = 'MIN_QT_ERROR';

  export default {
    name: 'AppError',
    props: {
      error: {
        type: AcquireError,
        translationKeyWithVars: ['error.max.order.exceeded'],
        default: () => ({
          key: '',
          message: '',
        }),
      },
    },
    computed: {
      ...mapGetters('CheckoutLinkModule', ['getCheckoutLinkMaxOrderAmount']),
      isMinQtyError() {
        return this.error.key?.startsWith(MIN_QT_ERROR) || false;
      },
      minQtyErrFormatted() {
        if (!this.isMinQtyError) {
          return null;
        }

        const [missingProduct, minQty] = this.error?.key?.split('--')[1]?.split('**');

        return { missingProduct, minQty: minQty.replace(/\D/g, '') };
      },
      getTranslatedKey() {
        return this.isMinQtyError
          ? this.$t('error.postSellOfferMinQty', { missingProduct: this.minQtyErrFormatted.missingProduct, minQty: this.minQtyErrFormatted.minQty })
          : this.$t(this.error.key, { maxOrderAmount: this.getCheckoutLinkMaxOrderAmount });
      },
    },
  };
</script>

<style scoped lang="scss">
  .app-error {
    display: flex;
    margin: 5px 0 20px 0;
    padding: 13px;
    color: $error-box-text;
    background-color: $error-box-background;
    border-radius: $radius-input;
    font-weight: $bold;
    font-size: $default-font-size;

    .icon {
      @include icon-background('exclamation-circle');
      width: 16px;
      height: 16px;
      min-width: 16px;
      margin-right: 10px
    }

    ::v-deep a {
      color: $error-box-text;
      text-decoration-color: $error-box-text;
    }
  }
</style>
