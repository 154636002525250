<template>
  <div
    ref="gallery"
    class="app-gallery">
    <LayoutOverlay
      v-if="preview"
      class="app-gallery__preview">
      <span class="container__close">
        <AppIconButton
          class="button"
          size="large"
          name="times"
          @click.native="togglePreview" />
      </span>

      <div class="masonry">
        <div
          v-for="(image, idx) in images"
          :key="`preview-${idx}`"
          data-test-id="masonry-item"
          class="masonry__item">
          <ProductOptimizedImage
            data-test-id="product-optimized-image"
            alt="product image in gallery"
            :src="image"
            :height="imageHeight"
            :width="imageWidth" />
        </div>
      </div>
    </LayoutOverlay>

    <div
      class="app-gallery__slider"
      :style="{ maxWidth: galleryWidth }">
      <div
        v-if="noImage"
        class="slider__empty">
        {{ $t('checkout.image.empty') }}
      </div>

      <template v-else>
        <div
          ref="slides"
          class="slider__slides">
          <article
            key="image-0"
            ref="image-0"
            data-test-id="slides-article"
            class="slides__article"
            @click="onImageClick">
            <ProductOptimizedImage
              data-test-id="product-optimized-image"
              alt="product image in gallery"
              :src="firstImage"
              :height="imageHeight"
              :width="imageWidth" />
          </article>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  import { IMAGE_CLICK } from '@/services/analytics/analytics.event';
  import { PRODUCT_IMAGE_SIZE } from '@/helpers/product/product.helper';

  import AppIconButton from '@/checkout-link/components/app/app-icon-button/AppIconButton';
  import LayoutOverlay from '@/checkout-link/components/layout/overlay/LayoutOverlay';
  import ProductOptimizedImage from '@/checkout-link/components/product/product-optimized-image/ProductOptimizedImage';

  export default {
    name: 'AppGallery',
    components: {
      AppIconButton,
      LayoutOverlay,
      ProductOptimizedImage,
    },
    props: {
      images: {
        type: Array,
        required: true,
      },
      defaultSelected: {
        type: Object,
        default: () => ({}),
      },
      imageHeight: {
        type: Number,
        default: PRODUCT_IMAGE_SIZE.GALLERY.HEIGHT,
      },
      imageWidth: {
        type: Number,
        default: PRODUCT_IMAGE_SIZE.GALLERY.WIDTH,
      },
    },
    data() {
      return {
        preview: false,
        galleryWidth: '100%',
      };
    },
    computed: {
      noImage() {
        return !this.images.length || !this.images[0];
      },
      firstImage() {
        return this.images[0];
      },
    },
    watch: {
      defaultSelected(value) {
        this.selectVariantImage(value);
      },
    },
    methods: {
      ...mapActions('AnalyticsModule', ['addCheckoutEvent']),
      setWidth() {
        const gallery = this.$refs.gallery;

        if (gallery) {
          const width = Math.ceil(gallery.clientWidth);
          this.galleryWidth = width ? `${Math.ceil(gallery.clientWidth - 1)}px` : '';
        }
      },
      togglePreview() {
        if (!this.preview) {
          this.addCheckoutEvent(IMAGE_CLICK);
        }
        this.preview = !this.preview;
      },
      onImageClick() {
        this.togglePreview();
      },
      selectVariantImage(variant) {
        // TODO: implement with the future implementation shared with page builder
      },
    },
    mounted() {
      this.setWidth();
      window.addEventListener('resize', this.setWidth);
    },
    destroyed() {
      window.removeEventListener('resize', this.setWidth);
    },
  };
</script>

<style scoped lang="scss">
  $image-height: 800px;
  $image-height-empty: 400px;

  .app-gallery {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 0;
    min-width: 0;

    &__preview {
      position: fixed !important;
      min-width: 0;

      ::v-deep .layout-overlay {
        &__container {
          max-width: unset;
        }
      }

      .container__close {
        .button {
          background-color: var(--card-background);
        }
      }
    }

    &__slider {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      min-height: 0;
      min-width: 0;
      margin: 0 auto;
      text-align: center;
      cursor: pointer;
    }
  }

  .slider {
    &__empty {
      @include heading();
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: $image-height-empty;
      max-height: 100%;
      box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.05), inset 0 0 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      color: var(--secondary-text);
    }

    &__slides {
      @include hiddenScroll();
      display: flex;
      flex: 1;
      flex-wrap: nowrap;
      width: 100%;
      max-height: $image-height;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;
    }
  }

  .slides {
    &__article {
      height: 100%;
      min-width: 100%;
      scroll-snap-align: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .masonry {
    $gap: 10px;

    box-sizing: border-box;
    transition: all .5s ease-in-out;

    margin: $gap auto;
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    &__item {
      @include image(100%, 100%, 0, center, 0);

      display: inline-block;
      vertical-align: top;

      margin: 0 0 $gap;
      padding: 0 calc(#{$gap} / 2);
      box-sizing: border-box;
    }
  }

  @include breakpoint(xsmall) {
    .masonry {
      &__item {
        height: rem(250px);
        width: fit-content;

        img {
          width: auto;
        }
      }
    }
  }

  @include breakpoint(small) {
    .masonry {
      &__item {
        height: rem(350px);
      }
    }
  }

  @include breakpoint(medium) {
    .slider {
      &__empty {
        max-height: none;
        height: 100%;

        .checkout-link-details & {
          min-height: $image-height-empty;
        }
      }

      &__slides {
        max-height: none;
        height: 100%;
      }
    }

    .masonry {
      &__item {
        height: rem(400px);
      }
    }
  }

  @include breakpoint(large) {
    .masonry {
      &__item {
        height: rem(450px);
      }
    }
  }
</style>
