<template>
  <div class="product product-in-offer">
    <div
      v-if="product.imageUrl"
      class="product__image">
      <ProductOptimizedImage
        data-test-id="product-optimized-image"
        :alt="product.label"
        :src="product.imageUrl"
        :width="imageWidth" />
    </div>
    <div class="product__description">
      <span
        data-test-id="description-title"
        class="description__title">
        {{ productTitle }}
      </span>
      <span
        data-test-id="description-subtitle"
        class="description__subtitle">
        {{ optionsAndCustomFields }}
      </span>
    </div>
    <div class="product__right-side">
      <CheckoutPrice
        data-test-id="checkout-product-price"
        class="rs__price"
        :amount="product.price"
        :currency="product.currency" />
    </div>
  </div>
</template>

<script>
  import { PRODUCT_IMAGE_SIZE } from '@/helpers/product/product.helper';

  import CheckoutPrice from '@/checkout-link/components/product/checkout-price/CheckoutPrice';
  import ProductOptimizedImage from '@/checkout-link/components/product/product-optimized-image/ProductOptimizedImage';

  export default {
    name: 'CheckoutProductInOffer',
    components: {
      CheckoutPrice,
      ProductOptimizedImage,
    },
    props: {
      product: {
        type: Object,
        required: true,
        // Expected object:
        // {
        //   _id,
        //   category,
        //   currency,
        //   description,
        //   imageUrl,
        //   label,
        //   quantity,
        //   universe,
        //   url,
        //   total,
        // }
      },
    },
    data() {
      return {
        imageWidth: PRODUCT_IMAGE_SIZE.IN_OFFER.WIDTH,
      };
    },
    computed: {
      productTitle() {
        const productQuantity = this.product.quantity > 1 ? `${this.product.quantity} x ` : '';
        return `${productQuantity}${this.product.productName || this.product.label}`;
      },
      optionsAndCustomFields() {
        const customFieldsValues = this.product.customFieldsValues?.map(cfv => cfv.value) || [];
        const variantOptions = this.product.variant_options || [];

        return [...variantOptions, ...customFieldsValues].join(' • ');
      },
    },
  };
</script>

<style scoped lang="scss">
  $product-image-size: 30px;
  $product-height: $product-image-size;
  $product-space: 10px;

  .product {
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    height: $product-height;
    max-height: $product-height;

    border-radius: $radius-product-card;
    background: var(--card-background);

    &__image {
      @include image($product-image-size, $product-image-size);
    }

    &__description {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-left: $product-space;
    }

    &__right-side {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
  }

  .rs__price {
    display: flex;

    @include body-medium();
    color: var(--primary-text);
    font-weight: $bold;
  }

  .rs__original-price {
    text-decoration: line-through;

    @include body-medium();
  }

  .description {
    &__title {
      @include body-medium();
      font-weight: $bold;
      color: var(--primary-text);
    }

    &__subtitle {
      flex-grow: 1;

      @include body-medium();
      text-transform: capitalize;
      color: var(--tertiary-raised-text);
    }
  }
</style>
