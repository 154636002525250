<template>
  <div class="subscription-info-card">
    <div class="subscription-info-card__title">
      <IconTruck class="title__icon" />
      <p class="title__text">{{ title }}</p>
    </div>

    <div class="subscription-info-card__content">
      <i18n
        v-if="isInCart"
        :path="contentKey">
        <template #frequency>{{ formattedFrequency.name }}</template>

        <template #amount>
          <CheckoutPrice
            :amount="amount"
            :currency="currency" />
        </template>

        <template #futureAmount>
          <CheckoutPrice
            :amount="futureAmount"
            :currency="currency" />
        </template>
      </i18n>

      <template v-else>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://help.acquire.app/en/articles/6263213-subscription-management"
          class="content__policy">
          {{ $t('checkout.purchaseOption.subscription.details.policy') }}
        </a>
      </template>
    </div>
  </div>
</template>

<script>
  import CheckoutPrice from '@/checkout-link/components/product/checkout-price/CheckoutPrice';
  import IconTruck from '@/checkout-link/components/icon/IconTruckFull';

  export default {
    name: 'SubscriptionInfoCard',
    components: {
      IconTruck,
      CheckoutPrice,
    },
    props: {
      isInCart: {
        type: Boolean,
        default: false,
      },
      frequency: {
        type: String,
        default: '',
      },
      amount: {
        type: Number,
        default: 0,
      },
      futureAmount: {
        type: Number,
        default: 0,
      },
      currency: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        required: true,
      },
      contentKey: {
        type: String,
        required: true,
      },
    },
    computed: {
      formattedFrequency() {
        const f = this.frequency.match(/P(?<nb>[0-9]+)(?<period>[MW])/).groups;
        const name = f.period === 'W' ? this.$tc('checkout.frequency.week', f.nb, { nb: f.nb }) : this.$tc('checkout.frequency.month', f.nb, { nb: f.nb });

        return { name, value: this.frequency };
      },
    },
  };
</script>

<style lang="scss" scoped>
.subscription-info-card {
  background-color: var(--primary-background);
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  color: var(--tertiary-text);

  &__title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
  }
}

.title {
  &__icon {
    fill: var(--tertiary-text);

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }
  }

  &__text {
    font-weight: $semi-bold;
    margin: 0;
  }
}

.content {
  &__text {
    margin-bottom: 5px;
  }

  &__policy {
    color: var(--tertiary-text);
  }
}
</style>
