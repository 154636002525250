<template>
  <div class="form-phone-code">
    <input
      v-model="code"
      type="tel"
      class="form-phone-code__field"
      data-cy="form-phone-code-input"
      inputmode="number"
      autocomplete="one-time-code"
      :placeholder="placeholder"
      :pattern="pattern"
      :maxlength="defaultPhoneCodeLength"
      :class="{ 'form-phone-code__field--error': hasAnyError }"
      @input="onInput" />
  </div>
</template>

<script>
  export default {
    name: 'FormPhoneCode',
    props: {
      codeValidation: {
        type: String,
        default: null,
      },
      hasPreValidation: {
        type: Boolean,
        default: false,
      },
      hasExternalError: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        defaultPhoneCodeLength: 4,
        code: null,
        hasError: false,
        emitted: false,
      };
    },
    computed: {
      placeholder() {
        let placeholder = '';
        for (let i = 0; i < this.defaultPhoneCodeLength; i++) {
          placeholder += '-';
        }

        return placeholder;
      },
      pattern() {
        return `[0-9]{${this.defaultPhoneCodeLength}}`;
      },
      hasAnyError() {
        return this.hasExternalError || this.hasError;
      },
    },
    methods: {
      getCodeValue() {
        return this.code;
      },
      hasFullLength() {
        return this.getCodeValue().length === this.defaultPhoneCodeLength;
      },
      stopEvent(event) {
        event.preventDefault();
        event.stopPropagation();
      },
      isNumber(key) {
        return Number.isInteger(parseInt(key, 10));
      },
      onInput(event) {
        this.stopEvent(event);

        // On Firefox, the data is in the event, on Chrome, it's null
        // so we get it from the source element
        const data = event.data || event.srcElement?.value;
        // Here we bypass the key press and check if we have an event
        // that comes with "data" containing numbers. If so, we can
        // copy/paste that into our input fields.
        if (data.toString().length === this.defaultPhoneCodeLength) {
          this.code = data;
          this.validateAndEmit();
        } else if (data.length === 1) {
          const isNumber = this.isNumber(data);

          if (!isNumber) {
            this.code = this.code.substring(0, this.code.length - 1);

            return null;
          }

          if (this.hasFullLength()) {
            this.validateAndEmit();
          }
        } else {
          return null;
        }
      },
      validateAndEmit() {
        const code = this.getCodeValue();
        if (!this.hasPreValidation && this.hasFullLength() && !this.emitted) {
          this.hasError = false;
          this.$emit('code', code);
          this.emitted = true;

          return;
        }

        const isRightCode = this.codeValidation === code;
        this.hasError = !isRightCode;

        if (!this.emitted) {
          this.$emit('code', isRightCode ? code : null);
          this.emitted = true;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  $margin-field: rem(10px);
  $number-width: rem(42px);
  $letter-spacing: calc(20px + #{$xx-large-font-size});

  .form-phone-code {
    display: inline-flex;
    width: fit-content;
    max-width: 100%;
    box-sizing: border-box;
    padding: $margin-field;
    margin: 0 auto;
    @include placeholder-webkit(var(--tertiary-text), var(--tertiary-text), 'xlarge');
    @include placeholder-styling() {
      text-align: center;
    }
    @include input-number-to-text();

    &__field {
      max-width: 300px;
      width: fit-content;
      height: rem(80px);
      padding: rem(23px) 0;
      box-sizing: border-box;
      background: var(--card-background);
      border-radius: $radius-large-input;
      border: 1px solid transparent;

      @include body-large();
      font-size: $xx-large-font-size;
      line-height: 140%;
      font-weight: $bold;
      color: var(--primary-text);
      text-align: left;
      letter-spacing: $letter-spacing;
      text-indent: $letter-spacing;
      font-family: $base-font-family!important;

      &:hover {
        border: 1px solid var(--main-color);
      }

      &:focus {
        border: 1px solid var(--main-color);
        outline: none;

        @include shadowAsBorder(var(--focus-shadow));
      }

      &--error {
        border-color: $error !important;
        color: $error;
      }

      & + & {
        margin-left: $margin-field;
      }
    }
  }
</style>
