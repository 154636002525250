<template>
  <div class="confirmation">
    <div class="confirmation__wrapper">
      <div class="confirmation__header">
        <img
          v-if="siteLogo"
          class="confirmation__logo"
          alt="Logo"
          :src="siteLogo" />
        <div v-if="order.number">#{{ order.number }}</div>
      </div>

      <div class="confirmation__card">
        <div class="confirmation__success">
          <IconVerified class="heading__icon" />
          <div class="heading__title">{{ $t('order.title') }}</div>
          <div v-if="order.email"
            class="heading__description">
            {{ $t('order.description') }}
            <span class="email">{{ order.email }}</span>
          </div>
          <div class="heading__price">
            <span>{{ $tc('order.itemCount', order.items.length, { n: order.items.length }) }}</span>
            •
            <AppTag v-if="order.addedPrice">
              <CheckoutPrice
                :amount="order.addedPrice"
                :currency="order.currency"
                prefix="+ " />
            </AppTag>

            <CheckoutPrice
              class="total__amount"
              :amount="order.total"
              :currency="order.currency" />
          </div>

          <div
            v-if="showPostSell"
            class="confirmation__post-sell">
            <PostSellContent class="post-sell__content" />

            <PostSellOffer :inside-modal="insideModal" />
          </div>
        </div>

        <div v-if="order.address"
          class="confirmation__shipping confirmation__section">
          <div class="confirmation__section-label">
            {{ $t('order.shipping') }}
          </div>
          <div class="confirmation__address">
            <div>{{ order.address.firstname }} {{ order.address.lastname }}</div>
            <div>{{ order.address.line1 }}</div>
            <div>{{ order.address.line2 }}</div>
            <div>{{ order.address.city }} {{ order.address.province }} {{ order.address.zip }}</div>
            <div>{{ order.address.country }}</div>
          </div>
        </div>

        <div v-if="order.payment.name"
          class="confirmation__payment confirmation__section">
          <div class="confirmation__section-label">
            {{ $t('order.payment') }}
          </div>
          <div class="confirmation__payment-method">
            <span
              v-if="order.payment.isLink"
              v-html="$t('checkout.stripeDefaultPaymentMethod')" />

            <span
              v-else-if="order.payment.isAfterpay"
              v-html="$t('checkout.afterpay')" />

            <span
              v-else
              :class="'credit-card credit-card--' + order.payment.icon" />
            {{ order.payment.identifier }}
          </div>
        </div>

        <div class="confirmation__details confirmation__section">
          <div class="confirmation__section-label">
            {{ $t('order.details') }}
          </div>
          <CheckoutProductListRemovable
            class="items__removable"
            :items="order.items" />
        </div>

        <div v-if="subscriptionItems.length"
          class="confirmation__subscriptions confirmation__section">
          <div class="confirmation__section-label">
            {{ $t('order.subscription') }}
          </div>
          <CheckoutProductListRemovable
            class="items__removable"
            :items="subscriptionItems" />

          <SubscriptionInfoCard
            v-if="order.frequency"
            class="products__info-card"
            :is-in-cart="true"
            :frequency="order.frequency"
            :future-amount="order.renewalAmount"
            :currency="order.currency"
            :title="$t('order.subscriptionInfoTitle')"
            content-key="order.subscriptionInfoContent" />
        </div>

        <div class="confirmation__total-amounts confirmation__section">
          <div class="total-amounts__detail">
            <div class="detail__label">{{ $t('checkout.price') }}</div>
            <div class="detail__price">
              <CheckoutPrice
                :amount="order.price"
                :currency="order.currency" />
            </div>
          </div>

          <div class="total-amounts__detail">
            <div class="detail__label">{{ $t('checkout.taxes') }}</div>
            <div class="detail__price">
              <CheckoutPrice
                :amount="order.taxes"
                :currency="order.currency" />
            </div>
          </div>

          <div class="total-amounts__detail">
            <div class="detail__label">{{ $t('checkout.shipping') }}</div>
            <div class="detail__price">
              <CheckoutPrice
                :amount="order.shipping"
                :currency="order.currency" />
            </div>
          </div>

          <div v-if="order.discount !== 0"
            class="total-amounts__detail">
            <div class="detail__label">{{ $t('checkoutLink.discount.discountTitle') }}</div>
            <div class="detail__price">
              <CheckoutPrice
                :amount="order.discount"
                :currency="order.currency" />
            </div>
          </div>

          <div class="total-amounts__total">
            <div class="total__label">{{ $t('checkout.total') }}</div>
            <CheckoutDiscountablePrice
              class="total__price"
              :amount="order.total"
              :currency="order.currency"
              variant="flat" />
          </div>
        </div>
      </div>

      <a class="confirmation__manage-orders"
        :href="manageOrdersLink"
        target="_blank"
        rel="noopener noreferrer">{{ $t('order.manage') }}</a>

      <div class="confirmation__footer" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import AppTag from '@/checkout-link/components/app/app-tag/AppTag.vue';
  import CheckoutDiscountablePrice from '@/checkout-link/components/product/checkout-price/CheckoutDiscountablePrice.vue';
  import CheckoutPrice from '@/checkout-link/components/product/checkout-price/CheckoutPrice.vue';
  import CheckoutProductListRemovable
    from '@/checkout-link/components/product/checkout-product-list-removable/CheckoutProductListRemovable.vue';
  import IconVerified from '@/checkout-link/components/icon/IconVerified.vue';
  import PostSellOffer from '@/checkout-link/views/post-sell-offer/PostSellOffer.vue';
  import PostSellContent from '@/checkout-link/views/post-sell-content/PostSellContent.vue';
  import SubscriptionInfoCard from '@/checkout-link/components/subscription/subscription-info-card/SubscriptionInfoCard.vue';
  import { wait } from '@/util';

  export default {
    name: 'CheckoutLinkConfirmation',
    components: {
      AppTag,
      CheckoutDiscountablePrice,
      CheckoutPrice,
      CheckoutProductListRemovable,
      IconVerified,
      PostSellContent,
      PostSellOffer,
      SubscriptionInfoCard,
    },
    props: {
      insideModal: {
        type: Boolean,
      },
    },
    computed: {
      ...mapGetters('OrderModule', { order: 'orderSummary' }),
      ...mapGetters('ContactModule', ['hasSomeInformation']),
      ...mapGetters('SessionModule', ['siteLogo', 'siteUrl', 'siteName']),
      ...mapGetters('FunnelModule', ['hasFunnel']),
      orderId() {
        return this.$route.params.orderId;
      },
      showPostSell() {
        return this.hasSomeInformation && this.hasFunnel;
      },
      subscriptionItems() {
        return this.order.items.filter(i => i.purchaseOption === 'SUBSCRIPTION');
      },
      manageOrdersLink() {
        return process.env.VUE_APP_SHOPPERS_PORTAL_URL;
      },
    },
    watch: {
      $route(to, from) {
        // handle when redirect from new confirmation page
        if (from.name === 'FunnelCheckout' && to.name === 'Confirmation') {
          if (this.orderId) {
            this.loadOrder();
          }
        }
      },
    },
    methods: {
      ...mapActions('CheckoutLinkModule', ['load']),
      ...mapActions('OrderModule', ['loadOrder']),
      async loadOrderNumber() {
        const fiveMinutes = 1000 * 60 * 5;
        const loadAttemptStart = Date.now();

        while (!this.order.number && Date.now() - loadAttemptStart < fiveMinutes) {
          await wait(2000);
          await this.loadOrder(this.orderId);
        }
      },
    },
    async mounted() {
      if (this.orderId) {
        await this.loadOrder(this.orderId);

        if (!this.order.number) {
          await this.loadOrderNumber();
        }
      }
    },
    async serverPrefetch() {
      if (this.orderId) {
        const order = await this.loadOrder(this.orderId);

        // load the checkout link (needed since we need to get and generate the html source template)
        if (order?.standaloneCheckoutId) {
          await this.load({
            customLink: order.standaloneCheckoutId,
            router: this.$router,
          });
        }
      }
    },
  };
</script>

<style scoped lang="scss">
  $section-max-width: 544px;

  .confirmation {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    padding: 0 !important;
    position: relative;
    padding: 16px;
    padding-bottom: 110px !important;
    background: #fff;

    &__wrapper {
      box-sizing: border-box;
      padding: 0 16px;
      width: 100%;
      max-width: 100%;

      @include breakpoint(small) {
        width: auto;
        min-width: 592px;
      }
    }

    &__header {
      height: 60px;
      padding: 12px 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: var(--main-color);
    }

    &__footer {
      height: 200px;
    }

    &__logo {
      height: 100%;
    }

    &__post-sell {
      max-width: 100%;
      margin: auto;

      & > * {
        margin-bottom: 16px;
      }
    }

    &__card {
      padding: 24px 16px;
      border-radius: 10px;
      border: 1px solid #EFEFEF;
      box-sizing: border-box;
      box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.03);
      background: var(--card-background);
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      .heading {
        &__icon {
          color: var(--main-color);
        }

        &__title {
          color: var(--main-color);
          font-weight: 700;
          font-size: 22px;
          line-height: 24px;
          text-align: center;
        }

        &__description {
          font-size: 14px;
          line-height: 21px;
          text-align: center;
        }

        &__price {
          display: flex;
          align-items: center;
          gap: 6px;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;

          & * {
            font-weight: inherit !important;
            font-size: inherit !important;
            line-height: inherit !important;
          }
        }
      }

      .title {
        &__order {
          @include heading();
          color: var(--primary-text);
          font-weight: $bold;
          margin-bottom: 5px;
          font-size: $large-font-size;
        }
      }

      .price {
        &__total {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 5px;

          & > * {
            margin-left: 5px;
          }
        }

      }

      .total {
        &__amount {
          @include heading();
          font-weight: $bold;
        }
      }

      .details {
        &__address {
          @include body-medium();
          font-weight: $semi-bold;
        }
      }

      .items {
        &__removable {
          padding-top: 12px;
        }
      }
    }

    &__section {
      padding: 24px 0 0;
      border-top: 0.5px solid #efefef;
      margin-top: 24px;
      width: 100%;
      max-width: $section-max-width;
      margin: 24px auto 0;
    }

    &__section-label {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    &__success {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    &__address {
      color: var(--tertiary-text);
      font-size: 12px;
      line-height: 21px;
    }

    &__details {
      box-sizing: border-box;
    }

    &__payment-method {
      display: flex;
      align-items: center;
      color: var(--tertiary-text);
      font-size: 12px;
      line-height: 21px;
      gap: 5px;

      .credit-card {
        width: 31.5px;
        height: 21px;
      }
    }

    &__total-amounts {
      .total-amounts {
        &__detail,
        &__total {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4px;
        }

        &__detail {
          color: var(--tertiary-raised-text);
          font-size: 12px;
          line-height: 24px;
        }

        &__total {
          margin-top: 16px;
          color: var(--primary-text);
          font-size: 14px;
          font-weight: 700;
        }
      }

      .detail {
        &__price {
          font-weight: 700;
        }
      }
    }

    &__manage-orders {
      display: block;
      box-sizing: border-box;
      max-width: $section-max-width;
      margin: 18px auto;
      padding: 15px 27px;
      background: #FFFFFF;
      border: 1px solid #EFEFEF;
      border-radius: 10px;
      color: var(--primary-text);
      text-decoration: none;
      outline: none;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;

    }
  }

  @include breakpoint(small) {
    .confirmation {
      &__container {
        padding: 0;
      }
    }
  }

  .subscription-info-card {
    margin: 8px 0 0;
  }

  .post-sell {
    &__content {
      box-shadow: var(--box-shadow-large-card);
    }
  }

  ::v-deep .email {
    font-weight: 600;
  }
</style>
