import { arrayToString } from '@/helpers/data/data.helper';
import { cardMasks } from '@/checkout-link/components/form/form-card-input/masks';

export const OrderStatus = {
  PAID: 'PAID',
  CHALLENGE: 'CHALLENGE',
};

export const getPaymentMethodDisplay = ({ paymentProvider, paymentIdentifier }) => arrayToString([
  paymentProvider,
  paymentIdentifier,
]);

export const getPaymentIcon = ({ paymentProvider }, defaultType = { name: '', icon: 'unknown' }) => {
  const provider = cardMasks.mask.find(card => card.cardtype === (paymentProvider?.toLowerCase() || defaultType.name));

  return provider?.icon || defaultType.icon;
};

export const getAddressDisplay = ({ firstname, lastname, line1, line2, zip, city, province, country }) => {
  const nameLine = arrayToString([firstname, lastname]);
  const addressLine = arrayToString([line1, line2]);
  const zipLine = arrayToString([province, zip]);

  const address = arrayToString([addressLine, city, zipLine, country], ', ');
  return arrayToString([nameLine, address], ' • ');
};

export const getCreditCardParameters = (creditCard, creditCardId, stripePaymentMethod) => {
  if (stripePaymentMethod) {
    return { stripePaymentMethod };
  }

  return !!creditCard ? { creditCard } : { creditCardId };
};
export const getAddressParameters = (address, addressId) => !!address ? { address } : { addressId };

export const isOrderPaymentLink = (orderPayment) => orderPayment === 'link';

export const isOrderPaymentAfterpay = (orderPayment) => orderPayment === 'afterpay_clearpay';
