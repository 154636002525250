import { createApp } from './main';
import { acquireId } from '@modules/acquire.module';
import iframely from '@/plugins/iframely';
import { loadStorage } from '@/services/localforage/storage.service';
import { getCookieSessionExpire } from '@/helpers/session/session.helper';

// client-specific bootstrapping logic...

// eslint-disable-next-line vue/require-name-property
const { app, router, store } = createApp({});

iframely.inject();

if (window.__INITIAL_STORAGE__) {
  loadStorage(JSON.parse(window.__INITIAL_STORAGE__));
}

if (window.__INITIAL_STATE__) {
  // We initialize the store state with the data injected from the server
  store.replaceState(window.__INITIAL_STATE__);

  if (process.client) {
    const sessionId = store.getters['SessionModule/sessionId'];
    document.cookie = `acquireSessionId=${sessionId}; path=/; SameSite=None; expires=${getCookieSessionExpire()}; Secure`;

    const cookieStack = store.getters['SessionModule/cookieStack'] || [];
    for (const cookie of cookieStack) {
      document.cookie = cookie;
    }
  }

  // load integration plugins
  store.dispatch('CheckoutLinkModule/loadPlugins');

  store.dispatch('SsrDebugModule/show');
}
router.onReady(() => {
  app.$mount(`#${acquireId}`, true);
});
