const state = () => ({
  theme: {
    colors: {
      backgroundPageColor: null,
      textColor: null,
      buttonTextColor: null,
      buttonBackgroundColor: null,
      checkoutButtonBackgroundColor: null,
      checkoutButtonTextColor: null,
      headerBackgroundColor: null,
      headerTextColor: null,
    },
    font: {
      type: null,
      name: null,
    },
  },
});

const actions = {
  setTheme({ commit, dispatch }, theme) {
    commit('setTheme', theme);
    dispatch('setCssFont');
    dispatch('setCssColors');
  },
  setCssFont({ getters, rootState }) {
    const { font } = getters;

    if (font.type === 'webfont') {
      const href = 'https://fonts.googleapis.com/css?family=';
      const rel = 'stylesheet';
      const media = 'all';

      if (process.client) {
        const link = document.createElement('link');
        link.rel = rel;
        link.href = href + font.name;
        link.media = media;

        document.head.append(link);
      } else {
        const parsedFont = font.name.replace(' ', '+');
        rootState.headExtra += `<link rel="${rel}" href="${href + parsedFont}" media="${media}">`;
      }
    }

    const key = '--main-font-family';
    const backup = '\'Public Sans\', sans-serif';
    const fontName = process.client
      ? (font.name ? JSON.stringify(font.name) : backup)
      : (font.name || backup);

    process.client
      ? document.body.style.setProperty(key, fontName)
      : rootState.bodyStyle += `${key}: ${fontName};`;
  },
  setCssColors({ getters, rootState }) {
    const { colors } = getters;

    if (process.client) {
      document.body.style.setProperty('--main-color-text', colors.buttonTextColor ?? '#FFFFFF');
      document.body.style.setProperty('--main-color', colors.buttonBackgroundColor ?? '#000000');
      document.body.style.setProperty('--page-color', colors.backgroundPageColor);
      document.body.style.setProperty('--checkout-background-color', colors.checkoutButtonBackgroundColor ?? '#000000');
      document.body.style.setProperty('--checkout-text-color', colors.checkoutButtonTextColor ?? '#FFFFFF');
      document.body.style.setProperty('--header-background-color', colors.headerBackgroundColor ?? '#000000');
      document.body.style.setProperty('--header-text-color', colors.headerTextColor ?? '#FFFFFF');
    } else {
      rootState.bodyStyle += `--main-color-text: ${colors.buttonTextColor};`;
      rootState.bodyStyle += `--main-color: ${colors.buttonBackgroundColor};`;
      rootState.bodyStyle += `--page-color: ${colors.backgroundPageColor};`;
      rootState.bodyStyle += `--checkout-background-color: ${colors.checkoutButtonBackgroundColor ?? colors.buttonBackgroundColor ?? '#000000'};`;
      rootState.bodyStyle += `--checkout-text-color: ${colors.checkoutButtonTextColor ?? colors.buttonTextColor ?? '#FFFFFF'};`;
      rootState.bodyStyle += `--header-background-color: ${colors.headerBackgroundColor ?? colors.buttonBackgroundColor ?? '#000000'};`;
      rootState.bodyStyle += `--header-text-color: ${colors.headerTextColor ?? colors.buttonTextColor ?? '#FFFFFF'};`;
    }
  },
};

const mutations = {
  setTheme(state, theme) {
    state.theme = theme;
  },
};

const getters = {
  theme: state => state.theme,
  font: (_, { theme }) => theme.font,
  colors: (_, { theme }) => theme.colors,
  buttonColor: (_, { colors }) => colors.buttonBackgroundColor,
  pageColor: (_, { colors }) => colors.backgroundPageColor,
  checkoutButtonBackgroundColor: (_, { colors }) => colors.checkoutButtonBackgroundColor ?? colors.buttonBackgroundColor ?? '#000000',
  checkoutButtonTextColor: (_, { colors }) => colors.checkoutButtonTextColor ?? colors.buttonTextColor ?? '#FFFFFF',
  headerBackgroundColor: (_, { colors }) => colors.headerBackgroundColor ?? colors.buttonBackgroundColor ?? '#000000',
  headerTextColor: (_, { colors }) => colors.headerTextColor ?? colors.buttonTextColor ?? '#FFFFFF',
};

export const ThemeModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
