<template>
  <div class="cart-empty">
    <div class="empty">
      <font-awesome-icon icon="box-open" />
      <span>{{ $t('cart.empty.description') }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'EmptyCart',
  };
</script>

<style lang="scss" scoped>
$cart-item-padding: 10px;

.cart-empty {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 $cart-item-padding;
  min-height: 80px;

  background: var(--primary-background);
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.05), inset 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary-text);

    & > * {
      margin-left: 12px;
    }
  }
}
</style>
