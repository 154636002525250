<template>
  <div
    class="pre-sell-product"
    :class="{ 'pre-sell-product--mono-product': isMonoProductFunnel }">
    <AppGallery
      class="pre-sell-product__image"
      :images="[preferredVariant.imageUrl || product.imageUrl]" />

    <div class="pre-sell-product__content">
      <div
        class="pre-sell-product__name"
        :title="product.name">
        {{ ellipsisTitle }}
      </div>

      <div class="pre-sell-product__price">
        <CheckoutPrice
          class="price__current"
          :amount="preferredVariant.price"
          :currency="getFunnelCurrency" />

        <CheckoutPrice
          v-if="isDiscountedPrice"
          class="price__former"
          data-test-id="price-discounted"
          :amount="preferredVariant.originalPrice"
          :currency="getFunnelCurrency" />
      </div>

      <p
        v-if="displayTaxesMessage"
        class="pre-sell-product__taxes">
        {{ $t('product.taxes') }}
      </p>

      <div class="pre-sell-product__actions">
        <AppButton
          class="actions__view"
          type="outlined"
          :disabled="!active"
          @click="handleViewDetails">
          {{ $t('product.details') }}
        </AppButton>

        <AppButton
          class="actions__add"
          type="primary"
          :disabled="isAddToCartDisabled"
          @click="handleAddToCart">
          <span v-if="!isUpdatingOrSaving">{{ addToCartButtonText }}</span>

          <LayoutLoader
            v-else
            :height="40"
            :is-text="true" />
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { productHasAvailableVariantsQuantities, isVariantAvailable } from '@/helpers/product/product.helper';

  import AppButton from '@/checkout-link/components/app/app-button/AppButton.vue';
  import AppGallery from '@/checkout-link/components/app/app-gallery/AppGallery';
  import CheckoutPrice from '@/checkout-link/components/product/checkout-price/CheckoutPrice';
  import LayoutLoader from '@/checkout-link/components/layout/loader/LayoutLoader.vue';

  export default {
    name: 'FunnelProduct',
    components: {
      AppButton,
      AppGallery,
      CheckoutPrice,
      LayoutLoader,
    },
    props: {
      active: {
        type: Boolean,
        required: true,
      },
      product: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters('FunnelModule', ['getFunnelCurrency', 'isMonoProductFunnel', 'isPresellFunnel']),
      ...mapGetters('CartModule', ['getItemTotal', 'isUpdating', 'getCartItemByProductId', 'getCartItemsVariantsIds']),
      ...mapGetters('OrderModule', ['isSaving']),
      hasMultipleVariants() {
        return this.product.variants.length > 1;
      },
      preferredVariant() {
        return this.product.variants.find(v => v.id === this.product.preferredVariantId);
      },
      isDiscountedPrice() {
        return this.preferredVariant.price < this.preferredVariant.originalPrice;
      },
      isProductOutOfStock() {
        return !productHasAvailableVariantsQuantities(this.product);
      },
      isVariantOutOfStock() {
        return !isVariantAvailable(this.preferredVariant);
      },
      isQuantityMaxReached() {
        return this.product.quantity.max > 0 && this.getItemTotal(this.product.productId) >= this.product.quantity.max;
      },
      isAddToCartDisabled() {
        return !this.active ||
          this.isProductOutOfStock ||
          this.isVariantOutOfStock ||
          this.isQuantityMaxReached ||
          this.isSaving ||
          this.isUpdating ||
          this.hasBeenAddedToCart;
      },
      isUpdatingOrSaving() {
        return this.isUpdating || this.isSaving;
      },
      addToCartButtonText() {
        if (this.isProductOutOfStock || this.isVariantOutOfStock) {
          return this.$t('product.outOfStock');
        }

        if (this.isQuantityMaxReached) {
          return this.$t('product.quantityLimited', { number: this.product.quantity.max });
        }

        if (!this.isPresellFunnel && this.isMonoProductFunnel) {
          return this.$t('product.buyNow');
        }

        if (this.hasBeenAddedToCart) {
          return this.$t('funnel.added');
        }

        return this.$t('funnel.add');
      },
      ellipsisTitle() {
        const truncate = (str, max, suffix) => str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;

        return truncate(this.product.name, 70, '...');
      },
      productVariantsIds() {
        return this.product.variants.map(v => v.variantId);
      },
      hasBeenAddedToCart() {
        return this.getCartItemsVariantsIds.some(variantId => this.productVariantsIds.includes(variantId)) &&
            (this.product.source === this.getCartItemByProductId(this.product.productId)?.source);
      },
      displayTaxesMessage() {
        return this.product.variants.every(v => v.taxable);
      },
    },
    methods: {
      handleAddToCart() {
        return this.hasMultipleVariants ? this.viewDetails('options') : this.addToCart(this.isMonoProductFunnel);
      },
      handleViewDetails() {
        if (this.product.descriptionHtml) {
          this.viewDetails('details');
        } else {
          this.viewDetails('options');
        }
      },
      viewDetails(tab) {
        this.$emit('view-details', {
          product: this.product,
          tab,
        });
      },
      addToCart(buyNow) {
        this.$emit('add-to-cart', {
          product: this.product,
          variant: this.preferredVariant,
          buyNow,
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .pre-sell-product {
    display: flex;
    flex-direction: column;
    flex-basis: 198px;
    flex-shrink: 0;
    box-sizing: border-box;
    width: 198px;
    height: 350px;
    background-color: var(--card-background);
    padding: 8px 8px 17px;
    border-radius: $radius-post-sell-product;
    box-shadow: var(--box-shadow-funnel-card);

    &--mono-product {
      width: 100%;
      height: auto;
    }

    &__image {
      display: flex;
      align-self: center;
      justify-content: center;
      height: 170px;
      width: 100%;
      border-radius: $radius-post-sell-product;
      background-color: var(--primary-background)
    }

    &__name {
      @include body-large();
      margin-bottom: 8px;
      min-height: 47px;
      font-weight: $bold;
      line-height: $line-height-xheading;
      overflow-wrap: break-word;
    }

    &__price {
      display: flex;
      align-items: baseline;
    }

    &__taxes {
      @include body-small();
      margin: 0;
      margin-bottom: 8px;
      font-weight: $bold;
      color: var(--main-color);
    }

    &__actions {
      display: flex;
      flex-direction: column;
    }
  }

  .price {
    &__current {
      @include body-large();
      font-weight: $bold;
      line-height: $line-height-medium;
      color: var(--main-color);
      margin-right: rem(5px);
    }

    &__former {
      @include body-medium();
      color: var(--tertiary-text);
      text-decoration: line-through;
      margin-right: rem(5px);
    }
  }

  .actions {
    &__view {
      height: rem(40px);
      margin-bottom: rem(8px);
      text-transform: uppercase;
      border: 2px solid var(--main-color);
      color: var(--main-color);
      font-weight: $bold;
      font-size: $default-font-size;
      line-height: rem($line-height-medium);

      &:disabled {
        color: var(--main-color);
        background: transparent;
        border: 2px solid var(--main-color);
      }
    }

    &__add {
      height: rem(40px);
      padding: 0 rem(20px);
      align-items: center;
    }
  }

  .pre-sell-product--mono-product {
    .pre-sell-product {
      &__image {
        height: 228px;
      }
    }
  }

  @include breakpoint(xsmall) {
    .pre-sell-product--mono-product {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: rem(28px);

      .pre-sell-product {
        &__image {
          flex: 1;
          margin-right: rem(34px);
          margin-bottom: 0;
          height: 252px;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        &__content {
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        &__name {
          font-size: rem(24px);
          line-height: rem(40px);
        }

        &__price {
          display: flex;
          align-items: center;
        }

        &__actions {
          margin-top: auto;
        }
      }

      .price-current {
        font-size: rem(24px);
        line-height: rem(40px);
      }

      .price-former {
        font-size: rem(18px);
        line-height: rem(40px);
      }
    }
  }
</style>
