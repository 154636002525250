<template>
  <div class="presell-display">
    <FunnelProductDetails
      v-if="details.isVisible"
      :product="details.product"
      :tab="details.tab"
      @click="onCloseModal"
      @add-to-cart="addToCart" />

    <div class="presell">
      <div
        class="presell__container">
        <div class="container__main">
          <div class="main__header">
            <img
              v-if="siteLogo"
              class="header__logo"
              :src="siteLogo"
              alt="logo" />
          </div>

          <div class="main__content">
            <p class="content__countdown">
              <FunnelCounter
                class="countdown__timer"
                :initial-duration="timerRemaining"
                :internal-timer="true"
                @elapsed="onElapsed" />
            </p>

            <p class="content__title">
              {{ getFunnelTitle }}
            </p>

            <p class="content__subtitle">
              {{ getFunnelSubtitle }}
            </p>

            <PostSellContent />
          </div>

          <FunnelProductDisplay
            class="main__products"
            @view-details="onToggleDetails" />
        </div>

        <div class="container__footer">
          <LayoutBrand class="footer__brand" />

          <CheckoutLinkMainFooter
            :action-disabled="disableMainFooterPayment"
            class="footer__action"
            @pay="handlePay" />
        </div>

        <BottomBar v-if="isMobile"
          :presell="true" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { DateTime } from 'luxon';

  import { getParsedLocaleDate } from '@/helpers/funnel/funnel.helper';
  import MobileMixin from '@/mixins/isMobile.mixin';

  import BottomBar from '@/checkout-link/components/layout/bottom-bar/BottomBar.vue';
  import CheckoutLinkMainFooter from '@/checkout-link/views/footer/MainFooter';
  import FunnelCounter from '@/checkout-link/components/funnel/funnel-counter/FunnelCounter.vue';
  import FunnelProductDetails from '@/checkout-link/components/funnel/funnel-product-details/FunnelProductDetails.vue';
  import FunnelProductDisplay from '@/checkout-link/components/funnel/funnel-product-display/FunnelProductDisplay.vue';
  import LayoutBrand from '@/checkout-link/components/layout/brand/LayoutBrand.vue';
  import PostSellContent from '@/checkout-link/views/post-sell-content/PostSellContent';
  import { FUNNEL_VIEWED } from '@/services/analytics/analytics.event';

  export default {
    name: 'PreSellDisplay',
    components: {
      BottomBar,
      CheckoutLinkMainFooter,
      FunnelCounter,
      FunnelProductDetails,
      FunnelProductDisplay,
      LayoutBrand,
      PostSellContent,
    },
    mixins: [MobileMixin],
    data() {
      return {
        details: {
          isVisible: false,
          product: null,
          tab: 'options',
        },
      };
    },
    computed: {
      ...mapGetters('SessionModule', ['siteLogo']),
      ...mapGetters('OrderModule', ['isSaving']),
      ...mapGetters('CartModule', ['getCartUpdateTime', 'isUpdating']),
      ...mapGetters('FunnelModule', [
        'getFunnelTitle', 'getFunnelSubtitle', 'getFunnelDuration',
        'getFunnelContent', 'isPresellFunnel', 'isTimerExpired',
        'hasFunnel', 'getFunnelId',
      ]),
      timerRemaining() {
        if (this.isTimerExpired) {
          return 0;
        }

        const now = DateTime.local();
        const cartCreatedTime = getParsedLocaleDate(this.getCartUpdateTime);
        const funneldEndTime = cartCreatedTime.plus({ seconds: this.getFunnelDuration });

        return Math.floor(funneldEndTime.diff(now, ['second']).values?.seconds) || 0;
      },
      disableMainFooterPayment() {
        return this.isUpdating || this.isSaving;
      },
    },
    methods: {
      ...mapActions('CheckoutLinkModule', ['addVariant']),
      ...mapActions('FunnelModule', ['setTimerExpired']),
      ...mapActions('OrderModule', ['processToCheckoutLink']),
      ...mapActions('AnalyticsModule', ['addFunnelEvent']),
      async onElapsed() {
        if (!this.isTimerExpired && this.hasFunnel) {
          this.onCloseModal();
          this.setTimerExpired();

          // Trigger presell payment on timer expiration
          if (this.isPresellFunnel) {
            await this.handlePay();
          }
        }
      },
      onToggleDetails({ product, tab }) {
        const selectedVariant = product.variants.find(variant => variant.id === product.preferredVariantId);

        this.details.isVisible = true;
        this.details.product = {
          ...product,
          selectedVariant,
        };
        this.details.tab = tab;
      },
      onCloseModal() {
        this.details.isVisible = false;
        this.details.product = null;
        this.details.tab = 'options';
      },
      async addToCart(data) {
        const { buyNow, ...items } = data;

        if (this.details.isVisible) {
          this.onCloseModal();
        }

        await this.addVariant({
          items,
          router: this.$router,
          buyNow,
        });
      },
      async handlePay() {
        await this.processToCheckoutLink({ init: false, router: this.$router });
      },
    },
    async mounted() {
      // When coming back from confirmation, check funnel type to push
      // to information route and not display an empty presell modal
      if (!this.isPresellFunnel) {
        await this.$router.push({
          name: 'Information',
          params: { ...this.$route.params },
        });
      } else {
        // Event use case for funnel view
        const eventData = { event: FUNNEL_VIEWED, data: { status: FUNNEL_VIEWED, baseOrderId: null, funnelId: this.getFunnelId, funnelOrderId: null } };
        this.addFunnelEvent(eventData);
      }
    },
  };
</script>

<style lang="scss" scoped>
.presell {
  @include modal-display;
  padding: 22px 10px 110px;
  background: rgba(0, 0, 0, 0.88);

  &__container {
    position: relative;
    box-sizing: border-box;
    max-width: 1098px;
    border-radius: $radius-card;
  }
}

.container {
  &__close {
    display: none;
  }

  &__main {
    @include scrollBarDisplay();
    height: 100%;
  }

  &__footer {
    display: none;
  }
}

.main {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 30px 20px;
  }

  &__products {
    padding: 0 10px 30px;
  }
}

.header {
  &__logo {
    max-height: 32px;
  }
}

.content {
  &__countdown,
  &__title,
  &__subtitle {
    margin: rem(5px) 0;
    text-align: center;
  }

  &__countdown {
    color: var(--secondary-raised-text);
    font-size: $large-font-size;
    font-weight: $bold;
    line-height: $line-height-heading;
  }

  &__title {
    color: var(--main-color);
    font-size: $x-large-font-size;
    font-weight: $bold;
    line-height: $line-height-xheading;
  }

  &__subtitle {
    color: var(--tertiary-text);
    line-height: $line-height-medium;
  }
}

.countdown {
  &__timer {
    color: $error;
  }
}

@include breakpoint(small) {
  .presell {
    padding: 22px 26px 120px;
  }
}

@include breakpoint(medium) {
  .presell {
    padding: rem(90px);
  }

  .container {
    @include modal-container;

    &__main {
      height: calc(100% - 95px);
    }

    &__footer {
      display: flex;
    }
  }

  .header {
    &__close {
      display: none;
    }
  }

  .main {
    &__header {
      justify-content: center;
    }

    &__content {
      max-width: 560px;
      margin: 0 auto;
      padding: 0 20px;
    }
  }

  .footer {
    &__brand,
    &__action{
      flex: 1;
      border-top: 1px solid var(--primary-background);
    }
  }
}
</style>
