import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const productLayoutLocales = require.context('vue-product-layout/src/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = { ...productLayoutLocales(key), ...locales(key) };
    }
  });

  return messages;
}

function getCurrentLocale() {
  const locale = navigator.language || process.env.VUE_APP_I18N_LOCALE || 'en';
  document.documentElement.lang = locale;
  return locale;
}

export const createVueI18n = (context) => {
  return new VueI18n({
    locale: process.client ? getCurrentLocale() : context.lang,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    silentFallbackWarn: true,
    messages: loadLocaleMessages(),
  });
};
