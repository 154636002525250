<template>
  <div
    class="user-payment">
    <!-- Add new payment method -->
    <div
      v-if="displayEditMode"
      class="user-payment__edit-mode">
      <div class="edit-mode__title">
        <div class="title__text">
          <font-awesome-icon
            class="title__icon"
            icon="lock" />

          <span>{{ editModeTitle }}</span>
        </div>

        <div class="title__cards">
          <IconCreditCardVisa class="cards__icon" />
          <IconCreditCardAmex class="cards__icon" />
          <IconCreditCardMastercard class="cards__icon" />
        </div>
      </div>

      <div
        v-if="showStripeElements"
        class="edit-mode__form">
        <div id="payment" />
      </div>

      <div
        v-else
        class="edit-mode__form">
        <FormCardInput
          data-test-id="card-number-input"
          :has-external-error="showErrors && !!errors.creditCard"
          :show-errors="showErrors"
          :existing-card="creditCard"
          @change="onNewPaymentMethodChange('creditCard', $event)"
          @validity-change="onValidityChange" />

        <FormInput
          class="form__zip-code"
          data-test-id="billing-zip-code-input"
          :value="creditCard.zipCode"
          :label="$t('paymentMethod.billingZipCode.label')"
          :placeholder="$t('paymentMethod.billingZipCode.placeholder')"
          :error="errors.zipCode"
          :show-errors="showErrors"
          @value-changed="onNewPaymentMethodChange('billingZipCode', $event)" />
      </div>

      <div
        v-if="cancelAllowed"
        class="edit-mode__cancel">
        <button type="button"
          @click="toggleEditMode">
          {{ $t('checkoutLink.checkout.cancel') }}
        </button>
      </div>
    </div>

    <!-- Display user payment methods -->
    <div
      v-else
      class="user-payment__display">
      <p
        v-if="displayTitle"
        class="display__title">
        <font-awesome-icon icon="lock" />
        <span>{{ $t('checkout.secureCheckout') }}</span>
      </p>

      <CreditCardDropdown
        :is-material="isMaterial"
        :inside-modal="insideModal"
        :class="{ 'has-funnel': hasFunnel }"
        :label="$t('checkout.summary.payment')"
        :mask-numbers="insideModal || isMobile"
        :auto-select-first="!displayEditMode"
        :add-option="hasEmptyOption"
        :add-option-label="$t('checkout.summary.addPaymentCard')"
        :disabled="disabledDropdown"
        :value="selectedCreditCard"
        :options="mappedCreditCards"
        @first-value-selected="onSelectedPaymentMethodChange"
        @value-changed="onSelectedPaymentMethodChange"
        @add-option="toggleEditMode" />
    </div>
  </div>
</template>

<script>
  import CheckoutUserInfo from '@mixins/checkoutUserInfo.mixin';

  import { mapActions, mapGetters } from 'vuex';
  import { getFullValue } from '@/helpers/data/data.helper';
  import FormCardInput from '@/checkout-link/components/form/form-card-input/FormCardInput.vue';
  import FormInput from '@/checkout-link/components/form/form-input/FormInput.vue';
  import CreditCardDropdown from '@/checkout-link/components/dropdown/CreditCardDropdown.vue';
  import IconCreditCardMastercard from '@/checkout-link/components/icon/IconCreditCardMastercard.vue';
  import IconCreditCardAmex from '@/checkout-link/components/icon/IconCreditCardAmex.vue';
  import IconCreditCardVisa from '@/checkout-link/components/icon/IconCreditCardVisa.vue';

  // Exported for unit tests purposes
  export const emptyCreditCard = {
    cardNumber: '',
    security: '',
    cardType: '',
    paymentMethod: 'creditcard',
    expiration: '',
    zipCode: '',
  };

  export default {
    name: 'UserPaymentMethods',
    components: {
      FormCardInput,
      FormInput,
      IconCreditCardAmex,
      IconCreditCardMastercard,
      IconCreditCardVisa,
      CreditCardDropdown,
    },
    mixins: [CheckoutUserInfo],
    props: {
      displayTitle: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showErrors: false,
        errors: {},
        creditCard: { ...emptyCreditCard },
        isCreditCardValid: false,
        stripeClient: null,
        elements: null,
        paymentElement: null,
      };
    },
    computed: {
      ...mapGetters('SessionModule', ['settingsGatewayProvider', 'settingsStripePublishableKey', 'sessionCurrency']),
      ...mapGetters('CartModule', ['hasSubscriptionItems', 'getSelectedCreditCard', 'getTotalCart']),
      ...mapGetters('ContactModule', ['creditCards', 'mappedCreditCards']),
      ...mapGetters('ContactModule/UserTemporaryInfoModule', ['getTempCreditCard', 'getTempStripePaymentMethod']),
      ...mapGetters('FunnelModule', ['hasFunnel', 'isPresellFunnel']),
      /** @public */
      existingUserInfo() {
        return !!this.mappedCreditCards.length;
      },
      editModeTitle() {
        return this.$t('paymentMethod.title');
      },
      disabledDropdown() {
        return this.hasFunnel && !this.isPresellFunnel;
      },
      selectedCreditCard() {
        return (this.getSelectedCreditCard && this.getSelectedCreditCard._id) || null;
      },
      showStripeElements() {
        return this.settingsGatewayProvider === 'stripe';
      },
    },
    watch: {
      getTotalCart(total) {
        this.elements?.update({ amount: total });
      },
    },
    methods: {
      ...mapActions('ContactModule/UserTemporaryInfoModule', ['storeTempCreditCard', 'cleanTempCreditCard', 'storeTempStripePaymentMethod', 'cleanTempStripePaymentMethod']),
      ...mapActions('CartModule', ['selectCreditCard']),
      // Selection mode
      onSelectedPaymentMethodChange(value) {
        const card = getFullValue(this.creditCards, value);
        this.selectCreditCard(card);
      },
      // Edit mode
      async onNewPaymentMethodChange(field, value) {
        if (field === 'creditCard') {
          // We need to set creditCard by spreading to kill js reference with store
          this.creditCard = { ...value };

          // But since we spread the entire object, a little cleanup is useful
          delete this.creditCard.cardIcon;
          delete this.creditCard.cardName;
        }

        if (field === 'billingZipCode') {
          this.creditCard.zipCode = value;
        }

        await this.validateForm();
      },
      async onValidityChange(cardIsValid) {
        this.isCreditCardValid = cardIsValid;

        // Validate fields on every change, but don't show errors until final validation
        await this.validateForm();
      },
      async validateForm(finalValidation = false) {
        this.errors = {
            creditCard: this.isCreditCardValid ? null : this.$t('form.card.error'),
            zipCode: this.creditCard.zipCode ? null : this.$t('form.field.required'),
          };

        // Display errors to user only during final validation before payment
        if (finalValidation) {
          this.showErrors = true;
        }

        if (this.hasError || !!this.getTempCreditCard) {
          await this.cleanTempCreditCard();
        }

        if (!this.hasError) {
          // Update new credit card in store - will be saved at payment
          await this.storeTempCreditCard(this.creditCard);
        }
      },
      async resetForm() {
        if (!!this.getTempCreditCard) {
          this.creditCard = { ...emptyCreditCard };
          // Also clean temporarily stored address
          await this.cleanTempCreditCard();
          // Reset errors is done in mixin's toggleEditMode method
        }

        if (!!this.getTempStripePaymentMethod) {
          await this.cleanTempStripePaymentMethod();
        }

        if (!this.paymentElement) {
          await this._tryLoadStripeElements();
        }
      },
      _prefill() {
        if (!!this.getTempCreditCard) {
          this.creditCard = { ...this.getTempCreditCard };
          this.isCreditCardValid = true;
        }
      },
      async _tryLoadStripeElements() {
        if (!this.showStripeElements || !this.displayEditMode) {
          return;
        }

        const { loadStripe } = await import('@stripe/stripe-js');

        this.stripeClient = await loadStripe(this.settingsStripePublishableKey);
        this.elements = this.stripeClient.elements({
          mode: 'payment',
          amount: this.getTotalCart,
          currency: this.sessionCurrency.toLowerCase(),
          setupFutureUsage: this.hasSubscriptionItems ? 'off_session' : undefined,
          paymentMethodCreation: 'manual',
        });

        this.paymentElement = this.elements.create('payment');
        this.paymentElement.mount('#payment');
        this.paymentElement.on('change', (event) => {
          if (event.complete) {
            this.createPaymentMethod();
          } else {
            this.cleanTempStripePaymentMethod();
          }
        });
      },
      async createPaymentMethod() {
        const { error } = await this.elements.submit();

        if (error?.code || !!this.getTempStripePaymentMethod) {
          await this.cleanTempStripePaymentMethod();
        }

        if (!error?.code) {
          const { paymentMethod } = await this.stripeClient.createPaymentMethod({ element: this.paymentElement });

          await this.storeTempStripePaymentMethod(paymentMethod);
        }
      },
    },
    async mounted() {
      // Unlike the other forms, the credit card form needs to be cleaned every time the component is mounted
      if (this.isPresellFunnel) {
        this._prefill();
      } else {
        await this.resetForm();
      }

      await this._tryLoadStripeElements();
    },
    beforeDestroy() {
      this.paymentElement?.destroy();
    },
  };
</script>

<style lang="scss" scoped>
  .user-payment {
    @include bodyWithFooter('container', true);

    &__edit-mode {
      border-radius: 13px;
      padding: 16px;
      background: var(--tertiary-background);
    }
  }

  .edit-mode {
    &__title {
      @include heading();
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: $bold;
      font-size: rem(15px);
      color: var(--primary-text);
    }

    &__cancel {
      @include full-btn-cancel();
      margin-top: rem(15px);
    }
  }

  .form {
    &__zip-code {
      margin-top: rem(15px);
    }

    &__button {
      padding-left: 2px;
    }
  }

  .title {
    &__icon {
      margin-right: 5px;
    }

    &__cards {
      display: flex;
    }
  }

  .cards {
    &__icon {
      height: 34px;
      width: 34px;
      margin-left: rem(5px);
    }
  }

  .display {
    &__title {
      margin: 0 0 rem(15px);
      font-size: rem(15px);
      font-weight: 700;

      span {
        margin-left: 7px;
      }
    }

    &__action {
      background: var(--main-color);
      border-radius: 2.5px;
      padding: 12px;
      line-height: 14px;
      font-weight: 400;
      color: var(--main-color-text);

      svg {
        margin-right: 5px;
      }
    }
  }

  .ellipsis {
    @include text-overflow;
  }

  @include breakpoint(medium) {
    .user-payment {
      .checkout--inside-modal & .display__title {
        display: flex;
      }
    }
  }
</style>
