<template>
  <div class="user-options">
    <!-- Display available additional options -->
    <FormCheckbox
      data-test-id="sign-up"
      :label="$t('registration.actions.signUp')"
      :value="signUp"
      @value-changed="onSignUpOptionChange($event)" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import CheckoutUserInfo from '@mixins/checkoutUserInfo.mixin';
  import FormCheckbox from '@/checkout-link/components/form/form-checkbox/FormCheckbox.vue';

  export default {
    name: 'UserOptions',
    components: {
      FormCheckbox,
    },
    mixins: [CheckoutUserInfo],
    data() {
      return {
        signUp: false,
      };
    },
    computed: {
      ...mapGetters('ContactModule/UserTemporaryInfoModule', ['getTempSignUpOption']),
    },
    methods: {
      ...mapActions('ContactModule/UserTemporaryInfoModule', ['storeTempSignUpOption', 'cleanTempSignUpOption']),
      onSignUpOptionChange(value) {
        this.storeTempSignUpOption(value);
      },
      /** @public */
      async resetForm() {
        this.signUp = false;
        // Also clean temporarily stored signup option
        await this.cleanTempSignUpOption();
      },
      _preFill() {
        if (!!this.getTempSignUpOption) {
          this.signUp = this.getTempSignUpOption;
        }
      },
    },
    mounted() {
      // For now we prefill only for the registration form
      this._preFill();
    },
  };
</script>
