import { render, staticRenderFns } from "./IconCart.vue?vue&type=template&id=5562244f&scoped=true&"
import script from "./IconCart.vue?vue&type=script&lang=js&"
export * from "./IconCart.vue?vue&type=script&lang=js&"
import style0 from "./IconCart.vue?vue&type=style&index=0&id=5562244f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5562244f",
  null
  
)

export default component.exports