import httpService from '@/services/http/http.service';
import httpHelper from '@/helpers/http/http.helper';

export const newEvent = async(name, data = {}, isFunnel = false) => {
  try {
    const params = isFunnel ? ['funnel'] : [];
    const route = httpHelper.buildV1ApiRoute(httpHelper.ANALYTICS_ENDPOINT, params);

    await httpService.post(route, { event: name, ...data });
  } catch (e) {
    // Silence is golden
    if (process.env.NODE_ENV !== 'production') {
      console.error(e);
    }
  }
};

export default { newEvent };
