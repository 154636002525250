import httpService from '@/services/http/http.service';
import httpHelper from '@/helpers/http/http.helper';

const loadCart = async(abandonedCartInfos = null) => {
  let route = httpHelper.buildV1ApiRoute(httpHelper.CART_ENDPOINT);

  if (abandonedCartInfos?.acquire) { // load cart from acquire abandoned cart ID
    route = `${route}?abandoned-cart-id=${abandonedCartInfos?.acquire}`;
  } else if (abandonedCartInfos?.shopify) { // load cart from shopify abandoned cart infos
    route = `${route}?cart-info=${abandonedCartInfos?.shopify}`;
  }

  return httpService.get(route);
};

const saveCart = async(cart) => {
  const route = httpHelper.buildV1ApiRoute(httpHelper.CART_ENDPOINT);

  return httpService.post(route, cart);
};

export default {
  loadCart,
  saveCart,
};
