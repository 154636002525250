import funnelService from '@/services/funnel/funnel.service';
import { EMPTY_FUNNEL } from '@/helpers/funnel/funnel.helper';

const state = () => ({
  funnel: { ...EMPTY_FUNNEL },
  presellFunnel: false,
  postsellFunnel: false,
  timerExpired: false,
  hasBeenChecked: false,
  buyNow: false,
  isLoading: false,
  error: null,
});

const actions = {
  async loadFunnel({ commit, dispatch }, fromRegistration = false) {
    commit('LOAD_FUNNEL');

    try {
      const isPresell = true;
      const { funnel } = await funnelService.loadFunnel();
      funnel && dispatch('setFunnel', { funnel, isPresell, isPostsell: false });

      dispatch('setChecked', true);
      commit('LOAD_FUNNEL_SUCCESS');
    } catch (error) {
      dispatch('loadFunnelError', error);
      throw error;
    }
  },
  setFunnel({ commit }, { funnel, isPresell = false, isPostsell = false }) {
    commit('SET_FUNNEL', funnel);
    commit('SET_IS_PRESELL', isPresell);
    commit('SET_IS_POSTSELL', isPostsell);
  },
  setChecked({ commit }, hasBeenChecked) {
    commit('SET_CHECKED', hasBeenChecked);
  },
  setTimerExpired({ commit }) {
    commit('SET_TIMER_EXPIRED', true);
  },
  setBuyNow({ commit }) {
    commit('SET_BUY_NOW', true);
  },
  clearFunnel({ commit }) {
    commit('CLEAR_FUNNEL');
    commit('SET_TIMER_EXPIRED', false);
    commit('SET_CHECKED', false);
    commit('SET_IS_PRESELL', false);
    commit('SET_IS_POSTSELL', false);
    commit('SET_BUY_NOW', false);
  },
  loadFunnelError({ commit }, error) {
    commit('LOAD_FUNNEL_ERROR', error);
  },
};

const mutations = {
  SET_FUNNEL(state, funnel) {
    state.funnel = {
      ...funnel,
      products: funnel.products.map(fp => ({ ...fp, source: 'FUNNEL' })), // Product context needed
    };
  },
  SET_IS_PRESELL(state, isPresell) {
    state.presellFunnel = isPresell;
  },
  SET_IS_POSTSELL(state, isPostsell) {
    state.postsellFunnel = isPostsell;
  },
  SET_CHECKED(state, hasBeenChecked) {
    state.hasBeenChecked = hasBeenChecked;
  },
  SET_TIMER_EXPIRED(state, isExpired) {
    state.timerExpired = isExpired;
  },
  SET_BUY_NOW(state, isBuyNow) {
    state.buyNow = isBuyNow;
  },
  CLEAR_FUNNEL(state) {
    state.funnel = { ...EMPTY_FUNNEL };
  },
  LOAD_FUNNEL(state) {
    state.isLoading = true;
    state.error = null;
  },
  LOAD_FUNNEL_SUCCESS(state) {
    state.isLoading = false;
    state.error = null;
  },
  LOAD_FUNNEL_ERROR(state, error) {
    state.isLoading = false;
    state.error = error;
  },
};

const getters = {
  isFunnelLoading: state => state.isLoading,
  funnelError: state => state.error,
  isTimerExpired: state => state.timerExpired,
  hasBeenChecked: state => state.hasBeenChecked,
  isPresellFunnel: state => state.presellFunnel,
  isPostsellFunnel: state => state.postsellFunnel,
  isBuyNowSelected: state => state.buyNow,
  getFunnel: state => state.funnel,
  hasFunnel: (_, { getFunnel }) => !!getFunnel?._id,
  getFunnelId: (_, { getFunnel }) => getFunnel?._id,
  getFunnelDuration: (_, { getFunnel }) => getFunnel?.duration * 60, // Received duration in seconds. Needed in minutes.
  getFunnelProducts: (_, { getFunnel }) => getFunnel?.products,
  getFunnelCurrency: (_, { getFunnel }) => getFunnel?.currency,
  getFunnelTitle: (_, { getFunnel }) => getFunnel?.title,
  getFunnelSubtitle: (_, { getFunnel }) => getFunnel?.subtitle,
  getFunnelContent: (_, { getFunnel }) => getFunnel?.content,
  isMonoProductFunnel: (_, { getFunnelProducts }) => getFunnelProducts?.length === 1,
  getFunnelItemsVariantsIds: (_, { getFunnelProducts }) =>
    getFunnelProducts?.reduce((pIds, p) => [...pIds, ...p.variants.map(v => v.variantId)], []) || [],
  presellIgnored: (_, { getFunnelItemsVariantsIds }, __, rootGetters) =>
    !rootGetters['CartModule/getCartItemsVariantsIds'].some(variantId => getFunnelItemsVariantsIds.includes(variantId)),
};

export const FunnelModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
