let acquireSiteId;
let acquireStoreSessionId;

const acquireId = 'acquire-cart';

// Define per env dedicated vars
const checkoutLinkOriginEnvironment = {
  apiTypeProperty: 'CHECKOUT_LINK',
};

const setAcquireSiteId = (id) => {
  if (id) {
    acquireSiteId = id;
  }
};

const setAcquireStoreSessionId = (id) => {
  if (id) {
    acquireStoreSessionId = id;
  }
};

export {
  acquireId,
  acquireSiteId,
  checkoutLinkOriginEnvironment,
  setAcquireSiteId,
  acquireStoreSessionId,
  setAcquireStoreSessionId,
};
