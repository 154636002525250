import httpHelper from '@/helpers/http/http.helper';
import httpService from '@/services/http/http.service';
import { objectToQueryString } from '@/helpers/data/data.helper';
import isEmpty from 'lodash/isEmpty';

const load = async(params, queryParams = null) => {
  let route = httpHelper.buildV1ApiRoute(httpHelper.STANDALONE_ENDPOINT + '/' + httpHelper.INIT_CHECKOUT_LINK_ENDPOINT, [...params]);

  if (!isEmpty(queryParams)) {
    const query = objectToQueryString(queryParams);
    route = `${route}?${query}`;
  }

  return await httpService.get(route, { trigger404Error: false });
};

const loadCheckoutLink = async(customLink, queryParams = null) => load([customLink], queryParams);

export default {
  load,
  loadCheckoutLink,
};
