import { acquireId } from '@modules/acquire.module';

export const isThisElement = (clickEvent, elementName) => {
  const firstPath = clickEvent.composedPath() && clickEvent.composedPath()[0];

  return typeof firstPath?.className === 'string' && firstPath.className.includes(elementName);
};

export const injectBrowserInAppClass = () => {
  if (navigator.userAgent.includes('iPhone') && navigator.userAgent.includes('Instagram')) {
    let classHeight = 'standard';
    if (window.screen.height >= 844) {
      classHeight = 'very-high';
    } else if (window.screen.height >= 812) {
      classHeight = 'high';
    } else if (window.screen.height >= 667) {
      classHeight = 'medium';
    }

    document.body.classList.add('in-app-instagram__' + classHeight);
  }
};
