<template>
  <div class="app-counter">{{ durationToDisplay }}</div>
</template>

<script>
  import { setCounterDownTimer } from '@/helpers/counter/counter.helper';

  export default {
    name: 'AppCounter',
    props: {
      initialDuration: {
        type: Number,
        default: parseInt(process.env.VUE_APP_UPSELL_COUNTER),
      },
      externalDuration: {
        type: Number,
        default: null,
      },
      internalTimer: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        duration: this.initialDuration || this.externalDuration || 0,
      };
    },
    computed: {
      minutesLeft() {
        return Math.floor(this.duration / 60);
      },
      secondsLeft() {
        return this.duration % 60;
      },
      durationToDisplay() {
        return this.minutesLeft.toString() + ':' + this.secondsLeft.toString().padStart(2, '0') + ' min';
      },
    },
    watch: {
      externalDuration() {
        this.duration = this.externalDuration;
      },
    },
    created() {
      if (this.internalTimer) {
        setCounterDownTimer(
          this.duration,
          (duration) => this.duration = duration,
          () => this.$emit('elapsed'),
        );
      } else {
        this.duration = this.externalDuration;
      }
    },
  };
</script>

<style scoped lang="scss">
  .app-counter {
    @include body-medium();
    color: var(--secondary-text);
    font-weight: $regular;
  }
</style>
