import Vue from 'vue';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleUp,
  faBoxOpen,
  faCaretRight,
  faCheck,
  faExpandAlt,
  faLock,
  faLongArrowAltDown,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faMinus,
  faMobile,
  faPlus,
  faSearch,
  faShoppingBag,
  faShoppingCart,
  faSortDown,
  faSortUp,
  faThumbsDown,
  faThumbsUp,
  faTicketAlt,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCcMastercard,
  faFacebookMessenger,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css';

library.add(
  faTrash,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faThumbsUp,
  faPlus,
  faMinus,
  faSortDown,
  faSortUp,
  faMobile,
  faFacebookMessenger,
  faWhatsapp,
  faCcMastercard,
  faCheck,
  faSearch,
  faTimes,
  faExpandAlt,
  faThumbsDown,
  faThumbsUp,
  faTicketAlt,
  faShoppingCart,
  faLongArrowAltDown,
  faBoxOpen,
  faCaretRight,
  faShoppingBag,
  faLock,
  faAngleDown,
  faAngleUp,
);

// Make sure you tell Font Awesome to skip auto-inserting CSS into the <head>
config.autoAddCss = false;

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
