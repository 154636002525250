const state = () => ({
  title: '',
  description: '',
  favIcon: '',
  openGraphTags: null,
  customHeadCode: '',
  customBodyCode: '',
});

const actions = {
  setTitle({ commit }, title) {
    commit('SET_TITLE', title);
  },
  setDescription({ commit }, description) {
    commit('SET_DESCRIPTION', description);
  },
  setFavicon({ commit }, favIcon) {
    commit('SET_FAVICON', favIcon);
  },
  setOpenGraphTags({ commit }, tags) {
    commit('SET_OPEN_GRAPH_TAGS', tags);
  },
  setCustomCode({ commit }, code) {
    commit('SET_CUSTOM_CODE', code);
  },
  setErrorHeader({ commit }, data) {
    commit('SET_TITLE', data.title);
    commit('SET_DESCRIPTION', data.description);
    commit('SET_OPEN_GRAPH_TAGS', null);
  },
};

const mutations = {
  SET_TITLE(state, title) {
    state.title = title;
  },
  SET_DESCRIPTION(state, description) {
    state.description = description;
  },
  SET_FAVICON(state, favIcon) {
    state.favIcon = favIcon;
  },
  SET_OPEN_GRAPH_TAGS(state, tags) {
    state.openGraphTags = tags;
  },
  SET_CUSTOM_CODE(state, data) {
    state.customHeadCode = `
      ${data.siteCustomCode?.head || ''}
      ${data.checkoutLinkCustomCode?.head || ''}
    `;

    state.customBodyCode = `
      ${data.siteCustomCode?.body || ''}
      ${data.checkoutLinkCustomCode?.body || ''}
    `;
  },
};

const getters = {
  title: state => state.title,
  description: state => state.description,
  favIcon: state => state.favIcon,
  openGraphTags: state => state.openGraphTags,
  customHeadCode: state => state.customHeadCode,
  customBodyCode: state => state.customBodyCode,
};

export const DocumentHeaderModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
