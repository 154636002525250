<template>
  <AppButton
    type="link"
    :icon-prefix="iconPrefix"
    :icon-suffix="iconSuffix"
    class="link"
    @click.native="onClick">
    <slot />
  </AppButton>
</template>

<script>
  import AppButton from '@/checkout-link/components/app/app-button/AppButton.vue';

  export default {
    name: 'AppLink',
    components: {
      AppButton,
    },
    props: {
      iconPlacement: {
        type: String,
        default: 'none',
        validator: function(value) {
          return ['left', 'right', 'none'].indexOf(value) !== -1;
        },
      },
      icon: {
        type: String,
        default: null,
      },
      to: {
        type: String,
        default: '',
      },
    },
    computed: {
      iconPrefix() {
        return this.iconPlacement === 'left' ? (this.icon || 'long-arrow-alt-left') : null;
      },
      iconSuffix() {
        return this.iconPlacement === 'right' ? (this.icon || 'long-arrow-alt-right') : null;
      },
    },
    methods: {
      onClick() {
        !this.to ? this.$emit('click') : this.toLocation();
      },
      toLocation() {
        this.$router.push(this.to);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .link {
    background-color: transparent;
    border: none;
    border-radius: $radius-default-button;
    color: var(--primary-text);
    @include body-small();
    font-weight: bold;
    outline: none;
    padding: rem(11px);
    text-decoration: none;

    &:hover {
      box-shadow: none;
    }

    &:focus,
    &:visited {
      border-width: 1px;
      color: var(--main-color);
    }
  }

</style>
