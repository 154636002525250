import { AuthModule } from './modules/auth/auth.module';
import { CartModule } from './modules/cart/cart.module';
import { ContactModule } from './modules/contact/contact.module';
import { UserModule } from './modules/user/user.module';
import { SessionModule } from './modules/session/session.module';
import { ProductModule } from './modules/product/product.module';
import { OrderModule } from './modules/order/order.module';
import { CheckoutLinkModule } from './modules/checkout-link/checkout-link.module';
import { ThemeModule } from './modules/theme/theme.module';
import { DocumentHeaderModule } from './modules/document-header/document-header.module';
import { FunnelModule } from './modules/funnel/funnel.module';
import { AnalyticsModule } from './modules/analytics/analytics.module';
import { SsrDebugModule } from './modules/ssr-debug/ssr-debug.module';

const plugins = [];

export default {
  strict: process.env.NODE_ENV !== 'production',
  state: () => ({
    bodyStyle: '',
    htmlStyle: '',
    headExtra: '',
    headMeta: '',
  }),
  modules: {
    AnalyticsModule,
    AuthModule,
    CartModule,
    ContactModule,
    UserModule,
    SessionModule,
    ProductModule,
    OrderModule,
    CheckoutLinkModule,
    ThemeModule,
    DocumentHeaderModule,
    FunnelModule,
    SsrDebugModule,
  },
  plugins: plugins,
};
