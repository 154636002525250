import { getUserLocale } from 'get-user-locale';
import Dinero from 'dinero.js';
import { DateTime } from 'luxon';
import { utils as currencyUtils } from 'iso4217';

export const arrayToString = (array, separator = ' ') => array.filter(data => !!data).join(separator);

export const objectToQueryString = (obj, separator = '&') => arrayToString(
  Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`),
  separator,
);

/**
 * Formats the given money "amount" and "currency" according to the
 * locale of the user.
 *
 * Since amounts come from the backend as integers, we first pass
 * the amount and currency to Dinero so that we can get the right
 * "unit" which is the numerical amount including the required
 * decimals for this currency.
 *
 * Then we use the standard Intl.NumberFormat to format the unit
 * to the currency using the user's locale.
 *
 * @param {*} amount the money amount
 * @param {*} currency the currency of the amount
 * @param {*} locale the locale of the user, defaults to #getUserLocale
 */
export const formattedPrice = (amount, currency, locale = getUserLocale()) => {
  if (!currency) {
    return '';
  }

  if (isFloat(amount)) {
    amount = Math.floor(amount);
  }

  const dineroPrice = Dinero({
    amount,
    currency,
    precision: currencyUtils.getByCode(currency)?.Fraction || 0, // Not every currency has a precision of 2 (See JPY)
  }).setLocale(locale);

  return Intl.NumberFormat(locale, {
    currency: dineroPrice.getCurrency(),
    style: 'currency',
    minimumFractionDigits: dineroPrice.getPrecision(),
  }).format(dineroPrice.toUnit());
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formattedShortDate = (date) => {
  const displayedDate = DateTime.fromISO(date);

  return displayedDate.setLocale(getUserLocale()).toLocaleString({ month: 'short', day: 'numeric' });
};

// https://codepen.io/andreaswik/pen/YjJqpK
export const lightOrDarkColor = (color) => {
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5 ? 'light' : 'dark';
};

/**
 * check if color is dark or light,
 * convert to HSL,
 * adjust Lightness level to create correct variation
 * https://css-tricks.com/converting-color-spaces-in-javascript/
 * @param {string} color - base color HEX code to convert in variation
 * @param {number} variationLevel - % of difference in Lightness to create variation
 * @param {number} alpha - transparency level - optional, default 1
 */
export const lightnessColorVariation = (color, variationLevel, alpha = 1) => {
  let r = 0;
  let g = 0;
  let b = 0;

  if (color.length === 4) {
    r = '0x' + color[1] + color[1];
    g = '0x' + color[2] + color[2];
    b = '0x' + color[3] + color[3];
  } else if (color.length === 7) {
    r = '0x' + color[1] + color[2];
    g = '0x' + color[3] + color[4];
    b = '0x' + color[5] + color[6];
  }

  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) { h += 360; }

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  // Lightness treatment depending on original color lightness
  l = lightOrDarkColor(color) === 'dark' ? l + variationLevel : l - variationLevel;

  return `hsla(${h}, ${s}%, ${l}%, ${alpha})`;
};

/**
 * Color converter Hex to RGBA
 * #00ff00 => rgba(0, 255, 0, alpha)
 * @param {string} color - base color HEX code to convert
 * @param {number} alpha - alpha level for transparency - optional, default 1
 */
export const HEXtoRGBA = (color, alpha = 1) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const isValidTextColourOnServer = async(stringToTest) => {
  if (process.client) {
    return;
  }

  const jsdom = await import('jsdom');
  const dom = new jsdom.JSDOM('<!DOCTYPE html><html lang=""><head></head><body></body></html>');
  const creator = dom.window.document;

  return isValidTextColourExec(creator, stringToTest);
};

export const isValidTextColour = (stringToTest) => isValidTextColourExec(document, stringToTest);

export const isValidTextColourExec = (creator, stringToTest) => {
  if ([null, '', 'inherit', 'transparent'].includes(stringToTest)) {
    return false;
  }

  const image = creator.createElement('img');
  image.style.color = 'transparent';
  image.style.color = stringToTest;

  return image.style.color !== 'transparent';
};

export function isNumeric(value) {
  return !isNaN(parseInt(value)) && isFinite(value);
}

export function isFloat(value) {
  return isNumeric(value) && !Number.isInteger(parseFloat(value));
}

export const getFullValue = (array, id) => array?.find(v => (v._id || v.id) === id);
