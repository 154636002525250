<template>
  <span :class="classes">
    {{ price }}
  </span>
</template>

<script>
  import { formattedPrice } from '@/helpers/data/data.helper';
  import { mapGetters } from 'vuex';

  export default {
    name: 'CheckoutPrice',
    props: {
      amount: {
        type: Number,
        required: true,
      },
      currency: {
        type: String,
        required: true,
      },
      prefix: {
        type: String,
        default: '',
      },
      variant: {
        type: String,
        default: 'default',
      },
      mainTextColor: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('SessionModule', ['acceptsLanguages']),
      textColor() {
        return this.mainTextColor ? 'price--color' : '';
      },
      classes() {
        return `price price--${this.variant} ${this.textColor}`;
      },
      price() {
        return this.prefix + formattedPrice(this.amount, this.currency, this.acceptsLanguages);
      },
    },
  };
</script>

<style scoped lang="scss">

  .price {
    color: inherit;
    font-family: var(--main-font-family);

    &--dark {
      color: var(--primary-text);
    }

    &--subdued {
      color: var(--tertiary-raised-text);
    }

    &--color {
      color: var(--main-color);
    }

  }
</style>
