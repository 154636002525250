<template>
  <div
    class="post-sell-offer"
    :class="postSellOfferClasses">
    <!-- Variant selection modal -->
    <FunnelProductDetails
      v-if="details.isVisible"
      :product="details.product"
      :tab="details.tab"
      @click="closeDetails"
      @add-to-cart="addToCart" />

    <!-- Header -->
    <div class="post-sell-offer__header">
      <div
        class="header-countdown"
        :class="{'disabled': isTimerExpired }">
        <FunnelCounter
          :initial-duration="timerRemaining"
          :internal-timer="true"
          @elapsed="timerExpired" />
      </div>

      <div class="header-message">
        <p class="header-message__title">{{ getFunnelTitle }}</p>
        <p class="header-message__description">{{ getFunnelSubtitle }}</p>
      </div>
    </div>

    <!-- Scrollable product cards -->
    <div :class="productDisplayClasses">
      <div
        v-for="product in getFunnelProducts"
        :key="product.productId"
        class="products-individual">
        <FunnelProduct
          :active="!isTimerExpired"
          :product="product"
          @add-to-cart="addToCart"
          @view-details="viewDetails" />
      </div>
    </div>

    <template>
      <ModalDisplay
        v-if="insideModal"
        @close="closeModal">
        <router-view />
      </ModalDisplay>

      <BottomBar v-if="displayBottomBar" />
    </template>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { DateTime } from 'luxon';

  import MobileMixin from '@mixins/isMobile.mixin';
  import { FUNNEL_ACTIVATED } from '@/services/analytics/analytics.event';
  import { getParsedLocaleDate } from '@/helpers/funnel/funnel.helper';

  import BottomBar from '@/checkout-link/components/layout/bottom-bar/BottomBar.vue';
  import FunnelCounter from '@/checkout-link/components/funnel/funnel-counter/FunnelCounter';
  import FunnelProduct from '@/checkout-link/components/funnel/funnel-product/FunnelProduct';
  import ModalDisplay from '@/checkout-link/components/modal-display/ModalDisplay.vue';
  import FunnelProductDetails from '@/checkout-link/components/funnel/funnel-product-details/FunnelProductDetails.vue';

  export default {
    name: 'PostSellOffer',
    components: {
      BottomBar,
      FunnelProductDetails,
      FunnelCounter,
      ModalDisplay,
      FunnelProduct,
    },
    mixins: [MobileMixin],
    props: {
      insideModal: {
        type: Boolean,
      },
    },
    data() {
      return {
        details: {
          isVisible: false,
          product: null,
          tab: 'options',
        },
      };
    },
    computed: {
      ...mapGetters('CartModule', ['isCartEmpty']),
      ...mapGetters('OrderModule', ['orderTimeCreated', 'orderError']),
      ...mapGetters('FunnelModule', [
        'getFunnelDuration', 'getFunnelProducts',
        'getFunnelCurrency', 'getFunnelTitle', 'getFunnelSubtitle',
        'hasFunnel', 'isTimerExpired', 'isMonoProductFunnel',
      ]),
      displayBottomBar() {
        return !this.isCartEmpty && !this.isMonoProductFunnel;
      },
      timerRemaining() {
        const orderCreatedTime = getParsedLocaleDate(this.orderTimeCreated);
        const now = DateTime.local();
        const funnelEndTime = orderCreatedTime.plus({ seconds: this.getFunnelDuration });

        return Math.floor(funnelEndTime.diff(now, ['seconds']).values.seconds);
      },
      lessThanFourProducts() {
        return this.getFunnelProducts.length < 4;
      },
      fourProducts() {
        return this.getFunnelProducts.length === 4;
      },
      postSellOfferClasses() {
        return {
          'is-mobile': this.isMobile,
          'post-sell-offer--three-products': this.lessThanFourProducts,
          'post-sell-offer--four-products': this.fourProducts,
        };
      },
      productDisplayClasses() {
        return [
          this.isMonoProductFunnel ? 'post-sell-offer__mono-product' : 'post-sell-offer__scrollable-products',
          { 'post-sell-offer__scrollable-products--three-products': this.lessThanFourProducts },
        ];
      },
    },
    methods: {
      ...mapActions('CheckoutLinkModule', ['addVariant']),
      ...mapActions('FunnelModule', ['setTimerExpired']),
      ...mapActions('OrderModule', ['clearOrderError']),
      ...mapActions('AnalyticsModule', ['addFunnelEvent']),
      timerExpired() {
        this.closeDetails();
        this.setTimerExpired();
      },
      addToCart(data) {
        const { buyNow, ...items } = data;

        if (this.details.isVisible) {
          this.closeDetails();
        }

        this.addFunnelEvent({ event: FUNNEL_ACTIVATED });
        this.addVariant({
          items,
          router: this.$router,
          buyNow,
        });
      },
      viewDetails({ product, tab }) {
        const selectedVariant = product.variants.find(variant => variant.id === product.preferredVariantId);
        const updatedProduct = {
          ...product,
          selectedVariant,
          currency: this.getFunnelCurrency,
        };

        this.details.isVisible = true;
        this.details.product = updatedProduct;
        this.details.tab = tab;
      },
      closeDetails() {
        this.details.isVisible = false;
        this.details.product = null;
        this.details.tab = 'options';
      },
      backToInformation() {
        if (this.$router.currentRoute.name !== 'FunnelInformation') {
          this.$router.push({ name: 'FunnelInformation', params: this.$router.currentRoute.params });
        }
      },
      closeModal() {
        if (this.orderError) {
          this.clearOrderError();
        }

        this.backToInformation();
      },
    },
    mounted() {
      if (!this.hasFunnel) {
        return;
      }

      // Load CheckoutLinkInformation component in MobileFooter router-view
      this.$router.push({ name: 'FunnelInformation', params: this.$router.currentRoute.params });

      // Cart prefill
      const addToCartProducts = this.getFunnelProducts.map(product => {
        // Do not prefill cart if no min quantity is defined or if the product has more than 1 variant
        if (!product?.quantity?.min || product.variants.length > 1) {
          return null;
        }

        return {
          product,
          variant: product.variants.find(v => v.id === product.preferredVariantId) || product.variants[0],
        };
      }).filter(data => !!data);

      if (!!addToCartProducts.length) {
        this.addVariant({ items: addToCartProducts, router: this.$router });
      }
    },
  };
</script>

<style scoped lang="scss">
  .post-sell-offer {
    box-sizing: border-box;
    box-shadow: var(--box-shadow-large-card);
    background: var(--card-background);
    margin: 0 auto;

    &--three-products {
      max-width: 680px;
    }

    &--four-products {
      max-width: 888px;
    }

    &__header {
      padding: rem(18px) rem(28px) 0;
      text-align: center;
      color: var(--post-sell-offer-text-color);
    }

    &__mono-product {
      padding: rem(16px) rem(16px) rem(29px);
    }

    &__scrollable-products {
      display: flex;
      padding: rem(16px) rem(16px) rem(29px);
      overflow-y: auto; // fall back for "overlay" value non compatible browsers
      overflow-y: overlay;

      /* Hide horizontal scrollbar */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Chrome */
      }
    }

    &__mobile-footer {
      width: 100%;
      height: 100%;
    }
  }

 .header-countdown {
    margin-bottom: rem(3px);

    ::v-deep .upsell-counter {
      &__label {
        @include label();
        color: var(--post-sell-offer-text-color);
        opacity: 1;
      }

      &__counter {
        @include body-medium();
        font-weight: $bold;
        color: var(--main-color);
      }
    }

    .disabled {
      ::v-deep .upsell-counter {
        &__counter {
          color: var(--post-sell-offer-text-color);
        }
      }
    }
  }

  .header-message {
    &__title {
      @include heading();
      margin: 0 0 3px;
      color: var(--main-color);
      font-weight: 700;
    }

    &__description {
      margin: 0;
      line-height: rem(16px);
      font-weight: 400;
      font-size: rem(12px);
      color: var(--secondary-text);
    }
  }

  .products-individual{
    border-radius: $radius-card;

    &:not(:last-child) {
      margin-right: rem(8px);
    }
  }

  @include breakpoint(small) {
    .post-sell-offer {
      border-radius: $radius-post-sell-product;

      &__scrollable-products {
        padding: rem(16px) rem(28px) rem(32px);

        &--three-products {
          justify-content: center;
        }
      }

      &__mono-product {
        padding: rem(28px);
      }
    }
  }
</style>
