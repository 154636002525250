import httpService from '@/services/http/http.service';
import httpHelper from '@/helpers/http/http.helper';
import { objectToQueryString } from '@/helpers/data/data.helper';

const sendOtp = async({ contactId, preference }) => {
  const route = httpHelper.buildV1ApiRoute(httpHelper.OTP_ENDPOINT);

  return await httpService.post(route, { contactId, preference });
};

const verifyOtp = async({ code, contactId }) => {
  const route = httpHelper.buildV1ApiRoute(httpHelper.OTP_ENDPOINT);

  return await httpService.put(route, { code, contactId });
};

const verifyOtpAccount = async(query) => {
  query = objectToQueryString(query);

  let route = httpHelper.buildV1ApiRoute(httpHelper.OTP_CONTACT_ENDPOINT);
  route = `${route}?${query}`;

  return await httpService.get(route);
};

const confirmPhoneNumber = async(phone) => {
  const route = httpHelper.buildV1ApiRoute(httpHelper.PHONE_NUMBER_ENDPOINT);

  return await httpService.put(route, { phone });
};

export default { sendOtp, verifyOtp, verifyOtpAccount, confirmPhoneNumber };
