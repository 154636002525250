<template>
  <LayoutModal
    class="warning-message"
    :has-overlay="true"
    :has-close-btn="false"
    @close="onStay">
    <div class="warning-message__logo">
      <img
        v-if="siteLogo"
        :src="siteLogo"
        alt="logo" />
    </div>

    <div class="warning-message__content">
      <h2
        v-if="message.title"
        class="content__title">
        {{ message.title }}
      </h2>
      <p
        v-if="message.description"
        class="content__text">
        {{ message.description }}
      </p>
    </div>

    <div class="warning-message__actions">
      <AppButton
        class="actions__primary"
        type="depressed"
        @click="onStay">
        <template v-if="isMobile">{{ $t('dontLeave.stay.mobile') }}</template>
        <template v-else>{{ $t('dontLeave.stay.desktop') }}</template>
      </AppButton>
    </div>
  </LayoutModal>
</template>

<script>
  import MobileMixin from '@mixins/isMobile.mixin';
  import AppButton from '@/checkout-link/components/app/app-button/AppButton';
  import LayoutModal from '@/checkout-link/components/layout/modal/LayoutModal';

  export default {
    name: 'WarningMessage',
    components: { AppButton, LayoutModal },
    mixins: [MobileMixin],
    props: {
      siteLogo: {
        type: [String, null],
        required: true,
      },
      message: {
        type: Object,
        default: null,
      },
    },
    methods: {
      onStay() {
        this.$emit('stay');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .warning-message {
    ::v-deep {
      .layout-modal {
        margin: auto;
        max-width: rem(650px);
        max-height: rem(400px);

        &__container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      .container {
        &__close {
          top: $layout-padding;
          right: $layout-padding;
        }
      }
    }

    &__logo {
      height: rem(65px);

      img {
        min-width: 0;
        min-height: 0;
        width: auto;
        height: 100%;
        object-fit: contain;
        max-width: 50%;
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;

      & > * {
        &:not(:last-child) {
          margin-right: rem(10px);
        }
      }
    }
  }

  .content {
    &__title {
      @include heading();
      font-size: $xx-large-font-size;
      font-weight: $bold;
      color: var(--primary-text);
    }

    &__text {
      margin: rem(16px) 0;

      @include body-large();
      font-weight: $regular;
      color: var(--primary-text);
    }
  }

  .actions {
    &__primary,
    &__secondary {
      width: fit-content;
      padding: rem(13px) rem(20px);
      font-weight: $semi-bold;
    }
  }

  @include breakpoint(small) {
    .warning-message {
      ::v-deep {
        $padding: rem(50px);

        .layout-modal {
          &__container {
            padding: $padding;
          }
        }

        .container {
          &__close {
            top: $padding;
            right: $padding;
          }
        }
      }

      &__logo {
        img {
          max-width: rem(150px);
        }
      }
    }
  }
</style>
