<template>
  <div
    :id="acquireId"
    @click="windowClick">
    <router-view />
  </div>
</template>

<script>
  import { acquireId } from '@modules/acquire.module';
  // import { startHotjarRecording } from '@/plugins/analyticsTools';

  // eslint-disable-next-line vue/require-name-property
  export default {
    data() {
      return {
        windowClicked: false,
      };
    },
    computed: {
      acquireId() {
        return acquireId;
      },
    },
    methods: {
      windowClick() {
        if (!this.windowClicked) {
          // startHotjarRecording();
          this.windowClicked = true;
        }
      },
    },
    async mounted() {
      const cndEndPoint = process.env.VUE_APP_S3_ASSETS_PATH_URL;

      const script = document.createElement('script');
      script.src = cndEndPoint + 'assets/scripts/glide/glide.js';
      script.id = 'glide';
      script.async = true;
      document.body.appendChild(script);

      const script2 = document.createElement('script');
      script2.src = cndEndPoint + 'assets/scripts/navbar/navbar-v2.min.js';
      script2.id = 'navbar';
      script2.async = true;
      document.body.appendChild(script2);

      const script3 = document.createElement('script');
      script3.src = cndEndPoint + 'assets/box/box-flex-checkout-link-v4.js';
      script3.id = 'box-flex';
      script3.async = true;
      document.body.appendChild(script3);
    },
  };
</script>

<style lang="scss">
  .pac-container {
    background-color: var(--card-raised-background);

    .pac-item, .pac-item-query {
      color: var(--primary-text);

      &:hover {
        background-color: var(--google-hover);
      }
    }
  }

  #acquire-cart {
    font-family: var(--main-font-family);
    color: var(--primary-text);
    width: 100%;
    height: 100%;
    position: fixed;

    background-color: var(--page-color);
  }
</style>
