<template>
  <div class="form-coupon-field">
    <span
      class="form-coupon-field__field"
      data-test-id="form-coupon-field"
      :class="{ 'form-coupon-field__field--focus': isFocus, 'form-coupon-field__field--error': hasError }">
      <input
        ref="coupon"
        class="field__input"
        :placeholder="$t('checkout.coupon.placeholder')"
        data-test-id="form-coupon-field-input"
        :value="inputValue"
        @focus="setFocus"
        @blur="removeFocus"
        @change="onChange" />

      <AppButton
        class="field__submit-button"
        @click.native="onSubmit">{{ $t('checkout.coupon.button') }}
      </AppButton>
    </span>

    <span
      v-if="hasError"
      class="form-coupon-field__error-message"
      data-test-id="form-coupon-field-error">
      {{ $t(errorMessage) }}
    </span>
  </div>
</template>

<script>
  import AppButton from '@/checkout-link/components/app/app-button/AppButton';

  export default {
    name: 'FormCouponField',
    components: { AppButton },
    props: {
      hasError: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        type: String,
        default: null,
        required: true,
      },
      value: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isFocus: false,
        inputValue: this.value,
      };
    },
    methods: {
      onChange(event) {
        this.inputValue = event.target.value;
      },
      onSubmit() {
        this.$emit('submit', this.$refs.coupon.value);
      },
      setFocus() {
        this.isFocus = true;
      },
      removeFocus() {
        this.isFocus = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  $margin-label: rem(5px);

  .form-coupon-field {
    display: flex;
    flex-direction: column;

    &__field {
      @include placeholder-webkit(var(--tertiary-text), var(--tertiary-text));

      border-radius: $radius-input;
      display: flex;
      align-items: center;
      border: 1px solid var(--input-border);
      margin-top: $margin-label;
      padding: 0;
      background: var(--card-background);

      &--focus {
        border: 1px solid var(--main-color);
      }

      &--error {
        border-color: $error !important;
        color: $error;

        input {
          color: $error !important;
        }
      }
    }

    &__error-message {
      margin-top: rem(5px);
      @include body-small();
      color: $error;
    }
  }

  .field {
    &__input {
      @include default-input();
      min-width: 0;
      background: var(--card-background);
      font-weight: $semi-bold;
      font-size: rem(14px) !important;
      border: 0 #ffffff;

      &::placeholder {
        color:red;
        font-size: rem(14px) !important;
      }

      &:hover, &:focus, &:active {
        border: 0;
        box-shadow: none;
      }
    }

    &__submit-button {
      border-radius: 4px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #2288FF;
      text-align: right;
      text-transform: uppercase;
      letter-spacing: inherit;
      width: auto;
      background-color: #fff;

      &:hover, &:focus, &:active {
        border: 0;
        box-shadow: none;
      }
    }
  }
</style>
