<template>
  <div class="cart-total">
    <AppError
      v-if="hasError"
      :error="error" />

    <div class="cart-total__content">
      <div class="content__detail">
        <div class="detail__label">{{ $t('checkout.price') }}</div>
        <div class="detail__price">
          <CheckoutPrice
            :amount="getTotalET"
            :currency="getCurrency" />
        </div>
      </div>

      <div class="content__detail">
        <div class="detail__label">{{ $t('checkout.taxes') }}</div>
        <div class="detail__price">
          <CheckoutPrice
            v-if="getSelectedAddress"
            :amount="getTotalVAT"
            :currency="getCurrency" />
          <span v-else>{{ $t('checkoutLink.checkout.nextStep') }}</span>
        </div>
      </div>

      <div class="content__detail">
        <div class="detail__label">{{ $t('checkout.shipping') }}</div>
        <div class="detail__price">
          <CheckoutPrice
            v-if="getSelectedAddress"
            :amount="getTotalShipping"
            :currency="getCurrency" />
          <span v-else>{{ $t('checkoutLink.checkout.nextStep') }}</span>
        </div>
      </div>

      <CheckoutDiscount class="content__discount" />

      <div
        v-if="couponAllowed"
        class="content__discount-field"
        data-test-id="coupon-wrapper">
        <CheckoutTotalCouponModal
          class="details__coupon"
          :error-message="couponError.key || ''"
          :has-error="!!couponError.key"
          :is-loading="couponAdding || couponRemoving"
          :hide-border-bottom="true"
          @submit-triggered="onCouponAdded" />
      </div>

      <div class="content__total">
        <div class="total__label">{{ $t('checkout.total') }}</div>
        <CheckoutDiscountablePrice
          class="total__price"
          :amount="getTotalCart"
          :currency="getCurrency"
          :discounted-amount="getTotalDiscountedCart"
          variant="flat" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import AppError from '@/checkout-link/components/app/app-error/AppError';
  import CheckoutDiscount from '@/checkout-link/components/checkout/checkout-total/discount/CheckoutDiscount';
  import CheckoutPrice from '@/checkout-link/components/product/checkout-price/CheckoutPrice';
  import CheckoutDiscountablePrice from '@/checkout-link/components/product/checkout-price/CheckoutDiscountablePrice';
  import CheckoutTotalCouponModal from '@/checkout-link/components/checkout/checkout-total/total-coupon-modal/CheckoutTotalCouponModal';

  export default {
    name: 'CartTotal',
    components: {
      AppError,
      CheckoutTotalCouponModal,
      CheckoutDiscountablePrice,
      CheckoutPrice,
      CheckoutDiscount,
    },
    computed: {
      ...mapGetters('CartModule', [
        'getCurrency', 'getTotalET', 'getTotalDiscountedCart', 'getTotalShipping', 'getTotalVAT', 'getTotalCart',
        'getSelectedAddress', 'couponError', 'couponAdding', 'couponRemoving',
      ]),
      ...mapGetters('CheckoutLinkModule', ['hasCheckoutLink', 'canAddCoupons']),
      ...mapGetters('OrderModule', ['orderError']),
      couponAllowed() {
        return this.hasCheckoutLink && this.canAddCoupons;
      },
      hasError() {
        return !!this.orderError;
      },
      error() {
        return !this.hasError
          ? null
          : this.orderError;
      },
    },
    methods: {
      ...mapActions('CartModule', ['addCoupon']),
      onCouponAdded(coupon) {
        if (coupon) {
          this.addCoupon(coupon);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @mixin line-price() {
    @include body-large;
    text-align: right;
    line-height: $line-height-xheading;
    font-weight: $bold;
  }

  @mixin line-label() {
    @include body-large;
    text-transform: capitalize;
    line-height: $line-height-xheading;
    font-weight: $regular;
  }

  .cart-total {
    display: flex;
    flex-direction: column;

    &__content {
      flex: 1 1 auto;
    }
  }

  .content {
    &__detail,
    &__total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    &__detail {
      color: var(--tertiary-raised-text);
    }

    &__discount {
      padding: 0;

      ::v-deep .item {
        &__value {
          @include line-price;
        }
      }
    }

    &__total {
      margin-top: 16px;
      padding: 8px 0;
      border-radius: 4px;

      color: var(--primary-text);
    }
  }

  .detail,
  .discount,
  .total {
    &__label {
      @include line-label
    }

    &__price {
      @include line-price
    }
  }

  .total {
    &__label {
      font-weight: $bold;
      font-size: $xs-large-font-size;
    }

    &__price {
      ::v-deep .discountable-price {
        &__original {
          font-weight: $regular;
        }
      }
    }
  }
</style>
