<template>
  <div class="discount">
    <div
      v-for="coupon in mappedCoupons"
      :key="coupon.id"
      class="discount__item">
      <div class="item__title">
        <div class="button">
          {{ coupon.name }}
          <AppIconButton
            class="close"
            name="times"
            size="medium"
            @click.native="onCouponRemoved(coupon.id)" />
        </div>
      </div>
      <CheckoutPrice
        v-if="coupon.value"
        class="item__value"
        prefix="-"
        :amount="coupon.value"
        :currency="getCurrency"
        variant="dark" />
    </div>

    <div
      v-for="coupon in visibleMappedFixedCoupons"
      :key="coupon.id"
      class="discount__item">
      <div class="item__title">
        <div
          v-if="coupon.name === 'DISCOUNT'"
          class="button">
          {{ $t('checkoutLink.discount.discountTitle') }}
        </div>
        <div
          v-else
          class="button">
          {{ coupon.name }}
        </div>
      </div>
      <CheckoutPrice
        class="item__value"
        prefix="-"
        :amount="coupon.value"
        :currency="getCurrency"
        variant="dark" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import CheckoutPrice from '@/checkout-link/components/product/checkout-price/CheckoutPrice';
  import AppIconButton from '@/checkout-link/components/app/app-icon-button/AppIconButton';

  export default {
    name: 'CheckoutDiscount',
    components: {
      CheckoutPrice,
      AppIconButton,
    },
    computed: {
      ...mapGetters('CartModule', ['getCurrency', 'mappedCoupons', 'mappedFixedCoupons']),
      visibleMappedFixedCoupons() {
        return this.mappedFixedCoupons.filter(el => el.value > 0);
      },
    },
    methods: {
      ...mapActions('CartModule', ['removeCoupon']),
      onCouponRemoved(coupon) {
        if (coupon) {
          this.removeCoupon(coupon);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.discount {
  padding: 10px 0 10px;
  width: 100%;

  &__item {
    display: flex;
    align-items: center;
    padding-bottom: calc(#{$layout-padding} / 2);
  }

  .item {
    &__title {
      flex: 1;

      .button {
        display: flex;
        align-items: center;
        width: fit-content;

        padding: 8px;
        border-radius: $radius-badge;
        background: $positive-background;

        @include body-medium();
        font-weight: $bold;
        color: $positive;

        .close {
          $icon-size: 12px;

          box-sizing: border-box;
          margin-left: 10px;
          padding: 5px;
          width: $icon-size;
          height: $icon-size;
          border: 1px solid transparent;

          @include body-medium();
          color: $positive;
        }
      }
    }

    &__value {
      color: $positive;
    }
  }
}

.item {
  &__title {
    flex: 1;
    @include body-medium();
    font-weight: $regular;
    color: var(--modal-secondary-text);
  }

  &__value {
    @include body-large();
    font-weight: $bold;
    color: var(--modal-secondary-text);
  }
}
</style>
