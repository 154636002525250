<template>
  <div class="product-list-removable">
    <CheckoutProductInOffer
      v-for="(item, idx) in items"
      :key="`${item.variantId}-${idx}`"
      class="product-list-removable__item"
      :product="item" />
    <div
      v-for="(item, idx) in removableItems"
      :key="`removable_${item.variantId}-${idx}`"
      class="product-list-removable__removable-item">
      <div class="removable-item__title">
        {{ item.label }}
      </div>
      <div class="removable-item__price">
        <CheckoutPrice
          class="rs__price"
          :amount="item.price"
          :currency="item.currency" />
      </div>
      <AppIconButton
        class="removable-item__remove"
        name="times"
        size="small"
        @click.native="onRemove(item.variantId)" />
    </div>
  </div>
</template>

<script>
  import CheckoutProductInOffer from '@/checkout-link/components/product/product-in-offer/CheckoutProductInOffer';
  import AppIconButton from '@/checkout-link/components/app/app-icon-button/AppIconButton';
  import CheckoutPrice from '@/checkout-link/components/product/checkout-price/CheckoutPrice';

  export default {
    name: 'CheckoutProductListRemovable',
    components: {
      CheckoutPrice,
      AppIconButton,
      CheckoutProductInOffer,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      removableItems: {
        type: Array,
        default: () => ([]),
      },
    },
    methods: {
      onRemove(id) {
        this.$emit('remove', id);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .product-list-removable {
    display: flex;
    flex-direction: column;

    &__item {
      margin-bottom: 10px;
    }

    &__removable-item {
      display: flex;
      align-items: center;
      background: var(--focus-shadow);
      border-radius: $radius-product-card;

      @include body-medium();
      font-weight: $bold;
      color: var(--main-color);
    }
  }

  .removable-item {
    &__title {
      flex: 3;
      padding: 5px 10px;
      box-sizing: border-box;
      border-bottom: 2px solid var(--main-color);
      border-radius: $radius-product-card;
    }

    &__price {
      flex: 1;
      padding: 5px 0;
      box-sizing: border-box;
      text-align: right;
    }

    &__remove {
      padding: 6px;
      margin-left: 7px;
      box-sizing: border-box;
      text-align: right;

      font-size: $large-font-size;
      line-height: $large-font-size;
      font-weight: $regular;
      color: var(--main-color);

      &:hover, &:focus {
        border: none;
      }
    }
  }
</style>
