import AnalyticsService from '@/services/analytics/analytics.service';

const state = {};

const actions = {
  async addCheckoutEvent(_, name) {
    await AnalyticsService.newEvent(name);
  },
  async addFunnelEvent({ rootGetters }, { event, data = {} }) {
    await AnalyticsService.newEvent(event, {
      // default value for event on the fly
      status: event,
      baseOrderId: rootGetters['OrderModule/orderId'],
      funnelId: rootGetters['FunnelModule/getFunnelId'],
      funnelOrderId: null,
      // overridden values for specific events from store
      ...data,
    }, true);
  },
};

const mutations = {};
const getters = {};

export const AnalyticsModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
