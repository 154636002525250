<template>
  <div class="funnel-counter">
    <div class="funnel-counter__label">{{ $t('cart.upsell.label') }}:</div>
    <AppCounter
      class="funnel-counter__counter"
      :initial-duration="initialDuration"
      :external-duration="externalDuration"
      :internal-timer="internalTimer"
      @elapsed="onElapsed" />
  </div>
</template>

<script>
  import AppCounter from '@/checkout-link/components/app/app-counter/AppCounter';

  export default {
    name: 'FunnelCounter',
    components: { AppCounter },
    props: {
      initialDuration: {
        type: Number,
        default: parseInt(process.env.VUE_APP_UPSELL_COUNTER),
      },
      externalDuration: {
        type: Number,
        default: null,
      },
      internalTimer: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      onElapsed() {
        this.$emit('elapsed');
      },
    },
  };
</script>

<style scoped lang="scss">
  .funnel-counter {
    display: inline-flex;
    align-items: baseline;

    &__label {
      @include body-xsmall();
      font-weight: $semi-bold;
      color: var(--primary-text);
      margin-right: 3px;
      text-transform: uppercase;
    }

    &__counter {
      @include heading();
      font-weight: $bold;
      color: #F54848;
    }
  }
</style>
