<template>
  <div
    v-click-outside="closeOptions"
    class="user-phone">
    <div
      ref="phone-field"
      class="user-phone__field"
      data-test-id="user-phone"
      :class="{ 'user-phone__field--focus': isFocused, 'user-phone__field--filled': isFilled, 'user-phone__field--error': hasError }">
      <div
        class="field__country-selector"
        @click="openOptions">
        <div
          class="country-selector__flag"
          :class="selectedCountryClass"
          data-test-id="form-phone-selected" />
        <font-awesome-icon
          class="country-selector__arrow"
          icon="sort-down" />
      </div>

      <div class="field__input">
        <label>
          <input
            class="material-input"
            placeholder=" "
            data-cy="user-phone-input"
            data-test-id="user-phone-input"
            :disabled="disabled"
            :value="phone"
            @focus="setFocus"
            @blur="removeFocus"
            @input="onNewPhoneValueChange" />

          <span class="material-label">{{ $t('form.phoneField.placeholder') }}</span>
        </label>
      </div>
    </div>

    <div
      class="user-phone__countries"
      :class="{ open: isOpen }"
      :style="{ top: top(), left: left(), width: width() }">
      <div
        class="countries__search"
        :class="{ 'countries__search--focus': isSearchFocused }">
        <span class="search__prefix"><font-awesome-icon icon="search" /></span>
        <input
          ref="countrySearch"
          class="search__input"
          :placeholder="$t('form.phoneField.search')"
          @keyup="searchCountry"
          @focus="setSearchFocus"
          @blur="removeSearchFocus" />
      </div>

      <div class="countries__container-items">
        <button
          v-for="country in (filteredCountries || countries)"
          :key="country.iso2"
          type="button"
          class="countries__item"
          :class="{ active: isSelected(country) }"
          @click="onSelect(country)">
          <span
            class="item__flag"
            :class="countryClass(country)" />
          <span class="item__name">{{ country.name }}</span>
          <span class="item__code">+{{ country.dialCode }}</span>
        </button>
      </div>
    </div>

    <span
      v-if="hasError"
      data-cy="user-phone-error"
      class="user-phone__error-message">
      {{ $t('form.phoneField.error') }}
    </span>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import debounce from 'lodash/debounce';
  import ClickOutside from 'vue-click-outside';
  import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
  import '@styles/countries.scss';
  import { findPhoneCountryByCode, getMappedPhoneCountries } from '@modules/phoneCountries/phoneCountries.module';

  export default {
    name: 'UserPhone',
    directives: {
      ClickOutside,
    },
    props: {
      defaultCountry: {
        type: String,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isFocus: false,
        isOpen: false,
        isSearchFocused: false,
        selected: null,
        countries: getMappedPhoneCountries(),
        filteredCountries: null,
        phone: null,
        hasError: false,
        debouncedValidation: null,
      };
    },
    computed: {
      ...mapGetters('ContactModule/UserTemporaryInfoModule', ['getTempPhone']),
      isFocused() {
        return this.isFocus && !this.isOpen;
      },
      isFilled() {
        return this.isFocused || this.phone;
      },
      selectedCountryClass() {
        return this.selected
          ? this.countryClass(this.selected)
          : null;
      },
    },
    watch: {
      defaultCountry() {
        this.selectDefaultAndEmit();
      },
    },
    methods: {
      ...mapActions('ContactModule/UserTemporaryInfoModule', ['storeTempPhone', 'cleanTempPhone']),
      left() {
        const { left } = this.$refs['phone-field']?.getBoundingClientRect() || {};
        const offset = left || 0;

        return `${(offset || 0)}px`;
      },
      top() {
        const { top } = this.$refs['phone-field']?.getBoundingClientRect() || {};
        const offset = top || 0;
        const marginTop = 10;
        const height = this.$refs['phone-field']?.offsetHeight;

        return `${(offset || 0) + (height || 0) + marginTop}px`;
      },
      width() {
        const width = this.$refs['phone-field']?.clientWidth;
        const borderWidth = 2;

        return width ? `${width + borderWidth}px` : 'auto';
      },
      countryClass(flag) {
        return `flag--${flag.iso2}`;
      },
      isSelected(country) {
        return this.selected && this.selected.iso2 === country.iso2;
      },
      setFocus() {
        this.isFocus = true;
        this.closeOptions();
      },
      removeFocus() {
        this.isFocus = false;
      },
      setSearchFocus() {
        this.isSearchFocused = true;
      },
      removeSearchFocus() {
        this.isSearchFocused = false;
      },
      onSelect(item) {
        this.closeOptions();
        this.setSelected(item);
      },
      setSelected(item) {
        this.selected = item;

        if (this.phone) {
          this.validateAndEmit(this.phone);
        }
      },
      openOptions() {
        if (!this.disabled) {
          this.isOpen = true;
          this.setFocusOnSearch();
        }
      },
      closeOptions() {
        if (this.isOpen) {
          this.isOpen = false;
        }
      },
      setFocusOnSearch() {
        this.$nextTick().then(() => this.$refs.countrySearch.focus());
      },
      searchCountry(event) {
        const searchText = event.target.value;

        if (searchText) {
          this.filteredCountries = this.countries.filter((country) =>
            country.name.toLowerCase().includes(searchText.toLowerCase()));
        } else {
          this.filteredCountries = null;
        }
      },
      onNewPhoneValueChange(event, phoneFromUrl = null) {
        this.phone = phoneFromUrl || event.target.value;
        this.debouncedValidation(this.phone);
      },
      async validateAndEmit(enteredPhone) {
        const phoneNumber = parsePhoneNumberFromString(enteredPhone, this.selected.iso2.toUpperCase());
        this.hasError = !phoneNumber || !phoneNumber.isValid();

        !this.hasError ? await this.emitValidPhone(phoneNumber) : await this.emitInvalidPhone();
      },
      async emitValidPhone(phone) {
        this.phone = phone.formatNational();

        this.$emit('phone-changed', phone.number);
        await this.storeTempPhone(phone.number);
      },
      async emitInvalidPhone() {
        this.$emit('phone-changed', null);
        await this.cleanTempPhone();
      },
      selectDefaultAndEmit(phoneFromUrl) {
        const country = findPhoneCountryByCode(this.defaultCountry) || this.countries[0];
        this.setSelected(country);

        if (phoneFromUrl) {
          this.onNewPhoneValueChange(null, phoneFromUrl);
        }
      },
    },
    created() {
      // See https://stackoverflow.com/a/49780382/866172 for why we
      // define debounce methods here.
      this.debouncedValidation = debounce(this.validateAndEmit, 1000);
    },
    mounted() {
      const phoneFromUrl = this.$route.query.phoneNumber?.replace(/ /g, '+') ||
        this.$route.query.phone?.replace(/ /g, '+') ||
        this.getTempPhone ||
        null;

      this.selectDefaultAndEmit(phoneFromUrl);
    },
  };
</script>

<style scoped lang="scss">
  $code-width: rem(35px);
  $flag-width: rem(40px);

  .user-phone {
    display: flex;
    height: rem(70px);
    width: 100%;
    flex-direction: column;

    &__field {
      position: relative;
      display: flex;
      align-items: center;
      border: 1px solid var(--input-border);
      border-radius: $radius-input;
      padding: 0 1px;

      &--focus {
        outline: none;
        border: 1px solid var(--main-color);
        @include shadowAsBorder(var(--focus-shadow));
      }

      &--error {
        border-color: $error !important;
        color: $error;

        input {
          color: $error !important;
        }
      }
    }

    &__error-message {
      @include body-small();

      margin: rem(5px) 0 0 rem(5px);
      padding-left: rem(10px);
      color: $error;
    }

    &__countries {
      width: 100%;

      visibility: hidden;
      opacity: 0;
      z-index: 99999;

      position: fixed;
      top: 0;

      box-shadow: var(--box-shadow-modal);
      border-radius: $radius-input;
      background: var(--card-background);
      margin-bottom: 30px;

      &.open {
        visibility: visible;
        opacity: 1;
        top: 100%;
      }
    }

    .countries {
      &__search {
        display: flex;
        align-items: center;
        margin: 0 rem(10px);

        border-bottom: 1px solid var(--primary-background);
        transition: border-bottom-color 0.2s;

        @include body-medium();
        @include placeholder-webkit();

        &--focus {
          border-bottom-color: var(--main-color);
        }
      }

      &__container-items {
        width: 100%;
        height: rem(177px);
        overflow-y: auto;

        border-bottom-left-radius: $radius-input;
        border-bottom-right-radius: $radius-input;
      }

      &__item {
        width: 100%;
        background: var(--card-background);
        border: none;
        padding: rem(10px) rem(13px);
        height: rem(50px);

        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        @include body-large();
        font-weight: $semi-bold;
        text-align: left;
        color: var(--primary-text);

        &:hover, &:focus, &.active {
          background: var(--primary-background);
          outline: none;
        }

        &:last-child {
          border-bottom-left-radius: $radius-input;
          border-bottom-right-radius: $radius-input;
        }
      }
    }

    .search {
      &__prefix {
        width: rem(12px);
        height: rem(12px);
        font-size: rem(12px);
        display: flex;
        align-items: center;

        svg {
          color: var(--tertiary-text);
        }
      }

      &__input {
        flex: 1;
        border: none;
        padding: rem(13px) rem(5px);
        background: transparent;

        @include body-medium();
        color: var(--primary-text);

        &:focus {
          outline: none;
        }
      }
    }

    .item {
      &__code {
        color: var(--tertiary-text);
        width: $code-width;
        text-align: right;
      }

      &__flag {
        border-radius: 1.6px;
        min-width: rem(40px);
        min-height: rem(24px);
        width: $flag-width;
        height: rem(24px);
      }

      &__name {
        margin-left: 10px;
        flex: 1;
        width: calc(100% - #{$flag-width} - #{$code-width});
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
      }
    }

    .field {
      &__country-selector {
        padding: rem(14px) rem(10px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }

      &__input {
        flex: 1;
        width: 100%;
        height: fit-content;
        max-height: 100%;
        box-sizing: border-box;
      }
    }

    .material-input {
      width: 87%;
      padding: 10px;
      border: none;
      font-size: rem(14px);
      font-weight: bold;
      color: var(--primary-text);
      background: var(--card-background);
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      & + span.material-label {
        position: absolute;
        top: 2px;
        left: 10px;
        font-size: rem(10px);
        font-weight: 400;
        white-space: nowrap;
        color: var(--tertiary-text);
        transition: 0.3s;
      }

      &:not(:focus):placeholder-shown + span.material-label {
        top: 17px;
        left: 70px;
        white-space: nowrap;
        color: var(--tertiary-text);
        font-size: rem(14px);
        transition: 0.3s;
      }
    }

    .country-selector {
      &__flag {
        border-radius: 1.6px;
        width: rem(30px);
        height: rem(18px);
      }

      &__arrow {
        margin-left: rem(10px);
        font-size: $default-font-size;
        line-height: $line-height-small;
        color: var(--primary-text);
      }
    }
  }

  .user-phone__field--focus,
  .user-phone__field--filled {
    .field__country-selector {
      padding: rem(18px) rem(10px) rem(10px) rem(10px);
    }
    .field__input {
      padding-top: rem(10px);
    }
  }
</style>
