import httpService, { AcquireError } from '@/services/http/http.service';
import httpHelper from '@/helpers/http/http.helper';
import { getCookieSessionExpire } from '@/helpers/session/session.helper';

const loadSettings = async() => {
  const route = httpHelper.buildV1ApiRoute(httpHelper.SETTINGS_ENDPOINT);

  return await httpService.get(route);
};

const loadSession = async(route) => {
  const data = await httpService.get(route.toString());
  const sessionId = data.sessionId || '';

  if (process.client) {
    document.cookie = `acquireSessionId=${sessionId}; path=/; SameSite=None; expires=${getCookieSessionExpire(1440)}; Secure`;
  }

  // Add some debug infos
  if (!data.cartId || data.cartId === 'undefined' || data.cartId === 'null') {
    throw new AcquireError({ message: 'CartId property not defined in session response. cartId = ' + data.cartId });
  }
  if (!data.sessionId || data.sessionId === 'undefined' || data.sessionId === 'null') {
    throw new AcquireError({ message: 'Session ID property not defined in session response. sessionId = ' + data.sessionId });
  }
  if (!data.siteId || data.siteId === 'undefined' || data.siteId === 'null') {
    throw new AcquireError({ message: 'site ID property not defined in session response. siteId = ' + data.siteId });
  }

  return data;
};

export default { loadSettings, loadSession };
