<template>
  <div
    class="user-shipping">
    <!-- Display available shipping options -->

    <SimpleDropdown
      class="user-shipping__selection-mode"
      :inside-modal="insideModal"
      :is-material="isMaterial"
      :label="$t('checkout.summary.shipping')"
      :value="(getSelectedShipping && getSelectedShipping.id) || null"
      :options="availableShippingOptions"
      :disabled="disabledShippingMenu"
      :loading="isShippingLoading"
      :auto-select-first="true"
      @value-changed="onShippingChange" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import CheckoutUserInfo from '@mixins/checkoutUserInfo.mixin';
  import { getFullValue } from '@/helpers/data/data.helper';

  import SimpleDropdown from '@/checkout-link/components/dropdown/SimpleDropdown';

  export default {
    name: 'UserShipping',
    components: {
      SimpleDropdown,
    },
    mixins: [CheckoutUserInfo],
    data() {
      return {
        shippingNotAvailable: [{
          name: this.$t('checkout.summary.shippingNotAvailable'),
          value: 'value',
        }],
        shippingNeedAddress: [{
          name: this.$t('checkout.summary.shippingNeedAddress'),
          value: 'value',
        }],
      };
    },
    computed: {
      ...mapGetters('CartModule', [
        'getMappedShippingOptions', 'getSelectedShipping',
        'isShippingLoading', 'shippingOptions', 'getSelectedAddress',
      ]),
      existingUserInfo() {
        return !!this.getMappedShippingOptions.length;
      },
      disabledShippingMenu() {
        return (this.isMobile || this.insideModal) &&
          (!this.isAddressLoading && !this.isShippingLoading &&
          !this.existingUserInfo);
      },
      availableShippingOptions() {
        if (!this.existingUserInfo && !this.getSelectedAddress) {
          return this.shippingNeedAddress;
        } else if (!this.existingUserInfo && this.getSelectedAddress) {
          return this.shippingNotAvailable;
        } else {
          return this.getMappedShippingOptions;
        }
      },
    },
    methods: {
      ...mapActions('CartModule', ['saveShippingOption']),
      async onShippingChange(value) {
        const shipping = getFullValue(this.shippingOptions, value);

        await this.saveShippingOption(shipping);
      },
    },
  };
</script>
