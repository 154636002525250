import { sync } from 'vuex-router-sync';

import Vue from 'vue';
import App from './App.vue';
import { createRouter } from './router';

import { createStore } from '@/store';
import { createVueI18n } from '@/i18n';

import '@/plugins';
import analyticsTools from '@/plugins/analyticsTools';
import spreedly from '@/plugins/spreedly';

import { checkoutLinkOriginEnvironment } from '@modules/acquire.module';
import { initHttpInstance } from '@/services/http/http.service';

import { disableIosTextFieldZoom } from './ios';
import { overrideRouterPrototypeFunctions } from './router/prototype';

require('@styles/main.scss');

Vue.config.productionTip = false;

// Ensure ios doesn't zoom on fields, because yeah, well, we just didn't
// ask you anything...
disableIosTextFieldZoom();

// export a factory function for creating fresh app, router and store
// instances
export function createApp(context) {
  const store = createStore();
  const router = createRouter(store);

  // Silent router warning for query param injection
  overrideRouterPrototypeFunctions(router);

  initHttpInstance(
    () => store.getters['SessionModule/sessionId'] || context?.cookies?.acquireSessionId,
    () => store.getters['SessionModule/siteId'],
    () => store.getters['ContactModule/contactId'] || null,
    router,
    context.host,
    context.referer,
    context.userAgent,
    context.ip,
    context.acceptsLanguages,
  );

  // sync so that route state is available as part of the store
  sync(store, router);

  // eslint-disable-next-line vue/require-name-property
  const app = new Vue({
    router,
    store,
    i18n: createVueI18n(context),
    render: h => h(App),
  });

  spreedly.injectScript(router);

  analyticsTools.injectGtm();
  // analyticsTools.injectDatadogRum(); disable datadog RUM
  // analyticsTools.injectDebug('checkout-link');

  store.dispatch('CartModule/setCheckoutType', checkoutLinkOriginEnvironment.apiTypeProperty);

  return { app, router, store };
}
