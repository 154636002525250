<template>
  <ProductDrawer
    :value="drawerOpen"
    :tab.sync="drawerTab"
    :product="product"
    :images="mappedImages"
    :selected-variant="selectedVariant"
    :preview="false"
    :description="formattedDescription"
    :min-quantity-product="minQuantityProduct"
    :custom-fields-values="customFieldsValues"
    :user-context="userContext"
    :purchase-option="purchaseOption"
    :product-price="productPrice"
    :discounted-price="0"
    :currency="getCurrency"
    :locale="acceptsLanguages"
    :is-quantity-max-reached="isQuantityMaxReached"
    :add-to-cart-disabled="addToCartDisabled"
    :has-options="hasOptions"
    @input="close"
    @add-to-cart="handleAddToCart"
    @changed-variant="onVariantChange" />
</template>

<script>
  import { mapGetters } from 'vuex';

  import { getProductVariantImages } from '@/helpers/checkout-link/checkout-link.helper';
  import {
    extractDescription,
    getVariantPrice,
    hasVariantsAndAttributes,
    productHasAvailableVariantsQuantities,
    isVariantAvailable,
  } from '@/helpers/product/product.helper';

  import { ProductDrawer } from 'vue-product-layout';

  export default {
    name: 'ProductInCartOptions',
    components: {
      ProductDrawer,
    },
    props: {
      product: {
        type: Object,
        required: true,
      },
      tab: {
        type: String,
        required: true,
      },
      variantId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        drawerOpen: false,
        selectedVariant: this.product.variants.find(v => v.id === this.variantId),
        drawerTab: this.tab,
        purchaseOption: 'ONE_TIME',
        customFieldsValues: [],
      };
    },
    computed: {
      ...mapGetters('CartModule', ['getCurrency', 'getItemTotal', 'isUpdating']),
      ...mapGetters('CheckoutLinkModule', ['getCheckoutLinkSettings']),
      ...mapGetters('OrderModule', ['isSaving']),
      ...mapGetters('SessionModule', ['acceptsLanguages']),
      mappedImages() {
        return getProductVariantImages(this.product, this.getCheckoutLinkSettings);
      },
      minQuantityProduct() {
        return this.product.quantity?.min || 0;
      },
      productPrice() {
        return getVariantPrice(this.selectedVariant);
      },
      isProductOutOfStock() {
        return !productHasAvailableVariantsQuantities(this.product);
      },
      isQuantityMaxReached() {
        return this.product.quantity.max > 0 && (this.getItemTotal(this.product.productId) >= this.product.quantity.max);
      },
      isVariantOutOfStock() {
        return !isVariantAvailable(this.selectedVariant);
      },
      addToCartDisabled() {
        return this.isProductOutOfStock ||
          this.isVariantOutOfStock ||
          this.isQuantityMaxReached ||
          this.isSaving ||
          this.isUpdating;
      },
      hasOptions() {
        return this.showAttributesSelector && this.hasMultipleVariants;
      },
      hasMultipleVariants() {
        return this.product.variants && this.product.variants.length > 1;
      },
      showAttributesSelector() {
        return hasVariantsAndAttributes(this.product) && !this.isProductOutOfStock;
      },
      userContext() {
        return {
          currency: this.getCurrency,
          locale: this.acceptsLanguages,
        };
      },
      formattedDescription() {
        return extractDescription(this.product.descriptionHtml);
      },
    },
    methods: {
      handleAddToCart() {
        this.$emit('add-to-cart', this.selectedVariant);
      },
      close() {
        this.$emit('close');
      },
      onVariantChange(variant) {
        this.selectedVariant = variant;
        this.$emit('changed-variant', variant);
      },
    },
    mounted() {
      this.drawerOpen = true;
    },
  };
</script>
