import Vue from 'vue';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/vue';

const isEnable = process.env.VUE_APP_ENV_VARIABLE === 'production' && process.client;
let isUserIdentify = false;

const injectDatadogRum = () => {
  if (isEnable) {
    datadogRum.init({
      applicationId: '6affafb2-bca4-4d0b-8d0a-4bdad4172aed',
      clientToken: 'pub260097340dfefb247b9993f5abf7682b',
      site: 'datadoghq.com',
      service: 'Checkout Link',
      env: process.env.VUE_APP_ENV_VARIABLE,
      sampleRate: 100,
      trackInteractions: true,
    });
  }
};

const injectGtm = () => {
  if (isEnable) {
    const script = document.createElement('script');
    script.innerText = `
      acquireGtmDataLayer = window.dataLayer || [];
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','acquireGtmDataLayer','GTM-584NL64');
    `;
    document.body.appendChild(script);
  }
};

const updateGtmVar = (contactId, email, firstname, lastname) => {
  if (isEnable && !isUserIdentify && contactId) {
    window.acquireGtmDataLayer.push({
      event: 'userLoggedIn',
      user: {
        id: contactId,
        email: email || '',
        fullName: firstname && lastname ? firstname + ' ' + lastname : '',
      },
    });
    isUserIdentify = true;
  }
};

export const startHotjarRecording = () => {
  if (isEnable) {
    window.acquireGtmDataLayer.push({
      event: 'windowClicked',
    });
  }
};

const injectDebug = (contextTag) => {
  if (isEnable) {
    Sentry.init({
      Vue,
      dsn: 'https://d4e0873e54934e6798816246ed7a24d8@o472497.ingest.sentry.io/5544991',
      environment: process.env.VUE_APP_ENV_VARIABLE,
      logErrors: true,
    });
    Sentry.setTag('context', contextTag);
  }
};

export default {
  isEnable,
  injectDatadogRum,
  injectGtm,
  updateGtmVar,
  injectDebug,
  startHotjarRecording,
};
