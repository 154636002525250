<template>
  <div class="detailed">
    <div class="detailed__content">
      <div class="content__cart">
        <h1 class="cart__title">
          {{ $t('details.review') }}
        </h1>

        <EmptyCart v-if="isCartEmpty" />

        <CartWithProducts
          v-else
          :display-settings="false" />
      </div>
      <hr class="content__separator" />
      <CartTotal class="content__total" />
    </div>

    <div class="detailed__footer">
      <LayoutBrand class="footer__brand" />

      <CheckoutLinkFooter
        class="footer__action"
        :action-text="actionText"
        :action-disabled="isCartEmpty"
        @click="processCheckout" />
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  import { formattedPrice } from '@/helpers/data/data.helper';

  import CartTotal from '@/checkout-link/views/total/cart-total/CartTotal';

  import CartWithProducts from '@/checkout-link/components/checkout/cart-with-products/CartWithProducts.vue';
  import CheckoutLinkFooter from '@/checkout-link/components/checkout-link-footer/CheckoutLinkFooter.vue';
  import EmptyCart from '@/checkout-link/components/checkout/empty-cart/EmptyCart.vue';
  import LayoutBrand from '@/checkout-link/components/layout/brand/LayoutBrand.vue';

  export default {
    name: 'DetailedView',
    components: {
      CartTotal,
      CheckoutLinkFooter,
      EmptyCart,
      CartWithProducts,
      LayoutBrand,
    },
    computed: {
      ...mapGetters('CartModule', ['isCartEmpty', 'getTotalDiscountedCart', 'getCurrency']),
      price() {
        return formattedPrice(this.getTotalDiscountedCart, this.getCurrency);
      },
      actionText() {
        const actionText = this.$t('checkoutLink.button.pay');
        const amount = !this.isCartEmpty ? this.price : '';

        return `${actionText} ${amount}`;
      },
    },
    methods: {
      ...mapActions('OrderModule', ['processToCheckoutLink']),
      processCheckout() {
        this.processToCheckoutLink({ init: true, router: this.$router });
      },
    },
  };
</script>

<style lang="scss" scoped>
.detailed {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__content {
    @include scrollBarDisplay();
    flex: 1 1 auto;
    padding: rem(20px);
  }

  &__footer {
    display: flex;
  }
}

.content {
  &__total {
    align-items: initial;
    margin-top: rem(50px);
  }

  &__separator {
    display: none;
  }
}

.cart {
  &__title {
    margin-top: 0;
    margin-bottom: 30px;
    font-weight: $bold;
    font-size: rem(24px);
  }
}

.footer {
  &__brand {
    display: none;
  }

  &__action {
    position: initial;
    flex: 1;
  }
}

@include breakpoint(medium) {
  .detailed {
    display: flex;
    flex-direction: column;
    padding: initial;

    &__content {
      overflow: hidden;
      display: flex;
      margin: 0;
      padding: 0;
    }
  }

  .content {
    &__cart {
      @include scrollBarDisplay();
      flex: 1 0;
      padding: 0 rem(40px);
    }

    &__separator {
      display: initial;
      flex: initial;
      align-self: center;
      border: none;
      border-right: 1px solid var(--primary-background);
      height: 30vh;
    }

    &__total {
      @include scrollBarDisplay();
      flex: 1 0;
      margin-top: 0;
      padding: 0 rem(40px);
    }
  }

  .footer {
    &__brand {
      display: flex;
      flex: 1;
      margin-right: 0;
      border-top: 1px solid var(--primary-background);
    }
  }
}
</style>
