<template>
  <div class="form-card-input">
    <div
      class="form-card-input__field"
      data-test-id="form-card-input-field"
      :class="{ 'form-card-input__field--error': displayError }">
      <label class="form-card-input__label form-card-input__number">
        <input
          ref="cardNumber"
          class="field__number"
          data-test-id="form-card-input-field-number"
          data-private
          type="text"
          pattern="[0-9 ]*"
          placeholder=" "
          inputmode="numeric"
          :value="form.cardNumber"
          @focus="setFocus"
          @blur="removeFocus" />

        <span class="field__label">{{ $t('form.card.field.number') }}</span>

        <span :class="'credit-card credit-card--' + creditCardIcon" />
      </label>

      <div class="form-card-input__inline-display">
        <label class="form-card-input__label form-card-input__exp">
          <input
            ref="expiration"
            class="field__exp"
            data-test-id="form-card-input-field-exp"
            data-private
            type="text"
            pattern="[0-9\/]*"
            placeholder=" "
            :value="form.expiration"
            inputmode="numeric"
            @focus="setFocus"
            @blur="removeFocus" />

          <span class="field__label">{{ $t('form.card.field.exp') }}</span>
        </label>

        <label class="form-card-input__label">
          <input
            ref="security"
            class="field__cvc"
            data-test-id="form-card-input-field-cvc"
            data-private
            type="text"
            pattern="[0-9]*"
            placeholder=" "
            :value="form.security"
            inputmode="numeric"
            @focus="setFocus"
            @blur="removeFocus" />

          <span class="field__label">{{ $t('form.card.field.cvc') }}</span>
        </label>
      </div>
    </div>

    <span
      v-if="displayError"
      class="form-card-input__error-message"
      data-test-id="form-card-input-error">
      {{ $t(error) }}
    </span>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import IMask from 'imask';
  import { cardMasks, expirationMask, securityMask, exceptionAllowed } from './masks';

  const formValid = {
    cardNumber: false,
    expiration: false,
    security: false,
  };

  const masksDefaults = {
    cardNumberMask: null,
    expirationDateMask: null,
    securityCodeMask: null,
  };

  export default {
    name: 'FormCardInput',
    props: {
      hasExternalError: {
        type: Boolean,
        default: false,
      },
      showErrors: {
        type: Boolean,
        default: true,
      },
      existingCard: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        isFocused: false,
        form: {
          cardName: '',
          cardType: '',
          cardNumber: '',
          expiration: '',
          security: '',
          cardIcon: '',
        },
        formValid: formValid,
        error: 'form.card.error',
        hasError: false,
      };
    },
    computed: {
      hasAnyError() {
        return this.hasExternalError || this.hasError;
      },
      fields() {
        return [this.form.cardNumber, this.form.expiration, this.form.security, this.form.cardType].join('');
      },
      validity() {
        return [this.formValid.cardNumber, this.formValid.expiration, this.formValid.security].join('');
      },
      displayError() {
        return this.hasAnyError && this.showErrors;
      },
      creditCardIcon() {
        return this.form.cardIcon || 'light';
      },
      isExistingCardFilled() {
        return Object.keys(this.existingCard).some(k => !!this.existingCard[k].length);
      },
    },
    watch: {
      existingCard() {
        this.isExistingCardFilled && this._setFormValues();
      },
      fields() {
        this.$emit('change', { ...this.form });
      },
      validity() {
        this.$emit('validity-change', Object.values(this.formValid).every(value => !!value));
      },
    },
    methods: {
      setFocus() {
        this.isFocused = true;
      },
      removeFocus() {
        this.isFocused = false;
      },
      defineMasks() {
        // Mask the Credit Card Number Input
        this.cardNumberMask = new IMask(this.$refs.cardNumber, cardMasks);
        // Mask the Expiration Date
        this.expirationDateMask = new IMask(this.$refs.expiration, expirationMask(true));
        // Mask the security code
        this.securityCodeMask = new IMask(this.$refs.security, securityMask);
      },
      setMasksListeners() {
        // Update expiration date field
        this.expirationDateMask.on('accept', () => {
          this.formValid.expiration = false;
          this.form.expiration = this.expirationDateMask.value;
        });
        // Update security code field
        this.securityCodeMask.on('accept', () => {
          this.formValid.security = false;
          this.form.security = this.securityCodeMask.value;
        });
        // Update card number field
        this.cardNumberMask.on('accept', () => {
          this.formValid.cardNumber = false;
          const cardType = this.cardNumberMask.masked.currentMask.cardtype;
          this.form.cardNumber = this.cardNumberMask.value;
          const card = cardMasks.mask.find(mask => mask.cardtype === cardType);

          if (card) {
            if (exceptionAllowed[card.cardtype]?.includes(this.cardNumberMask.unmaskedValue.length)) {
              this.formValid.cardNumber = true;
            }

            this.form.cardType = card.cardtype;
            this.form.cardName = card.name;
            this.form.cardIcon = card.icon;
            return;
          }

          this.resetCardDefaults();
        });

        this.cardNumberMask.on('complete', () => {
          this.formValid.cardNumber = true;
          this.focusElement('expiration');
        });

        this.expirationDateMask.on('complete', () => {
          this.formValid.expiration = true;
          this.focusElement('security');
        });

        this.securityCodeMask.on('complete', () => {
          const cardType = this.cardNumberMask.masked.currentMask.cardtype;
          const card = cardMasks.mask.find(mask => mask.cardtype === cardType);

          this.formValid.security = this.form.security.length === card.cvvLength;
        });
      },
      resetCardDefaults() {
        this.form.cardType = null;
      },
      focusElement(input) {
        this.$refs[input].focus();
      },
      _setFormValues() {
        Object.keys(this.existingCard).forEach(key => {
          if (!!this.existingCard[key].length && this.form[key] !== undefined) {
            this.form[key] = this.existingCard[key];
          }
        });
      },
    },
    mounted() {
      this.defineMasks();
      this.setMasksListeners();
    },
    destroyed() {
      this.form.cardNumber = null;
      this.form.expiration = null;
      this.form.security = null;
    },
  };
</script>

<style scoped lang="scss">
  .form-card-input {
    padding-top: $field-padding;

    &__label {
      width: 100%;
      position: relative;
    }

    &__field {
      &--error {
        border-color: $error !important;
        color: $error;

        input {
          color: $error !important;
        }
      }
    }

    &__number {
      display: block;
      margin-bottom: 15px;
    }

    &__exp {
      margin-right: 15px;
    }

    &__inline-display {
      display: flex;
      justify-content: space-between;
    }

    &__error-message {
      @include body-small();
      margin-top: rem(5px);
      color: $error;
    }
  }

  .field {
    &__number, &__exp, &__cvc {
      @include material-input();
      padding: rem(12px) 0 0 rem(10px);
    }
  }

  .credit-card {
    position: absolute;
    top: 25px;
    right: 15px;
  }
</style>
