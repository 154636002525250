/* Mixin content for use in parent component:
    - Props: insideModal, isMobile
    - Data: isEditMode, emptyOption, errors, showErrors
    - Computed properties: selectLevel, displayEditMode, hasEmptyOption, hasError
    - Methods: toggleEditMode
    - Event emitted: edit-mode-state */

export default {
  props: {
    insideModal: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isMaterial: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isEditMode: false,
      emptyOption: { name: '', value: null },
      errors: {},
      showErrors: false,
    };
  },
  computed: {
    selectLevel() {
      return this.insideModal ? 'primary' : 'secondary';
    },
    hasEmptyOption() {
      return (this.insideModal || this.isMobile);
    },
    hasError() {
      return Object.values(this.errors).some(err => !!err);
    },
    // Need computed property existingUserInfo in parent component in order to work
    displayEditMode() {
      return (this.insideModal || this.isMobile) && (this.isEditMode || !this.existingUserInfo);
    },
    cancelAllowed() {
      return this.existingUserInfo;
    },
  },
  watch: {
    // The parent needs to register the edit mode state so it knows which fields to validate before payment
    displayEditMode(isDisplayed) {
      this._emitEditModeState(isDisplayed);
    },
  },
  methods: {
    // Methods for use from parent component
    async toggleEditMode() {
      this.isEditMode = !this.isEditMode;

      // Reset forms and errors when edit mode is toggled.
      // Note: resetForm and errors do not exist in UserShipping, but there is no edit mode either, so it is safe
      await this.resetForm();
      this.errors = {};
      this.showErrors = false;
    },
    // Methods for internal use
    _emitEditModeState(isDisplayed) {
      this.$emit('edit-mode-state', isDisplayed);
    },
  },
  // Hack to force event emit on first evaluation, before the watcher kicks in on value change
  mounted() {
    this._emitEditModeState(this.displayEditMode);
  },
};
