<template>
  <img
    :data-test-id="dataTestId"
    :src="imageSrc"
    :alt="alt"
    :title="imageTitle" />
</template>

<script>
  import {
    PRODUCT_FILE_FROM_URL_REGEX, PRODUCT_FILE_PARTS_REGEX,
    PRODUCT_IMAGE_SIZE, DOMAINS_SUPPORTING_PARAMS,
    getShopifyImageOptimizedFile,
  } from '@/helpers/product/product.helper';

  export default {
    name: 'ProductOptimizedImage',
    props: {
      src: {
        type: String,
        required: true,
      },
      alt: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        default: null,
      },
      width: {
        type: Number,
        default: null,
        validator: value =>
          (value >= PRODUCT_IMAGE_SIZE.MIN.WIDTH) && (value <= PRODUCT_IMAGE_SIZE.MAX.WIDTH),
      },
      height: {
        type: Number,
        default: null,
        validator: value =>
          (value >= PRODUCT_IMAGE_SIZE.MIN.HEIGHT) && (value <= PRODUCT_IMAGE_SIZE.MAX.HEIGHT),
      },
    },
    computed: {
      dataTestId() {
        return `optimized-product-image_${this.src}_${this.width || ''}x${this.height || ''}`;
      },
      imageTitle() {
        return this.title || this.alt;
      },
      imageSrc() {
        return this.paramsSupported ? this.optimizedSrc : this.src;
      },
      paramsSupported() {
        return DOMAINS_SUPPORTING_PARAMS.some(domainRegex => this.src.match(domainRegex));
      },
      optimizedSrc() {
        if (this.src.match(DOMAINS_SUPPORTING_PARAMS[0])) {
          return this.shopifyOptimizedUrl;
        }

        if (this.src.match(DOMAINS_SUPPORTING_PARAMS[1])) {
          return this.bigCommerceOptimizedUrl;
        }

        return this.src;
      },
      shopifyOptimizedUrl() {
        // Get file from image url
        const [fileFromUrl] = this.src.match(PRODUCT_FILE_FROM_URL_REGEX);
        // Get filename and extension from file
        const [file, filename, extension] = fileFromUrl.match(PRODUCT_FILE_PARTS_REGEX);
        // Add size params after filename and join extension
        const optimizedFile = getShopifyImageOptimizedFile(filename, extension, this.width, this.height);
        // Replace file in original image url with optimized file containing size params
        return this.src.replace(file, optimizedFile);
      },
      bigCommerceOptimizedUrl(src, width, height) {
        let sizes = '150w';

        if (this.width && this.height) {
          sizes = `${this.width}x${this.height}`;
        } else if (!this.height) {
          sizes = `${this.width}w`;
        }

        let optimizedUrl = this.src;

        if (optimizedUrl.indexOf('/product_images/attribute') !== -1) {
          // if image origin come from the product variant object
          optimizedUrl = optimizedUrl.replace('/product_images/', `/images/stencil/${sizes}/`);
        } else {
          // if image origin come from the product object
          optimizedUrl = optimizedUrl.replace('/images/', '/');
          optimizedUrl = optimizedUrl.replace('/products/', `/images/stencil/${sizes}/products/`);
        }

        return optimizedUrl;
      },
    },
  };
</script>
