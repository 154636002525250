<template>
  <div class="form-address-input">
    <label
      v-if="!showCompleteForm"
      class="form-address-input__label"
      data-test-id="form-address-input-label">
      <gmap-autocomplete
        class="form-address-input__field"
        :class="{ 'form-address-input__field--error': displayError }"
        data-test-id="form-address-input-field"
        type="text"
        role="presentation"
        name="search"
        placeholder=" "
        :value="value"
        :select-first-on-enter="true"
        :required="required"
        @place_changed="onChange"
        @focus.native="onFocus" />
      <span class="field__label">{{ label }}</span>
    </label>

    <div
      v-if="showCompleteForm"
      class="complete-form">
      <div class="complete-form__message">
        {{ $t('address.form.message') }}
      </div>
      <FormInput
        class="complete-form__field"
        :value="address.address_components.line1"
        :label="$t('address.form.address.label')"
        :placeholder="$t('address.form.address.placeholder')"
        @value-changed="onCompleteAddressChange('line1', $event)" />

      <div class="complete-form__display complete-form__display--first">
        <FormInput
          class="display__field"
          :value="address.address_components.city"
          :label="$t('address.form.city.label')"
          :placeholder="$t('address.form.city.placeholder')"
          @value-changed="onCompleteAddressChange('city', $event)" />
        <FormInput
          class="display__field"
          :value="address.address_components.zip"
          :label="$t('address.form.zip.label')"
          :placeholder="$t('address.form.zip.placeholder')"
          @value-changed="onCompleteAddressChange('zip', $event)" />
      </div>

      <div class="complete-form__display">
        <SimpleDropdown
          class="display__field"
          :label="$t('address.form.country.label')"
          :value="address.address_components.country"
          :options="countries"
          @value-changed="onCompleteAddressChange('country', $event)" />

        <SimpleDropdown
          v-if="provinces.length"
          class="display__field"
          :label="$t('address.form.province.label')"
          :auto-select-first="true"
          :value="address.address_components.province"
          :options="provinces"
          @value-changed="onCompleteAddressChange('province', $event)" />
      </div>
    </div>

    <span
      v-if="displayError"
      class="form-address-input__error-message"
      data-test-id="form-address-input-error">
      {{ error }}
    </span>
  </div>
</template>

<script>
  import FormInput from '@/checkout-link/components/form/form-input/FormInput';
  import SimpleDropdown from '@/checkout-link/components/dropdown/SimpleDropdown';
  import { getCountries, getProvinces } from '@modules/countries/countries.module';

  export default {
    name: 'FormAddressInput',
    components: {
      FormInput,
      SimpleDropdown,
    },
    props: {
      label: {
        type: String,
        default: '',
      },
      error: {
        type: String,
        default: null,
      },
      showErrors: {
        type: Boolean,
        default: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      value: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        address: { address_components: {}, formatted_address: '' },
        showCompleteForm: false,
        countries: getCountries(),
        provinces: [],
      };
    },
    computed: {
      displayError() {
        return !!this.error && this.showErrors;
      },
    },
    methods: {
      mappingAddress(location) {
        const addressComponentStreetNumber = this.findAddressValue(location, ['street_number']);
        const addressComponentRoute = this.findAddressValue(location, ['route']);
        const addressComponentLocality = this.findAddressValue(location, ['locality']);
        const addressComponentSubLocality = this.findAddressValue(location, ['administrative_area_level_2', 'administrative_area_level_3', 'sublocality_level_1', 'neighborhood']);
        const addressComponentProvince = this.findAddressValue(location, ['province', 'administrative_area_level_1']);
        const addressComponentZip = this.findAddressValue(location, ['postal_code']);
        const addressComponentCountry = this.findAddressValue(location, ['country'], 'short_name');

        this.address.address_components.line1 = `${addressComponentStreetNumber || ''} ${addressComponentRoute || ''}`.trim();
        this.address.address_components.city = !addressComponentLocality ? addressComponentSubLocality : addressComponentLocality;
        this.address.address_components.province = addressComponentProvince;
        this.address.address_components.zip = addressComponentZip;
        this.address.address_components.country = addressComponentCountry;
        this.address.formatted_address = location.formatted_address;

        // If address type has "sublocality" AND no street number/name -> set in line 1 the user first name, lastname OR addressComponentSubLocality
        if (this.address.address_components.line1 === '' || this.address.address_components.zip === '') {
          this.showCompleteForm = true;

          // init province list
          if (this.address.address_components.country) {
            this.onCompleteAddressChange('country', this.address.address_components.country);
          }
        }
      },
      onChange(location) {
        this.mappingAddress(location);
        this.$emit('value-changed', this.address);
      },
      onFocus() {
        this.$emit('reset-value');
      },
      onCompleteAddressChange(field, value) {
        this.address.address_components[field] = value;

        // if country, try to load provinces
        if (field === 'country') {
          this.provinces = getProvinces(value);

          // if current province is not in provinces list, set the first one has default value
          if (this.provinces && !this.provinces?.find(item => item.value === this.address.address_components.province)) {
            this.address.address_components.province = this.provinces[0]?.value;
          }
        }

        this.$emit('value-changed', this.address);
      },
      findAddressValue(location, options, name = 'long_name') {
        return location.address_components?.find(component => !!component.types?.filter(type => options.includes(type)).length)?.[name];
      },
    },
  };
</script>

<style scoped lang="scss">
  .form-address-input {
    display: flex;
    flex-direction: column;

    &__label {
      position: relative;
    }

    &__field {
      @include material-input();
      padding: rem(12px) 0 0 rem(10px);
      /* hack for safari 13.1 */
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
    }

    &__error-message {
      margin-top: rem(5px);
      @include body-small();
      color: $error;
    }
  }

  .complete-form {
    &__message {
      background: $error-box-background;
      margin-bottom: 15px;
      padding: rem(6px) rem(8px);
      border-radius: 3px;
      font-size: rem(12px);
      line-height: rem(16px);
    }

    &__field {
      margin-bottom: rem(25px);
    }

    &__display {
      display: flex;
      margin-bottom: rem(25px);
    }
  }

  .display {
    &__field {
      flex: 1;

      &:nth-child(2) {
        margin-left: 14px;
        max-width: 217px;
      }
    }
  }
</style>
