<template>
  <div class="not-opened">
    <div
      v-if="getCheckoutLinkStartDate"
      class="not-opened__countdown">
      <p class="countdown__title">{{ $t('preRegistration.countdown') }}</p>

      <CountDown
        :date="getCheckoutLinkStartDate"
        @done="reloadPage" />
    </div>

    <div v-if="preRegistrationContent"
      class="not-opened__page">
      <ContentBlockReadOnly v-if="preRegistrationContent"
        :content="preRegistrationContent" />
    </div>

    <BottomBarNotOpenedYet v-if="!contactId" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import BottomBarNotOpenedYet from '@/checkout-link/components/layout/bottom-bar/BottomBarNotOpenedYet';
  import ContentBlockReadOnly from '@/checkout-link/components/content/content-block/read-only/ContentBlockReadOnly';
  import CountDown from '@/checkout-link/components/count-down/CountDown';

  export default {
    name: 'NotOpened',
    components: {
      BottomBarNotOpenedYet,
      ContentBlockReadOnly,
      CountDown,
    },
    computed: {
      ...mapGetters('CheckoutLinkModule', ['preRegistrationContent', 'getCheckoutLinkStartDate', 'isLoadedAndNotAnException']),
      ...mapGetters('ContactModule', ['contactId']),
    },
    watch: {
      isLoadedAndNotAnException(value) {
        value && this.reloadPage();
      },
    },
    methods: {
      ...mapActions('CheckoutLinkModule', ['setPreRegistrationState']),
      reloadPage() {
        this.setPreRegistrationState(false);
        this.$router.replace({ name: 'Information', params: this.$router.currentRoute.params });
      },
    },
    mounted() {
      if (this.isLoadedAndNotAnException) {
        this.reloadPage();
      }
    },
  };
</script>

<style lang="scss" scoped>
  $max-width: rem(450px);

  .not-opened {
    @include main-layout;
    padding-bottom: 0 !important;
    min-height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__countdown {
      box-sizing: border-box;
      margin-top: auto;
      width: 100%;
      padding: 20px;
      background-color: var(--card-raised-background);
      border-radius: 8px;
      text-align: center;
    }

    &__page {
      box-sizing: border-box;
      width: 100%;
      padding: 20px;
      margin-top: 23px;
      background-color: var(--card-raised-background);
      border-radius: 8px;
      text-align: center;

      ::v-deep .content-block-read-only {
        padding: 0 !important;
      }
    }
  }

  .countdown {
    &__title {
      @include heading();
      margin-top: 0;
      margin-bottom: $layout-padding;
      font-weight: $bold;
      color: var(--primary-text);
    }
  }

  @include breakpoint(medium) {
    .not-opened {
      &__page {
        padding: 40px;
        margin-top: 33px;
      }

      &__countdown {
        max-width: $max-width;
        padding: 35px 35px 24px;
      }
    }
  }
</style>
