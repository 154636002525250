import { DateTime } from 'luxon';
import isEqual from 'lodash/isEqual';

export const subscriptionStatus = {
  CANCELLED: 'cancelled',
};

export const checkoutLinkStatus = {
  // API Status
  ACTIVE: 'ACTIVE',

  // UI Status
  DRAFT: 'DRAFT',
  INACTIVE: 'INACTIVE',
  NOT_OPENED: 'NOT_OPENED',
  CLOSED: 'CLOSED',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  MAX_ORDER_EXCEED: 'MAX_ORDER_EXCEED',
  CANCELLED: 'CANCELLED',
};

export const checkoutLinkDiscount = {
  PERCENTAGE: 'PERCENTAGE',
  FIXED_AMOUNT: 'FIXED',
};

export const getDiscountedPrice = (selectedVariant, incentive) => {
  const type = incentive?.incentiveType;
  const value = incentive?.amount || 0;

  switch (type) {
  case checkoutLinkDiscount.PERCENTAGE:
    return Math.max((selectedVariant.price - (selectedVariant.price * value) / 100), 0);
  case checkoutLinkDiscount.FIXED_AMOUNT:
    return Math.max(selectedVariant.price - value, 0);
  default:
    return 0;
  }
};

export const getSelectedVariant = (product, preferredVariantId) => {
  return product?.variants?.find(v => v.id === preferredVariantId) || null;
};

export const getImages = (product) => {
  if (!product) {
    return [];
  }

  const images = [...(product.images || [])];

  if (!images.length) {
    images.push({ url: product.imageUrl });
  }

  return images.map(img => ({ src: img.url }));
};

export const getProductVariantImages = (product, checkoutLinkSettings) => {
  if (!product) {
    return [];
  }

  const loadAllProductImages = !!checkoutLinkSettings?.loadAllProductImages;
  const productImages = product.images.map(image => image.src).filter(image => image);
  const variantsImages = product.variants.map(variant => variant.imageUrl).filter(image => image);

  // Default, load only variants images (fallback on product if no images in variants)
  // Otherwise, load all, merge products and variants images in an unique array
  let images = variantsImages;

  if (loadAllProductImages || variantsImages.length === 0) {
    images = [...new Set([...productImages, ...variantsImages])];
  }

  if (!images.length && product.imageUrl) {
    images.push(product.imageUrl);
  }

  return [...new Set(images)];
};

export const isActiveStatus = (status) => status?.toUpperCase() === checkoutLinkStatus.ACTIVE.toUpperCase();

export const isOutOfStockStatus = (status) => status?.toUpperCase() === checkoutLinkStatus.OUT_OF_STOCK.toUpperCase();

export const isMaxOrderExceed = (status) => status?.toUpperCase() === checkoutLinkStatus.MAX_ORDER_EXCEED.toUpperCase();

export const setTimezone = (date, timezone) => DateTime.fromISO(date).setZone(timezone);

export const isNotOpenedYet = (date, now = null) => date && date > (now || DateTime.local());

export const isOver = (date, now = null) => date && date < (now || DateTime.local());

export const isValidAsset = (asset) => asset !== null;

export const verifyValidity = (qtyMin, inventory) => {
  const qtyInventory = inventory.quantity;

  return inventory.available || qtyMin === 0 || inventory.outOfStockPolicy === 'CONTINUE' || qtyMin <= qtyInventory;
};

export const areShippingMethodsIdentical = (oldShippingMethods, newShippingMethods) =>
  (oldShippingMethods.length === newShippingMethods.length) &&
    newShippingMethods.every(nsm =>
      isEqual(nsm, oldShippingMethods.find(osm => osm.id === nsm.id) || {}));
