<template>
  <div
    ref="bottomBar"
    class="bottom-bar bottom-bar--pre-registration bottom-bar--loaded"
    :style="{ '--bg-alpha-color': alphaColor, 'display': displayBottomBar }">
    <p class="bottom-bar__pre-registration">
      {{ $t('preRegistration.button.help') }}
    </p>

    <AppButton
      class="bottom-bar__action"
      type="primary"
      data-test-id="bottom-bar-action-button"
      @click="gotToPreRegistration">
      {{ actionText }}
    </AppButton>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import MobileMixin from '@mixins/isMobile.mixin';
  import { lightnessColorVariation } from '@/helpers/data/data.helper';
  import AppButton from '@/checkout-link/components/app/app-button/AppButton';

  export default {
    name: 'BottomBarNotOpenedYet',
    components: {
      AppButton,
    },
    mixins: [MobileMixin],
    data() {
      return {
        resizeObserver: null,
      };
    },
    computed: {
      ...mapGetters('ContactModule', ['isPartial']),
      ...mapGetters('ThemeModule', ['buttonColor']),
      actionText() {
        return this.$t(`preRegistration.button.text.${this.isPartial ? 'known' : 'unknown'}`);
      },
      alphaColor() {
        const containerLightnessVariation = 20;
        const containerAlpha = 0.8;

        return lightnessColorVariation(this.buttonColor, containerLightnessVariation, containerAlpha);
      },
      displayBottomBar() {
        return this.resizeObserver ? 'grid' : 'none';
      },
    },
    methods: {
      async gotToPreRegistration() {
        await this.$router.push({
          name: 'Preregistration',
          params: { ...this.$route.params },
        });
      },
      _setBarPosition() {
        if (!process.client) return;

        this.$refs.bottomBar.style.left = `${(window.innerWidth - this.$refs.bottomBar.offsetWidth) / 2}px`;
      },
      _initResizeObserver() {
        const resizeObserver = new ResizeObserver(this._setBarPosition);
        resizeObserver.observe(this.$refs.bottomBar);

        this.resizeObserver = resizeObserver;
      },
      _clearResizeObserver() {
        this.resizeObserver.unobserve(this.$refs.bottomBar);
      },
    },
    mounted() {
      // We need to reposition the cart every time its size changes, or the window size changes.
      this._initResizeObserver();
      window.addEventListener('resize', this._setBarPosition);

      this.$nextTick().then(this._setBarPosition);
    },
    beforeDestroy() {
      this._clearResizeObserver();
      window.removeEventListener('resize', this._setBarPosition);
    },
  };
</script>

<style lang="scss" scoped>
$bar-max-width-small: 600px;
$bar-max-width: 1000px;
$bar-width: calc(100vw - #{rem(20px)});
$bar-width-small: calc(100vw - #{rem(50px)});
$bar-width-medium: calc(100vw - #{rem(130px)});
$bar-width-large: calc(100vw - #{rem(200px)});

.bottom-bar {
  position: fixed;
  z-index: 102;
  bottom: 8px;
  display: none; // keep it hidden to avoid delay between loading page and js position calculation
  grid-template-columns: 46px 1fr;
  grid-template-rows: 1fr;
  column-gap: 10px;
  row-gap: 2px;
  box-sizing: border-box;
  width: $bar-width;
  max-width: $bar-max-width;
  min-width: 0;
  border-radius: 8px;
  padding: 12px;
  background: var(--main-color);
  color: var(--main-color-text);

  &--with-display {
    grid-template-rows: 1fr 17px;
  }

  &--loaded {
    background: var(--bg-alpha-color);
  }

  &--pre-registration {
    max-width: $bar-max-width-small;

    .bottom-bar__pre-registration {
      @include body-medium;
      margin: 0 0 6px 0;
      grid-column: 1 / -1;
      grid-row: 1 / 2;
      font-weight: $bold;
      text-align: center;
    }

    .bottom-bar__action {
      grid-row: 2 / 3;
      grid-column: 1 / -1;
    }
  }

  &__action {
    @include body-medium;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    width: 100%;
    min-width: 140px;
    height: 46px;
    padding: 16px;
    font-weight: $bold;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__bottom-line {
    grid-column: 1 / -1;
    grid-row: 2 / -1;
    justify-self: center;
    font-size: rem(10px);
  }
}

@include breakpoint(small) {
  .bottom-bar {
    width: $bar-width-small;
    bottom: 16px;
  }
}

@include breakpoint(medium) {
  .bottom-bar {
    grid-template-columns: 46px fit-content(80px) fit-content(215px) fit-content(230px) 46px minmax(190px, 1fr);
    width: $bar-width-medium;

    &__action {
      grid-column: 6 / 7;
      grid-row: 1 / 2;
    }

    &__bottom-line {
      display: none;
    }
  }
}

@include breakpoint(large) {
  .bottom-bar {
    width: $bar-width-large;
  }
}
</style>
