const loadRecommendedProducts = async() => {
  // TODO: to remove with api response
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([]);
    }, 300);
  });
};

const loadInOfferProducts = async() => {
  // TODO: to remove with api response
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([]);
    }, 300);
  });
};

export default {
  loadRecommendedProducts,
  loadInOfferProducts,
};
