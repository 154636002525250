<template>
  <div class="checkout-link-layout-wrap">
    <WarningMessage
      v-if="showWarning && !acquireWarningSeen"
      :site-logo="siteLogo"
      :message="warningMessage"
      @stay="hideWarning" />

    <CheckoutLinkLayout
      v-if="hasCheckoutLink"
      data-test-id="checkout-link-layout"
      :class="{ overlay, 'with-footer': displayBottomBar }"
      :is-blur="isBlur">
      <!-- Overlay router-view will display all exceptions pages -->
      <template
        v-if="overlay"
        #overlay>
        <router-view />
      </template>

      <template #content>
        <div
          class="checkout-link-page"
          :class="{ blur: isBlur }">
          <div class="checkout-link-page__cart">
            <div class="checkout-link">
              <div
                class="checkout-link__container">
                <div
                  ref="wrapper"
                  class="is-wrapper"
                  v-html="getTemplateWithProducts" />
              </div>
            </div>
          </div>

          <!-- will dispatch presell route and funnel data -->
          <PreSellDisplay v-if="isPresell" />

          <!-- will dispatch all authentications routes -->
          <AuthDisplay
            v-if="isAuthView"
            @close="closeModal">
            <router-view />
          </AuthDisplay>

          <!-- will dispatch all other modal routes -->
          <ModalDisplay
            v-if="insideModal"
            data-cy="modal-display"
            @close="closeModal">
            <router-view />
          </ModalDisplay>

          <BottomBar
            v-if="displayBottomBar"
            data-cy="bottom-bar" />
        </div>
      </template>
    </CheckoutLinkLayout>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import MobileMixin from '@mixins/isMobile.mixin';
  import AuthDisplay from '@/checkout-link/components/auth-display/AuthDisplay.vue';
  import BottomBar from '@/checkout-link/components/layout/bottom-bar/BottomBar.vue';
  import CheckoutLinkLayout from '@/checkout-link/components/layout/wrapper/CheckoutLinkLayout.vue';
  import ModalDisplay from '@/checkout-link/components/modal-display/ModalDisplay.vue';
  import PreSellDisplay from '@/checkout-link/components/funnel/pre-sell-display/PreSellDisplay.vue';
  import WarningMessage from '@/checkout-link/components/warning-message/WarningMessage.vue';
  import { ADD_TO_CART_CLICK, IMAGE_CLICK } from '@/services/analytics/analytics.event';
  import { TemplateHelper } from '@/helpers/checkout-link/checkout-link.template.helper';

  export default {
    name: 'CheckoutLink',
    components: {
      AuthDisplay,
      BottomBar,
      CheckoutLinkLayout,
      ModalDisplay,
      PreSellDisplay,
      WarningMessage,
    },
    mixins: [MobileMixin],
    props: {
      customLink: {
        type: String,
        default: null,
      },
      overlay: {
        type: Boolean,
      },
      isAuthView: {
        type: Boolean,
      },
      insideModal: {
        type: Boolean,
      },
      hideBottomBar: {
        type: Boolean,
      },
      isPresell: {
        type: Boolean,
      },
    },
    data() {
      return {
        showWarning: false,
      };
    },
    computed: {
      ...mapGetters('CheckoutLinkModule', [
        'getCheckoutLink', 'hasCheckoutLink',
        'warningMessage', 'getCheckoutLinkCountDown',
        'getMappedProducts', 'getMappedDynamicProducts',
        'getTemplateWithProducts', 'getCheckoutLinkSettings',
        'getDynamicProductLayout',
      ]),
      ...mapGetters('CartModule', ['isCartEmpty', 'hasCart']),
      ...mapGetters('SessionModule', ['siteName', 'siteUrl', 'siteLogo', 'acquireWarningSeen', 'siteCurrency', 'acceptsLanguages', 'countryCode']),
      ...mapGetters('OrderModule', ['orderError']),
      ...mapGetters('ThemeModule', ['pageColor']),
      isBlur() {
        return this.overlay;
      },
      displayBottomBar() {
        // never display the bottom bar on checkout url - cause multiple event on user shipping/address component
        if (this.hideBottomBar) {
          return false;
        }
        return !this.isCartEmpty;
      },
    },
    watch: {
      warningMessage(warningMessage) {
        this.setWarningMessage(warningMessage);
      },
    },
    methods: {
      ...mapActions('CheckoutLinkModule', [
        'load', 'addVariant',
        'dispatchViewEvent', 'goToSiteUrl',
      ]),
      ...mapActions('SessionModule', ['setWarningMessageSeen']),
      ...mapActions('OrderModule', ['clearOrderError']),
      ...mapActions('AnalyticsModule', ['addCheckoutEvent']),
      ...mapActions('CartModule', ['getCurrency']),
      closeModal() {
        if (this.orderError) {
          this.clearOrderError();
        }

        if (this.$router.currentRoute.name !== 'Information') {
          this.$router.push({
            name: 'Information',
            params: this.$router.currentRoute.params,
          });
        }
      },
      async loadCheckoutLink() {
        !this.hasCheckoutLink && await this.load({
          customLink: this.customLink,
          router: this.$router,
        });
      },
      async hideWarning() {
        try {
          this.showWarning = false;
          await this.setWarningMessageSeen();
        } catch (e) {
          console.debug(e);
        }
      },
      setWarningMessage(warningMessage) {
        if (!warningMessage) return;

        this.showWarning = warningMessage.title || warningMessage.description;
      },
      onAddToCart(value) {
        this.addCheckoutEvent(ADD_TO_CART_CLICK);
        this.addVariant({ items: value, router: this.$router });
      },
      onProductImageClick() {
        this.addCheckoutEvent(IMAGE_CLICK);
      },
      async refreshProducts() {
        const template = new TemplateHelper(this.$refs.wrapper, this.getCheckoutLinkSettings, {
          lang: this.acceptsLanguages,
          country: this.countryCode,
          currency: this.siteCurrency,
        }, this.getMappedProducts, this.getMappedDynamicProducts, this.getDynamicProductLayout, this.pageColor, false);

        await template.refreshProducts(this.onAddToCart, this.onProductImageClick);
      },
    },
    async mounted() {
      await this.loadCheckoutLink();
      await this.refreshProducts();
      await this.dispatchViewEvent();

      // required if the store is updated before mounted
      this.setWarningMessage(this.warningMessage);

      // Dispatch acquirePluginEventProductView event
      // Wait 1 sec to wait plugins integration to be loaded
      if (process.client) {
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent('acquirePluginEventProductView', { detail: this.getCheckoutLink }));
        }, 1000);
      }
    },
    async serverPrefetch() {
      await this.loadCheckoutLink();
    },
  };
</script>

<style lang="scss">
  // @TODO Load differently styles from vue product component?
  @import "vue-product-layout/dist/vueProductLayout.css";

  .is-wrapper {
    color: #000;
    font-family: var(--main-font-family);
    background: var(--page-color);

    .is-section-navbar .is-topbar {
      top: 44px;
    }
  }
</style>

<style lang="scss" scoped>
  .checkout-link-layout-wrap {
    width: 100%;
    height: 100%;
  }

  .checkout-link-page {
    position: relative;
    box-sizing: border-box;
    height: 100%;

    &__full {
      width: 100%;
      height: 100%;

      background-color: var(--footer-background);
      border-radius: 5px;
    }

    &__cart {
      width: 100%;
      height: 100%;

      margin-right: 20px;
    }

    &__mobile-footer {
      width: 100%;
      height: 100%;
    }
  }

  .checkout-link {
    @include body();
    max-width: 100%;

    &__container {
      padding: 0;
      overflow: inherit;
      flex: 1;
      max-width: 100%;

      ::v-deep .hidden {
        display: none;
      }
    }
  }

  .container {
    &__header {
      ::v-deep .layout-header__app-link {
        flex: 1;
      }
    }

    &__product {
      padding: $layout-padding;
      background-color: var(--card-background);
      border-radius: $radius-image;
      margin-bottom: rem(15px);
    }
  }

  .blur {
    @include blur;
  }

  @include breakpoint(medium) {
    .checkout-link-page {
      &__full {
        max-width: 608px;
      }

      &__mobile-footer {
        display: none;
      }
    }
  }
</style>
