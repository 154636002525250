<template>
  <div class="quantity">
    <AppIconButton
      :disabled="isDecreaseDisabled"
      class="quantity__minus"
      name="minus"
      color="dark"
      size="small"
      @click.native="onQuantityDecrement" />

    <div class="quantity__number">
      {{ quantity }}
    </div>

    <AppIconButton
      :disabled="isIncreaseDisabled"
      class="quantity__plus"
      name="plus"
      color="dark"
      size="small"
      @click.native="onQuantityIncrement" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  import { QUANTITY_DECREASE_CLICK, QUANTITY_INCREASE_CLICK } from '@/services/analytics/analytics.event';
  import AppIconButton from '@/checkout-link/components/app/app-icon-button/AppIconButton';

  export default {
    name: 'AppQuantity',
    components: {
      AppIconButton,
    },
    props: {
      defaultQuantity: {
        type: Number,
        default: 0,
      },
      minQuantity: {
        type: Number,
        default: 0,
      },
      maxQuantity: {
        type: Number,
        default: 0,
      },
      countProductInCart: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        quantity: this.defaultQuantity,
      };
    },
    computed: {
      isDecreaseDisabled() {
        return this.quantity <= this.minQuantity;
      },
      isIncreaseDisabled() {
        return this.maxQuantity > 0 && this.countProductInCart >= this.maxQuantity;
      },
    },
    watch: {
      defaultQuantity() {
        this.quantity = this.defaultQuantity;
      },
    },
    methods: {
      ...mapActions('AnalyticsModule', ['addCheckoutEvent']),
      onQuantityDecrement() {
        this.addCheckoutEvent(QUANTITY_DECREASE_CLICK);
        this.quantity = Math.max(this.quantity - 1, 1);
        this.onQuantityEmit();
      },
      onQuantityIncrement() {
        this.addCheckoutEvent(QUANTITY_INCREASE_CLICK);
        this.quantity += 1;
        this.onQuantityEmit();
      },
      onQuantityEmit() {
        this.$emit('quantity-changed', this.quantity);
      },
    },
  };
</script>

<style scoped lang="scss">

  .quantity {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    &__minus, &__plus {
      margin: 8px 8px 0;

      @include body-small();
      color: var(--primary-text);
    }

    &__minus {
      margin-left: 0;
    }

    &__plus {
      margin-right: 0;
    }

    &__number {
      display: flex;
      justify-content: center;
      align-items: center;

      @include icon-button-size(map-get($icon-button-size, small));
      @include body-large();
      color: var(--primary-text);
      font-weight: bold;
    }
  }
</style>
