import Vue from 'vue';

if (process.client) {
  import('vue2-google-maps').then(VueGoogleMap => {
    Vue.use(VueGoogleMap, {
      load: {
        key: process.env.VUE_APP_GOOGLEMAP_API_KEY,
        libraries: 'places',
      },
    });
  });
}
