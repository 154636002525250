<template>
  <div class="content-block-read-only ck ck-content"
    v-html="content" />
</template>

<script>
  export default {
    name: 'ContentBlockReadOnlyV2',
    props: {
      content: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss">
.is-wrapper {
  height: auto !important;

  .is-shadow-1, .is-shadow-2, .is-shadow-3 {
    -webkit-box-shadow: unset !important;
    -moz-box-shadow: unset !important;
    box-shadow: unset !important;
  }
}
</style>

<style lang="scss" scoped>
$card-radius: $radius-image;

.content-block-read-only {
  width: 100%;
  box-sizing: border-box;
  position: relative;

  &.ck {
    @include body-medium();
    color: var(--tertiary-raised-text);

    &.ck-content {
      width: 100%;
      min-width: 0;
      border-radius: $card-radius !important;
      padding: rem(13px);
      word-wrap: break-word;
    }

    ::v-deep {
      @include content-styles();

      .iframely-responsive {
        position: initial !important;
      }

      & > * {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      p {
        font-size: rem(12px);
      }

      img {
        max-width: 100% !important;
        border-radius: $radius-image;
      }
    }
  }
}

// Apply specific rules to display iframely iframes embedded insta/pinterest for small screen (Iphone X, Iphone 6/7/8 S...)
@include breakpoint(xxsmall, max-width) {
  .content-block-read-only {
    &.ck {
      &.ck-content {
        oembed[url*='instagram.'] {
          .iframely-embed {
            margin: 0 rem(-13px);
          }
        }

        oembed[url*='pinterest.'] {
          .iframely-embed {
            margin: 0 rem(-18px);
          }
        }
      }
    }
  }
}
</style>
