// Inject Spreedly script
const injectScript = (router) => {
  if (process.client && !document.getElementById('spreedly')) {
    const script = document.createElement('script');
    script.src = 'https://core.spreedly.com/iframe/iframe-v1.min.js';
    script.id = 'spreedly';
    script.async = true;
    document.body.appendChild(script);
  }
};

// Create Spreedly DOM elements - delete existing elements in case of new 3DS cycle creation
const createDomElements = () => {
  const deviceFingerprintContainerElem = document.getElementById('spreedly-device-fingerprint');
  const challengeModalContainerElem = document.getElementById('spreedly-challenge-modal');

  if (deviceFingerprintContainerElem) {
    deviceFingerprintContainerElem.remove();
  }
  if (challengeModalContainerElem) {
    challengeModalContainerElem.remove();
  }

  const deviceFingerprintElem = document.createElement('div');
  deviceFingerprintElem.id = 'spreedly-device-fingerprint';
  document.body.appendChild(deviceFingerprintElem);

  const challengeModalElem = document.createElement('div');
  challengeModalElem.id = 'spreedly-challenge-modal';
  document.body.appendChild(challengeModalElem);

  const challengeElem = document.createElement('div');
  challengeElem.id = 'spreedly-challenge';
  document.getElementById('spreedly-challenge-modal').appendChild(challengeElem);
};

// Reload Spreedly Iframe (needed after payment succeed to be able to re use 3DS challenge on cross sell without old 3DS context)
const recreateIframe = () => {
  if (process.client && document.getElementById('spreedly')) {
    document.getElementById('spreedly').remove();
    injectScript();
  }
};

// Generate browser infos data
const browserInfos = () => {
  if (!process.client || typeof window.Spreedly === 'undefined') {
    return null;
  }

  // Capture browser data by using `Spreedly.ThreeDS.serialize().
  return window.Spreedly.ThreeDS.serialize(
    '05', // browser size
    'text/html,application/xhtml+xml;q=0.9,*/*;q=0.8',
  );
};

export default {
  injectScript,
  browserInfos,
  createDomElements,
  recreateIframe,
};
