import Vue from 'vue';
import VueRouter from 'vue-router';

import { checkoutLinksRoutes, checkoutSuffixRoutes } from '@/checkout-link/router/checkout-link-routes';
import { funnelCheckoutRoutes, funnelCheckoutSuffixRoutes } from '@/checkout-link/router/funnel-checkout-routes';

import { routerBeforeEach } from '@/checkout-link/router/router.before-each';
import { routerAfterEach } from '@/checkout-link/router/router.after-each';

if (typeof process === 'undefined' || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter);
}

const suffixRoutes = [...checkoutSuffixRoutes, ...funnelCheckoutSuffixRoutes];

const routes = [
  {
    path: '/404',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/not-found/NotFound'),
  },
  {
    path: '/confirmation/:orderId',
    name: 'Confirmation',
    component: () => import(/* webpackChunkName: "confirmation" */ '../views/confirmation/Confirmation'),
    props: route => ({
      insideModal: route.meta?.modal,
    }),
    children: [...funnelCheckoutRoutes],
  },
  {
    path: '/:customLink([\\w-]*)',
    component: () => import(/* webpackChunkName: "checkout-link" */ '../views/checkout-link/CheckoutLink'),
    props: route => ({
      hideBottomBar: route.meta?.hideBottomBar,
      isAuthView: route.meta?.isAuthView,
      isPresell: route.meta?.isPresell,
      overlay: route.meta?.overlay,
      insideModal: route.meta?.modal,
      customLink: route.params?.customLink || null,
    }),
    children: [...checkoutLinksRoutes],
  },
  { path: '*', redirect: '/404' },
];

export const createRouter = (store) => {
  const router = new VueRouter({
    routes,
    fallback: false,
    mode: 'history',
  });

  router.beforeEach(routerBeforeEach(store));
  router.afterEach(routerAfterEach);
  return router;
};

export default createRouter;
