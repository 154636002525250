<template>
  <button
    type="button"
    :disabled="disabled"
    :class="buttonClass">
    <font-awesome-icon :icon="name" />
  </button>
</template>

<script>
  export default {
    name: 'AppIconButton',
    props: {
      name: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        default: 'dark',
      },
      size: {
        type: String,
        default: 'medium',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      buttonClass() {
        return {
          'icon-button': true,
          [`icon-button--${this.color}`]: !!this.color,
          [`icon-button--${this.size}`]: !!this.size,
          'icon-button--disabled': this.disabled,
        };
      },
    },
  };
</script>

<style scoped lang="scss">
  .icon-button {
    background-color: transparent;
    border: none;
    box-shadow: var(--box-shadow-modal);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border: 1px solid var(--main-color);
    }

    &:focus {
      border: 1px solid var(--main-color);
      outline: none;

      @include shadowAsBorder(var(--focus-shadow));
    }

    &--dark {
      color: var(--primary-text);
    }

    &--muted {
      color: var(--secondary-raised-text);
    }

    &--small {
      @include icon-button-size(map-get($icon-button-size, small))
    }

    &--medium {
      @include icon-button-size(map-get($icon-button-size, medium))
    }

    &--large {
      @include icon-button-size(map-get($icon-button-size, large))
    }

    &--disabled {
      cursor: default;
      color: var(--secondary-shadow) !important;

      &:hover {
        border-color: var(--primary-background);
      }

      &:focus {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }
</style>
