<template>
  <div class="info-update">
    <div class="info-update__container">
      <div v-if="otpAccountId"
        class="container__body">
        <h2 class="body__title">
          {{ titleText }}
        </h2>
        <p class="body__help">
          {{ helpText }}
        </p>
        <div class="body__info-selection">
          <span v-for="(info, idx) in availableInfo"
            :key="`info-${idx}`"
            class="info-selection__option">
            <input
              :id="`info-${idx}`"
              v-model="selectedInfo"
              class="option__input"
              :value="info.value"
              type="radio" />
            <label :for="`info-${idx}`"
              class="option__label">{{ info.display }}</label>
          </span>
        </div>

        <AppError
          v-if="contactError"
          class="body__error"
          :error="{ contactError }" />

        <AppButton
          :disabled="isSaving"
          @click="handleContactUpdate">
          {{ buttonText }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { parsePhoneNumber } from 'libphonenumber-js/max';

  import IsInsideModal from '@mixins/isInsideModal.mixin';

  import AppButton from '@/checkout-link/components/app/app-button/AppButton.vue';
  import AppError from '@/checkout-link/components/app/app-error/AppError';

  export default {
    name: 'InfoUpdate',
    components: {
      AppButton,
      AppError,
    },
    mixins: [IsInsideModal],
    data() {
      return {
        selectedInfo: null,
        availableInfo: [],
      };
    },
    computed: {
      ...mapGetters('ContactModule', ['firstname', 'email', 'phoneNumber', 'isSaving', 'contactError']),
      ...mapGetters('AuthModule', ['otpAccountId', 'otpMismatchedInfo']),
      ...mapGetters('ContactModule/UserTemporaryInfoModule', ['getTempEmail', 'getTempPhone']),
      titleText() {
        return `${this.$t('checkoutLink.authentication.infoUpdate.title')} ${this.firstname}!`;
      },
      helpText() {
        return this.$t(`checkoutLink.authentication.infoUpdate.${this.otpMismatchedInfo === 'phoneNumber' ? 'mismatchPhone' : 'mismatchEmail'}`);
      },
      buttonText() {
        return this.$t(`checkoutLink.authentication.infoUpdate.${this.otpMismatchedInfo === 'phoneNumber' ? 'confirmPhone' : 'confirmEmail'}`);
      },
    },
    methods: {
      ...mapActions('ContactModule', ['updateContactOtpCredentials']),
      async handleContactUpdate() {
        const contact = {
          email: this.otpMismatchedInfo === 'email' ? this.selectedInfo : this.email,
          phoneNumber: this.otpMismatchedInfo === 'phoneNumber' ? this.selectedInfo : this.phoneNumber,
        };

        const payload = {
          contact,
          router: this.$router,
          updatedOtpCredential: this.otpMismatchedInfo === 'phoneNumber' ? 'phoneNumber' : 'email',
        };

        await this.updateContactOtpCredentials(payload);
      },
      _setAvailableInfo() {
        this.availableInfo = [
          {
            value: this.otpMismatchedInfo === 'phoneNumber' ? this.phoneNumber : this.email,
            display: this.otpMismatchedInfo === 'phoneNumber' ? parsePhoneNumber(this.phoneNumber).formatNational() : this.email,
          },
          {
            value: this.otpMismatchedInfo === 'phoneNumber' ? this.getTempPhone : this.getTempEmail,
            display: this.otpMismatchedInfo === 'phoneNumber' ? parsePhoneNumber(this.getTempPhone).formatNational() : this.getTempEmail,
          },
        ];

        this.selectedInfo = this.availableInfo[0].value;
      },
    },
    mounted() {
      if (!this.otpAccountId) {
        this.$router.push({
          name: 'AuthenticationCode',
          params: { ...this.$route.params },
        });
      }

      this._setAvailableInfo();
    },
  };
</script>

<style lang="scss" scoped>
.info-update {
  align-items: center;

  &__container {
    padding: 0 rem(25px);
  }
}

.container {
  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20%;
    padding-top: rem(30px);
  }
}

.body {
  &__title {
    @include heading();

    color: var(--primary-text);
    font-weight: $bold;
    text-align: center;
    margin: 0 0 8px 0;
  }

  &__help {
    @include body-large();

    color: var(--secondary-text);
    text-align: center;
    margin: 0 0 rem(24px) 0;
    //line-height: rem(14px);
  }

  &__info-selection {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: rem(18px);
    border: 1px solid #F0F1F5;
    box-sizing: border-box;
    border-radius: 8px;
  }

  &__error {
    justify-content: center;
    text-align: center;
  }
}

.info-selection {
  &__option {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 15px;

    &:first-child {
      border-bottom: 1px solid #F0F1F5;
    }
  }
}

.option {
  &__label {
    @include body-large();

    margin-left: rem(17px);
  }
}

@include breakpoint(small) {
  .container {
    &__body {
      padding-bottom: rem(30px);
      padding-top: 0;
    }
  }
}
</style>
