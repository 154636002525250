// Helper variables
export const DOMAINS_SUPPORTING_PARAMS = ['^https:\\/\\/cdn.shopify.com', '^https:\\/\\/cdn(\\d+).bigcommerce.com'];
export const PRODUCT_FILE_FROM_URL_REGEX = /[^/\\&?]+\.\w{3,4}(?=([?&].*$|$))/;
export const PRODUCT_FILE_PARTS_REGEX = /^([^\\]*)\.(\w+)$/;
export const PRODUCT_IMAGE_SIZE = {
  MIN: {
    WIDTH: 30,
    HEIGHT: 30,
  },
  MAX: {
    WIDTH: 675,
    HEIGHT: 450,
  },
  GALLERY: {
    WIDTH: 675,
    HEIGHT: 450,
  },
  VERTICAL: {
    WIDTH: 160,
  },
  IN_CART: {
    WIDTH: 150,
  },
  IN_OFFER: {
    WIDTH: 30,
  },
  IN_FOOTER: {
    WIDTH: 100,
  },
};

// Helper methods
export const extractDescription = (description) => {
  if (!description) {
    return description;
  }
  // Remove variable placeholders
  return description.replace(/{{.*?}}/ig, '');
};

export const hasVariantsAndAttributes = (product) => !(product.variants.length === 1 && product.variants[0].attributes.length <= 1);

export const getVariantPrice = (variant) => variant.originalPrice || variant.price;

export const getVariantDiscountedPrice = (variant) => variant.price;

/**
 * check if product has available variants in inventory and return a boolean
 * !== undefined at the end makes sure it returns a boolean value
 * TODO: refacto to array.some function ?
 */
export const productHasAvailableVariantsQuantities = (product) => {
  return product.variants.find(variant => variant.inventory.quantity > 0 || (!variant.inventory.tracked && variant.inventory.available === true)) !== undefined;
};

export const isVariantAvailable = (variant) => {
  return variant.inventory?.quantity > 0 || (variant.inventory?.quantity <= 0 && variant.inventory?.available);
};

export const getShopifyImageOptimizedFile = (filename, extension, width, height) => {
  const paramsDefined = filename && extension && (width || height);
  return paramsDefined ? `${filename}_${width || ''}x${height || ''}.${extension}` : null;
};
