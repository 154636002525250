<template>
  <div
    ref="bottomBar"
    class="bottom-bar"
    :class="{
      'bottom-bar--unregistered': !hasSomeInformation,
      'bottom-bar--loaded': isLoaded,
      'bottom-bar--with-display': isDisplayVisible
    }"
    :style="{
      '--bg-alpha-color': alphaColor,
      'display': displayBottomBar
    }">
    <div class="bottom-bar__blur" />

    <template>
      <template v-if="hasSomeInformation && isLoaded">
        <div class="bottom-bar__payment">
          <IconCreditCard
            v-if="getSelectedCreditCard"
            class="payment__icon" />

          <UserPaymentMethods
            class="payment__select"
            :is-material="false"
            :inside-modal="false"
            :is-mobile="isMobile" />
        </div>

        <div
          v-if="existingShippingOptions && shippingRequired"
          class="bottom-bar__shipping">
          <IconTruckFull class="shipping__icon" />

          <UserShipping
            class="shipping__select"
            :is-material="false"
            :inside-modal="false"
            :is-mobile="isMobile" />
        </div>

        <div class="bottom-bar__address">
          <IconGeoPin
            v-if="getSelectedAddress"
            class="address__icon" />

          <UserAddresses
            class="address__select"
            :is-material="false"
            :inside-modal="false"
            :is-mobile="isMobile" />
        </div>
      </template>

      <button
        type="button"
        class="bottom-bar__thumbnails"
        :disabled="isTimerExpired"
        @click="openDetails">
        <ProductsImages :products-images="productsImages" />
      </button>
    </template>

    <AppButton
      class="bottom-bar__action"
      type="primary"
      :disabled="actionDisabled"
      :icon-prefix="buttonIcon"
      data-test-id="bottom-bar-action-button"
      @click="openModal">
      {{ actionText }} {{ price }}
    </AppButton>

    <div
      v-if="isDisplayVisible"
      class="bottom-bar__bottom-line">
      <DiscountFlat
        v-if="discountFlatVisible"
        :checkout-link-incentive="getCheckoutLinkIncentive"
        :currency="getCurrency" />

      <DiscountBrackets
        v-if="discountBracketsVisible"
        :checkout-link-incentive="getCheckoutLinkIncentive"
        :total-cart="getTotalCart"
        :currency="getCurrency" />

      <div v-if="giftVisible"
        class="bottom-line__gift">
        <IconGift class="gift-icon" />
        {{ $t('checkoutLink.information.giftInCart') }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import MobileMixin from '@mixins/isMobile.mixin';
  import { formattedPrice, lightnessColorVariation } from '@/helpers/data/data.helper';

  import AppButton from '@/checkout-link/components/app/app-button/AppButton.vue';
  import DiscountBrackets from '@/checkout-link/components/discount-brackets/DiscountBrackets.vue';
  import DiscountFlat from '@/checkout-link/components/discount-flat/DiscountFlat.vue';
  import IconCreditCard from '@/checkout-link/components/icon/IconCreditCard.vue';
  import IconGeoPin from '@/checkout-link/components/icon/IconGeoPin.vue';
  import IconGift from '@/checkout-link/components/icon/IconGift.vue';
  import IconTruckFull from '@/checkout-link/components/icon/IconTruckFull.vue';
  import ProductsImages from '@/checkout-link/components/products-images/ProductsImages.vue';
  import UserAddresses from '@/checkout-link/components/user-addresses/UserAddresses.vue';
  import UserPaymentMethods from '@/checkout-link/components/user-payment-methods/UserPaymentMethods.vue';
  import UserShipping from '@/checkout-link/components/user-shipping/UserShipping.vue';

  export default {
    name: 'BottomBar',
    components: {
      AppButton,
      DiscountBrackets,
      DiscountFlat,
      IconCreditCard,
      IconGeoPin,
      IconGift,
      IconTruckFull,
      ProductsImages,
      UserAddresses,
      UserPaymentMethods,
      UserShipping,
    },
    mixins: [MobileMixin],
    props: {
      presell: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isLoaded: false,
        resizeObserver: null,
      };
    },
    computed: {
      ...mapGetters('CartModule', [
        'isCartEmpty', 'hasValidQuantity', 'isUpdating',
        'getTotalDiscountedCart', 'getTotalCart', 'getCurrency', 'getItems',
        'getMappedShippingOptions', 'getSelectedCreditCard', 'getSelectedAddress',
        'shippingRequired', 'getGiftItems',
      ]),
      ...mapGetters('CheckoutLinkModule', [
        'getCheckoutLinkIncentive', 'hasCheckoutLink',
      ]),
      ...mapGetters('ContactModule', ['hasSomeInformation']),
      ...mapGetters('FunnelModule', ['hasFunnel', 'isTimerExpired', 'isPresellFunnel', 'presellIgnored']),
      ...mapGetters('ThemeModule', ['checkoutButtonBackgroundColor']),
      ...mapGetters('OrderModule', ['isSaving']),
      isDisplayVisible() {
        return this.isMobile &&
          !this.hasFunnel &&
          (this.discountBracketsVisible || this.discountFlatVisible || this.giftVisible);
      },
      isConfirmationRoute() {
        return this.$route?.name === 'Confirmation' ||
          this.$route?.name === 'FunnelCheckout' ||
          this.$route?.name === 'FunnelInformation';
      },
      actionDisabled() {
        return this.isCartEmpty || this.isUpdating || this.isSaving ||
            (this.hasFunnel && this.isTimerExpired && this.isConfirmationRoute);
      },
      buttonIcon() {
        return 'lock';
      },
      price() {
        return formattedPrice(this.getTotalDiscountedCart, this.getCurrency);
      },
      actionText() {
        if (this.declinePresellOffer) {
          return this.$t('checkoutLink.button.decline');
        }

        return this.$t('checkoutLink.button.pay');
      },
      discountFlatVisible() {
        return this.getCheckoutLinkIncentive?.mode === 'FLAT' && this.getCheckoutLinkIncentive?.amount > 0;
      },
      discountBracketsVisible() {
        return this.getCheckoutLinkIncentive?.mode === 'BRACKETS';
      },
      giftVisible() {
        return this.getGiftItems.length > 0;
      },
      existingShippingOptions() {
        return !!this.getMappedShippingOptions.length;
      },
      productsImages() {
        return this.getItems.map(product => product.imageUrl);
      },
      alphaColor() {
        const containerLightnessVariation = 20;
        const containerAlpha = 0.8;

        return lightnessColorVariation(this.checkoutButtonBackgroundColor, containerLightnessVariation, containerAlpha);
      },
      hasCartOrPreRegistration() {
        return !this.isCartEmpty;
      },
      displayBottomBar() {
        return this.resizeObserver && this.hasCartOrPreRegistration && this.isLoaded ? 'grid' : 'none';
      },
      isPresellRoute() {
        return this.$route?.name === 'Presell';
      },
      declinePresellOffer() {
        return this.hasFunnel && this.isPresellFunnel && this.presellIgnored && this.isPresellRoute;
      },
    },
    methods: {
      ...mapActions('OrderModule', ['processToCheckoutLink']),
      async openModal() {
        await this.processToCheckoutLink({ init: !this.presell, router: this.$router });
      },
      async openDetails() {
        if ((this.hasCheckoutLink && !this.hasSomeInformation) || this.isCartEmpty) {
          await this.$router.push({
            name: 'Details',
            params: { ...this.$route.params },
          });
        } else {
          await this.openModal();
        }
      },
      _setBarPosition() {
        if (!process.client) return;

        this.$refs.bottomBar.style.left = `${(window.innerWidth - this.$refs.bottomBar.offsetWidth) / 2}px`;
      },
      _initResizeObserver() {
        const resizeObserver = new ResizeObserver(this._setBarPosition);
        resizeObserver.observe(this.$refs.bottomBar);

        this.resizeObserver = resizeObserver;
      },
      _clearResizeObserver() {
        this.resizeObserver.unobserve(this.$refs.bottomBar);
      },
    },
    mounted() {
      // We need to reposition the cart every time its size changes, or the window size changes.
      this._initResizeObserver();
      window.addEventListener('resize', this._setBarPosition);

      this.$nextTick().then(this._setBarPosition);
      this.isLoaded = true;
    },
    beforeDestroy() {
      this._clearResizeObserver();
      window.removeEventListener('resize', this._setBarPosition);
    },
  };
</script>

<style lang="scss" scoped>
  $alias: &;
  $blur-level: 10px;
  $thumbnail-size: 46px;

  $bar-max-width-small: 600px;
  $bar-max-width: 1000px;
  $bar-width: calc(100vw - #{rem(20px)});
  $bar-width-small: calc(100vw - #{rem(50px)});
  $bar-width-medium: calc(100vw - #{rem(130px)});
  $bar-width-large: calc(100vw - #{rem(200px)});

  .bottom-bar {
    position: fixed;
    z-index: 102;
    bottom: 8px;
    display: none; // keep it hidden to avoid delay between loading page and js position calculation
    grid-template-columns: 46px 1fr;
    grid-template-rows: 1fr;
    column-gap: 10px;
    row-gap: 2px;
    box-sizing: border-box;
    width: $bar-width;
    max-width: $bar-max-width;
    min-width: 0;
    border-radius: 8px;
    padding: 12px;
    background: var(--checkout-background-color);
    color: var(--checkout-text-color);

    &--unregistered {
      max-width: $bar-max-width-small;
    }

    &--loaded {
      background: var(--bg-alpha-color);
    }

    &--with-display {
      grid-template-rows: 1fr 17px;
    }

    &--pre-registration {
      max-width: $bar-max-width-small;

      .bottom-bar__pre-registration {
        @include body-medium;
        margin: 0 0 6px 0;
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        font-weight: $bold;
        text-align: center;
      }

      .bottom-bar__action {
        grid-row: 2 / 3;
        grid-column: 1 / -1;
      }
    }

    &__blur {
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 8px;
      -webkit-backdrop-filter: blur($blur-level);
      backdrop-filter: blur($blur-level);
    }

    &__payment,
    &__shipping,
    &__address {
      display: none;
    }

    &__thumbnails {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      min-width: $thumbnail-size;
      height: $thumbnail-size;
      border-radius: 4px;
      border: none;
      padding: 0;
      cursor: pointer;
      background: transparent;
    }

    &__action {
      @include body-medium;
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      width: 100%;
      min-width: 140px;
      height: 46px;
      padding: 16px;
      font-weight: $bold;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      white-space: nowrap;
      color: var(--checkout-text-color);
      background: var(--checkout-background-color);
    }

    &__bottom-line {
      grid-column: 1 / -1;
      grid-row: 2 / -1;
      justify-self: center;
      font-size: rem(10px);
    }
  }

  .payment__icon,
  .shipping__icon,
  .address__icon {
    position: relative;
    top: 2px;
    min-width: 20px;
    margin-right: 4px;
  }

  .payment {
    &__select {
      flex: 1 0 auto;
      max-width: 145px;
    }
  }

  .shipping,
  .address {
    &__select {
      flex: 1 0 auto;
      max-width: 205px;
    }
  }

  @include breakpoint(small) {
    .bottom-bar {
      width: $bar-width-small;
      bottom: 16px;
    }
  }

  @include breakpoint(medium) {
    .bottom-bar {
      grid-template-columns: 46px fit-content(80px) fit-content(215px) fit-content(230px) 46px minmax(190px, 1fr);
      width: $bar-width-medium;

      &__payment,
      &__shipping,
      &__address {
        display: flex;
        align-self: center;
      }

      &__payment {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
      }

      &__shipping {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
      }

      &__address {
        grid-column: 4 / 5;
        grid-row: 1 / 2;
      }

      &__thumbnails {
        grid-column: 5 / 6;
        grid-row: 1 / 2;
      }

      &__action {
        grid-column: 6 / 7;
        grid-row: 1 / 2;
      }

      &__bottom-line {
        display: none;
      }
    }

    .bottom-bar--unregistered {
      .bottom-bar__thumbnails {
        grid-column: 1 / 2;
      }

      .bottom-bar__action {
        grid-column: 2 / -1;
      }
    }
  }

  @include breakpoint(large) {
    .bottom-bar {
      width: $bar-width-large;
    }
  }
</style>

<style lang="scss">
  .bottom-bar {
    .v-select.vs--disabled {
      .vs__selected {
        color: var(--checkout-text-color)
      }

      .vs__actions {
        display: none;
      }
    }

    .vs__selected {
      color: var(--checkout-text-color)
    }
  }

  .bottom-line {
    &__gift {
      display: flex;
      align-items: center;
      gap: 6px;
      height: 25px;
    }
  }

  .gift-icon {
    width: 12px;
    height: 12px;
  }
</style>
