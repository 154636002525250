<template>
  <div
    v-if="productsImagesWithBackup.length === 1"
    class="products-images-solo">
    <ProductOptimizedImage
      class="products-images-solo__image"
      alt="product image"
      :src="productsImagesWithBackup[0]"
      :width="imageWidth" />
  </div>

  <div
    v-else-if="productsImagesWithBackup.length === 2"
    class="products-images-duo">
    <ProductOptimizedImage
      class="products-images-duo__image"
      alt="product image"
      :src="productsImagesWithBackup[0]"
      :width="imageWidth" />
    <ProductOptimizedImage
      class="products-images-duo__image"
      alt="product image"
      :src="productsImagesWithBackup[1]"
      :width="imageWidth" />
  </div>

  <div
    v-else-if="productsImagesWithBackup.length >= 3"
    class="products-images-trio">
    <ProductOptimizedImage
      class="products-images-trio__image"
      alt="product image"
      :src="productsImagesWithBackup[0]"
      :width="imageWidth" />

    <div class="products-images-trio__vertical-display">
      <ProductOptimizedImage
        class="vertical-display__image"
        alt="product image"
        :src="productsImagesWithBackup[1]"
        :width="imageWidth" />

      <ProductOptimizedImage
        v-if="productsImagesWithBackup.length === 3"
        class="vertical-display__image"
        alt="product image"
        :src="productsImagesWithBackup[2]"
        :width="imageWidth" />
      <div
        v-else
        class="vertical-display__counter">
        <span class="counter__text">
          {{ remainingProductsNumberInCart }}
        </span>
        <ProductOptimizedImage
          class="counter__image"
          alt="product image"
          :src="productsImagesWithBackup[2]"
          :width="imageWidth" />
      </div>
    </div>
  </div>
</template>

<script>
  import placeholderImage from '@/assets/image-placeholder.svg';
  import { PRODUCT_IMAGE_SIZE } from '@/helpers/product/product.helper';

  import ProductOptimizedImage from '@/checkout-link/components/product/product-optimized-image/ProductOptimizedImage';

  export default {
    name: 'ProductsImages',
    components: {
      ProductOptimizedImage,
    },
    props: {
      productsImages: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        imageWidth: PRODUCT_IMAGE_SIZE.IN_FOOTER.WIDTH,
      };
    },
    computed: {
      productsImagesWithBackup() {
        return this.productsImages.map(imageUrl => {
          return imageUrl || placeholderImage;
        });
      },
      remainingProductsNumberInCart() {
        return `+${this.productsImages.length - 3}`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  $border-radius: 4px;

  .products-images-solo,
  .products-images-duo,
  .products-images-trio {
    border-radius: $border-radius;
    height: 100%;
    width: 100%;
  }

  .products-images-duo,
  .products-images-trio {
    display: flex;
    justify-content: space-between;
  }

  .products-images-solo {
    &__image {
      border-radius: $border-radius;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .products-images-duo {
    &__image {
      width: calc(50% - 1px);
      height: 100%;
      object-fit: cover;

      &:first-child {
        border-radius: $border-radius 0 0 $border-radius;
      }

      &:last-child {
        border-radius: 0 $border-radius $border-radius 0;
      }
    }
  }

  .products-images-trio {
    &__image {
      display: flex;
      border-radius: $border-radius 0 0 $border-radius;
      width: calc(50% - 1px);
      height: 100%;
      object-fit: cover;
    }

    &__vertical-display {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(50% - 1px);
      height: 100%;
    }
  }

  .vertical-display {
    &__image {
      width: 100%;
      height: calc(50% - 1px);
      object-fit: cover;

      &:first-child {
        border-radius: 0 $border-radius 0 0;
      }

      &:last-child {
        border-radius: 0 0 $border-radius 0;
      }
    }

    &__counter {
      position: relative;
      height: calc(50% - 1px);
      border-radius: 0 0 $border-radius 0;
    }
  }

  .counter {
    &__text {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 0 0 $border-radius 0;
      background: rgba(0, 0, 0, 0.5);
      color: var(--main-color-text);
      font-weight: $bold;
      font-size: 11px;
      line-height: 13px;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0 0 $border-radius 0;
    }
  }
</style>
