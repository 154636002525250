export const PAGE_VIEW = 'PAGE_VIEW';
export const ADD_TO_CART_CLICK = 'ADD_TO_CART_CLICK';
export const QUANTITY_INCREASE_CLICK = 'QUANTITY_INCREASE_CLICK';
export const QUANTITY_DECREASE_CLICK = 'QUANTITY_DECREASE_CLICK';
export const IMAGE_CLICK = 'IMAGE_CLICK';
export const ACQUIRE_CLICK = 'ACQUIRE_CLICK';
export const BUY_CLICK = 'BUY_CLICK';
export const CART_DETAIL_CLICK = 'CART_DETAIL_CLICK';
export const INITIALIZE_CHECKOUT_CLICK = 'INITIALIZE_CHECKOUT_CLICK';
export const FUNNEL_VIEWED = 'viewed';
export const FUNNEL_ACTIVATED = 'activated';
export const FUNNEL_PURCHASED = 'purchased';
