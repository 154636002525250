<template>
  <div class="subscription-settings">
    <div class="subscription-settings__frequency">
      <SubscriptionFrequency
        :frequency-options="getCheckoutLinkFrequencies"
        :selected-frequency-option="getSelectedFrequency"
        :is-loading="isFrequencyLoading"
        @save-frequency-option="onSaveFrequency" />
    </div>

    <div v-if="subscriptionShippingRequired && !areShippingOptionsIdentical"
      class="subscription-settings__shipping">
      <h4 class="title">
        {{ $t('cart.subscriptionShipping') }}
      </h4>

      <UserSubscriptionShipping :inside-modal="isInsideModal" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import IsInsideModal from '@mixins/isInsideModal.mixin';

  import SubscriptionFrequency from '@/checkout-link/components/subscription/subscription-frequency/SubscriptionFrequency.vue';
  import UserSubscriptionShipping from '@/checkout-link/components/user-subscription-shipping/UserSubscriptionShipping';

  export default {
    name: 'CartWithProducts',
    components: {
      SubscriptionFrequency,
      UserSubscriptionShipping,
    },
    mixins: [IsInsideModal],
    computed: {
      ...mapGetters('CartModule', ['isFrequencyLoading', 'getSelectedFrequency', 'subscriptionShippingRequired', 'areShippingOptionsIdentical']),
      ...mapGetters('CheckoutLinkModule', ['getCheckoutLinkFrequencies']),
    },
    methods: {
      ...mapActions('CartModule', ['saveFrequencyOption']),
      onSaveFrequency(value) {
        this.saveFrequencyOption(value);
      },
    },
  };
</script>

<style scoped lang="scss">
  .subscription-settings {
    margin-top: rem(-10px);

    &__shipping {
      margin-top: rem(10px);

      .title {
        margin: 0 0 rem(10px);
        font-weight: 700;
        color: var(--tertiary-text);
      }
    }
  }
</style>
