<template>
  <div
    class="card-select"
    :class="[isMaterial ? 'card-select--material' : 'card-select--basic']">
    <!-- material-input: do not change following class 'field-label' -->
    <span
      v-if="isMaterial"
      class="field__label">
      {{ label }}
    </span>

    <VSelect
      ref="select"
      v-model="selectedValue"
      :options="options"
      :reduce="obj => obj.value"
      label="name"
      :disabled="disabled"
      append-to-body
      :calculate-position="calculatePosition"
      :searchable="false"
      :clearable="false"
      @input="emitValueChange">
      <!-- Selected option display -->
      <template
        slot="selected-option"
        slot-scope="option">
        <span
          v-if="isMaterial && !option.isLink"
          :class="'card-select__card credit-card--' + option.type" />

        <span
          v-if="option.isLink"
          v-html="$t('checkout.stripeDefaultPaymentMethod')" />

        <span v-else>
          {{ maskNumbers ? '**** **** ****' : option.name }}
          {{ option.last }}
        </span>
      </template>

      <!-- Select options in container display -->
      <template
        slot="option"
        slot-scope="option">
        <span
          v-if="!option.isLink"
          :class="'credit-card credit-card--' + option.type" />

        <div class="card-select__options">
          <span
            v-if="option.isLink"
            v-html="$t('checkout.stripeDefaultPaymentMethod')" />

          <span v-else>
            {{ maskNumbers ? '**** **** ****' : option.name }}
            {{ option.last }}
          </span>
        </div>

        <font-awesome-icon
          class="card-select__check"
          icon="check" />
      </template>

      <!-- Add option button -->
      <template
        v-if="addOption"
        #list-footer>
        <li>
          <div class="card-select__add">
            <font-awesome-icon icon="plus" />

            <span
              class="add__icon"
              @click="emitAddOption">{{ addOptionLabel }}</span>
          </div>
        </li>
      </template>
    </VSelect>
  </div>
</template>

<script>
  import VSelect from 'vue-select';
  import DropDownMixin from '@mixins/dropdown.mixin';

  export default {
    name: 'CreditCardDropdown',
    components: {
      VSelect,
    },
    mixins: [DropDownMixin],
    props: {
      maskNumbers: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    mounted() {
      console.log({ options: this.options });
    },
  };
</script>

<style scoped lang="scss">
  .card-select {
    &--material {
      @include material-input();
      text-align: left;
    }

    &__card {
      width: 21px;
      margin-right: 10px;
      border: 1px solid var(--input-border);
      border-radius: 2.5px;
      padding: 0 10px;
    }

    &__add {
      margin: 10px;
      border-radius: 2.5px;
      padding: 12px;
      background: var(--main-color);
      color: var(--main-color-text);
      line-height: 14px;
      font-weight: 400;
      font-family: var(--main-font-family);
      cursor: pointer;

      & svg {
        margin-right: 5px;
      }
    }

    &__options {
      flex: 1;
    }

    &__check {
      display: none;
    }
  }

  .credit-card {
    width: 21px;
    height: 15px;
    margin-right: 5px;
  }
</style>

<style lang="scss">
  .card-select--basic {
    & .vs__selected {
      color: var(--main-color-text);
      font-weight: $bold;
    }
  }

  .card-select--material {
    & .vs__selected {
      color: var(--primary-text);
    }

    .v-select {
      height: 100%;

      & .vs__dropdown-toggle {
        height: 100%;
      }

      &.vs--open .vs__selected {
        position: relative;
        top: 13px;
      }

      & .vs__selected {
        position: relative;
        top: 13px;
      }

      .vs__actions {
        position: relative;
        top: 12px;
        right: -5px;
      }
    }
  }
</style>
