<template>
  <div
    class="info-bar"
    :class="{'info-bar--loaded': isLoaded}"
    :style="cssStyles">
    <div class="info-bar__display">
      <div
        v-if="countDownVisible"
        class="display__countdown">
        <IconClock class="countdown__icon" />

        <span class="countdown__text">
          {{ $t('checkoutLink.information.countdown') }}
        </span>

        <CountDown
          class="countdown__counter"
          :date="countDownDate"
          :is-on-cart="true"
          @done="onCountDownEnd" />
      </div>

      <div
        v-if="discountBracketsVisible"
        class="display__brackets">
        <DiscountBrackets
          :checkout-link-incentive="getCheckoutLinkIncentive"
          :total-cart="getTotalCart"
          :currency="getCurrency" />
      </div>

      <div
        v-if="discountFlatVisible"
        class="display__flat">
        <DiscountFlat
          :checkout-link-incentive="getCheckoutLinkIncentive"
          :currency="getCurrency" />
      </div>

      <div
        v-if="giftVisible"
        class="display__gift">
        <IconGift class="gift-icon" />
        {{ $t('checkoutLink.information.giftInCart') }}
      </div>
    </div>

    <div class="info-bar__cart">
      <button
        type="button"
        data-test-id="info-bar-cart-details"
        data-cy="info-bar-cart"
        @click="openDetails">
        <span
          v-if="firstname"
          class="cart__name">
          {{ firstNameEllipsis }}
        </span>
        <IconCart class="cart__icon" />
        <span
          class="cart__items-count"
          data-cy="info-bar-product-count">
          {{ getItemTotalInCart }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import { lightnessColorVariation, HEXtoRGBA } from '@/helpers/data/data.helper.js';
  import MobileMixin from '@mixins/isMobile.mixin';

  import IconCart from '@/checkout-link/components/icon/IconCart.vue';
  import IconClock from '@/checkout-link/components/icon/IconClock.vue';
  import IconGift from '@/checkout-link/components/icon/IconGift.vue';
  import CountDown from '@/checkout-link/components/count-down/CountDown.vue';
  import DiscountBrackets from '@/checkout-link/components/discount-brackets/DiscountBrackets.vue';
  import DiscountFlat from '@/checkout-link/components/discount-flat/DiscountFlat.vue';

  export default {
    name: 'InfoBar',
    components: {
      CountDown,
      IconClock,
      IconGift,
      DiscountBrackets,
      DiscountFlat,
      IconCart,
    },
    mixins: [MobileMixin],
    data() {
      return {
        isLoaded: false,
      };
    },
    computed: {
      ...mapGetters('CheckoutLinkModule', [
        'getCookieExpirationDate', 'getCheckoutLinkEndDate', 'getCheckoutLinkCountDown',
        'getCheckoutLinkIncentive',
      ]),
      ...mapGetters('CartModule', ['getTotalCart', 'getCurrency', 'getItemTotalInCart', 'getGiftItems']),
      ...mapGetters('ContactModule', ['firstname']),
      ...mapGetters('ThemeModule', ['headerBackgroundColor']),
      firstNameEllipsis() {
        const maxLimit = this.isMobile ? 10 : 17;

        return this.firstname.length > maxLimit ? `${this.firstname.substring(0, maxLimit - 3)}...` : this.firstname;
      },
      countDownDate() {
        return this.getCookieExpirationDate || this.getCheckoutLinkEndDate;
      },
      countDownVisible() {
        return this.getCookieExpirationDate || (this.getCheckoutLinkCountDown && this.getCheckoutLinkEndDate);
      },
      discountFlatVisible() {
        return this.getCheckoutLinkIncentive?.mode === 'FLAT' && this.getCheckoutLinkIncentive?.amount > 0;
      },
      discountBracketsVisible() {
        return this.getCheckoutLinkIncentive?.mode === 'BRACKETS' && this.getCheckoutLinkIncentive.bracketType !== 'CART_QUANTITY' && !this.getCheckoutLinkIncentive.brackets.some(e => e.incentiveType === 'FREE_SHIPPING');
      },
      giftVisible() {
        return this.getGiftItems.length > 0;
      },
      cssStyles() {
        const containerLightnessVariation = 20;
        const containerAlpha = 0.8;
        const displayBackgroundAlpha = 0.9;

        return {
          '--variation-color': lightnessColorVariation(this.headerBackgroundColor, containerLightnessVariation, containerAlpha),
          '--bg-alpha-color': HEXtoRGBA(this.headerBackgroundColor, displayBackgroundAlpha),
        };
      },
    },
    methods: {
      ...mapActions('CheckoutLinkModule', ['setExceptionExpired']),
      openDetails() {
        this.$router.push({
          name: 'Details',
          params: { ...this.$route.params },
        });
      },
      onCountDownEnd() {
        this.setExceptionExpired({ router: this.$router });
      },
    },
    mounted() {
      this.isLoaded = true;
    },
  };
</script>

<style lang="scss" scoped>
  $blur-level: 10px;

  .info-bar {
    position: sticky;
    z-index: 100;
    top: 0;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    height: 44px;
    padding: 6px 20px;
    background: var(--header-background-color);
    color: var(--header-text-color);
    -webkit-backdrop-filter: blur($blur-level);
    backdrop-filter: blur($blur-level);

    &--loaded {
      background: var(--bg-alpha-color);
    }

    &__display {
      flex: 1;
      display: flex;
    }

    &__cart {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-basis: 135px;
      box-sizing: border-box;

      button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        padding: 0 12px;

        border: none;
        color: inherit;
        background-color: transparent;
        border-radius: 21px;
        cursor: pointer;

        &:focus-visible,
        &:focus {
          outline: none;
          background: var(--variation-color);
        }
      }
    }
  }

  .display {
    &__countdown {
      display: flex;
      align-items: center;
      border-radius: 21px;
      min-width: 210px;
      padding: 0 10px 0 12px;
      background: var(--variation-color);
      font-size: rem(11px);
      -webkit-backdrop-filter: blur($blur-level);
      backdrop-filter: blur($blur-level);
    }

    &__brackets,
    &__flat,
    &__gift {
      display: none;
    }
  }

  .countdown {
    &__icon {
      margin-right: 5px;
      fill: var(--header-text-color);
    }

    &__text {
      margin-right: 3px;
    }

    &__counter {
      display: inline-flex;
    }
  }

  .cart {
    &__icon {
      position: relative;
      top: -2px;
      margin-right: 9px;
      margin-left: 16px;
      width: 13px;
      fill: var(--header-text-color);
    }

    &__name {
      @include body-medium;
      font-size: rem(12px);
      font-weight: 700;
    }

    &__icon {
      font-size: rem(14px);
    }
  }

  @include breakpoint(xsmall) {
    .display {
      &__countdown {
        font-size: rem(12px);
      }
    }
  }

  @include breakpoint(medium) {
    .info-bar {
      &__display {
        justify-content: center;
        margin-left: 150px;
      }

      &__cart {
        flex-basis: 150px;

        button {
          border-radius: 8px;
        }
      }
    }

    .display {
      &__brackets,
      &__flat,
      &__gift {
        display: flex;
        align-items: center;
        margin-left: 14px;
        padding: 0 20px;
        border-radius: 8px;
        background: var(--variation-color);
        -webkit-backdrop-filter: blur($blur-level);
        backdrop-filter: blur($blur-level);
      }

      &__countdown {
        padding: 0 20px 0 22px;
        border-radius: 8px;
      }

      &__gift {
        gap: 6px;
      }
    }
  }

  .gift-icon {
    width: 15px;
    height: 15px;
  }
</style>
