import md5 from 'md5';

const inject = () => {
  if (!document.getElementById('acquire-iframely')) {
    const md5Key = md5(process.env.VUE_APP_IFRAMELY_API_KEY);
    const script = document.createElement('script');

    script.src = `//cdn.iframe.ly/embed.js?key=${md5Key}`;
    script.id = 'acquire-iframely';

    document.body.appendChild(script);
  }
};

export default {
  inject,
};
