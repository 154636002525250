<template>
  <div data-test-id="landline-phone"
    class="landline">
    <h1 class="landline__title">
      {{ $t('authentication.landline.title') }}
    </h1>

    <h2 class="landline__subtitle">
      {{ $t('authentication.landline.subtitle') }}
    </h2>

    <UserPhone
      class="landline__phone"
      :disabled="isVerifyingOtpAccount"
      :default-country="defaultCountry"
      @phone-changed="handlePhoneChange" />

    <AppButton
      :disabled="!canSubmit"
      class="landline__button"
      @click="handleSubmit">
      {{ $t('authentication.landline.action') }}
    </AppButton>

    <div v-if="hasError"
      class="landline__error">
      {{ $t('error.phonenumber.exist') }}
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import { userDefaultCountry } from '@/helpers/contact/contact.helper';

  import UserPhone from '@/checkout-link/components/user-phone/UserPhone';
  import AppButton from '@/checkout-link/components/app/app-button/AppButton.vue';

  export default {
    name: 'LandlinePhone',
    components: {
      UserPhone,
      AppButton,
    },
    data() {
      return {
        canSubmit: false,
        phoneNumber: '',
        hasError: false,
      };
    },
    computed: {
      ...mapGetters('AuthModule', ['isVerifyingOtpAccount']),
      ...mapGetters('SessionModule', ['countryCode']),
      defaultCountry() {
        return userDefaultCountry(this.countryCode, this?.$ssrContext?.acceptsLanguages);
      },
    },
    methods: {
      ...mapActions('AuthModule', ['confirmPhoneNumber']),
      handlePhoneChange(phoneNumber) {
        if (!phoneNumber) {
          this.canSubmit = false;
          this.phoneNumber = '';
          this.hasError = false;
        } else {
          this.canSubmit = true;
          this.phoneNumber = phoneNumber;
          this.hasError = false;
        }
      },
      async handleSubmit() {
        try {
          await this.confirmPhoneNumber({
            phoneNumber: this.phoneNumber,
            router: this.$router,
          });
          this.hasError = false;
        } catch (error) {
          this.hasError = true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.landline {
  width: 100%;
  padding: 20px 0;

  &__title {
    margin-bottom: 16px;
    font-size: rem(18px);
    text-align: center;
    line-height: 21px;
  }

  &__subtitle {
    margin-bottom: 30px;
    color: var(--secondary-text);
    font-size: rem(14px);
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  &__error {
    display: flex;
    max-width: 232px;
    margin: 30px 0;
    padding: 13px;
    color: $error-box-text;
    background-color: $error-box-background;
    border-radius: $radius-input;
    font-weight: $bold;
    font-size: $default-font-size;
  }

  &__phone {
    margin-bottom: 10px;
  }
}
</style>
