<template>
  <div class="subscription-frequency">
    <h4
      class="subscription-frequency__title">
      {{ $t('cart.frequency') }}
    </h4>

    <SimpleDropdown
      class="subscription-frequency__selection-mode"
      :inside-modal="insideModal"
      :is-material="isMaterial"
      :label="$t('checkout.summary.frequency')"
      :value="selectedFrequencyOption || null"
      :options="availableFrequencyOptions"
      :disabled="disabledFrequencyMenu"
      :loading="isLoading"
      :auto-select-first="true"
      @value-changed="onFrequencyChange" />
  </div>
</template>

<script>
  import CheckoutUserInfo from '@mixins/checkoutUserInfo.mixin';
  import { getFullValue } from '@/helpers/data/data.helper';

  import SimpleDropdown from '@/checkout-link/components/dropdown/SimpleDropdown';

  export default {
    name: 'SubscriptionFrequency',
    components: {
      SimpleDropdown,
    },
    mixins: [CheckoutUserInfo],
    props: {
      frequencyOptions: {
        type: Array,
        default: () => [],
      },
      selectedFrequencyOption: {
        type: String,
        default: null,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        frequencyNotAvailable: [{
          name: this.$t('checkout.summary.notAvailable'),
          value: 'value',
        }],
      };
    },
    computed: {
      existingFrequencyOptions() {
        return !!this.frequencyOptions.length;
      },
      disabledFrequencyMenu() {
        return (this.isMobile || this.insideModal) &&
          (!this.isAddressLoading && !this.isLoading &&
            !this.existingFrequencyOptions && this.frequencyRequired);
      },
      availableFrequencyOptions() {
        return !!this.existingFrequencyOptions
          ? this.frequencyOptions.map(f => this.formattedFrequency(f))
          : this.frequencyNotAvailable;
      },
    },
    methods: {
      onFrequencyChange(value) {
        const frequency = getFullValue(this.frequencyOptions, value) || value;

        this.$emit('save-frequency-option', frequency);
      },
      formattedFrequency(frequency) {
        const f = frequency.match(/P(?<nb>[0-9]+)(?<period>[MW])/).groups;
        const name = f.period === 'W' ? this.$tc('checkout.frequency.week', f.nb, { nb: f.nb }) : this.$tc('checkout.frequency.month', f.nb, { nb: f.nb });

        return { name, value: frequency };
      },
    },
  };
</script>

<style lang="scss" scoped>
.subscription-frequency {
  margin-top: 30px;

  &__title {
    margin: 0 0 rem(10px);
    font-weight: $bold;
    color: var(--tertiary-text);
  }
}
</style>
