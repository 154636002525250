import analyticsService from '@/services/analytics/analytics.service';
import { PAGE_VIEW } from '@/services/analytics/analytics.event';

const hasQueryParams = (route) => !!Object.keys(route.query || {}).length;

export const routerBeforeEach = (store) => (to, from, next) => {
  if (to.name !== from.name) {
    if (process.client && from.fullPath !== '/') {
      analyticsService.newEvent(PAGE_VIEW);
    }

    return !hasQueryParams(to) && hasQueryParams(from)
      ? next({ ...to, query: from.query })
      : next();
  }
};
