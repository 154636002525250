import fetch from 'cross-fetch';

import HttpHelper from '@/helpers/http/http.helper';

export class AcquireError extends Error {
  constructor({ key, message, error }) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.key = key || error;
  }
}

const fetchResponse = async(method, route, body, options = {}, router, httpInstance) => {
  const headers = await getHeaders(options.sessionId, options.siteId, options.contactId, httpInstance);

  const cleanedBody = {
    method,
    headers: headers,
    body: body ? JSON.stringify(body) : null,
  };

  if (!body) {
    delete cleanedBody.body;
  }

  return await fetch(route, cleanedBody).then(async response => {
    if (response.ok) {
      if (response.status === 204) {
        return {}; // Empty object if we have no content
      }

      return await response.json();
    } else {
      const statusCode = response.status;

      if (statusCode === 403) {
        await router.push({ name: '' }); // redirect to the checkout link home
        throw new AcquireError({ key: 403, message: '403 ' + route });
      }

      if (statusCode === 404) {
        console.log(route);
        console.log(cleanedBody);
        console.log(response);
        await router.replace({ name: 'NotFound' });

        if (options?.trigger404Error !== false) {
          throw new AcquireError({ key: 404, message: '404 ' + route });
        }

        return;
      }

      let errorResponse = await response.json();
      errorResponse = errorResponse.errors
        ? { ...errorResponse.errors, status: errorResponse.statusCode }
        : errorResponse;
      errorResponse =
        errorResponse.error && !errorResponse.message
          ? { ...errorResponse, message: errorResponse.error }
          : errorResponse;

      throw new AcquireError(errorResponse);
    }
  });
};

const getHeaders = async(sessionId, siteId, acquireContactId, httpInstance) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json', // eslint-disable-line quote-props
    'x-acquire-site-session-id': sessionId || '',
    'x-acquire-site-id': siteId || '',
    'x-acquire-contact-id': acquireContactId || '',
    'x-referer': httpInstance.referer || (process.client ? document.referrer : ''),
    'x-user-agent': httpInstance.userAgent || (process.client ? navigator.userAgent : ''),
    ...((!process.client && { 'Accept-Language': httpInstance.acceptsLanguages }) || {}),
  };

  if (httpInstance.host) {
    return {
      ...defaultHeaders,
      Referer: httpInstance.host,
      'x-client-ip': httpInstance.ip,
    };
  }

  return defaultHeaders;
};

class Http {
  getSessionId
  getSiteId
  getContactId
  router

  constructor(getSessionId, getSiteId, getContactId, router, host, referer, userAgent, ip, acceptsLanguages) {
    this.getSessionId = getSessionId;
    this.getSiteId = getSiteId;
    this.getContactId = getContactId;
    this.router = router;
    this.host = host;
    this.referer = referer;
    this.userAgent = userAgent;
    this.ip = ip;
    this.acceptsLanguages = acceptsLanguages;
  }

  async del(route) { return fetchResponse(HttpHelper.METHOD_DELETE, route, null, { sessionId: this.getSessionId(), siteId: this.getSiteId(), contactId: this.getContactId() }, this.router, this); }
  async get(route, options) { return fetchResponse(HttpHelper.METHOD_GET, route, null, { ...options, sessionId: this.getSessionId(), siteId: this.getSiteId(), contactId: this.getContactId() }, this.router, this); }
  async post(route, body) { return fetchResponse(HttpHelper.METHOD_POST, route, body, { sessionId: this.getSessionId(), siteId: this.getSiteId(), contactId: this.getContactId() }, this.router, this); }
  async put(route, body) { return fetchResponse(HttpHelper.METHOD_PUT, route, body, { sessionId: this.getSessionId(), siteId: this.getSiteId(), contactId: this.getContactId() }, this.router, this); }
}

let httpInstance = new Http(() => null, () => null);

export const initHttpInstance = function(getSessionId, getSiteId, getContactId, router, host = null, referer = null, userAgent = null, ip = null, acceptsLanguages = '') {
  httpInstance = new Http(getSessionId, getSiteId, getContactId, router, host, referer, userAgent, ip, acceptsLanguages);
};

// del because "delete" is a reserved keyword
const del = async(route) => httpInstance.del(route);
const get = async(route, options) => httpInstance.get(route, options);
const post = async(route, body) => httpInstance.post(route, body);
const put = async(route, body) => httpInstance.put(route, body);

export default {
  delete: del,
  get,
  post,
  put,
  fetchResponse,
  getHeaders,
};
