<template>
  <div :class="classes">
    <CheckoutPrice
      v-show="showDiscountedAmount"
      class="discountable-price__original"
      :amount="amount"
      :currency="currency"
      variant="subdued" />
    <CheckoutPrice
      v-if="isFlat"
      class="discountable-price__total"
      :amount="amountPrice"
      :main-text-color="mainTextColor"
      :currency="currency" />
    <AppTag
      v-else
      class="discountable-price__total"
      :main-text-color="mainTextColor"
      :variant="variant">
      <CheckoutPrice
        :main-text-color="mainTextColor"
        :amount="amountPrice"
        :currency="currency" />
    </AppTag>
  </div>
</template>

<script>
  import CheckoutPrice from './CheckoutPrice';
  import AppTag from '@/checkout-link/components/app/app-tag/AppTag';

  export default {
    name: 'CheckoutDiscountablePrice',
    components: {
      AppTag,
      CheckoutPrice,
    },
    props: {
      amount: {
        type: Number,
        required: true,
      },
      currency: {
        type: String,
        required: true,
      },
      discountedAmount: {
        type: Number,
        default: undefined,
      },
      variant: {
        type: String,
        default: 'small',
      },
      mainTextColor: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      classes() {
        return `discountable-price discountable-price--${this.variant}`;
      },
      isFlat() {
        return this.variant === 'flat';
      },
      showDiscountedAmount() {
        return this.discountedAmount < this.amount;
      },
      amountPrice() {
        return this.discountedAmount >= 0 ? this.discountedAmount : this.amount;
      },
    },
  };
</script>

<style scoped lang="scss">

  .discountable-price {
    display: flex;
    align-items: baseline;

    &__original {
      @include heading();
      margin-right: rem(10px);
      text-decoration: line-through;
    }

    &--small {
      .discountable-price {
        &__original {
          @include body-medium();
        }
      }
    }

    &--flat {
      .discountable-price {
        &__original {
          padding-top: 0;

          @include body-medium();
          color: var(--tertiary-text);
        }

        &__total {
          margin-left: 5px;
        }
      }
    }
  }
</style>
