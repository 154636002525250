import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';

import { stripHtml } from 'string-strip-html';

import { checkoutLinkOriginEnvironment } from '@modules/acquire.module';
import checkoutLinkService from '@/services/checkout-link/checkout-link.service';
import pluginsService from '@/services/plugins/plugins.service';
import { PAGE_VIEW } from '@/services/analytics/analytics.event';

import analyticsTools from '@/plugins/analyticsTools';
import {
  getDiscountedPrice, getImages,
  getSelectedVariant, isActiveStatus, checkoutLinkStatus,
  isNotOpenedYet, setTimezone, isOver, subscriptionStatus, verifyValidity,
  areShippingMethodsIdentical,
} from '@/helpers/checkout-link/checkout-link.helper';
import httpHelper from '@/helpers/http/http.helper';
import { getCookieSessionExpire } from '@/helpers/session/session.helper';
import { getFavicon } from '@/checkout-link/favicons/favicon.utils';
import cloneDeep from 'lodash/cloneDeep';
import { TemplateHelper } from '@/helpers/checkout-link/checkout-link.template.helper';

export const emptyCurrent = {
  error: null,
  isLoading: false,
  isLoaded: false,
  value: null,
  productIds: null,
  productIdsSubscriptionOnly: null,
  productIdsSubscriptionHybrid: null,
  id: null,
  templateWithProducts: '',
  dynamicProductLayout: '',
};

const state = () => ({
  current: { ...emptyCurrent },
  selectedVariant: null,
  exceptionPage: {
    status: null,
    message: null,
    startDate: null,
    endDate: null,
  },
  isPreRegistration: false,
  uploadHeaders: null,
  uploadUrl: null,
  queryParams: {},
  cookieExpirationDate: null,
});

const actions = {
  async load({ dispatch, getters }, { customLink, router }) {
    if (getters.getCheckoutLinkLoading) {
      return;
    }

    await dispatch('loadCheckoutLink', {
      customLink,
      router,
    });
  },
  async loadCheckoutLink({ commit, dispatch, rootGetters, getters }, { customLink, router }) {
    commit('LOAD_CHECKOUT_LINK');
    dispatch('FunnelModule/clearFunnel', null, { root: true });

    try {
      const queryParams = router.currentRoute.query;

      if (!isEmpty(queryParams)) {
        // Replace space by "+"
        // because router replace query parameter "+" by spaces
        // Backend need to be able to find users with email/phone that contain a "+"
        for (const [key, value] of Object.entries(queryParams)) {
          if (key === 'email' || key === 'phone' || key === 'phoneNumber') {
            queryParams[key] = value.replace(/ /g, '+');
          }
        }
      }

      commit('SET_QUERY_PARAMS', queryParams);

      const technicalParams = httpHelper.buildSessionAndInitCheckoutLinkParameters(
        rootGetters['SessionModule/fullUrl'],
        null,
        rootGetters['SessionModule/ipAddress'],
      );

      const data = await checkoutLinkService.loadCheckoutLink(customLink, { ...getters.getQueryParams, ...technicalParams });
      const { standaloneCheckout: checkoutLink = false } = data || {};

      dispatch('SsrDebugModule/push', { title: 'INIT CHECKOUT LINK', content: data }, { root: true });

      if (!data || !checkoutLink) {
        commit('LOAD_CHECKOUT_LINK_FAILURE', null);
        return;
      }

      if (checkoutLink.version !== 2) {
        await router.replace('/404');
        return;
      }

      commit('SET_ID', checkoutLink.id); // need to store id to get the value in load session

      if (getters.getQueryParams.productids) {
        const productIds = getters.getQueryParams?.productids?.split(',').filter(p => p) || [];

        commit('SET_PRODUCT_IDS', productIds);

        if (getters.getQueryParams.subscription_hybrid) {
          const productsSubscriptionHybrid = getters.getQueryParams?.subscription_hybrid?.split(',').filter(p => p) || [];
          commit('SET_PRODUCT_IDS_SUBSCRIPTION_HYBRID', productsSubscriptionHybrid);
        }

        if (getters.getQueryParams.subscription_only) {
          const productsSubscriptionOnly = getters.getQueryParams?.subscription_only?.split(',').filter(p => p) || [];
          commit('SET_PRODUCT_IDS_SUBSCRIPTION_ONLY', productsSubscriptionOnly);
        }
      }

      dispatch('CartModule/setCheckoutType', checkoutLinkOriginEnvironment.apiTypeProperty, { root: true });

      await dispatch('SessionModule/setSiteId', checkoutLink.siteId, { root: true });

      const session = await dispatch('SessionModule/initSession', data, { root: true });

      dispatch('ThemeModule/setTheme', checkoutLink.theme, { root: true });

      const status = checkoutLink.status;
      const message = checkoutLink.message;
      commit('SET_EXCEPTION_PAGE', { status, message, checkoutLink });

      commit('SET_CHECKOUT_LINK', checkoutLink);

      if (!process.client) {
        await dispatch('injectCustomCode', data);
        await dispatch('generateMetaTags', checkoutLink);

        dispatch('setDynamicProductLayout', checkoutLink.dynamicProductLayout);
        await dispatch('generateTemplateWithProducts', checkoutLink);
      }

      // CANCELLED Exception
      if (checkoutLink.subscriptionStatus === subscriptionStatus.CANCELLED) {
        commit('SET_EXCEPTION_PAGE', { status: checkoutLinkStatus.CANCELLED, message, checkoutLink });

        await router.replace({
          name: 'Exception',
          params: { ...router.currentRoute.params },
        });

        commit('LOAD_CHECKOUT_LINK_SUCCESS', checkoutLink);

        return;
      }

      // NOT_OPENED page
      if (getters.isNotOpenedYet(checkoutLink)) {
        commit('SET_PREREGISTRATION', true);

        if (router.currentRoute?.name !== 'NotOpened') {
          await router.replace({
            name: 'NotOpened',
            params: { ...router.currentRoute.params },
          });
        }

        if (!isEmpty(getters.getQueryParams)) {
          await dispatch('ContactModule/loadPartialContact', getters.getQueryParams, { root: true });
        }

        commit('LOAD_CHECKOUT_LINK_SUCCESS', checkoutLink);

        return;
      }

      // CLOSED Exception
      if (!isActiveStatus(status) || getters.isOver(checkoutLink)) {
        if (status === checkoutLinkStatus.DRAFT) {
          await router.replace('/404');
        } else if (router.currentRoute?.name !== 'Exception') {
          await router.replace({
            name: 'Exception',
            params: { ...router.currentRoute.params },
          });
        }

        commit('LOAD_CHECKOUT_LINK_SUCCESS', checkoutLink);

        return;
      }

      // SESSION_EXPIRED Exception
      const duration = checkoutLink.campaign.maxSessionDurationPerUser;
      const cookie = rootGetters['SessionModule/sessionDuration'];

      if (!duration && cookie) { // old cookie but no duration anymore
        const expiration = getCookieSessionExpire(0);
        const newCookie = `acquireSessionDuration-${checkoutLink.customLink}=; path=/; SameSite=None; expires=${expiration}; Secure`;
        dispatch('SessionModule/addCookie', newCookie, { root: true });
      } else if (duration && !cookie) { // duration but no cookie yet
        const time = getCookieSessionExpire(duration); // GMT
        const expiration = checkoutLink.campaign.endDate
          ? setTimezone(checkoutLink.campaign.endDate, rootGetters['SessionModule/timezone']).toUTC()
          : getCookieSessionExpire(duration * 1000);

        commit('SET_COOKIE_EXPIRATION_DATE', DateTime.fromISO(new Date(time).toISOString()));

        const newCookie = `acquireSessionDuration-${checkoutLink.customLink}=${time}; path=/; SameSite=None; expires=${expiration}; Secure`;
        dispatch('SessionModule/addCookie', newCookie, { root: true });
      } else if (duration && cookie) { // duration and cookie found
        const now = DateTime.local();
        const expirationDate = DateTime.fromISO(new Date(cookie).toISOString());

        if (expirationDate < now) {
          // disable the exception for now, clients prefer that we restart the counter
          // dispatch('setExceptionExpired', { router, checkoutLink });

          // to restart the counter at the end of the session, re create the cookie with inital values
          const time = getCookieSessionExpire(duration); // GMT
          const expiration = checkoutLink.campaign.endDate
            ? setTimezone(checkoutLink.campaign.endDate, rootGetters['SessionModule/timezone']).toUTC()
            : getCookieSessionExpire(duration * 1000);

          commit('SET_COOKIE_EXPIRATION_DATE', DateTime.fromISO(new Date(time).toISOString()));

          const newCookie = `acquireSessionDuration-${checkoutLink.customLink}=${time}; path=/; SameSite=None; expires=${expiration}; Secure`;
          dispatch('SessionModule/addCookie', newCookie, { root: true });
        } else {
          commit('SET_COOKIE_EXPIRATION_DATE', expirationDate);
        }
      }

      // OUT_OF_STOCK Exception
      if (getters.isInvalid(checkoutLink) || getters.isNotAvailable(checkoutLink)) {
        commit('SET_EXCEPTION_PAGE', { status: checkoutLinkStatus.OUT_OF_STOCK, message, checkoutLink });

        if (router.currentRoute.name !== 'Exception') {
          await router.replace({
            name: 'Exception',
            params: { ...router.currentRoute.params },
          });
        }

        commit('LOAD_CHECKOUT_LINK_SUCCESS', checkoutLink);

        return;
      }

      if (getters.getQueryParams['abandoned-cart-id'] || getters.getQueryParams['cart-info']) {
        const abandonedCartInfos = {
          acquire: getters.getQueryParams['abandoned-cart-id'] ?? null,
          shopify: getters.getQueryParams['cart-info'] ?? null,
        };

        await dispatch('CartModule/loadCart', abandonedCartInfos, { root: true });
      } else if (session && session.cartId) {
        // try to load an existing cart from session
        await dispatch('CartModule/loadCart', null, { root: true });
      }

      const existingCartItems = rootGetters['CartModule/getItems'];
      const addToCartProducts = checkoutLink?.products.map(product => {
        const productInCart = !!existingCartItems ? existingCartItems.find(item => item.variant.id === (product.preferredVariantId || product.variants[0])) : [];
        const sufficientQty = !!productInCart && (productInCart.quantity >= (product?.quantity?.min || 0));
        /* Do not prefill cart if:
         - no min quantity is defined,
         - the product has more than 1 variant,
         - the product is already in cart in sufficient quantity */
        if (!product?.quantity?.min || product?.variants?.length > 1 || sufficientQty) {
          return null;
        }

        return {
          product,
          purchaseOption: product?.purchaseOptions[0] === 'SUBSCRIPTION' ? 'SUBSCRIPTION' : 'ONE_TIME',
          variant: product.variants.find(v => v.id === product.preferredVariantId) || product.variants[0],
        };
      }).filter(product => !!product);

      if (!!addToCartProducts.length) {
        await dispatch('addVariant', { items: addToCartProducts, router });
      }

      analyticsTools.updateGtmVar(
        session?.contact?._id,
        session?.contact?.email,
        session?.contact?.firstname,
        session?.contact?.lastname,
      );

      if (!isEmpty(getters.getQueryParams)) {
        await dispatch('ContactModule/loadPartialContact', getters.getQueryParams, { root: true });
      }

      commit('LOAD_CHECKOUT_LINK_SUCCESS', checkoutLink);

      return checkoutLink;
    } catch (error) {
      commit('LOAD_CHECKOUT_LINK_FAILURE', error);

      throw error;
    }
  },
  loadPlugins({ rootGetters }) {
    // Write plugins config as global var (document header)
    pluginsService.exposePluginsConfigInWindow(
      rootGetters['SessionModule/settings'],
      {
        apiEndpoint: httpHelper.buildV1ApiRoute(''),
        siteId: rootGetters['SessionModule/siteId'],
        currency: rootGetters['SessionModule/siteCurrency'],
      },
    );

    // Load all plugins
    pluginsService.loadPlugins(rootGetters['SessionModule/settings']);
  },
  dispatchViewEvent({ rootGetters, dispatch }) {
    dispatch('AnalyticsModule/addCheckoutEvent', PAGE_VIEW, { root: true });
  },
  async generateMetaTags({ dispatch, commit, getters, rootGetters }, checkoutLink) {
    const products = checkoutLink.products;
    const DEFAULT_IMAGE_INFORMATION = { width: 240, height: 240, type: 'png', mime: 'image/png', url: 'https://cdn.acquire.app/logo/logo_240x240.png' };
    const favIconUrl = await getFavicon(rootGetters['SessionModule/siteUrl']);

    // set default values - single product
    let title = rootGetters['SessionModule/siteName'] + (products[0]?.name ? ' | ' + products[0]?.name : '');
    let description = stripHtml(products[0]?.description || '').result.slice(0, 200);
    let imageUrl = products[0]?.imageUrl || '';
    let imageInformation = null;

    if (products.length > 1) {
      title = rootGetters['SessionModule/siteName'];
      description = products.map(product => product?.name).join(', ') || '';

      imageUrl = rootGetters['SessionModule/siteLogo'];
    }

    // Get images size
    if (imageUrl) {
      const probe = require('probe-image-size');
      try {
        imageInformation = await probe(imageUrl);
      } catch (e) {
        console.error(imageUrl, e);
      }
    }

    if (!imageInformation || imageInformation.width < 240 || imageInformation.height < 240) {
      imageInformation = DEFAULT_IMAGE_INFORMATION;
    }

    const tags = `<meta name="twitter:card" content="summary">
    <meta name="twitter:site" content="@acquire_app" />
    <meta name="twitter:creator" content="@acquire_app" />
    <meta name="pinterest-rich-pin" content="false" />
    <meta property="og:title" content="${title}" />
    <meta property="og:description" content="${description}" />
    <meta property="og:type" content="article" />
    <meta property="og:og:site_name" content="Acquire.app" />
    <meta property="og:url" content="${checkoutLink.campaign.url}" />
    <meta property="og:image" content="${imageInformation.url}" />
    <meta property="og:image:secure_url" content="${imageInformation.url}" />
    <meta property="og:image:type" content="${imageInformation.mime}" />
    <meta property="og:image:width" content="${imageInformation.width}" />
    <meta property="og:image:height" content="${imageInformation.height}" />`;

    dispatch('DocumentHeaderModule/setTitle', title, { root: true });
    dispatch('DocumentHeaderModule/setDescription', description, { root: true });
    dispatch('DocumentHeaderModule/setFavicon', favIconUrl, { root: true });
    dispatch('DocumentHeaderModule/setOpenGraphTags', tags, { root: true });
  },
  async injectCustomCode({ dispatch, commit, getters, rootGetters }, data) {
    const siteCustomCode = data.settings?.customCode;
    const checkoutLinkCustomCode = data.standaloneCheckout.settings?.customCode;

    dispatch('DocumentHeaderModule/setCustomCode', { siteCustomCode, checkoutLinkCustomCode }, { root: true });
  },
  setSelectedVariant({ commit, dispatch, getters }, { variant, router }) {
    if (variant) {
      const product = getters.getCheckoutLinkProduct;

      // do not add automatically the variant in the cart if single product and has variants
      if (getters.hasOneProduct && !getters.hasOneVariant) {
        return;
      }

      commit('SET_SELECTED_VARIANT', variant);

      dispatch('addVariant', { items: [{ variant, product }], router });
    }
  },
  async addVariant({ dispatch, getters, rootGetters }, { items, router, buyNow }) {
    if ((!items[0]?.variant && !items?.variant) || (!items[0]?.product && !items?.product)) {
      return;
    } else if (!Array.isArray(items)) {
      items = [items];
    } else if (items.every(i => !verifyValidity(i.product?.quantity?.min, i.variant?.inventory))) {
      router.currentRoute.name !== 'Exception' && await router.replace({
        name: 'Exception',
        params: { ...router.currentRoute.params },
      });

      return;
    }

    const formatItems = items.map(item => {
      const hasMinQuantityAndVariants = (item.product?.quantity && item.product?.quantity?.min !== 0) && item.product.variants.length < 2;
      const quantity = hasMinQuantityAndVariants ? item.product?.quantity?.min : 1;

      return {
        ...item.variant,
        variantId: item.variant.id,
        label: item.product?.name || '',
        productId: item.product.productId,
        quantity,
        quantityPolicy: item.product?.quantity,
        imageUrl: item.variant?.imageUrl || item.product?.imageUrl || (item.product.images && item.product.images[0]) || '',
        purchaseOption: item.purchaseOption,
        source: item.product.source,
        // Add a property customFields for custom fields values if product has custom fields
        ...((!!item.customFieldsValues && { customFieldsValues: item.customFieldsValues }) || {}),
      };
    });

    // Deep copy of available shipping options before cart is updated, in case we need to compare them with new ones for a "buyNow".
    const existingShippingMethods = cloneDeep(rootGetters['CartModule/shippingOptions']);

    await dispatch('CartModule/addItem', formatItems, { root: true });

    if (buyNow) {
      // Deep copy of new available shipping options after cart has been updated.
      const newShippingMethods = cloneDeep(rootGetters['CartModule/shippingOptions']);
      const identicalShippingMethods = areShippingMethodsIdentical(existingShippingMethods, newShippingMethods);

      await dispatch('CartModule/buyNow', { router, identicalShippingMethods }, { root: true });
    }
  },
  // MAX_ORDER_EXCEED Exception
  async setExceptionMaxOrder({ commit, getters }, router) {
    const checkoutLink = getters.getCheckoutLink;

    commit('SET_EXCEPTION_PAGE', { status: checkoutLinkStatus.MAX_ORDER_EXCEED, message: null, checkoutLink });

    if (router.currentRoute.name !== 'Exception') {
      await router.replace({
        name: 'Exception',
        params: { ...router.currentRoute.params },
      });
    }
  },
  async setExceptionExpired({ commit, getters }, { router, checkoutLink }) {
    const checkoutLinkToUse = checkoutLink || getters.getCheckoutLink;

    commit('SET_EXCEPTION_PAGE', { status: checkoutLinkStatus.INACTIVE, message: null, checkoutLink: checkoutLinkToUse });

    if (router.currentRoute.name !== 'Exception') {
      await router.replace({
        name: 'Exception',
        params: { ...router.currentRoute.params },
      });
    }
  },
  setSettings({ commit }, settings) {
    commit('SET_SETTINGS', settings);
  },
  setCampaign({ commit }, campaign) {
    commit('SET_CAMPAIGN', campaign);
  },
  setPreRegistrationState({ commit }, isPreRegistration) {
    commit('SET_PREREGISTRATION', isPreRegistration);
  },
  async generateTemplateWithProducts({ commit, getters, rootGetters }, checkoutLink) {
    const template = new TemplateHelper(checkoutLink.template, getters.getCheckoutLinkSettings, {
      lang: rootGetters['SessionModule/acceptsLanguages'],
      country: rootGetters['SessionModule/countryCode'],
      currency: rootGetters['SessionModule/siteCurrency'],
    },
    getters.getMappedProducts,
    getters.getMappedDynamicProducts,
    getters.getDynamicProductLayout,
    rootGetters['ThemeModule/pageColor'],
    true);

    const html = await template.generateTemplate();

    commit('SET_TEMPLATE_WITH_PRODUCTS', html);
  },
  setDynamicProductLayout({ commit }, layout) {
    commit('SET_DYNAMIC_PRODUCT_LAYOUT', layout);
  },
};

const mutations = {
  LOAD_CHECKOUT_LINK(state) {
    state.current.isLoading = true;
    state.current.isLoaded = false;
    state.current.error = null;
  },
  LOAD_CHECKOUT_LINK_SUCCESS(state, checkoutLink) {
    state.current.isLoading = false;
    state.current.isLoaded = true;
    state.current.error = null;
    state.current.value = {
      ...checkoutLink,
      products: checkoutLink.products.map(cp => ({ ...cp, source: 'CHECKOUTLINK' })), // Product context needed
    };
  },
  LOAD_CHECKOUT_LINK_FAILURE(state, error) {
    state.current.isLoading = false;
    state.current.isLoaded = true;
    state.current.error = error;
  },
  SET_OTHER_DISCOUNTS(state) {
    state.otherDiscount.status.isLoading = true;
    state.otherDiscount.status.error = null;
  },
  SET_OTHER_DISCOUNTS_SUCCESS(state, products) {
    state.otherDiscount.status.isLoading = false;
    state.otherDiscount.status.error = null;
    state.otherDiscount.values = products;
  },
  SET_OTHER_DISCOUNTS_FAILURE(state, error) {
    state.otherDiscount.status.isLoading = false;
    state.otherDiscount.status.error = error;
    state.otherDiscount.values = [];
  },
  SET_SELECTED_VARIANT(state, variant) {
    state.selectedVariant = variant;
  },
  SET_EXCEPTION_PAGE(state, { status, message, checkoutLink }) {
    state.exceptionPage = {
      status,
      message,
      startDate: checkoutLink.startDate,
      endDate: checkoutLink.endDate,
    };
  },
  SET_PRODUCT_IDS(state, productIds) {
    state.current.productIds = productIds;
  },
  SET_PRODUCT_IDS_SUBSCRIPTION_HYBRID(state, productIds) {
    state.current.productIdsSubscriptionHybrid = productIds;
  },
  SET_PRODUCT_IDS_SUBSCRIPTION_ONLY(state, productIds) {
    state.current.productIdsSubscriptionOnly = productIds;
  },
  SET_ID(state, id) {
    state.current.id = id;
  },
  SET_QUERY_PARAMS(state, queryParams) {
    state.queryParams = queryParams;
  },
  SET_CHECKOUT_LINK(state, checkoutLink) {
    state.current.value = checkoutLink;
  },
  SET_UPLOAD_HEADERS(state, uploadHeaders) {
    state.uploadHeaders = uploadHeaders;
  },
  SET_UPLOAD_URL(state, uploadUrl) {
    state.uploadUrl = uploadUrl;
  },
  SET_SETTINGS(state, settings) {
    const current = state.current || {};

    state.current = {
      ...current,
      value: {
        ...current.value,
        settings,
      },
    };
  },
  SET_CAMPAIGN(state, campaign) {
    const current = state.current || {};

    state.current = {
      ...current,
      value: {
        ...current.value,
        campaign,
      },
    };
  },
  SET_COOKIE_EXPIRATION_DATE(state, date) {
    state.cookieExpirationDate = date;
  },
  SET_PREREGISTRATION(state, value) {
    state.isPreRegistration = value;
  },
  CLEAR_CURRENT_CHECKOUT_LINK(state) {
    state.current = { ...emptyCurrent };
  },
  SET_TEMPLATE_WITH_PRODUCTS(state, html) {
    state.current.templateWithProducts = html;
  },
  SET_DYNAMIC_PRODUCT_LAYOUT(state, layout) {
    state.current.dynamicProductLayout = layout;
  },
};

const getters = {
  getOtherDiscountsState: state => state?.otherDiscount,
  getCheckoutLinkState: state => state?.current,

  getCheckoutLink: (_, { getCheckoutLinkState }) => getCheckoutLinkState?.value || null,
  getCheckoutLinkLoading: (_, { getCheckoutLinkState }) => getCheckoutLinkState?.isLoading,
  getCheckoutLinkLoaded: (_, { getCheckoutLinkState }) => getCheckoutLinkState?.isLoaded,
  getCheckoutLinkError: (_, { getCheckoutLinkState }) => getCheckoutLinkState?.error,
  getCheckoutLinkIncentive: (_, { getCheckoutLink }) => getCheckoutLink?.incentive,

  getCheckoutLinkCampaign: (_, { getCheckoutLink }) => getCheckoutLink?.campaign,
  getCheckoutLinkStartDate: (_, { getCheckoutLinkCampaign }, __, rootGetters) => getCheckoutLinkCampaign?.startDate
    ? setTimezone(getCheckoutLinkCampaign?.startDate, rootGetters['SessionModule/timezone'])
    : null,
  getCheckoutLinkEndDate: (_, { getCheckoutLinkCampaign }, __, rootGetters) => getCheckoutLinkCampaign?.endDate
    ? setTimezone(getCheckoutLinkCampaign?.endDate, rootGetters['SessionModule/timezone'])
    : null,

  getCheckoutLinkProducts: (_, { getCheckoutLink }) => getCheckoutLink?.products || [],
  getCheckoutLinkDynamicProducts: (_, { getCheckoutLink }) => getCheckoutLink?.dynamicProducts || [],
  getCheckoutLinkOfferedProducts: (_, { getCheckoutLink }) => getCheckoutLink?.offeredProducts || [],
  getCheckoutLinkProductsLength: (_, { getCheckoutLinkProducts }) => getCheckoutLinkProducts?.length || 0,
  hasProducts: (_, { getCheckoutLinkProductsLength }) => getCheckoutLinkProductsLength > 0,
  hasOneProduct: (_, { getCheckoutLinkProductsLength }) => getCheckoutLinkProductsLength === 1,

  getCheckoutLinkProduct: (_, { getCheckoutLinkProducts }) => (getCheckoutLinkProducts?.length && getCheckoutLinkProducts[0]) || null,
  hasOneVariant: (_, { getCheckoutLinkProduct }) => getCheckoutLinkProduct?.variants.length === 1,
  getMappedProduct: (_, { getCheckoutLinkProduct, getCheckoutLinkIncentive, productMapping }) => productMapping(getCheckoutLinkProduct, getCheckoutLinkIncentive),

  getMappedProducts: (_, { getCheckoutLinkProducts, productMapping, getCheckoutLinkIncentive }) =>
    getCheckoutLinkProducts.map(p => productMapping(p, getCheckoutLinkIncentive)),

  getMappedDynamicProducts: (_, { getCheckoutLinkDynamicProducts, productMapping, getCheckoutLinkIncentive }) =>
    getCheckoutLinkDynamicProducts.map(p => productMapping(p, getCheckoutLinkIncentive)),
  getMappedOfferedProducts: (_, { getCheckoutLinkOfferedProducts, productMapping, getCheckoutLinkIncentive }) =>
    getCheckoutLinkOfferedProducts.map(p => productMapping(p, getCheckoutLinkIncentive)),

  getTemplateWithProducts: (_, { getCheckoutLinkState }) => getCheckoutLinkState?.templateWithProducts,
  getDynamicProductLayout: (_, { getCheckoutLinkState }) => getCheckoutLinkState?.dynamicProductLayout || '',

  productMapping: (_, __, ___, rootGetters) => (product, incentive) => {
    if (!product) {
      return {};
    }

    const selectedVariant = getSelectedVariant(product, product.preferredVariantId);

    return {
      ...product,
      price: selectedVariant?.price || 0,
      discountedPrice: getDiscountedPrice(selectedVariant, incentive),
      selectedVariant: selectedVariant,
      images: getImages(product),
      label: selectedVariant?.productAndVariantName || '',
      currency: product?.currency || rootGetters['SessionModule/siteCurrency'],
      description: product?.descriptionHtml || '',
    };
  },

  hasCheckoutLink: (_, { getCheckoutLink }) => !!getCheckoutLink,
  hasCheckoutLinkIncentive: (_, { getCheckoutLinkIncentive }) => !!getCheckoutLinkIncentive && (getCheckoutLinkIncentive?.amount > 0 || getCheckoutLinkIncentive?.mode === 'BRACKETS'),
  getCheckoutLinkSettings: (_, { getCheckoutLink }) => getCheckoutLink?.settings,
  getCheckoutLinkMaxOrder: (_, { getCheckoutLinkSettings }) => getCheckoutLinkSettings?.maxOrderPerUser,
  getCheckoutLinkMaxOrderAmount: (_, { getCheckoutLinkMaxOrder }) => getCheckoutLinkMaxOrder?.amount || null,
  hasCheckoutLinkMaxOrder: (_, { getCheckoutLinkMaxOrderAmount }) => !!getCheckoutLinkMaxOrderAmount,
  getCheckoutLinkCountDown: (_, { getCheckoutLinkSettings }) => getCheckoutLinkSettings?.showCountDown || false,
  getCheckoutLinkOrdersCount: (_, { getCheckoutLink }) => getCheckoutLink?.orderCount || 0,
  getCheckoutLinkFrequencies: (_, { getCheckoutLink }) => getCheckoutLink?.subscription?.frequencies || [],
  allowCheckoutLinkCoupons: (_, { getCheckoutLinkSettings }) => !!getCheckoutLinkSettings?.allowCoupons || false,
  canAddCoupons: (_, { allowCheckoutLinkCoupons, hasCheckoutLinkIncentive }) => allowCheckoutLinkCoupons && !hasCheckoutLinkIncentive,
  warningMessage: (_, { getCheckoutLinkSettings }) => getCheckoutLinkSettings?.warningMessage,
  priceHidden: (_, { getCheckoutLinkSettings }) => getCheckoutLinkSettings?.hideProductPrice || false,
  descriptionHidden: (_, { getCheckoutLinkSettings }) => getCheckoutLinkSettings?.hideProductDescription || false,
  getQueryParams: state => state?.queryParams || {},

  preRegistrationTitle: (_, { getCheckoutLinkSettings }) => getCheckoutLinkSettings.preRegistrationTitle,
  preRegistrationSubTitle: (_, { getCheckoutLinkSettings }) => getCheckoutLinkSettings.preRegistrationSubTitle,
  preRegistrationContent: (_, { getCheckoutLinkSettings }) => getCheckoutLinkSettings.preRegistrationContent,
  preRegistrationCreditCardRequired: (_, { getCheckoutLinkSettings }) => getCheckoutLinkSettings.preRegistrationCreditCardRequired,

  productIds: (_, { getCheckoutLinkState }) => getCheckoutLinkState?.productIds,
  productIdsSubscriptionOnly: (_, { getCheckoutLinkState }) => getCheckoutLinkState?.productIdsSubscriptionOnly,
  productIdsSubscriptionHybrid: (_, { getCheckoutLinkState }) => getCheckoutLinkState?.productIdsSubscriptionHybrid,
  getId: (_, { getCheckoutLinkState }) => getCheckoutLinkState?.id,
  getCookieExpirationDate: (state) => state.cookieExpirationDate ? DateTime.fromISO(state.cookieExpirationDate) : null,

  getExceptionPage: state => state?.exceptionPage || null,
  getExceptionPageStatus: (_, { getExceptionPage }) => getExceptionPage?.status || '',
  getExceptionPageStatusCode: (_, { getExceptionPageStatus, getCheckoutLinkEndDate }) => isOver(getCheckoutLinkEndDate) ? checkoutLinkStatus.INACTIVE : getExceptionPageStatus,
  getExceptionPageMessage: (_, { getExceptionPage, getExceptionPageStatusCode }) => getExceptionPage?.message || (getExceptionPageStatusCode ? `error.checkoutlink.${getExceptionPageStatusCode}` : ''),
  isNotOpenedYetException: (_, { getExceptionPageStatus, getCheckoutLinkStartDate }) => isActiveStatus(getExceptionPageStatus) && isNotOpenedYet(getCheckoutLinkStartDate),
  isOverException: (_, { getExceptionPageStatus, getCheckoutLinkEndDate }) => isActiveStatus(getExceptionPageStatus) && isOver(getCheckoutLinkEndDate),
  hasError: (_, { isNotOpenedYetException, isOverException, isActiveCheckoutLink }) => isNotOpenedYetException || isOverException || !isActiveCheckoutLink,

  isActiveCheckoutLink: (_, { getExceptionPageStatus }) => isActiveStatus(getExceptionPageStatus),
  isOver: (_, { getCheckoutLink }, __, rootGetters) => (checkoutLink = undefined, now = undefined) => isOver(setTimezone((checkoutLink || getCheckoutLink)?.campaign?.endDate, rootGetters['SessionModule/timezone']), now),
  isNotOpenedYet: (_, { getCheckoutLink }, __, rootGetters) => (checkoutLink = undefined, now = undefined) => isNotOpenedYet(setTimezone((checkoutLink || getCheckoutLink)?.campaign?.startDate, rootGetters['SessionModule/timezone'])),
  notInDates: (_, { isNotOpenedYet, isOver }) => (checkoutLink = undefined, now = undefined) => isNotOpenedYet(checkoutLink, now) || isOver(checkoutLink, now),
  isInvalid: (_, { getCheckoutLink }) => (checkoutLink = undefined) => (checkoutLink || getCheckoutLink)?.products.some(p => {
    const qtyMin = p?.quantity?.min;
    const qtyInventory = p?.variants[0]?.inventory?.quantity;
    const qtyTracked = p?.variants[0]?.inventory?.tracked;
    const outOfStockPolicy = p?.variants[0]?.inventory?.outOfStockPolicy;

    return (outOfStockPolicy === 'DENY' && qtyMin > qtyInventory && qtyTracked && qtyMin !== 0);
  }),
  isNotAvailable: (_, { getCheckoutLink }) => (checkoutLink = null) => !!(checkoutLink || getCheckoutLink)?.products?.length && (checkoutLink || getCheckoutLink)?.products.every(p => p?.variants.every(v => !v?.inventory?.available)),
  isNotAnExceptionCheckoutLink: (_, { notInDates, isInvalid, isNotAvailable, isActiveCheckoutLink }) => (now = undefined) => isActiveCheckoutLink && !notInDates(undefined, now) && !isInvalid() && !isNotAvailable(),
  isLoadedAndNotAnException: (_, { getCheckoutLinkLoading, getCheckoutLinkLoaded, hasCheckoutLink, isNotAnExceptionCheckoutLink }) => !getCheckoutLinkLoading && getCheckoutLinkLoaded && hasCheckoutLink && isNotAnExceptionCheckoutLink(),

  isPreRegistration: state => state.isPreRegistration,

  getUploadHeaders: state => state.uploadHeaders,
  getUploadUrl: state => state.uploadUrl,
};

export const CheckoutLinkModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
