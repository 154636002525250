/**
 * Returns the favicon of the site with "siteUrl" if
 * it can be found.
 *
 * @param {*} siteUrl
 */
export const getFavicon = async(siteUrl) => {
  const siteUrlWithoutProtocol = siteUrl
    .replace('https://', '')
    .replace('http://', '');
  return `https://proxy.sdwebstore.com/favicon/${siteUrlWithoutProtocol}`;
};
