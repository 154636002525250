<template>
  <div class="countdown">
    <template v-if="isOnCart">
      <span
        v-if="hasIcon"
        class="icon-hourglass" />
      <!-- TODO: create single translation with params to clean this up -->
      {{ remainDays }} {{ $tc('countDown.days', remainDays) }} · {{ remainHours }}:{{ remainMinutes }}:{{ remainSeconds }}
    </template>

    <template v-else>
      <div
        v-if="daysVisible"
        class="countdown__item"
        data-test-id="desktop-days">
        <span class="remain">{{ remainDays }}</span>
        <span class="label">{{ $tc('countDown.days', remain.days) }}</span>
      </div>
      <div class="countdown__item">
        <span class="remain">{{ remainHours }}</span>
        <span class="label">{{ $tc('countDown.hours', remain.hours) }}</span>
      </div>
      <div class="countdown__item">
        <span class="remain">{{ remainMinutes }}</span>
        <span class="label">{{ $tc('countDown.minutes', remain.minutes) }}</span>
      </div>
      <div
        v-if="secondsVisible"
        class="countdown__item"
        data-test-id="desktop-seconds">
        <span class="remain">{{ remainSeconds }}</span>
        <span class="label">{{ $tc('countDown.seconds', remain.seconds) }}</span>
      </div>
    </template>
  </div>
</template>

<script>
  import { DateTime } from 'luxon';

  export default {
    name: 'CountDown',
    props: {
      date: {
        type: Object,
        required: true,
      },
      isOnCart: {
        type: Boolean,
        default: false,
      },
      hasIcon: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        timer: null,
        now: DateTime.local(),
      };
    },
    computed: {
      daysVisible() {
        return +this.remainDays > 0;
      },
      secondsVisible() {
        return +this.remainDays === 0;
      },
      remainDays() {
        return this.getRemainDisplay(this.remain.days);
      },
      remainHours() {
        return this.getRemainDisplay(this.remain.hours);
      },
      remainMinutes() {
        return this.getRemainDisplay(this.remain.minutes);
      },
      remainSeconds() {
        return this.getRemainDisplay(this.remain.seconds);
      },
      remain() {
        const fullDiff = this.date.diff(this.now).toObject();

        if (fullDiff.milliseconds <= 0) {
          this.$emit('done');
          clearInterval(this.timer);

          return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          };
        }

        const diff = this.date.diff(this.now, ['days', 'hours', 'minutes', 'seconds']).toObject();

        return {
          days: Math.floor(diff.days),
          hours: Math.floor(diff.hours),
          minutes: Math.floor(diff.minutes),
          seconds: Math.floor(diff.seconds),
        };
      },
    },
    methods: {
      getRemainDisplay(remain) {
        return remain.toString().length === 1 ? `0${remain}` : remain.toString();
      },
    },
    created() {
      this.timer = setInterval(() => {
        this.now = DateTime.local();
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
  };
</script>

<style lang="scss" scoped>
  .countdown {
    display: flex;
    justify-content: center;
    align-items: center;

    &__item {
      display: flex;
      flex-direction: column;
      margin: 0 10px;

      .remain {
        width: 65px;
        padding: rem(15px) 0;
        border-radius: 4px;

        background-color: var(--main-color);
        color: var(--main-color-text);
        font-size: rem(32px);
        font-weight: bold;
        line-height: rem(24px);
        text-align: center;
      }

      .label {
        @include body-large();
        padding-top: rem(10px);

        color: var(--tertiary-text);
        text-align: center;
        text-transform: capitalize;
      }
    }
  }

  .icon-hourglass {
    height: 16px;
    width: 13px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('@assets/icons/hourglass.svg?inline');
  }

  @include breakpoint(small) {
    .countdown__item {
      .remain {
        width: 70px;
      }
    }
  }

</style>
