<template>
  <button
    type="button"
    :class="buttonClass"
    :disabled="disabled"
    @click="onClick">
    <font-awesome-icon
      v-if="iconPrefix"
      :icon="iconPrefix"
      class="button__icon-prefix"
      data-test-id="icon-prefix" />
    <slot />
    <font-awesome-icon
      v-if="iconSuffix"
      :icon="iconSuffix"
      class="button__icon-suffix"
      data-test-id="icon-suffix" />
  </button>
</template>

<script>
  export default {
    name: 'AppButton',
    props: {
      type: {
        type: String,
        default: 'primary',
        validator: function(value) {
          return ['primary', 'secondary', 'depressed', 'link', 'outlined', 'outlined-selected', 'text'].indexOf(value) !== -1;
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      iconPrefix: {
        type: String,
        default: null,
      },
      iconSuffix: {
        type: String,
        default: null,
      },
    },
    computed: {
      buttonClass() {
        return `button button--${this.type}`;
      },
    },
    methods: {
      onClick(event) {
        this.$emit('click', event);
      },
    },
  };
</script>

<style scoped lang="scss">
  .button {
    @include button();

    &:hover {
      box-shadow: var(--box-shadow-button);
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: default;
      background: var(--main-color);
      color: var(--main-color-text);
      opacity: 0.5;

      &:hover, &:focus {
        box-shadow: none;
      }
    }

    &--primary {
      border: none;
      border-radius: $radius-default-button;
      padding: rem(18px) rem(20px);
      text-transform: uppercase;
      width: 100%;
      background: var(--main-color);
      color: var(--main-color-text);
      font-weight: $bold;
      align-items: initial;

      &:focus {
        @include shadowAsBorder(var(--focus-shadow));
      }
    }

    &--secondary {
      background-color: var(--primary-text);
      border: none;
      border-radius: $radius-secondary-button;
      color: var(--card-background);
      padding: rem(12.5px) rem(20px);
      text-transform: uppercase;
      font-weight: $bold;

      &:focus {
        @include shadowAsBorder(var(--primary-shadow));
      }
    }

    &--link {
      .button__icon-prefix {
        margin-right: rem(8px);
      }

      .button__icon-suffix {
        margin-left: rem(8px);
      }
    }

    &--outlined {
      padding: rem(9px) rem(15px);
      border-radius: $radius-outlined-button;
      border: 1px solid var(--primary-text);
      background: transparent;

      color: var(--primary-text);
      font-weight: 500;
      font-size: $large-font-size;
      line-height: $line-height-large;

      &:focus {
        @include shadowAsBorder(var(--primary-shadow));
      }
    }

    &--depressed {
      background-color: var(--main-color);
      border: none;
      border-radius: $radius-secondary-button;
      color: var(--main-color-text);
      padding: rem(18px) rem(20px);
      text-transform: uppercase;

      &:focus {
        @include shadowAsBorder(var(--focus-shadow));
      }
    }

    &--text {
      background-color: transparent;
      border: none;
      border-radius: $radius-secondary-button;
      color: var(--main-color);
      padding: rem(18px) rem(20px);
      text-transform: uppercase;

      &:focus {
        @include shadowAsBorder(var(--focus-shadow));
      }
    }

    &__icon-prefix {
      margin-right: rem(17px);
    }

    &__icon-suffix {
      margin-left: rem(17px);
    }
  }
</style>
