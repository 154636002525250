import contactService from '@/services/contact/contact.service';
import { UserTemporaryInfoModule } from '@/store/modules/contact/user-temporary-info/user-temporary-info.module';
import {
  contactHasSomeInformation, getAddressInline,
  getCreditCardDate, getCreditCardIcon,
  isCreditCardLinkPayment,
  isValidLoadPartialContactQueryParameters, sanitizedParams,
} from '@/helpers/contact/contact.helper';
import { capitalize } from '@/helpers/data/data.helper';
import { getCookieSessionExpire } from '@/helpers/session/session.helper';
import { preRegistrationFromRegistrationMapper } from '@/helpers/data-mapper/data-mapper.helper';

export const EmptyContact = {
  _id: '',
  firstname: '',
  lastname: '',
  email: '',
  accountIds: [
    // {
    //   accountId: '',
    //   siteId: ''
    // }
  ],
  creditCards: [
    // {
    //   _id: '',
    //   token: '',
    //   cardType: '',
    //   paymentMethod: '',
    //   last4: '',
    //   expirationMonth: '',
    //   expirationYear: ''
    // }
  ],
  addresses: [
    // {
    //   _id: '',
    //   line1: '',
    //   line2: '',
    //   city: '',
    //   province: '',
    //   zip: '',
    //   country: ''
    // }
  ],
  phoneNumber: null,
};

const state = () => ({
  contact: { ...EmptyContact },
  updatedOtpCredential: null,
  status: {
    error: null,
    isFetching: false,
    isSaving: false,
    isPreRegister: false,
  },
});

const actions = {
  async loadPartialContact({ commit, dispatch, rootGetters }, params) {
    // Important: Do NOT load a partial contact if a contact is already linked to the session
    if (!rootGetters['SessionModule/contactId'] && isValidLoadPartialContactQueryParameters(params)) {
      commit('LOAD_CONTACT_REQUEST');

      try {
        params = sanitizedParams(params);

        const data = await contactService.getPartialContact(params);
        commit('LOAD_CONTACT_SUCCESS', data);

        dispatch('SsrDebugModule/push', {
          title: 'LOAD PARTIAL CONTACT',
          content: data,
        }, { root: true });
      } catch (e) {
        commit('LOAD_CONTACT_FAILURE', e);
      }
    }
  },
  async updateContact({ commit, dispatch, getters, rootGetters }, contact) {
    const acquireContactId = getters.contactId;
    const acquireSessionId = rootGetters['SessionModule/sessionId'] || '';

    if (process.client) {
      if (!acquireContactId && contact?._id) {
        document.cookie = `acquireContactId=${contact._id}; path=/; SameSite=None; Secure`;

        window.dispatchEvent(new CustomEvent('acquirePluginEventAuthenticateUser', { detail: contact }));
      }

      document.cookie = `acquireSessionId=${acquireSessionId}; path=/; SameSite=None; expires=${getCookieSessionExpire(1440)}; Secure`;
    }

    commit('UPDATE_CONTACT', contact);
  },
  async updateContactOtpCredentials({ commit, dispatch, rootGetters }, { contact, router, updatedOtpCredential }) {
    commit('UPDATE_CONTACT_INFO');

    try {
      await contactService.updateContactOtpCredentials(contact);

      commit('UPDATE_CONTACT_INFO_SUCCESS', updatedOtpCredential);

      dispatch('updateContact', contact);
      dispatch('ContactModule/UserTemporaryInfoModule/cleanUserTempInfo', null, { root: true });
      dispatch('AuthModule/clearOtpAccount', null, { root: true });

      if (rootGetters['CheckoutLinkModule/isPreRegistration']) {
        return await router.replace({
          name: 'NotOpened',
          params: { ...router.currentRoute.params },
        });
      }

      dispatch('OrderModule/processToCheckoutLink', { init: true, router }, { root: true });
    } catch (error) {
      commit('UPDATE_CONTACT_INFO_FAILURE', error);
    }
  },
  async preRegister({ commit, dispatch, getters, rootGetters }, { router }) {
    commit('PRE_REGISTER_REQUEST');
    let mappedContact = null;
    const contact = rootGetters['ContactModule/UserTemporaryInfoModule/getTempContactFormatted'];

    try {
      mappedContact = preRegistrationFromRegistrationMapper({
        ...contact,
        phoneNumber: getters.phoneNumber ?? contact.phoneNumber,
      },
      rootGetters['CheckoutLinkModule/preRegistrationCreditCardRequired'],
      rootGetters['CheckoutLinkModule/getId'],
      );

      const data = await contactService.preRegisterContact(mappedContact);
      commit('PRE_REGISTER_SUCCESS', data);
      await dispatch('updateContact', data);

      await dispatch('ContactModule/UserTemporaryInfoModule/cleanUserTempInfo', null, { root: true });

      await router.replace({
        name: 'NotOpened',
        params: { ...router.currentRoute.params },
      });

      return data;
    } catch (e) {
      commit('PRE_REGISTER_FAILURE', e);
    }
  },
  clearUpdatedOtpCredentials({ commit }) {
    commit('CLEAR_UPDATED_OTP_CREDENTIALS');
  },
  async clearContact({ commit, dispatch }) {
    await dispatch('ContactModule/UserTemporaryInfoModule/cleanUserTempInfo', null, { root: true });
    commit('CLEAR_CONTACT');
  },
};

const mutations = {
  LOAD_CONTACT_REQUEST(state) {
    state.status.isFetching = true;
    state.status.error = null;
  },
  LOAD_CONTACT_SUCCESS(state, contact) {
    state.status.isFetching = false;
    state.contact = contact;
    state.status.error = null;
  },
  LOAD_CONTACT_FAILURE(state, error) {
    state.status.isFetching = false;
    state.status.error = error;
    state.contact = { ...EmptyContact };
  },
  UPDATE_CONTACT(state, contact) {
    state.contact = { ...state.contact, ...contact };
  },
  UPDATE_PHONE_NUMBER(state, phoneNumber) {
    state.contact = { ...state.contact, phoneNumber };
  },
  UPDATE_CONTACT_INFO(state) {
    state.updatedOtpCredential = null;
    state.status.isSaving = true;
    state.status.error = null;
  },
  UPDATE_CONTACT_INFO_SUCCESS(state, updatedOtpCredential) {
    state.updatedOtpCredential = updatedOtpCredential;
    state.status.isSaving = false;
    state.status.error = null;
  },
  UPDATE_CONTACT_INFO_FAILURE(state, error) {
    state.updatedOtpCredential = null;
    state.status.isSaving = false;
    state.status.error = error;
  },
  CLEAR_UPDATED_OTP_CREDENTIALS(state) {
    state.updatedOtpCredential = null;
  },
  PRE_REGISTER_REQUEST(state) {
    state.status.isPreRegister = true;
    state.status.error = null;
  },
  PRE_REGISTER_SUCCESS(state, contact) {
    state.status.isPreRegister = false;
    state.contact = contact;
    state.status.error = null;
  },
  PRE_REGISTER_FAILURE(state, error) {
    state.status.isPreRegister = false;
    state.status.error = error;
  },
  CLEAR_CONTACT(state) {
    state.contact = { ...EmptyContact };
    state.updatedOtpCredential = null;

    state.status = {
      error: null,
      isFetching: false,
      isSaving: false,
      isPreRegister: false,
    };
  },
};

const getters = {
  contact: state => state.contact,
  contactId: (_, { contact }) => contact?._id || null,
  phoneNumber: (_, { contact }) => contact?.phoneNumber || null,
  hasSomeInformation: (_, { contact }) => contactHasSomeInformation(contact),
  isPartial: (_, { contact }) => !!contact.firstname && !!contact._id,
  hasContact: (_, { contact }) => !!contact._id,

  isFetching: state => state.status.isFetching,
  isSaving: state => state.status.isSaving,
  contactError: state => state.status.error,

  email: (_, { contact }) => contact?.email || null,
  addresses: (_, { contact }) => contact?.addresses || [],
  creditCards: (_, { contact }) => contact?.creditCards || [],
  firstname: (_, { contact }) => contact?.firstname || contact?.firstName || null,
  fullName: (_, { contact }) => `${contact?.firstname || ''} ${contact?.lastname || ''}`,

  mappedAddresses: (_, { addresses }) => addresses?.map(address => ({ name: getAddressInline(address), value: address._id })),
  mappedCreditCards: (_, { creditCards }) => creditCards?.map(card => ({
    type: getCreditCardIcon(card),
    name: capitalize(card.cardType),
    last: card.last4,
    date: getCreditCardDate(card),
    value: card._id,
    isLink: isCreditCardLinkPayment(card),
  })),
  updatedOtpCredential: state => state.updatedOtpCredential,
};

export const ContactModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
  modules: {
    UserTemporaryInfoModule,
  },
};
