<template>
  <div
    class="display"
    :class="containerClass">
    <FunnelProduct
      v-for="product in getFunnelProducts"
      :key="product.producId"
      class="display__product"
      :product="product"
      :active="!isTimerExpired"
      @view-details="viewDetails"
      @add-to-cart="addToCart" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import FunnelProduct from '@/checkout-link/components/funnel/funnel-product/FunnelProduct';

  export default {
    name: 'FunnelProductDisplay',
    components: { FunnelProduct },
    computed: {
      ...mapGetters('FunnelModule', ['getFunnelProducts', 'isMonoProductFunnel', 'isTimerExpired']),
      containerClass() {
        return this.isMonoProductFunnel ? 'display--mono' : 'display--multi';
      },
    },
    methods: {
      ...mapActions('CheckoutLinkModule', ['addVariant']),
      viewDetails(event) {
        this.$emit('view-details', event);
      },
      addToCart(data) {
        const { buyNow, ...items } = data;

        this.addVariant({
          items,
          router: this.$router,
          buyNow,
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .display {
    &--mono {
      max-width: 650px;
      margin: 0 auto;
    }

    &--multi {
      display: flex;
      overflow-y: auto; // fall back for "overlay" value non compatible browsers
      overflow-y: overlay;

      /* Hide horizontal scrollbar */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Chrome */
      }
    }

    &__product {
      &:not(:last-child) {
        margin-right: rem(8px);
      }
    }
  }

  @include breakpoint(medium) {
    .display {
      &--mono {
        max-width: 536px;
      }
    }
  }

  @include breakpoint(large) {
    .display {
      &--multi {
        justify-content: center;
      }
    }
  }
</style>
