<template>
  <div class="authentication-code">
    <div class="authentication-code__container">
      <div class="container__body">
        <h1 v-if="isRegularAuthent"
          class="body__title">
          {{ $t('checkoutLink.authentication.code.title') }}
          <span class="title__icon" />
        </h1>

        <h2 class="body__subtitle">
          {{ subtitleText }}
        </h2>

        <div v-if="!isMismatchedInfo">
          <p class="body__help">
            {{ methodText }}
            <span class="body__help--important">
              {{ sentTo }}
            </span>.
          </p>

          <p v-if="!otpAccountSelected"
            class="body__help">
            {{ $t('checkoutLink.authentication.code.help.text.afterMethod') }}
            <a
              class="body__help--link"
              @click="askNewCode">
              {{ $t('checkoutLink.authentication.code.help.link') }}
            </a>
            <span> | </span>
            <a
              class="body__help--link"
              @click="restartAuth">
              {{ $t('checkoutLink.authentication.code.help.restart') }}
            </a>
          </p>
        </div>

        <p v-if="isMismatchedInfo"
          class="body__help">
          <i18n
            :path="mismatchedTextPath">
            <template #uniqueInfo>
              {{ uniqueInfo }}
            </template>
            <template #sentTo>
              <span class="body__help--important">
                {{ sentTo }}
              </span>
            </template>
          </i18n>
        </p>

        <p v-if="isMismatchedInfo"
          class="body__help">
          {{ $t('checkoutLink.authentication.code.help.text.afterMethod') }}
          <a
            class="body__help--link"
            @click="askNewCode">
            {{ $t('checkoutLink.authentication.code.help.link') }}
          </a>
          <span> | </span>
          <a
            class="body__help--link"
            @click="restartAuth">
            {{ $t('checkoutLink.authentication.code.help.restart') }}
          </a>
        </p>

        <div class="body__code">
          <AppError
            v-if="errorCode || errorOtpAccount"
            class="code__error"
            data-cy="authentication-code-error"
            :error="errorCode || errorOtpAccount" />
          <FormPhoneCode
            v-if="!isVerifying && !isSaving"
            data-test-id="authentication-code-body-phone"
            :has-external-error="!!errorCode"
            @code="onVerifyCode" />
          <LayoutLoader v-else />
        </div>

        <p v-if="isRegularAuthent"
          class="body__help">
          <a
            class="body__help--link-secondary"
            data-test-id="change-otp-method"
            @click="changeMethod">
            {{ changeMethodText }}
          </a>
        </p>

        <p v-if="otpAccountSelected"
          class="body__help">
          {{ $t('checkoutLink.authentication.code.help.notReceived') }} <br />
          <a
            class="body__help--link"
            data-test-id="change-otp-method"
            @click="askNewCode">
            {{ $t('checkoutLink.authentication.code.help.linkAlternate') }}
          </a>
          <span> | </span>
          <a
            class="body__help--link"
            @click="restartAuth">
            {{ $t('checkoutLink.authentication.code.help.restart') }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import IsInsideModal from '@mixins/isInsideModal.mixin';
  import FormPhoneCode from '@/checkout-link/components/form/form-phone-code/FormPhoneCode';
  import LayoutLoader from '@/checkout-link/components/layout/loader/LayoutLoader';
  import AppError from '@/checkout-link/components/app/app-error/AppError';

  export default {
    name: 'CheckoutLinkAuthenticationCode',
    components: {
      AppError,
      LayoutLoader,
      FormPhoneCode,
    },
    mixins: [IsInsideModal],
    beforeRouteEnter(_, from, next) {
      next(vm => {
        vm.previousRoute = from;
      });
    },
    data() {
      return {
        previousRoute: null,
      };
    },
    computed: {
      ...mapGetters('AuthModule', [
        'sentTo', 'errorCode', 'errorOtpAccount', 'isVerifying', 'otpPreference',
        'otpAccountId', 'otpMismatchedInfo', 'otpAccountPhone', 'otpAccountEmail',
        'isMismatchedInfo', 'otpAccountSelected', 'otpPartialContactAuthentication',
        'isObfuscatedInfo',
      ]),
      ...mapGetters('OrderModule', ['isSaving']),
      ...mapGetters('SessionModule', ['advancedSettings']),
      ...mapGetters('CheckoutLinkModule', ['isPreRegistration']),
      isSmsOtp() {
        return this.otpPreference === 'sms';
      },
      isRegularAuthent() {
        return !this.otpAccountSelected && !this.isMismatchedInfo && !this.isObfuscatedInfo;
      },
      mismatchedTextPath() {
        return `checkoutLink.authentication.code.help.${this.otpMismatchedInfo === 'phoneNumber' ? 'mismatchPhone' : 'mismatchEmail'}`;
      },
      subtitleText() {
        if (this.otpAccountSelected) {
          return this.$t('checkoutLink.authentication.accountSelected.title');
        }

        return this.$t(`checkoutLink.authentication.code.${this.isMismatchedInfo ? 'subtitleMismatch' : 'subtitle'}`);
      },
      methodText() {
        if (this.otpAccountSelected) {
          return this.$t('checkoutLink.authentication.accountSelected.message');
        }

        return this.$t(`checkoutLink.authentication.code.help.text.${this.isSmsOtp ? 'beforePhone' : 'beforeEmail'}`);
      },
      changeMethodText() {
        return this.$t(`checkoutLink.authentication.method.${this.isSmsOtp ? 'switchToEmail' : 'switchToPhone'}`);
      },
      uniqueInfo() {
        return this.otpMismatchedInfo === 'phoneNumber' ? this.otpAccountEmail : this.otpAccountPhone;
      },
    },
    methods: {
      ...mapActions('AuthModule', ['verifyOtp', 'sendOtp', 'clearOtpAccount']),
      ...mapActions('ContactModule', ['clearContact']),
      async onVerifyCode(code) {
        if (code) {
          try {
            await this.verifyOtp({ code, router: this.$router });
          } catch (error) {
            throw error;
          }
        }
      },
      async changeMethod() {
        await this.sendOtp({ preference: this.isSmsOtp ? 'email' : 'sms' });
      },
      async askNewCode() {
        await this.sendOtp({ preference: this.otpPreference });
      },
      async restartAuth() {
        // Clear store
        await this.clearContact();
        await this.clearOtpAccount();

        if (this.previousRoute.name === 'Preregistration') {
          this.$router.go(-1);
        }

        /*
          Vue router does not allow an empty object to be set as query. This is was the only way to do it without
          reloading the entire page. Since the values are undefined, the router will remove them itself from the url
          as soon as the push promise settles
        */
        const query = {
          ...((!!this.$route.query.email && { email: undefined }) || {}),
          ...((!!this.$route.query.phone && { phone: undefined }) || {}),
          ...((!!this.$route.query.phoneNumber && { phoneNumber: undefined }) || {}),
        };

        // Go back to checkout without the existing query params in url
        await this.$router.push({
          name: 'Checkout',
          params: { ...this.$route.params },
          query,
        });
      },
    },
    mounted() {
      if (!this.otpAccountId) {
        this.$router.push({
          name: this.isPreRegistration ? 'NotOpened' : 'Checkout',
          params: { ...this.$route.params },
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
  .authentication-code {
    align-items: center;

    &__container {
      padding: 0 rem(25px);
    }
  }

  .container {
    &__body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 20%;
      padding-top: rem(30px);
    }
  }

  .body {
    &__title {
      @include large-heading();
      color: var(--primary-text);
      text-align: center;
      margin: 0 0 8px 0;
    }

    &__subtitle {
      @include heading();
      font-weight: $bold;
      text-align: center;
      margin: 0 0 8px 0;
    }

    &__help {
      @include body-large();

      color: var(--secondary-text);
      text-align: center;
      margin: 0;
      line-height: rem(24px);

      &--link {
        text-decoration: underline;
        cursor: pointer;
      }

      &--link-secondary {
        color: var(--primary-text);
        cursor: pointer;
      }

      &--important {
        font-weight: bold;
        color: var(--primary-text);
      }
    }

    &__code {
      margin-top: rem(30px);
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: fit-content;

      ::v-deep .form-phone-code {
        padding: 0 0 rem(20px);

        &__field {
          background: var(--card-raised-background);
        }
      }
    }
  }

  .title {
    &__icon {
      display: inline-block;
      height: 22px;
      width: 22px;
      margin-left: 3px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url('~@assets/icons/welcome_back.svg');
    }
  }

  .code {
    &__error {
      justify-content: center;
      text-align: center;
    }
  }

  @include breakpoint(small) {
    .container {
      &__body {
        padding-bottom: rem(30px);
        padding-top: 0;
      }
    }
  }
</style>
