import { createPopper } from '@popperjs/core';

export default {
  data() {
    return {
      selectedValue: null,
      positionPlacement: 'bottom-start',
    };
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    addOption: {
      type: Boolean,
      default: false,
    },
    addOptionLabel: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    autoSelectFirst: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isMaterial: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.selectedValue = this.value;
    this.selectFirstOption();
  },
  watch: {
    value(value) {
      this.selectedValue = value;
    },
    options() {
      this.selectFirstOption();
    },
  },
  methods: {
    emitValueChange(data) {
      this.$emit('value-changed', data);
    },
    emitAddOption() {
      this.$refs.select.open = false;
      this.$emit('add-option');
    },
    selectFirstOption() {
      if (this.autoSelectFirst && this.options.length && !this.value) {
        this.selectedValue = this.options[0]?.value;
      }

      if (this.selectedValue && this.value !== this.selectedValue) {
        this.$emit('first-value-selected', this.selectedValue);
      }
    },
    calculatePosition(dropdownList, component, { width }) {
      const isParentMaterial = component.$parent.isMaterial;
      const isParentAttributeSelector = component.$parent.isAttributeSelector;

      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       * In case of a material parent but not an attribute selector,
       * add 25px to fit parent size
       */
      if (isParentMaterial && !isParentAttributeSelector) {
        width = `${(+width.split('p')[0] + 25)}px`;
      }
      dropdownList.style.width = width;

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * Check if parent select is a material input and adjust its horizontal position
       * accordingly, offseting it on the left
       */
      const horizontalOffset = isParentMaterial ? -11 : 0;
      const verticalOffset = 10;

      /**
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.positionPlacement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [horizontalOffset, verticalOffset],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top-start',
              );
            },
          },
        ],
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    },
  },
};
