<template>
  <div class="layout-overlay">
    <div
      class="layout-overlay__container"
      @click="checkIfCanClose">
      <slot />
    </div>
  </div>
</template>

<script>
  import { isThisElement } from '@/helpers/layout/layout.helper';

  export default {
    name: 'LayoutOverlay',
    methods: {
      checkIfCanClose(event) {
        if (isThisElement(event, 'layout-overlay__container')) {
          this.$emit('click');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.layout-overlay {
  z-index: 99998;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: $overlay-background;

  &__container {
    @include main-layout-wrapper();
    @include main-layout();

    background: transparent;
  }
}

.container {
  &__close {
    z-index: 9999;
    position: absolute;
    top: 10px;
    right: 10px;

    .button {
      background-color: var(--card-background);
    }
  }
}
</style>
