import productService from '@/services/product/product.service';

// This is what a product looks like
// {
//   _id: '',
//   label: '',
//   description: '',
//   variant_options: ['small', 'red'], // from default variant... to fit with design
//   options_with_values: [
//     {name: 'Size', value: 'small'},
//     {name: 'Color', value: 'red'},
//   ], // from default variant... to fit with design
//   imageUrl: 'path/to/image', // to fit with design but just first image from array
//   images: [
//      { _id: '', product_id: '', variant_ids: [], src: '' },
//   ],
//   category: '',
//   universe: '',
//   currency: '',
//   price: '',
//   variants: [
//     { _id: '', price: '', options: [{name: 'Size', value: 'small'}, {name: 'Color', value: 'red'}], available: true, product_id: '' },
//     { _id: '', price: '', options: [{name: 'Size', value: 'large'}, {name: 'Color', value: 'red'}], available: true, product_id: '' },
//     { _id: '', price: '', options: [{name: 'Size', value: 'small'}, {name: 'Color', value: 'blue'}], available: false, product_id: '' },
//     { _id: '', price: '', options: [{name: 'Size', value: 'large'}, {name: 'Color', value: 'blue'}], available: true, product_id: '' },
//   ],
//   selectedVariant: { _id: '', price: '', options: [{name: 'Size', value: 'small'}, {name: 'Color', value: 'red'}], available: true }, // default variant to fit with design
//   options: [
//     { name: 'Size', options: [{ label: 'Large', value: 'large' }, { label: 'Small', value: 'small' }] },
//     { name: 'Color', options: [{ label: 'Blue', value: 'blue' }, { label: 'Red', value: 'red' }] },
//   ]
// }

const state = () => ({
  recommendedProducts: {
    values: [],
    status: {
      error: null,
      isLoading: false,
    },
  },
  inOfferProducts: {
    values: [],
    status: {
      error: null,
      isLoading: false,
    },
  },
});

const actions = {
  loadRecommendedProducts({ commit }) {
    commit('SET_RECOMMENDED_PRODUCTS');
    productService.loadRecommendedProducts().then(data => {
      commit('SET_RECOMMENDED_PRODUCTS_SUCCESS', data);
    }).catch((error) => {
      commit('SET_RECOMMENDED_PRODUCTS_FAILURE', error);
    });
  },
  loadInOfferProducts({ commit }) {
    commit('SET_IN_OFFER_PRODUCTS');
    productService.loadInOfferProducts().then(data => {
      commit('SET_IN_OFFER_PRODUCTS_SUCCESS', data);
    }).catch((error) => {
      commit('SET_IN_OFFER_PRODUCTS_FAILURE', error);
    });
  },
};

const mutations = {
  SET_RECOMMENDED_PRODUCTS(state) {
    state.recommendedProducts.status.isLoading = true;
    state.recommendedProducts.status.error = null;
  },
  SET_RECOMMENDED_PRODUCTS_SUCCESS(state, products) {
    state.recommendedProducts.status.isLoading = false;
    state.recommendedProducts.status.error = null;
    state.recommendedProducts.values = products;
  },
  SET_RECOMMENDED_PRODUCTS_FAILURE(state, error) {
    state.recommendedProducts.status.isLoading = false;
    state.recommendedProducts.status.error = error;
    state.recommendedProducts.values = [];
  },
  SET_IN_OFFER_PRODUCTS(state) {
    state.inOfferProducts.status.isLoading = true;
    state.inOfferProducts.status.error = null;
  },
  SET_IN_OFFER_PRODUCTS_SUCCESS(state, products) {
    state.inOfferProducts.status.isLoading = false;
    state.inOfferProducts.status.error = null;
    state.inOfferProducts.values = products;
  },
  SET_IN_OFFER_PRODUCTS_FAILURE(state, error) {
    state.inOfferProducts.status.isLoading = false;
    state.inOfferProducts.status.error = error;
    state.inOfferProducts.values = [];
  },
};

const getters = {
  getRecommendedProducts: state => state?.recommendedProducts?.values || [],
  getRecommendedProductsLoading: state => state?.recommendedProducts?.status?.isLoading,
  getRecommendedProductsError: state => state?.recommendedProducts?.status?.error,

  getInOfferProducts: state => state?.inOfferProducts?.values || [],
  getInOfferProductsLoading: state => state?.inOfferProducts?.status?.isLoading,
  getInOfferProductsError: state => state?.inOfferProducts?.status?.error,

  hasRecommendedProducts: (_, { getRecommendedProducts }) => !!getRecommendedProducts.length,
  hasInOfferProducts: (_, { getInOfferProducts }) => !!getInOfferProducts.length,
};

export const ProductModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
