<template>
  <div
    class="layout-loader"
    :class="{'layout-loader--text': isText }">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style="margin: auto; background: transparent; display: block;"
      :width="width"
      :height="height"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <g transform="translate(20 50)">
        <circle
          cx="0"
          cy="0"
          r="6"
          :fill="color"
          transform="scale(0.834619 0.834619)">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.375s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite" />
        </circle>
      </g><g transform="translate(40 50)">
        <circle
          cx="0"
          cy="0"
          r="6"
          :fill="color"
          transform="scale(0.999994 0.999994)">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.25s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite" />
        </circle>
      </g><g transform="translate(60 50)">
        <circle
          cx="0"
          cy="0"
          r="6"
          :fill="color"
          transform="scale(0.831818 0.831818)">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.125s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite" />
        </circle>
      </g><g transform="translate(80 50)">
        <circle
          cx="0"
          cy="0"
          r="6"
          :fill="color"
          transform="scale(0.498237 0.498237)">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="0s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite" />
        </circle>
      </g>
    </svg>
  </div>
</template>

<script>
  export default {
    name: 'LayoutLoader',
    props: {
      color: {
        type: String,
        default: 'auto',
      },
      height: {
        type: Number,
        default: 100,
      },
      width: {
        type: Number,
        default: 100,
      },
      isText: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layout-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    fill: var(--main-color);

    &--text {
      fill: var(--main-color-text);
    }
  }
</style>
