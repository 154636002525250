<template>
  <div>
    <div class="product product-in-cart">
      <div class="product__image">
        <ProductOptimizedImage
          data-test-id="product-optimized-image"
          :alt="product.label"
          :src="productImageBackup"
          :width="imageWidth" />
      </div>

      <div class="product__details">
        <div class="details__top">
          <div class="product__description">
            <span
              data-test-id="description-title"
              class="description__title"
              :title="productName">
              {{ productName }}
            </span>

            <span
              data-test-id="description-subtitle"
              class="description__subtitle">
              {{ optionsAndCustomFields }}
            </span>
          </div>

          <AppIconButton
            v-if="canDelete"
            data-test-id="products-trash"
            class="products__trash"
            name="trash"
            color="muted"
            size="small"
            :disabled="!canDelete"
            @click.native="onDelete" />
        </div>

        <div class="details__bottom">
          <AppQuantity
            v-if="product.source !== 'GIFT'"
            data-test-id="app-quantity"
            class="product__quantity"
            :min-quantity="minQuantity || 1"
            :max-quantity="maxQuantity"
            :count-product-in-cart="getItemTotal(product.productId)"
            :default-quantity="product.quantity"
            @quantity-changed="onQuantityChanged" />

          <AppButton
            v-if="(product.source === 'GIFT' && mappedProduct.variants.length > 1)"
            class="btn-select-options"
            type="primary"
            @click.native="onSelectOptions">
            {{ $t('productInCart.selectOptions') }}
          </AppButton>

          <div class="product__prices">
            <CheckoutPrice
              v-if="showDiscountedAmount"
              class="product__original-price"
              :amount="price"
              :currency="product.currency"
              variant="subdued" />

            <CheckoutPrice
              data-test-id="checkout-product-price"
              class="product__price"
              :amount="discountedPrice"
              :currency="product.currency" />
          </div>
        </div>
      </div>
    </div>
    <ProductInCartOptions
      v-if="options.isVisible"
      :product="mappedProduct"
      :variant-id="product.variantId"
      :tab="options.tab"
      @close="closeOptions"
      @add-to-cart="onVariantUpdate" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import { PRODUCT_IMAGE_SIZE } from '@/helpers/product/product.helper';
  import placeholderImage from '@/assets/image-placeholder.svg';

  import AppButton from '@/checkout-link/components/app/app-button/AppButton.vue';
  import AppIconButton from '@/checkout-link/components/app/app-icon-button/AppIconButton';
  import AppQuantity from '@/checkout-link/components/app/app-quantity/AppQuantity';
  import CheckoutPrice from '@/checkout-link/components/product/checkout-price/CheckoutPrice';
  import ProductOptimizedImage from '@/checkout-link/components/product/product-optimized-image/ProductOptimizedImage';
  import ProductInCartOptions from '@/checkout-link/components/product/product-in-cart-options/ProductInCartOptions';

  export default {
    name: 'CheckoutProductInCart',
    components: {
      AppButton,
      AppQuantity,
      AppIconButton,
      CheckoutPrice,
      ProductOptimizedImage,
      ProductInCartOptions,
    },
    props: {
      product: {
        type: Object,
        required: true,
      },
      qtyTotal: {
        type: Number,
        required: true,
        default: 0,
      },
      canDelete: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        quantity: this.product.quantity,
        imageWidth: PRODUCT_IMAGE_SIZE.IN_CART.WIDTH,
        options: {
          isVisible: false,
          tab: 'options',
        },
      };
    },
    computed: {
      ...mapGetters('CartModule', ['getItemTotal']),
      ...mapGetters('CheckoutLinkModule', ['getMappedOfferedProducts']),
      productName() {
        return this.product?.productName || this.product?.label;
      },
      productImageBackup() {
        return this.product.imageUrl === '' ? placeholderImage : this.product.imageUrl;
      },
      minQuantity() {
        return this.qtyTotal > this.product?.quantityPolicy?.min ? 1 : this.product?.quantityPolicy?.min;
      },
      maxQuantity() {
        const hasInfiniteInventory = this.product.variant?.inventory.available && !this.product.variant?.inventory.tracked;
        const hasInfiniteQty = this.product?.quantityPolicy ? this.product.quantityPolicy?.max === 0 : true;
        const hasQtyInferiorToInventory = this.product?.quantityPolicy?.max < this.product.variant?.inventory.quantity && !hasInfiniteQty;

        if (hasInfiniteQty) {
          if (!hasInfiniteInventory) {
            return this.product.variant?.inventory.quantity;
          }

          return 0;
        }

        if (!hasInfiniteInventory) {
          return hasQtyInferiorToInventory ? this.product?.quantityPolicy?.max : this.product.variant?.inventory.quantity;
        }

        return this.product?.quantityPolicy?.max;
      },
      optionsAndCustomFields() {
        const customFieldsValues = this.product.customFieldsValues?.map(cfv => cfv.value) || [];
        const variantOptions = this.product.variant_options || [];
        const quantity = this.product.source === 'GIFT' ? [`${this.$t('productInCart.qty')} ${this.product.quantity}`] : [];

        return [...quantity, ...variantOptions, ...customFieldsValues].join(' • ');
      },
      showDiscountedAmount() {
        if (this.product.source === 'GIFT') {
          return true;
        }

        if (!!this.product?.subscriptionPrice) {
          return this.product.subscriptionPrice !== this.product.unitPrice;
        }

        if (Number.isFinite(this.product.discountPrice)) {
          return this.product.discountPrice !== this.product.unitPrice;
        }

        return false;
      },
      variantOriginalPrice() {
        return this.product.variant.originalPrice ?? this.product.variant.price;
      },
      price() {
        return this.product.source === 'GIFT' ? this.variantOriginalPrice * this.product.quantity : this.product.price;
      },
      discountedPrice() {
        if (this.product.source !== 'GIFT' && this.showDiscountedAmount) {
          return !!this.product?.subscriptionPrice ? this.product.subscriptionPrice : this.product.totalDiscountedPrice;
        }

        return this.product.price;
      },
      mappedProduct() {
        return this.getMappedOfferedProducts.find(p => p.productId === this.product.productId);
      },
    },
    watch: {
      product() {
        this.quantity = this.product.quantity;
      },
    },
    methods: {
      ...mapActions('CartModule', ['updateItemVariant']),
      onDelete() {
        this.$emit('delete', {
          _id: this.product.variantId,
          purchaseOption: this.product.purchaseOption,
          customFieldsValues: this.product.customFieldsValues || [],
        });
      },
      onQuantityChanged(quantity) {
        this.$emit('quantity-changed', {
          quantity,
          _id: this.product.variantId,
          purchaseOption: this.product.purchaseOption,
          customFieldsValues: this.product.customFieldsValues || [],
        });
      },
      onSelectOptions() {
        this.options.isVisible = true;
      },
      closeOptions() {
        this.options.isVisible = false;
      },
      onVariantUpdate(newVariant) {
        this.updateItemVariant({ item: this.product, newVariant });
      },
    },
  };
</script>

<style scoped lang="scss">
  $product-image-size: 70px;
  $product-card-bottom-margin: 3px;
  $product-card-padding: 10px;
  $product-quantity-margin: 8px;
  $product-height: calc(#{$product-image-size} + calc(#{$product-card-padding * 2}));

  .product {
    display: flex;
    width: 100%;
    height: $product-height;
    max-height: $product-height;
    box-sizing: border-box;

    padding: $product-card-padding 0;
    border-radius: $radius-product-card;
    background: var(--card-background);

    &__image {
      display: flex;
      align-items: center;

      flex-shrink: 0;
      @include image($product-image-size, $product-image-size);

      img {
        height: auto;
        width: auto;
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-left: $product-card-padding;
      min-width: 0;
    }

    &__description {
      display: flex;
      flex-direction: column;
      flex: 1 0;
      padding-right: $product-card-padding;
      min-width: 0;
    }

    &__trash {
      display: flex;
      flex: 0 1;
      justify-self: right;
    }

    &__quantity {
      flex: 1 0;
    }

    &__prices {
      margin-top: 8px;
      display: flex;
    }

    &__price {
      display: flex;
      align-items: flex-end;
      flex: 0 1;
      font-weight: bold;
      color: var(--primary-text);

      @include body-large();
    }

    &__original-price {
      margin-right: rem(10px);
      text-decoration: line-through;

      @include body-large();
    }
  }

  .details {
    &__top {
      display: flex;
    }

    &__bottom {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      justify-content: flex-end;
    }
  }

  .description {
    &__title {
      margin-bottom: $product-card-bottom-margin;

      @include body-large();
      color: var(--primary-text);
      font-weight: bold;

      @include text-overflow();
    }

    &__subtitle {
      flex-grow: 1;

      @include body-medium();
      color: var(--tertiary-raised-text);
      text-transform: capitalize;
      line-height: 140%;
    }
  }

  .btn-select-options {
    font-size: 9px;
    padding: 6px 10px;
    width: auto;
    margin-top: 8px;
    margin-right: auto;
  }
</style>
