<template>
  <a
    class="layout-brand"
    target="_blank"
    rel="noopener noreferrer"
    :href="'http://acquire.app/?utm_source=checkoutlinks&utm_content=' + siteName"
    @click="onClick">
    <span
      class="layout-brand__img"
      data-test-id="layout-brand-img" />
  </a>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { ACQUIRE_CLICK } from '@/services/analytics/analytics.event';

  export default {
    name: 'LayoutBrand',
    computed: {
      ...mapGetters('SessionModule', ['siteName']),
    },
    methods: {
      ...mapActions('AnalyticsModule', ['addCheckoutEvent']),
      onClick() {
        this.addCheckoutEvent(ACQUIRE_CLICK);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layout-brand {
    @include label();
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: rem(10px);
    color: var(--modal-secondary-text);
    white-space: nowrap;

    &__img {
      @include brand('black');
      height: rem(18px);
      width: rem(74px);
      margin: 0 0 0 rem(10px);
    }

    .checkout-link-layout__header &,
    .checkout-link-layout__footer &,
    .overlay & {
      display: flex;
      justify-content: center;
      margin: 0;
      color: var(--label);

      &__img {
        height: rem(24px);
        @include brand('black');

        .acquire-cart--dark & {
          @include brand('white');
        }
      }
    }

    .overlay & {
      &__img {
        @include brand('black-color');

        .acquire-cart--dark & {
          @include brand('white-color');
        }
      }
    }
  }
</style>
