<template>
  <div class="form-checkbox">
    <label
      class="form-checkbox__label"
      data-test-id="form-checkbox-label"
      :class="{ 'form-checkbox__label--error': error }">
      <input
        class="form-checkbox__hidden-field"
        data-test-id="form-checkbox-hidden-field"
        type="checkbox"
        :required="required"
        :checked="currentValue"
        @click="toggleValue"
        @keyup.enter="toggleValue" />
      <span class="form-checkbox__field">
        <font-awesome-icon icon="check" />
      </span>
      {{ label }}
    </label>

    <span
      v-if="error"
      class="form-checkbox__error-message"
      data-test-id="form-checkbox-error">
      {{ error }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'FormCheckbox',
    props: {
      label: {
        type: String,
        default: '',
      },
      error: {
        type: String,
        default: null,
      },
      required: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      currentValue: null,
    }),
    watch: {
      value(newValue) {
        // Needed if the value prop changes when the component is already mounted (parent form prefill for example)
        this.currentValue = newValue;
      },
    },
    methods: {
      toggleValue() {
        this.currentValue = !this.currentValue;
        this.emitValue();
      },
      emitValue() {
        this.$emit('value-changed', this.currentValue);
      },
    },
    mounted() {
      this.currentValue = this.value;
    },
  };
</script>

<style scoped lang="scss">
  .form-checkbox {
    $checkbox-size: rem(20px);
    $checkbox-margin: rem(10px);
    $checkbox-border: 1px;

    display: flex;
    flex-direction: column;
    padding: rem(15px) 0 0 rem(2px);

    &__label {
      @include body-medium();
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      color: var(--tertiary-raised-text);

      &--error {
        color: $error;

        .form-checkbox__field {
          border: 1px solid $error !important;
        }
      }
    }

    &__hidden-field {
      position: absolute;
      width: $checkbox-size;
      height: $checkbox-size;
      margin: 0 1px;
      opacity: 0;

      &:checked + .form-checkbox__field {
        background: var(--main-color);
        border: 1px solid var(--main-color);
      }

      &:checked + .form-checkbox__field svg {
        display: initial;
        color: var(--main-color-text);
      }
    }

    &__field {
      box-sizing: border-box;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      margin-right: $checkbox-margin;
      width: $checkbox-size;
      height: $checkbox-size;
      border: 1px solid var(--input-border);
      border-radius: $radius-input-checkbox;
      background: transparent;

      svg {
        display: none;
      }
    }

    &__error-message {
      margin-top: rem(5px);
      margin-left: calc(#{$checkbox-margin} + #{$checkbox-size} + calc(#{$checkbox-border} * 2));
      @include body-small();
      color: $error;
    }
  }
</style>
