<template>
  <div class="form-input">
    <label
      class="form-input__label"
      data-test-id="form-input-label">
      <input
        class="form-input__field"
        :class="{ 'form-input__field--error': displayError }"
        data-test-id="form-input-field"
        placeholder=" "
        :disabled="disabled"
        :required="required"
        :type="type"
        :value="value"
        :maxlength="maxLength"
        @change="onChange"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur" />
      <span class="field__label">{{ label }}</span>
    </label>
    <span
      v-if="displayError"
      class="form-input__error-message"
      data-test-id="form-input-error">
      {{ error }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'FormInput',
    props: {
      type: {
        type: String,
        default: 'text',
      },
      label: {
        type: String,
        default: '',
      },
      error: {
        type: String,
        default: null,
      },
      showErrors: {
        type: Boolean,
        default: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      value: {
        type: String,
        default: '',
      },
      maxLength: {
        type: Number,
        default: 1000,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      displayError() {
        return !!this.error && this.showErrors;
      },
    },
    methods: {
      onChange(event) {
        this.$emit('value-changed', event.target.value);
      },
      onInput(event) {
        this.$emit('value-input', event.target.value);
      },
      onFocus(event) {
        this.$emit('input-focus');
      },
      onBlur(event) {
        this.$emit('input-blur');
      },
    },
  };
</script>

<style scoped lang="scss">
.form-input {
  display: flex;

  &__label {
    position: relative;
    width: 100%;

    & input {
      @include material-input();
      padding: rem(12px) 0 0 rem(10px);
    }
  }

  &__error-message {
    @include body-small();
    margin-top: rem(5px);
    color: $error;
  }
}
</style>
