<template>
  <div class="registration">
    <!-- following class is used in toggleParentScrollElements method -->
    <div class="registration__content">
      <div class="content__pre-registration">
        <div class="pre-registration__title">
          {{ preRegistrationTitle || $t('preRegistration.registration.title') }}
        </div>

        <div class="pre-registration__text">
          {{ preRegistrationSubTitle || $t('preRegistration.registration.text') }}
        </div>

        <div class="pre-registration__countdown">
          <p class="countdown__title">{{ $t('preRegistration.countdown') }}</p>

          <CountDown :date="getCheckoutLinkStartDate" />
        </div>
      </div>

      <hr class="content__separator" />

      <!-- following class is used in toggleParentScrollElements method -->
      <div class="content__user-info">
        <!--
          Do not use v-if here instead of v-show since
          it will force child components to remount and causes
          issues when edit mode is open and payment fails
        -->
        <div
          class="user-info__form"
          data-test-id="user-info__form">
          <UserEmail
            :disabled="isVerifyingOtpAccount"
            @email-changed="handleAuthInfoChange('email', $event)" />

          <UserPhone
            :disabled="isVerifyingOtpAccount"
            :default-country="defaultCountry"
            @phone-changed="handleAuthInfoChange('phoneNumber', $event)" />

          <UserAddresses
            ref="address"
            class="form__addresses"
            :inside-modal="isInsideModal"
            :is-mobile="isMobile"
            :should-update-cart-on-change="false"
            @edit-mode-state="handleEditModeState('address', $event)" />

          <UserPaymentMethods
            v-if="!hidePaymentMethod"
            ref="creditCard"
            class="form__payment"
            :inside-modal="isInsideModal"
            :is-mobile="isMobile"
            @edit-mode-state="handleEditModeState('creditCard', $event)" />

          <UserOptions class="form__options" />

          <UserAgreement
            class="form__agreement"
            :advanced-settings="advancedSettings" />

          <AppError
            v-if="hasError"
            :error="error" />
        </div>
      </div>
    </div>

    <div class="registration__footer">
      <LayoutBrand class="footer__brand" />

      <div class="footer__action">
        <AppButton
          type="primary"
          :disabled="mainActionDisabled"
          data-cy="checkout-link-footer-action-button"
          data-test-id="checkout-link-footer-action-button-element"
          @click.native="submitPreRegister">
          {{ $t('preRegistration.registration.button') }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import { userDefaultCountry } from '@/helpers/contact/contact.helper';
  import IsInsideModal from '@mixins/isInsideModal.mixin';

  import CountDown from '@/checkout-link/components/count-down/CountDown';
  import LayoutBrand from '@/checkout-link/components/layout/brand/LayoutBrand.vue';
  import UserAddresses from '@/checkout-link/components/user-addresses/UserAddresses';
  import UserAgreement from '@/checkout-link/components/user-agreement/UserAgreement';
  import UserEmail from '@/checkout-link/components/user-email/UserEmail';
  import UserPhone from '@/checkout-link/components/user-phone/UserPhone';
  import UserOptions from '@/checkout-link/components/user-options/UserOptions';
  import UserPaymentMethods from '@/checkout-link/components/user-payment-methods/UserPaymentMethods';
  import AppError from '@/checkout-link/components/app/app-error/AppError';
  import AppButton from '@/checkout-link/components/app/app-button/AppButton';

  export default {
    name: 'CheckoutLinkPreregistration',
    components: {
      AppError,
      CountDown,
      LayoutBrand,
      UserAddresses,
      UserAgreement,
      UserEmail,
      UserPhone,
      UserOptions,
      UserPaymentMethods,
      AppButton,
    },
    mixins: [IsInsideModal],
    data() {
      return {
        isInfoInEditMode: {
          creditCard: null,
          address: null,
        },
        authInfo: {},
      };
    },
    computed: {
      ...mapGetters('SessionModule', ['advancedSettings', 'countryCode']),
      ...mapGetters('AuthModule', ['errorPhone', 'isVerifyingOtpAccount']),
      ...mapGetters('ContactModule', ['contactError']),
      ...mapGetters('ContactModule/UserTemporaryInfoModule', ['getTempCreditCard', 'getTempAddress', 'getTempEmail']),
      ...mapGetters('CheckoutLinkModule', [
        'getCheckoutLinkStartDate', 'preRegistrationCreditCardRequired',
        'preRegistrationTitle', 'preRegistrationSubTitle',
      ]),
      defaultCountry() {
        return userDefaultCountry(this.countryCode, this?.$ssrContext?.acceptsLanguages);
      },
      hasError() {
        return !!this.contactError;
      },
      error() {
        return !this.hasError
          ? null
          : this.contactError;
      },
      editableInfoList() {
        return Object.keys(this.isInfoInEditMode);
      },
      isInfoValid() {
        return {
          // The values will exist in store only if they are valid - See logic in User info related components
          creditCard: !!this.getTempCreditCard,
          address: !!this.getTempAddress && !!this.getTempAddress.firstname && !!this.getTempAddress.lastname,
        };
      },
      allowProcessing() {
        return this.editableInfoList.every(info => !this.isInfoInEditMode[info] || this.isInfoValid[info]);
      },
      mainActionDisabled() {
        return !this.allowProcessing;
      },
      hidePaymentMethod() {
        return !this.preRegistrationCreditCardRequired;
      },
    },
    methods: {
      ...mapActions('ContactModule', ['preRegister']),
      ...mapActions('AuthModule', ['verifyOtpAccount']),
      // Event handlers
      async handleAuthInfoChange(info, value) {
        this.authInfo[info] = value;
        const { email = null, phoneNumber = null } = this.authInfo;

        if (email && phoneNumber) {
          const query = {
            email,
            phoneNumber,
          };

          await this.verifyOtpAccount({ query, router: this.$router });
        }
      },
      handleEditModeState(info, isDisplayed) {
        this.isInfoInEditMode[info] = isDisplayed;
      },
      async submitPreRegister() {
        await this._validateEditModesValues();

        if (this.allowProcessing) {
          await this.preRegister({ router: this.$router });
        }
      },
      // Methods for internal use
      async _validateEditModesValues() {
        if (this.isInfoInEditMode.creditCard) {
          await this.$refs.creditCard?.validateForm(true);
        }

        if (this.isInfoInEditMode.address) {
          await this.$refs.address?.validateForm();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .registration {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;

    &__content {
      @include scrollBarDisplay();
      box-sizing: border-box;
      height: 100%;
      padding: rem(20px);
    }

    &__footer {
      display: flex;
      border-top: 1px solid var(--primary-background);
    }
  }

  .content {
    &__separator {
      display: none;
    }

    &__user-info {
      min-width: 0;
    }

    &__pre-registration {
      display: flex;
      flex-direction: column;
    }
  }

  .pre-registration {
    &__title {
      @include large-heading();
      margin-bottom: rem(16px);

      font-weight: $bold;
      line-height: calc(#{$xx-large-font-size} + 2px);
    }

    &__text {
      @include body-large();
      line-height: rem(24px);
      color: var(--tertiary-raised-text);
    }

    &__countdown {
      margin-top: rem(16px);
      padding: rem(16px) 0;
      border-top: 1px solid var(--primary-background);
      text-align: center;
    }
  }

  .countdown {
    &__title {
      @include heading();
      margin-top: 0;
      margin-bottom: $layout-padding;
      font-weight: $bold;
      color: var(--primary-text);
    }
  }

  .user-info {
    &__loader {
      height: 50%;
    }
  }

  .form {
    &__addresses {
      margin-top: 5px;
    }

    &__addresses,
    &__payment,
    &__shipping,
    &__options,
    &__agreement {
      margin-bottom: rem(25px);
    }

    &__payment {
      border-radius: 13px;
      padding: 16px;
      background: var(--tertiary-background);
    }
  }

  .footer {
    &__brand {
      display: none;
    }

    &__action {
      height: 46px;
      flex: 1;
      padding: rem(24px);
    }
  }

  @include breakpoint(medium) {
    .registration {
      &__content {
        display: flex;
        overflow: hidden;
        margin: 0;
        padding: 0;
      }
    }

    .content {
      &__cart {
        @include scrollBarDisplay();
        display: initial;
        flex: 1 0;
        padding: 0 rem(40px);
      }

      &__separator {
        display: initial;
        flex: initial;
        align-self: center;
        border: none;
        border-right: 1px solid var(--primary-background);
        height: 30vh;
      }

      &__user-info {
        @include scrollBarDisplay();
        flex: 1 0;
        padding: 0 rem(40px);
      }

      &__pre-registration {
        @include scrollBarDisplay();
        flex: 1 0;
        padding: 0 rem(40px);
        height: 100%;
      }
    }

    .pre-registration {
      &__title {
        margin-bottom: rem(24px);
        font-size: $xxx-large-font-size;
        line-height: calc(#{$xxx-large-font-size} + 2px);
      }

      &__countdown {
        margin-top: auto;
        padding: rem(40px) 0;
        border-top: none; // C'est vraiment moche avec comme on a le footer
      }
    }

    .footer {
      &__brand {
        display: flex;
        flex: 1;
        margin-right: 0;
        padding: rem(24px) rem(40px);
      }

      &__action {
        padding: rem(24px) rem(40px);
      }
    }
  }
</style>
