<template>
  <div>
    <FormInput
      class="user-email"
      type="email"
      :disabled="disabled"
      :value="email"
      :label="$t('registration.email.label')"
      :placeholder="$t('registration.email.placeholder')"
      :error="errors.email"
      data-test-id="email-input"
      @value-input="onNewEmailValueChange($event)" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { formValidation } from '@/helpers/contact/contact.helper';

  import debounce from 'lodash/debounce';

  import FormInput from '@/checkout-link/components/form/form-input/FormInput';

  export default {
    name: 'UserEmail',
    components: {
      FormInput,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        email: '',
        errors: {},
        debouncedValidation: null,
      };
    },
    computed: {
      ...mapGetters('ContactModule/UserTemporaryInfoModule', ['getTempEmail']),
      ...mapGetters('FunnelModule', ['hasFunnel']),
    },
    methods: {
      ...mapActions('ContactModule/UserTemporaryInfoModule', ['storeTempEmail', 'cleanTempEmail']),
      // Edit mode
      onNewEmailValueChange(email) {
        this.email = email.replace(/ /g, '+');
        this.debouncedValidation(this.email);
      },
      async validateAndEmit() {
        this.errors = formValidation({
          email: this.email,
        }, true, this.$i18n);

        this.errors.email ? await this.emitInvalidEmail() : await this.emitValidEmail(this.email);
      },
      async emitValidEmail(email) {
        this.$emit('email-changed', email);
        await this.storeTempEmail(email);
      },
      async emitInvalidEmail() {
        this.$emit('email-changed', null);
        await this.cleanTempEmail();
      },
    },
    created() {
      this.debouncedValidation = debounce(this.validateAndEmit, 1000);
    },
    mounted() {
      if (this.$route.query.email || this.getTempEmail) {
        this.onNewEmailValueChange(this.$route.query.email || this.getTempEmail);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .user-email {
    @include bodyWithFooter('container', true);
    height: rem(70px);

    ::v-deep .form-input {
      flex-direction: column;

      &__field {
        &--error {
          border-color: $error !important;
          color: $error;
        }
      }

      &__error-message {
        padding-left: rem(10px);
        margin-bottom: rem(5px);
      }
    }
  }
</style>
