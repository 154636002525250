<template>
  <CheckoutLinkFooter
    ref="footer"
    :class="{ opened: isDetailVisible }"
    :action-text="newActionText"
    :action-amount="price"
    :action-disabled="disabledFooterAction"
    :has-icon="displayIcon"
    :has-amount="displayAmount"
    @click="onMainButtonClick" />
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import { formattedPrice } from '@/helpers/data/data.helper';
  import MobileMixin from '@mixins/isMobile.mixin';
  import { INITIALIZE_CHECKOUT_CLICK } from '@/services/analytics/analytics.event';
  import CheckoutLinkFooter from '@/checkout-link/components/checkout-link-footer/CheckoutLinkFooter';

  export default {
    name: 'CheckoutLinkMainFooter',
    components: {
      CheckoutLinkFooter,
    },
    mixins: [
      MobileMixin,
    ],
    props: {
      actionDisabled: {
        type: Boolean,
        default: false,
      },
      actionText: {
        type: String,
        default: 'checkoutLink.button.pay',
      },
      displayAmount: {
        type: Boolean,
        default: true,
      },
      displayIcon: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        isDetailVisible: false,
      };
    },
    computed: {
      ...mapGetters('CartModule', ['getCurrency', 'getTotalDiscountedCart']),
      ...mapGetters('FunnelModule', ['hasFunnel', 'isTimerExpired', 'isPresellFunnel', 'presellIgnored']),
      price() {
        return formattedPrice(this.getTotalDiscountedCart, this.getCurrency);
      },
      newActionText() {
        if (this.hasFunnel && this.isPresellFunnel && this.presellIgnored && this.isPresellRoute) {
          return this.$t('checkoutLink.button.decline');
        }

        if (this.hasFunnel) {
          return this.$t('checkoutLink.button.pay');
        }

        return this.$t(`${this.actionText}`);
      },
      isConfirmationRoute() {
        return this.$route?.name === 'Confirmation' ||
          this.$route?.name === 'FunnelCheckout' ||
          this.$route?.name === 'FunnelInformation';
      },
      isPresellRoute() {
        return this.$route?.name === 'Presell';
      },
      disabledFooterAction() {
        return this.actionDisabled ||
            (this.hasFunnel && this.isTimerExpired && this.isConfirmationRoute);
      },
    },
    methods: {
      ...mapActions('CartModule', ['addCoupon']),
      ...mapActions('AnalyticsModule', ['addCheckoutEvent']),
      onMainButtonClick() {
        this.$emit('pay');
        this.addCheckoutEvent(INITIALIZE_CHECKOUT_CLICK);
      },
    },
  };
</script>

<style lang="scss" scoped>
.information {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__contact {
    display: none;
  }
}

.welcome {
  &__contact {
    flex-direction: column;
  }
}

.contact {
  width: 100%;

  &__title {
    @include heading();
    margin-bottom: 15px;
    font-weight: $bold;
  }

  &__text {
    display: none;
    width: 100%;
    padding-bottom: 15px;

    @include body-large();
    color: var(--tertiary-raised-text);

    border-bottom: 1px solid var(--product-description-border);
  }

  &__max-order-reached {
    @include body-large;
    color: var(--google-hover);
    width: 100%;

    margin-top: 5px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--product-description-border);
  }
}

.margin-bottom {
  margin-bottom: 15px;
}

.welcome__contact {
  padding-bottom: 0;

  &.opened .contact__text, &.opened .contact__title {
    display: block;
  }
}

.total {
  padding-bottom: 0 !important;

  ::v-deep {
    .checkout-total-flat__details.opened {
      display: none !important;
    }

    .header__title {
      display: none;
    }

    .total__coupon {
      display: none;
    }

    .total-button {
      height: 50px;
      width: 70px;
      align-items: center;
    }
  }
}

.total-detail--mobile {
  display: none;
}

.cart {
  display: none;
  flex-direction: column;
  width: 100%;

  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--product-description-border);

  &__empty {
    background: var(--footer-background) !important;
  }
}

.opened {
  display: block;
}

.checkout-link-footer-header {
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

@include breakpoint(medium) {
  .total-detail--mobile {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
  }

  .information {
    &__container {
      flex-direction: column-reverse;
    }

    &__contact {
      display: block;
      padding-bottom: rem(10px);
    }
  }

  .welcome {
    &__contact {
      display: flex;
      align-items: center;
      text-align: center;
    }
  }

  .contact {
    &__text {
      display: block;
    }
  }

  .total {
    padding-top: 0 !important;

    ::v-deep {
      .checkout-total-flat__details.opened {
        display: flex !important;
      }

      .total__coupon {
        display: block;
      }

      .header__title {
        display: block;
        margin: 0;
        @include heading();
        font-weight: $bold;
      }

      .details__coupon:not(.no-border) {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--product-description-border);
      }
    }
  }

  .cart {
    display: block;

    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}

.countdown {
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
}

.content {
  &__text {
    margin-right: 4px;
    line-height: 24px;
    font-size: 14px;
  }

  &__time {
    color: var(--main-color);
    font-weight: $bold;
    font-size: 14px;
  }
}
</style>
