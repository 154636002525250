import Vue from 'vue';
import vSelect from 'vue-select';

import IconCaret from '@/checkout-link/components/icon/IconCaret';

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: createElement => createElement(IconCaret, { class: 'field__caret' }),
  },
});

Vue.component('VSelect', vSelect);
